import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Switch,
  Select
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import NumberInput from "../atoms/NumberInput";
import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import useMedicationBase from "@/hooks/useMedicationBase";
import {
  IMedicationBaseData,
  MedicationBase
} from "@oben-core-web/models/medication-base";
import { MedicationBaseService } from "@oben-core-web/services/medication-base-service";
import medicationBaseSchema from "@/resolvers/medicationBase";

const AdminMedBaseManagement = () => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { medicationBases, refetch } = useMedicationBase(
    placeBasedCareProvider!.id
  );
  const [selectedMedicationBase, setSelectedMedicationBase] =
    useState<MedicationBase>();
  const columnHeaders: (
    | ColDef<MedicationBase & { actions: any }>
    | (ColDef<MedicationBase & { actions: any }> & {
        children: ColDef<MedicationBase & { actions: any }>[];
      })
  )[] = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.25,
      cellRenderer: ({ data }: any) => (
        <IconButton
          aria-label={`edit-med-base-${data.id}`}
          icon={<EditIcon />}
          variant='ghost'
          onClick={() => {
            setSelectedMedicationBase(data);
            onToggle();
          }}
        />
      )
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 0.25,
      cellRenderer: ({ data }: any) => (
        <Switch
          isChecked={data.enabled}
          onChange={async (event: ChangeEvent<HTMLInputElement>) => {
            const medBaseService = new MedicationBaseService();
            const medBase = MedicationBase.fromMap(data.id, data);
            medBase.enabled = event.target.checked;
            await medBaseService
              .updateMedicationBase(medBase)
              .then(async () => {
                toast({
                  status: "success",
                  description: `Medication Base ${
                    !event.target.checked ? "Enabled" : "Disabled"
                  }`
                });
                await refetch();
              });
          }}
        />
      )
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.8
    },
    {
      field: "defaultDosage.strength",
      headerName: "Strength",
      flex: 0.35
    },
    {
      field: "defaultDosage.durationCount",
      headerName: "Duration",
      flex: 0.35
    },
    {
      field: "defaultDosage.frequencyCount",
      headerName: "Frequency",
      flex: 0.35
    },
    { field: "defaultDosage.frequencyPeriod", headerName: "Period", flex: 0.35 }
  ];

  return (
    <VStack alignItems={"flex-start"} h={"full"}>
      <HStack alignItems={"center"} justifyContent={"space-between"} w={"full"}>
        <Heading fontSize={"large"} as={"h1"}>
          Manage Medication Bases
        </Heading>
        <Button onClick={onToggle} size='sm' colorScheme='blue'>
          Add Medication Base
        </Button>
      </HStack>
      <Box className='ag-theme-quartz' h={"full"} w={"full"}>
        <AgGridReact
          columnDefs={columnHeaders}
          rowData={medicationBases as (MedicationBase & { actions: any })[]}
        />
      </Box>
      {isOpen && (
        <AdminMedBaseForm
          isOpen={isOpen}
          selectedMedicationBase={selectedMedicationBase}
          closeModal={() => {
            if (selectedMedicationBase) {
              setSelectedMedicationBase(undefined);
            }
            onToggle();
          }}
          onSave={() => {
            refetch();
            if (selectedMedicationBase) {
              setSelectedMedicationBase(undefined);
            }
            onToggle();
          }}
        />
      )}
    </VStack>
  );
};

export default AdminMedBaseManagement;

interface IAdminMedBaseForm {
  isOpen: boolean;
  selectedMedicationBase?: MedicationBase;
  closeModal: () => void;
  onSave: () => void;
}

const AdminMedBaseForm = ({
  isOpen,
  selectedMedicationBase,
  closeModal,
  onSave
}: IAdminMedBaseForm) => {
  const toast = useToast();
  const isEditMode = !!selectedMedicationBase;
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty }
  } = useForm<IMedicationBaseData>({
    defaultValues: isEditMode
      ? { id: selectedMedicationBase.id, ...selectedMedicationBase.toJson() }
      : MedicationBase.fromMap("", {
          id: "",
          enabled: true,
          placeBasedCareProvId: placeBasedCareProvider!.id
        }).toJson(),
    resolver: yupResolver(medicationBaseSchema) as any
  });
  const submissionHandler = async (data: IMedicationBaseData) => {
    // if (!isDirty) return;
    try {
      const medBase = MedicationBase.fromMap(data.id ?? "", data);
      const medBaseService = new MedicationBaseService();
      if (isEditMode) {
        await medBaseService.updateMedicationBase(medBase).then(() => {
          toast({
            status: "success",
            description: "Successfully updated Medication Base"
          });
        });
      } else {
        await medBaseService.addMedicationBase(medBase).then(() => {
          toast({
            status: "success",
            description: "Successfully created Medication Base"
          });
        });
      }
      onSave && onSave();
    } catch (e) {
      console.log("Admin MedicationBase Form Error", e);
      toast({
        status: "error",
        description: isEditMode
          ? "Failed to update Medication Base"
          : "Failed to create Medication Base"
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>
            {isEditMode ? "Edit Medication Base" : "Create Medication Base"}
          </ModalHeader>
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel fontSize='small'>Medication Name</FormLabel>
                <Input {...register("name")} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.defaultDosage?.strength}>
                <FormLabel fontSize='small'>Strength (mg)</FormLabel>
                <NumberInput control={control} name='defaultDosage.strength' />
                <FormErrorMessage>
                  {errors.defaultDosage?.strength?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.defaultDosage?.frequencyCount}>
                <FormLabel fontSize='small'>Count</FormLabel>
                <NumberInput
                  control={control}
                  name='defaultDosage.frequencyCount'
                />
                <FormErrorMessage>
                  {errors.defaultDosage?.frequencyCount?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.defaultDosage?.frequencyPeriod}>
                <FormLabel fontSize='small'>Frequency Period</FormLabel>
                <Select {...register("defaultDosage.frequencyPeriod")}>
                  <option value={FreqPeriod.Daily}>{FreqPeriod.Daily}</option>
                  <option value={FreqPeriod.Weekly}>{FreqPeriod.Weekly}</option>
                </Select>
                <FormErrorMessage>
                  {errors.defaultDosage?.frequencyPeriod?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.defaultDosage?.durationCount}>
                <FormLabel fontSize='small'>Duration</FormLabel>
                <NumberInput
                  control={control}
                  name='defaultDosage.durationCount'
                />
                <FormErrorMessage>
                  {errors.defaultDosage?.durationCount?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.enabled}>
                <FormLabel fontSize='small'>Enabled</FormLabel>
                <Switch {...register("enabled")} />
                <FormErrorMessage>{errors.enabled?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              isDisabled={isSubmitting || !isValid || !isDirty}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
