import { object, string } from "yup";

const addressSchema = object({
  street1: string().nullable(),
  street2: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  zip: string().nullable()
});

export default addressSchema;
