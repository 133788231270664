import { DocumentSnapshot } from "firebase/firestore";

import { ServiceLocationService } from "../services/service-location-service";
import { Address } from "./address";

export interface IServiceLocationData {
  // common fields for all "entities"
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  // serviceLocation-specific fields
  screeningsGoal: number;
  shouldExecuteCloudFunction: boolean; // set by Cloud function
  currentRanking: number | null; // set by Cloud Functions. Will be null if function has never run
  totalScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthQualified: number | null; // set by Cloud Functions. Will be null if function has never run
  placeBasedCareProvIds: string[];
}

export class ServiceLocation {
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  screeningsGoal: number;
  shouldExecuteCloudFunction: boolean; // set by Cloud function
  currentRanking: number | null; // set by Cloud Functions. Will be null if function has never run
  totalScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthQualified: number | null; // set by Cloud Functions. Will be null if function has never run
  placeBasedCareProvIds: string[];

  constructor({
    id,
    address,
    businessName,
    enabled,
    screeningsGoal,
    shouldExecuteCloudFunction,
    currentRanking,
    totalScreenings,
    currentMonthScreenings,
    currentMonthQualified,
    placeBasedCareProvIds
  }: IServiceLocationData) {
    this.id = id;
    this.businessName = businessName;
    this.address = address;
    this.enabled = enabled;
    this.screeningsGoal = screeningsGoal;
    this.shouldExecuteCloudFunction = shouldExecuteCloudFunction;
    this.currentRanking = currentRanking;
    this.totalScreenings = totalScreenings;
    this.currentMonthScreenings = currentMonthScreenings;
    this.currentMonthQualified = currentMonthQualified;
    this.placeBasedCareProvIds = placeBasedCareProvIds;
  }

  get remainingScreenings(): number {
    return this.screeningsGoal - (this.currentMonthScreenings ?? 0);
  }

  async updateDb(): Promise<void> {
    const db = new ServiceLocationService();
    await db.updateServiceLocation(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): ServiceLocation {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return ServiceLocation.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ServiceLocation {
    return new ServiceLocation({
      id: id,
      address: Address.fromMap(data["address"] ?? {}),
      businessName: data["businessName"] ?? "",
      enabled: data["enabled"] ?? false,
      screeningsGoal: data["screeningsGoal"] ?? 0,
      shouldExecuteCloudFunction: data["shouldExecuteCloudFunction"] ?? false,
      currentRanking: data["currentRanking"] ?? null,
      totalScreenings: data["totalScreenings"] ?? null,
      currentMonthScreenings: data["currentMonthScreenings"] ?? null,
      currentMonthQualified: data["currentMonthQualified"] ?? null,
      placeBasedCareProvIds: data["placeBasedCareProvIds"] ?? []
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      address: this.address.toJson(),
      businessName: this.businessName,
      enabled: this.enabled,
      screeningsGoal: this.screeningsGoal ? Number(this.screeningsGoal) : 0,
      // "shouldExecuteCloudFunction": this.shouldExecuteCloudFunction, // only set by Cloud Functions
      // "currentRanking": this.currentRanking ? Number(this.currentRanking) : null, // only set by Cloud Functions
      // "totalScreenings": this.totalScreenings ? Number(this.totalScreenings) : null, // only set by Cloud Functions
      // "currentMonthScreenings": this.currentMonthScreenings ? Number(this.currentMonthScreenings) : null, // only set by Cloud Functions
      // "currentMonthQualified": this.currentMonthQualified ? Number(this.currentMonthQualified) : null, // only set by Cloud Functions
      placeBasedCareProvIds: this.placeBasedCareProvIds
    };
  }
}
