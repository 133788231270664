import { object, string, number } from "yup";
import addressSchema from "./address";

const serviceLocationSchema = object({
  id: string().optional(),
  address: addressSchema,
  businessName: string().required(),
  screeningsGoal: number().required()
});

export default serviceLocationSchema;
