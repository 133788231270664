import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { ServiceLocationService } from "@oben-core-web/services/service-location-service";
import { BpReadingService } from "@oben-core-web/services/bp-reading-service";
import { useCallback } from "react";
import { groupBy, reduce } from "lodash";
import { ServiceLocation } from "@oben-core-web/models/service-location";
import { CommunityHealthWorker } from "@oben-core-web/models/community-health-worker";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { DateTime } from "luxon";
import {
  BillableItem,
  BillableItemType
} from "@oben-core-web/models/billable-item";
import { BillStatus } from "@oben-core-web/models/bill-status-change";

export interface IEngagedBarberByCityMetric {
  serviceLocations: ServiceLocation[];
  communityHealthWorkers: CommunityHealthWorker[];
  engagedCHWCount: number;
  totalCHWCount: number;
}

const useProgramManagerQueries = (placeBasedCareProvId: string) => {
  const getBillableItemsByApptStatus = useCallback(async () => {
    if (!placeBasedCareProvId) return;
    const billableItemService = new BillableItemService();
    // get billableItems for this year
    const now = DateTime.now();
    const billableItems = await billableItemService.getAllBillableItems({
      placeBasedCareProvId,
      start: now.set({ year: now.get("year"), month: 0, day: 1 }).toJSDate(),
      end: null
    });
    // map in most recent status
    const billableItemsWithMostRecentStatus = billableItems.map((bi) => {
      const billStatus =
        bi.currentStatus ??
        bi.billStatusChanges.toSorted((a, b) => (a.date < b.date ? 1 : -1))[0]
          ?.status;
      return {
        ...bi,
        billStatus:
          billStatus === BillStatus.New
            ? "Pending Approval"
            : billStatus === BillStatus.RejectedByPBCP
            ? "Rejected by Care Team"
            : billStatus
      } as BillableItem & { billStatus: string };
    });

    // group by paymentStatus
    const groupedBillableItems = groupBy(
      billableItemsWithMostRecentStatus,
      "billStatus"
    );
    // create subgroupings for each appointment status
    const paymentStatusesByApptStatus = reduce(
      groupedBillableItems,
      (a, value, status) => {
        const apptStatusGroups = groupBy(value, (ebi) => {
          return ebi.billableItemType === BillableItemType.PrimaryService
            ? "Haircut"
            : ebi.billableItemType === BillableItemType.CHWService
            ? "CHW Reimbursement"
            : ebi.billableItemType === BillableItemType.EnrollmentIncentive
            ? "Enrollment Incentive"
            : ebi.description.includes("no-show")
            ? "No show"
            : ebi.description.includes("cancelled during")
            ? "No show"
            : ebi.description.includes("cancelled within")
            ? "Cancellation"
            : ebi.description.includes("Appointment started") ||
              ebi.description.includes("Haircut")
            ? "Haircut"
            : ebi.description.includes("CHW")
            ? "CHW Reimbursement"
            : "ERROR";
        });
        const apptStatusesByBillStatus: Record<
          string,
          {
            billableItems: (BillableItem & { billStatus: string })[];
            total: number;
          }
        > = {};
        for (const apptStatus in apptStatusGroups) {
          const apptGroupRow = {
            billableItems: apptStatusGroups[apptStatus],
            total: apptStatusGroups[apptStatus].reduce((a, c) => {
              return a + c.amount;
            }, 0)
          };
          apptStatusesByBillStatus[apptStatus] = apptGroupRow;
        }

        a[status] = apptStatusesByBillStatus;
        return a;
      },
      {} as Record<
        string,
        Record<
          string,
          {
            billableItems: (BillableItem & { billStatus: string })[];
            total: number;
          }
        >
      >
    );
    return paymentStatusesByApptStatus;
  }, [placeBasedCareProvId]);

  const getCHWsByStripeStatus = useCallback(async () => {
    if (!placeBasedCareProvId) return;
    const chwService = new CommunityHealthWorkerService();
    const communityHealthWorkers =
      await chwService.getCHWsByPlaceBasedCareProvId(placeBasedCareProvId);
    return groupBy(communityHealthWorkers, "stripeStatus");
  }, [placeBasedCareProvId]);

  const getCHWEngagementByCity = useCallback(async () => {
    if (!placeBasedCareProvId) return;

    const communityHealthWorkershopService = new ServiceLocationService();
    const chwService = new CommunityHealthWorkerService();
    const bpReadingService = new BpReadingService();
    const serviceLocations =
      await communityHealthWorkershopService.getPlaceBasedCareProvServiceLocations(
        placeBasedCareProvId
      );
    const communityHealthWorkers =
      await chwService.getCHWsByPlaceBasedCareProvId(placeBasedCareProvId);
    const communityHealthWorkershopGroups = groupBy(
      serviceLocations,
      "address.city"
    );

    const metricResults = reduce(
      communityHealthWorkershopGroups,
      (a, value, key) => {
        const cityKey = key === "" ? "[No City]" : key;
        if (!a[cityKey]) {
          a[cityKey] = {
            serviceLocations: [],
            communityHealthWorkers: [],
            engagedCHWCount: 0,
            totalCHWCount: 0
          };
        }
        a[cityKey].serviceLocations = value;
        return a;
      },
      {} as Record<string, IEngagedBarberByCityMetric>
    );

    for (const city in metricResults) {
      // match communityHealthWorkers to city based on currentServiceLocationId
      const communityHealthWorkersInCity = communityHealthWorkers.filter((b) =>
        metricResults[city].serviceLocations
          .map((bs) => bs.id)
          .includes(b.currentServiceLocationId)
      );
      let engagedCount = 0;
      if (communityHealthWorkersInCity.length > 0) {
        // get count of readings by each set of communityHealthWorkers
        engagedCount = await bpReadingService.getBpReadingCountByCHWs({
          chwIds: communityHealthWorkersInCity.map((b) => b.uid)
        });
      }
      // update results
      metricResults[city].communityHealthWorkers = communityHealthWorkersInCity;
      metricResults[city].engagedCHWCount = engagedCount;
      metricResults[city].totalCHWCount = communityHealthWorkersInCity.length;
    }
    return metricResults;
  }, [placeBasedCareProvId]);

  return {
    getCHWEngagementByCity,
    getCHWsByStripeStatus,
    getBillableItemsByApptStatus
  };
};

export default useProgramManagerQueries;
