import { object, string, number, boolean, array } from "yup";
import userNameSchema from "./userName";
import { UserType } from "@oben-core-web/constants/core-enums";
import addressSchema from "./address";

const staffMemberSchema = object({
  uid: string().required(),
  name: userNameSchema,
  email: string().email().required(),
  phoneNumber: string().nullable(),
  enabled: boolean().required(),
  pwdNeedsReset: boolean().required(),
  userType: string().oneOf([
    UserType.Pharmacist,
    UserType.Physician,
    UserType.ProgramManager
  ]),
  address: addressSchema.nullable(),
  npi: string().nullable(),
  placeBasedCareProvId: string().required(),
  defaultWorkingHours: array().of(
    object({
      dayOfWeek: number().required(),
      startTime: string().required(),
      endTime: string().required()
    })
  ),
  isProgramAdmin: boolean().required()
});

export default staffMemberSchema;
