import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { ASCVDRisk } from '../models/ascvd-risk';
import { GaLog } from '../utils/ga-log';

export class ASCVDRiskService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'ascvdRisks';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all BP readings for a specified patient  
  // TODO: Implement optional filtering
  async getPatientASCVDRisks(patientId: string): Promise<ASCVDRisk[]> {
    const queryRef = query(
      this._collectionReference,
      where('patientId', '==', patientId),
      orderBy('readingDate', 'desc')
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ASCVDRisk.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getASCVDRisk(ascvdRiskId: string): Promise<ASCVDRisk> {
    try {
      const docRef = doc(this._collectionReference, ascvdRiskId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`ASCVD Risk not found: ${ascvdRiskId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return ASCVDRisk.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addASCVDRisk(ascvdRisk: ASCVDRisk): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, ascvdRisk.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateASCVDRisk(ascvdRisk: ASCVDRisk): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, ascvdRisk.id);
      await updateDoc(docRef, ascvdRisk.toJson());
      GaLog.updateDocument(this._collectionReference.path, ascvdRisk.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // ASCVDRisks can only be updated, not deleted
  // async deleteASCVDRisk(ascvdRiskId: string): Promise<void> {} 
}
