import { DocumentSnapshot } from "firebase/firestore";

import { AppointmentService } from "../services/appointment-service";
import {
  AppointmentStatus,
  AppointmentStatusHelper,
  AppointmentStatusChange
} from "./appointment-status-change";
import { UserName } from "./user-name";

export interface IAppointmentData {
  id: string;
  serviceLocationId: string;
  serviceLocationName: string;
  chwId: string;
  chwName: UserName;
  patientId: string;
  patientName: UserName;
  pharmacistId: string;
  pharmacistName: UserName;
  date: Date | null;
  length: number;
  billableEventId: string;
  outreachId: string | null;
  currentStatus: AppointmentStatus;
  statusChanges: AppointmentStatusChange[];
  placeBasedCareProvId: string;
}

export class Appointment {
  id: string;
  serviceLocationId: string;
  serviceLocationName: string;
  chwId: string;
  chwName: UserName;
  patientId: string;
  patientName: UserName;
  pharmacistId: string;
  pharmacistName: UserName;
  date: Date | null;
  length: number;
  billableEventId: string;
  outreachId: string | null;
  currentStatus: AppointmentStatus;
  statusChanges: AppointmentStatusChange[];
  placeBasedCareProvId: string;

  constructor({
    id,
    serviceLocationId,
    serviceLocationName,
    chwId,
    chwName,
    patientId,
    patientName,
    pharmacistId,
    pharmacistName,
    date,
    length,
    billableEventId,
    outreachId,
    currentStatus,
    statusChanges,
    placeBasedCareProvId
  }: IAppointmentData) {
    this.id = id;
    this.serviceLocationId = serviceLocationId;
    this.serviceLocationName = serviceLocationName;
    this.chwId = chwId;
    this.chwName = chwName;
    this.patientId = patientId;
    this.patientName = patientName;
    this.pharmacistId = pharmacistId;
    this.pharmacistName = pharmacistName;
    this.date = date;
    this.length = length;
    this.billableEventId = billableEventId;
    this.outreachId = outreachId;
    this.currentStatus = currentStatus;
    this.statusChanges = statusChanges;
    this.placeBasedCareProvId = placeBasedCareProvId
  }

  async updateDb(): Promise<void> {
    const db = new AppointmentService();
    await db.updateAppointment(this);
  }

  updateState(appt: Appointment) {
    this.id = appt.id;
    this.serviceLocationId = appt.serviceLocationId;
    this.serviceLocationName = appt.serviceLocationName;
    this.chwId = appt.chwId;
    this.chwName = appt.chwName;
    this.patientId = appt.patientId;
    this.patientName = appt.patientName;
    this.pharmacistId = appt.pharmacistId;
    this.pharmacistName = appt.pharmacistName;
    this.date = appt.date;
    this.length = appt.length;
    this.billableEventId = appt.billableEventId;
    this.outreachId = appt.outreachId;
    this.currentStatus = appt.currentStatus;
    this.statusChanges = appt.statusChanges;
    this.placeBasedCareProvId = appt.placeBasedCareProvId
  }

  static fromFirestore(docSnap: DocumentSnapshot): Appointment {
    const data = docSnap.data() as { [key: string]: any };
    return Appointment.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Appointment {
    return new Appointment({
      id: id,
      serviceLocationId: data["serviceLocationId"] ?? "",
      serviceLocationName: data["serviceLocationName"] ?? "",
      chwId: data["chwId"] ?? "",
      chwName: UserName.fromMap(data["chwName"] ?? {}),
      patientId: data["patientId"] ?? "",
      patientName: UserName.fromMap(data["patientName"] ?? {}),
      pharmacistId: data["pharmacistId"] ?? "",
      pharmacistName: UserName.fromMap(data["pharmacistName"] ?? {}),
      date: data["date"] ? new Date(data["date"]) : null,
      length: data["length"] ?? 0,
      billableEventId: data["billableEventId"] ?? "",
      outreachId: data["outreachId"] ?? null,
      currentStatus:
        AppointmentStatusHelper.valueOf(data["currentStatus"] ?? "") ??
        AppointmentStatus.Unknown,
      statusChanges: data["statusChanges"] //
        ? data["statusChanges"].map((element: any) =>
            AppointmentStatusChange.fromMap(element)
          )
        : [],
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      serviceLocationId: this.serviceLocationId,
      serviceLocationName: this.serviceLocationName,
      chwId: this.chwId,
      chwName: this.chwName.toJson(),
      patientId: this.patientId,
      patientName: this.patientName.toJson(),
      pharmacistId: this.pharmacistId,
      pharmacistName: this.pharmacistName.toJson(),
      date: this.date?.toISOString() ?? null,
      length: Number(this.length),
      billableEventId: this.billableEventId,
      outreachId: this.outreachId ?? null,
      currentStatus: this.currentStatus,
      statusChanges: this.statusChanges.map((element) => element.toJson()),
      placeBasedCareProvId: this.placeBasedCareProvId
    };
  }
}
