import useAppointments from "@/hooks/useAppointments";
import useCurrentUser from "@/hooks/useCurrentUser";
import { DateTime } from "luxon";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { Appointment } from "@oben-core-web/models/appointment";
import { transformToLabel } from "@/lib/enumUtils";
import {
  Heading,
  Link as ChakraLink,
  IconButton,
  HStack,
  Tooltip,
  Box,
  BoxProps
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { MdCalendarMonth, MdPerson } from "react-icons/md";
import { startCase } from "lodash";
import { UserType } from "@oben-core-web/constants/core-enums";
import { useEffect, useMemo, useState } from "react";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";

interface IUpcomingAppointments extends BoxProps {}
const UpcomingAppointments = (props: IUpcomingAppointments) => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [staffMemberIds, setStaffMemberIds] = useState<string[]>([]);
  const memoizedStart = useMemo(() => {
    return DateTime.now().startOf("day").toJSDate();
  }, []);
  const memoizedEnd = useMemo(() => {
    return DateTime.now().startOf("day").plus({ days: 30 }).toJSDate();
  }, []);
  const { appointments } = useAppointments({
    pharmacistId:
      currentUser?.userType !== UserType.ProgramManager
        ? currentUser?.uid ?? ""
        : staffMemberIds,
    start: memoizedStart,
    end: memoizedEnd,
    statuses: [AppointmentStatus.New, AppointmentStatus.Confirmed]
  });
  useEffect(() => {
    const fetchStaffMemberIds = async () => {
      if (
        currentUser?.userType === UserType.ProgramManager &&
        currentUser?.placeBasedCareProvId &&
        staffMemberIds.length === 0
      ) {
        const staffMemberService = new StaffMemberService();
        const wus = await staffMemberService.getPlaceBasedCareProvStaffMembers(
          currentUser?.placeBasedCareProvId
        );
        setStaffMemberIds(
          wus
            .filter(
              (wu) =>
                wu.userType === UserType.Pharmacist ||
                wu.userType === UserType.Physician
            )
            .map((u) => u.uid)
        );
      }
    };
    fetchStaffMemberIds();
  }, [currentUser, staffMemberIds]);
  const rowData = appointments.map((appt) => {
    const latestStatusChange = getLatestStatusChange(appt);
    const statusLabel = latestStatusChange
      ? transformToLabel(latestStatusChange.status)
      : "Unknown";
    return {
      appointment: appt,
      date: DateTime.fromJSDate(appt.date!).toFormat("D"),
      time: DateTime.fromJSDate(appt.date!).toFormat("t"),
      patient:
        appt.patientName.fullName ??
        appt.patientName.first + " " + appt.patientName.last,
      patientId: appt.patientId,
      chwName: appt.chwName.fullName,
      serviceLocation: appt.serviceLocationName,
      pharmacist: appt.pharmacistName.fullName,
      status:
        statusLabel === "New"
          ? "Unconfirmed"
          : statusLabel === "Late Canceled"
          ? "Cancelled"
          : startCase(statusLabel)
    };
  });

  const columnDefs: ColDef[] = [
    { field: "date", headerName: "Date", minWidth: 100, flex: 0.4 },
    { field: "time", headerName: "Time", minWidth: 100, flex: 0.4 },
    {
      field: "patient",
      headerName: "Patient",
      minWidth: 100,
      flex: 0.9,
      cellRenderer: (params: any) => (
        <ChakraLink as={Link} to={`/patient/${params.data.patientId}`}>
          {params.data.patient}
        </ChakraLink>
      )
    },
    {
      field: "chwName",
      headerName: "Community Health Worker",
      minWidth: 100,
      flex: 0.8
    },
    {
      field: "serviceLocation",
      headerName: "Service Location",
      minWidth: 100,
      flex: 0.8
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.5,
      valueFormatter: (row) => startCase(row.data.status)
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.4,
      cellRenderer: (params: any) => {
        return (
          <HStack>
            <Tooltip label={"Go to Appointment"}>
              <IconButton
                onClick={() => {
                  const { appointment } = params.data;
                  if (!appointment) return;
                  navigate("/appointments", {
                    state: {
                      appointment: {
                        ...appointment,
                        startDateTime: appointment.date,
                        endDateTime: DateTime.fromJSDate(appointment.date)
                          .plus({ minutes: appointment.length })
                          .toJSDate()
                      },
                      isOpen: true
                    },
                    replace: true
                  });
                }}
                variant={"ghost"}
                aria-label={`nav-to-appointment-${params.data.id}`}
                icon={<MdCalendarMonth size={24} />}
              />
            </Tooltip>
            <Tooltip label={"Go to Patient"}>
              <IconButton
                onClick={() => {
                  const { appointment } = params.data;
                  if (!appointment) return;
                  navigate(`/patient/${params.data.patientId}`);
                }}
                variant={"ghost"}
                aria-label={`nav-to-patient-${params.data.patientId}`}
                icon={<MdPerson size={24} />}
              />
            </Tooltip>
          </HStack>
        );
      }
    }
  ];
  if (currentUser?.userType === UserType.ProgramManager) {
    columnDefs.splice(5, 0, {
      field: "pharmacist",
      headerName: "Pharmacist",
      flex: 0.8
    });
  }
  return (
    <Box
      className='ag-theme-quartz upcoming-appointments-table'
      w={"full"}
      {...props}
    >
      <Heading fontSize={"lg"} my={2}>
        Upcoming Appointments
      </Heading>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs as any}
        rowSelection='single'
        // onRowSelected={handleRowSelected}
        // onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
        // autoSizeStrategy={{
        //   type: "fitGridWidth",
        //   defaultMinWidth: 100
        //   // width: columnDefs.reduce((a, c) => {
        //   //   return a + c.width;
        //   // }, 0)
        // }}
      />
    </Box>
  );
};

export default UpcomingAppointments;

const getLatestStatusChange = (appointment: Appointment) => {
  const { statusChanges, currentStatus } = appointment;
  if (currentStatus) return { status: currentStatus };
  const sortedChanges = statusChanges.toSorted((a, b) => {
    return DateTime.fromJSDate(a.date) < DateTime.fromJSDate(b.date) ? -1 : 1;
  });
  return sortedChanges[0];
};
