import React from "react";
import { ColDef } from "ag-grid-community";
import { Box, BoxProps, IconButton } from "@chakra-ui/react";
import { BsPencil } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
// import useClaims from "@/hooks/useClaims";
import { DateTime } from "luxon";
import { Claim } from "@oben-core-web/models/claim";
import { startCase } from "lodash";

interface ICHWClaimTable extends BoxProps {
  claims: Claim[];
  onClaimSelected: (claim: any) => void;
}

const CHWClaimTable = ({
  claims,
  onClaimSelected,
  ...props
}: ICHWClaimTable) => {
  const colDefs: ColDef[] = [
    {
      field: "patientName",
      headerName: "Patient Name",
      valueFormatter: ({ data }) => {
        return data.patientName.first + " " + data.patientName.last;
      },
      minWidth: 100,
      flex: 1
    },
    {
      field: "renderingProvName",
      headerName: "Barber Name",
      valueFormatter: ({ data }) => {
        return data.renderingProvName.first + " " + data.renderingProvName.last;
      },
      minWidth: 100,
      flex: 1
    },
    {
      field: "serviceDate",
      headerName: "Appointment Date",
      valueFormatter: ({ data }) => {
        return DateTime.fromJSDate(data.serviceDate).toFormat("D");
      },
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "payerBusinessName",
      headerName: "Insurance",
      minWidth: 100,
      flex: 0.75
    },
    {
      field: "currentStatus",
      headerName: "Status",
      minWidth: 100,
      flex: 0.75,
      valueFormatter: ({ data }) => {
        return startCase(data.currentStatus);
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ({ data }: { data: any }) => {
        return (
          <IconButton
            variant='link'
            icon={<BsPencil />}
            color='black'
            aria-label={`approve-claim-btn-${data.id}`}
            onClick={() => onClaimSelected(data)}
          />
        );
      },
      minWidth: 100,
      flex: 0.4
    }
  ];

  return (
    <Box className='ag-theme-quartz' {...props}>
      <AgGridReact
        className='program-manager-patient-scheduling-table'
        rowData={claims}
        columnDefs={colDefs}
        rowSelection='single'
      />
    </Box>
  );
};

export default CHWClaimTable;
