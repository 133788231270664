import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { Patient } from "@oben-core-web/models/patient";
import { PendingPatient } from "@oben-core-web/models/pending-patient";
import { DateTime } from "luxon";
import { Outreach, OutreachStatus } from "@oben-core-web/models/outreach";
import { startCase } from "lodash";
import { IconButton } from "@chakra-ui/react";
import { MdPhone } from "react-icons/md";
import { parsePhoneNumber } from "@/lib/parseUtils";
import useServiceLocations from "@/hooks/useServiceLocations";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

interface IPatientEnrollmentTable {
  enrollmentPatients: {
    patient: Patient;
    enrollmentOutreach: Outreach | null;
  }[];
  setEnrollmentPatient: React.Dispatch<
    React.SetStateAction<Patient | PendingPatient | undefined>
  >;
}

const PatientEnrollmentTable = ({
  enrollmentPatients,
  setEnrollmentPatient
}: IPatientEnrollmentTable) => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { serviceLocations } = useServiceLocations(placeBasedCareProvider!.id);
  const patientData = enrollmentPatients.map((p) => ({
    ...p,
    name: p.patient.name ? p.patient.name.fullName : "Unnamed",
    dob: p.patient.dob,
    phone: p.patient.phoneNumber ? parsePhoneNumber(p.patient.phoneNumber) : "",
    registrationDate: p.patient.registrationDate,
    // email: p.patient.email,
    lastBpReading:
      p.patient.latestBpReading?.systolic +
      "/" +
      p.patient.latestBpReading?.diastolic,
    readingLocation: p.patient.latestBpReading?.locationId
      ? serviceLocations.find(
          (bs) => bs.id === p.patient.latestBpReading!.locationId
        )?.businessName
      : "Home reading",
    outreachStatus: p.enrollmentOutreach?.status
      ? p.enrollmentOutreach.status !== OutreachStatus.Unknown
        ? startCase(p.enrollmentOutreach?.status)
        : "New"
      : "New",
    lastOutreachAttemptStatus: startCase(
      p.enrollmentOutreach?.attempts[p.enrollmentOutreach?.attempts.length - 1]
        ?.result
    )
  }));

  const columnHeaders: ColDef[] = [
    { field: "name", headerName: "Name", minWidth: 100, flex: 1 },
    {
      field: "phone",
      headerName: "Phone",
      valueFormatter: ({ value }) => {
        const cleaned = ("" + value).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value;
      },
      minWidth: 100,
      flex: 0.5
    },
    // { field: "email", headerName: "Email", minWidth:100,flex: 1 },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      valueFormatter: ({ value }) => {
        return DateTime.fromJSDate(value).toFormat("D");
      },
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "lastBpReading",
      headerName: "Last BP Reading",
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "readingLocation",
      headerName: "Reading Location",
      minWidth: 100,
      flex: 0.6
    },
    {
      field: "outreachStatus",
      headerName: "Last Outreach Status",
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "lastOutreachAttemptStatus",
      headerName: "Last Outreach Attempt Result",
      minWidth: 100,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: (row: any) => {
        return (
          <IconButton
            aria-label={`patient-enrollment-btn-${row.data.uid}`}
            onClick={() => {
              setEnrollmentPatient(
                row.data.patient.uid
                  ? Patient.fromMap(row.data.patient.uid, row.data.patient)
                  : PendingPatient.fromMap(row.data.id, row.data)
              );
            }}
            rounded={"xl"}
            variant={"ghost"}
            size={"xs"}
            // colorScheme='teal'
            icon={<MdPhone size={20} />}
          />
        );
      },
      minWidth: 100,
      flex: 0.5
    }
  ];

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        className='enrollment-table'
        rowData={patientData}
        columnDefs={columnHeaders}
        // onRowClicked={(clickEvent) => {
        //   console.log({ data: clickEvent.data });
        //   setEnrollmentPatient(
        //     clickEvent.data.patient.uid
        //       ? Patient.fromMap(
        //           clickEvent.data.patient.uid,
        //           clickEvent.data.patient
        //         )
        //       : PendingPatient.fromMap(clickEvent.data.id, clickEvent.data)
        //   );
        // }}
      />
    </div>
  );
};

export default PatientEnrollmentTable;
