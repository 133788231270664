import { useState, useEffect } from "react";
import { MedAdherenceService } from "@oben-core-web/services/med-adherence-service";
import { MedAdherence } from "@oben-core-web/models/med-adherence";

const useMedAdherence = ({
  patientId,
  carePlanId
}: {
  patientId: string;
  carePlanId: string;
}) => {
  const [medAdherences, setMedAdherences] = useState<MedAdherence[]>([]);
  useEffect(() => {
    const fetchAdherences = async () => {
      const medAdherenceService = new MedAdherenceService();
      const adherences = await medAdherenceService.getPatientMedAdherences(
        patientId,
        carePlanId
      );
      setMedAdherences(adherences);
    };
    fetchAdherences();
  }, [patientId, carePlanId]);
  return { medAdherences };
};

export default useMedAdherence;
