import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import useWebProgramUsers from "@/hooks/useProgramStaffMembers";
import useStaffMembers from "@/hooks/useStaffMembers";
import { EditIcon } from "@chakra-ui/icons";
import {
  Center,
  Text,
  VStack,
  Heading,
  Box,
  SimpleGrid,
  Button,
  HStack,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Switch,
  Divider,
  Checkbox,
  useToast
} from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import {
  IPlaceBasedCareProvData,
  PlaceBasedCareProv
} from "@oben-core-web/models/place-based-care-prov";
import React from "react";
import { useForm } from "react-hook-form";
import CurrencyInput from "../atoms/CurrencyInput";
import NumberInput from "../atoms/NumberInput";
import { PlaceBasedCareProvService } from "@oben-core-web/services/place-based-care-prov-service";

const AdminProgramManagement = ({
  handleTabsChange
}: {
  handleTabsChange: (index: number) => void;
}) => {
  const { isOpen: editMode, onToggle: toggleEditMode } = useDisclosure();
  const { placeBasedCareProvider, refetch } = usePlaceBasedCareProvider();
  const { staffMembers } = useStaffMembers([UserType.Physician]);

  if (!placeBasedCareProvider) {
    return (
      <Center h={"full"} w={"full"}>
        No Place Based Care Provider Found
      </Center>
    );
  }
  return (
    <VStack alignItems={"flex-start"} w={"full"}>
      <HStack w={"50%"} justifyContent={"space-between"}>
        <VStack alignItems={"flex-start"}>
          <Heading fontSize={"large"} as={"h1"}>
            {placeBasedCareProvider.businessName}
          </Heading>
          <Text>{placeBasedCareProvider.address.completeAddress}</Text>
        </VStack>
        <IconButton
          aria-label={`edit-admin-program-btn`}
          icon={<EditIcon />}
          variant='ghost'
          onClick={toggleEditMode}
        />
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <VStack w={"50%"}>
          <Box border={"1px solid gray"} rounded={"md"} p={2} w={"full"}>
            <Heading as={"h2"} mb={2} fontSize='medium'>
              Program Settings
            </Heading>
            <SimpleGrid columns={2}>
              <Text fontSize='small'>CHW Service Payment Amount</Text>
              <Text textAlign={"right"}>
                $
                {(placeBasedCareProvider.chwServicePaymentAmount / 100).toFixed(
                  2
                )}
              </Text>

              <Text fontSize='small'>Enrollment Incentive Amount</Text>
              <Text textAlign={"right"}>
                $
                {(
                  placeBasedCareProvider.enrollmentIncentivePaymentAmount / 100
                ).toFixed(2)}
              </Text>

              <Text fontSize='small'>Funds non-Medicaid CHW Services</Text>
              <Text textAlign={"right"}>
                {String(placeBasedCareProvider.fundsNonMedicaidCHWServices)}
              </Text>

              <Text fontSize='small'>Program BP Goal</Text>
              <Text textAlign={"right"}>
                {placeBasedCareProvider.programBpGoal.systolic +
                  "/" +
                  placeBasedCareProvider.programBpGoal.diastolic}
              </Text>

              <Text fontSize='small'>Supervising Physician</Text>
              <Text textAlign={"right"}>
                {
                  staffMembers.find(
                    (wu) => wu.uid === placeBasedCareProvider.supervisingPhysId
                  )?.name.fullName
                }
              </Text>
            </SimpleGrid>
          </Box>

          <Box border={"1px solid gray"} rounded={"md"} p={2} w={"full"}>
            <Heading as={"h2"} mb={2} fontSize='medium'>
              Billing Provider
            </Heading>
            <SimpleGrid columns={2}>
              <Text fontSize='small'>Name</Text>
              <Text textAlign={"right"}>
                {placeBasedCareProvider.billingProvBusinessName}
              </Text>

              <Text fontSize='small'>Address</Text>
              <Text textAlign={"right"}>
                {placeBasedCareProvider.billingProvAddress.completeAddress}
              </Text>

              <Text fontSize='small'>Tax Identification Number</Text>
              <Text textAlign={"right"}>
                {placeBasedCareProvider.billingProvTIN}
              </Text>

              <Text fontSize='small'>NPI</Text>
              <Text textAlign={"right"}>
                {placeBasedCareProvider.billingProvNPI}
              </Text>
            </SimpleGrid>
          </Box>
        </VStack>
        <VStack w={"50%"}>
          <Button
            size='sm'
            colorScheme='blue'
            onClick={() => handleTabsChange(1)}
          >
            Manage Users
          </Button>
          <Button
            size='sm'
            colorScheme='blue'
            onClick={() => handleTabsChange(2)}
          >
            Manage Barbershops
          </Button>
          <Button
            size='sm'
            colorScheme='blue'
            onClick={() => handleTabsChange(3)}
          >
            Manage Barbers
          </Button>
        </VStack>
      </HStack>
      {editMode && (
        <EditProgramForm
          isOpen={editMode}
          closeModal={toggleEditMode}
          onSave={async () => {
            await refetch();
            toggleEditMode();
          }}
          placeBasedCareProvider={placeBasedCareProvider}
        />
      )}
    </VStack>
  );
};

export default AdminProgramManagement;

interface IEditProgramForm {
  placeBasedCareProvider: PlaceBasedCareProv;
  isOpen: boolean;
  closeModal: () => void;
  onSave: () => void;
}

const EditProgramForm = ({
  placeBasedCareProvider,
  isOpen,
  closeModal,
  onSave
}: IEditProgramForm) => {
  const toast = useToast();
  const { isOpen: useProgramDetails, onToggle: toggleUseProgramDetails } =
    useDisclosure({ defaultIsOpen: true });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm<IPlaceBasedCareProvData>({
    defaultValues: placeBasedCareProvider.toJson()
  });
  const { staffMembers } = useWebProgramUsers(placeBasedCareProvider.id);
  const physicians = staffMembers.filter(
    (wu) => wu.userType === UserType.Physician
  );
  const submissionHandler = async (data: IPlaceBasedCareProvData) => {
    if (!isDirty) return;
    const placeBasedCareProv = PlaceBasedCareProv.fromMap(
      placeBasedCareProvider.id ?? "",
      data
    );
    const placeBasedCareProvService = new PlaceBasedCareProvService();
    await placeBasedCareProvService
      .updatePlaceBasedCareProv(placeBasedCareProv)
      .then(() => {
        toast({
          status: "success",
          description: "Successfully updated Program"
        });
      })
      .catch((e) => {
        console.log("Failed to update Place Based Care Provider", e);
        toast({ status: "error", description: "Failed to update Program" });
      });
    onSave && onSave();
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalCloseButton />
          <ModalHeader>Edit Program</ModalHeader>
          <ModalBody>
            <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
              <VStack
                border={"1px solid lightgray"}
                rounded={"lg"}
                p={4}
                alignItems={"flex-start"}
              >
                <Heading as='h2' fontSize='medium'>
                  Program
                </Heading>
                <FormControl isInvalid={!!errors.businessName}>
                  <FormLabel fontSize='small'>Business Name</FormLabel>
                  <Input {...register("businessName")} />
                  <FormErrorMessage>
                    {errors.businessName?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.supervisingPhysId}>
                  <FormLabel fontSize='small'>Supervising Physician</FormLabel>
                  <Select
                    placeholder='Select a Physician'
                    {...register("supervisingPhysId")}
                  >
                    {physicians.map((phys) => (
                      <option
                        key={`admin-prgrm-physician-option-${phys.uid}`}
                        value={phys.uid}
                      >
                        {phys.name.fullName}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.supervisingPhysId?.message}
                  </FormErrorMessage>
                </FormControl>
                <Divider my={2} />
                <VStack alignItems={"flex-start"}>
                  <Heading as='h3' fontSize='medium'>
                    Address
                  </Heading>
                  <FormControl isInvalid={!!errors.address?.street1}>
                    <FormLabel fontSize='small'>Street 1</FormLabel>
                    <Input {...register("address.street1")} />
                    <FormErrorMessage>
                      {errors.address?.street1?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.address?.street2}>
                    <FormLabel fontSize='small'>Street 2</FormLabel>
                    <Input {...register("address.street2")} />
                    <FormErrorMessage>
                      {errors.address?.street2?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack>
                    <FormControl w={"50%"} isInvalid={!!errors.address?.city}>
                      <FormLabel fontSize='small'>City</FormLabel>
                      <Input {...register("address.city")} />
                      <FormErrorMessage>
                        {errors.address?.city?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl w={"25%"} isInvalid={!!errors.address?.state}>
                      <FormLabel fontSize='small'>State</FormLabel>
                      <Input {...register("address.state")} />
                      <FormErrorMessage>
                        {errors.address?.state?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl w={"25%"} isInvalid={!!errors.address?.zip}>
                      <FormLabel fontSize='small'>Zip Code</FormLabel>
                      <Input {...register("address.zip")} />
                      <FormErrorMessage>
                        {errors.address?.zip?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </VStack>
                <Divider my={2} />
                <VStack alignItems={"flex-start"}>
                  <Heading as='h3' fontSize='medium'>
                    Program Blood Pressure Goal
                  </Heading>
                  <HStack>
                    <FormControl
                      w={"25%"}
                      isInvalid={!!errors.programBpGoal?.systolic}
                    >
                      <FormLabel fontSize='small'>Systolic</FormLabel>
                      <NumberInput
                        name='programBpGoal.systolic'
                        control={control}
                      />
                      <FormErrorMessage>
                        {errors.programBpGoal?.systolic?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w={"25%"}
                      isInvalid={!!errors.programBpGoal?.diastolic}
                    >
                      <FormLabel fontSize='small'>Diastolic</FormLabel>
                      <NumberInput
                        name='programBpGoal.diastolic'
                        control={control}
                      />
                      <FormErrorMessage>
                        {errors.programBpGoal?.diastolic?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w={"50%"}
                      isInvalid={!!errors.programBpGoal?.setDate}
                    >
                      <FormLabel fontSize='small'>Set Date</FormLabel>
                      <Input
                        {...register("programBpGoal.setDate")}
                        type='date'
                      />
                      <FormErrorMessage>
                        {errors.programBpGoal?.setDate?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </VStack>
                <Divider my={2} />
                <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                  <Heading as='h3' fontSize='medium'>
                    Payments
                  </Heading>
                  <HStack w={"full"}>
                    <FormControl isInvalid={!!errors.chwServicePaymentAmount}>
                      <FormLabel fontSize='small'>CHW Service Fee</FormLabel>
                      <CurrencyInput
                        name='chwServicePaymentAmount'
                        control={control}
                      />
                      <FormErrorMessage>
                        {errors.chwServicePaymentAmount?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!errors.enrollmentIncentivePaymentAmount}
                    >
                      <FormLabel fontSize='small'>
                        CHW Enrollment Incentive
                      </FormLabel>
                      <CurrencyInput
                        name='enrollmentIncentivePaymentAmount'
                        control={control}
                      />
                      <FormErrorMessage>
                        {errors.enrollmentIncentivePaymentAmount?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                  <FormControl isInvalid={!!errors.fundsNonMedicaidCHWServices}>
                    <FormLabel fontSize='small'>
                      Funds non-Medicaid CHW Services
                    </FormLabel>
                    <Switch {...register("fundsNonMedicaidCHWServices")} />
                    <FormErrorMessage>
                      {errors.fundsNonMedicaidCHWServices?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </VStack>
              <HStack alignItems={"center"} justifyContent={"center"}>
                <FormLabel htmlFor='use-program'>
                  Billing Provider same as Program
                </FormLabel>
                <Checkbox
                  id='use-program'
                  isChecked={useProgramDetails}
                  onChange={toggleUseProgramDetails}
                  mb={2}
                />
              </HStack>

              {!useProgramDetails && (
                <VStack border={"1px solid lightgray"} rounded='lg' p={4}>
                  <VStack alignItems={"flex-start"} w={"full"}>
                    <Heading as='h2' fontSize='medium'>
                      Billing Provider
                    </Heading>
                    <FormControl isInvalid={!!errors.billingProvBusinessName}>
                      <FormLabel fontSize='small'>
                        Billing Provider Name
                      </FormLabel>
                      <Input {...register("billingProvBusinessName")} />
                      <FormErrorMessage>
                        {errors.billingProvBusinessName?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <HStack w={"full"}>
                      <FormControl isInvalid={!!errors.billingProvTIN}>
                        <FormLabel fontSize='small'>
                          Billing Provider TIN
                        </FormLabel>
                        <Input {...register("billingProvTIN")} />
                        <FormErrorMessage>
                          {errors.billingProvTIN?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.billingProvNPI}>
                        <FormLabel fontSize='small'>
                          Billing Provider NPI
                        </FormLabel>
                        <Input {...register("billingProvNPI")} />
                        <FormErrorMessage>
                          {errors.billingProvNPI?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </VStack>
                  <Divider my={2} />
                  <VStack alignItems={"flex-start"} w={"full"}>
                    <Heading as='h2' fontSize='medium'>
                      Billing Provider Address
                    </Heading>
                    <FormControl
                      isInvalid={!!errors.billingProvAddress?.street1}
                    >
                      <FormLabel fontSize='small'>Street 1</FormLabel>
                      <Input {...register("billingProvAddress.street1")} />
                      <FormErrorMessage>
                        {errors.billingProvAddress?.street1?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!errors.billingProvAddress?.street2}
                    >
                      <FormLabel fontSize='small'>Street 2</FormLabel>
                      <Input {...register("billingProvAddress.street2")} />
                      <FormErrorMessage>
                        {errors.billingProvAddress?.street2?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <HStack alignItems={"center"}>
                      <FormControl
                        w={"50%"}
                        isInvalid={!!errors.billingProvAddress?.city}
                      >
                        <FormLabel fontSize='small'>City</FormLabel>
                        <Input {...register("billingProvAddress.city")} />
                        <FormErrorMessage>
                          {errors.billingProvAddress?.city?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        w={"25%"}
                        isInvalid={!!errors.billingProvAddress?.state}
                      >
                        <FormLabel fontSize='small'>State</FormLabel>
                        <Input {...register("billingProvAddress.state")} />
                        <FormErrorMessage>
                          {errors.billingProvAddress?.state?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        w={"25%"}
                        isInvalid={!!errors.billingProvAddress?.zip}
                      >
                        <FormLabel fontSize='small'>Zip Code</FormLabel>
                        <Input {...register("billingProvAddress.zip")} />
                        <FormErrorMessage>
                          {errors.billingProvAddress?.zip?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </VStack>
                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter alignItems={"center"} justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              colorScheme='blue'
              isDisabled={!isDirty || !isValid}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
