import { useState, useEffect, useCallback } from "react";
import { CarePlan, PlanStatus } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";

const usePatientCarePlans = (patientId?: string) => {
  const [carePlans, setCarePlans] = useState<CarePlan[]>([]);
  const fetchCarePlans = useCallback(async () => {
    if (!patientId) return;
    const carePlanService = new CarePlanService();
    const clientCarePlans = await carePlanService.getPatientCarePlans(
      patientId
    );
    setCarePlans(clientCarePlans);
  }, [patientId]);

  useEffect(() => {
    fetchCarePlans();
  }, [fetchCarePlans]);
  return {
    carePlans,
    currentCarePlan: carePlans
      .filter(
        (cp) =>
          cp.status !== PlanStatus.Closed && cp.status !== PlanStatus.Unknown
      )
      .sort((a, b) => (a.createdDate <= b.createdDate ? 1 : -1))[0],
    refetch: fetchCarePlans
  };
};

export default usePatientCarePlans;
