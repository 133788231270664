import { useCallback, useEffect, useState } from "react";
import { Payer } from "@oben-core-web/models/payer";
import { PayerService } from "@oben-core-web/services/payer-service";

const usePayers = () => {
  const [payers, setPayers] = useState<Payer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const fetchPayers = useCallback(async () => {
    try {
      setLoading(true);
      const payerService = new PayerService();
      const payerRows = await payerService.getAllPayers();
      setPayers(payerRows);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPayers();
  }, [fetchPayers]);

  return { payers, fetchPayers, loading, error };
};

export default usePayers;
