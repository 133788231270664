import { DocumentSnapshot } from "firebase/firestore";

import { UserType, UserTypeHelper } from "../constants/core-enums";
import { CholesterolReadingService } from "../services/cholesterol-reading-service";

export interface iCholesterolReadingData {
  id: string;
  patientId: string; // can be either PendingPatientId or PatientId
  HDL: number;
  LDL: number;
  total: number;
  readingDate: Date;
  recorderType: UserType;
  recorderId: string;
  details: string;
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate?: Date | null; // time that reading was flagged as invalid
  invalidatorType?: UserType | null;
  invalidatorId?: string | null; // userId of person that flagged it as invalid
  placeBasedCareProvId: string;
}

export class CholesterolReading {
  id: string;
  patientId: string; // can be either PendingPatientId or PatientId
  HDL: number;
  LDL: number;
  total: number;
  readingDate: Date;
  recorderType: UserType;
  recorderId: string;
  details: string;
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate?: Date | null; // time that reading was flagged as invalid
  invalidatorType?: UserType | null;
  invalidatorId?: string | null; // userId of person that flagged it as invalid
  placeBasedCareProvId: string;

  constructor({
    id,
    patientId,
    HDL,
    LDL,
    total,
    readingDate,
    recorderType,
    recorderId,
    details,
    isInvalid = false,
    invalidDate,
    invalidatorType,
    invalidatorId,
    placeBasedCareProvId
  }: iCholesterolReadingData) {
    this.id = id;
    this.patientId = patientId;
    this.HDL = HDL;
    this.LDL = LDL;
    this.total = total;
    this.readingDate = readingDate;
    this.recorderType = recorderType;
    this.recorderId = recorderId;
    this.details = details;
    this.isInvalid = isInvalid;
    this.invalidDate = invalidDate;
    this.invalidatorType = invalidatorType;
    this.invalidatorId = invalidatorId;
    this.placeBasedCareProvId = placeBasedCareProvId
  }

  //
  // Misc helper methods
  //
  async updateDb(): Promise<void> {
    const db = new CholesterolReadingService();
    await db.updateCholesterolReading(this);
  }

  // Factories
  //
  static fromFirestore(doc: DocumentSnapshot): CholesterolReading {
    const data = doc.data() as { [key: string]: any };
    return CholesterolReading.fromMap(doc.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): CholesterolReading {
    return new CholesterolReading({
      id: id,
      patientId: data["patientId"] ?? "",
      HDL: data["HDL"] ?? 0,
      LDL: data["LDL"] ?? 0,
      total: data["total"] ?? 0,
      readingDate: data["readingDate"]
        ? new Date(data["readingDate"])
        : new Date(1, 0, 1), // default to 1901-Jan-01
      recorderType:
        UserTypeHelper.valueOf(data["recorderType"] ?? "") ?? UserType.Unknown,
      recorderId: data["recorderId"] ?? "",
      details: data["details"] ?? "",
      isInvalid: data["isInvalid"] ?? false,
      invalidDate: data["invalidDate"] ? new Date(data["invalidDate"]) : null,
      invalidatorType:
        UserTypeHelper.valueOf(data["invalidatorType"] ?? "") ?? null,
      invalidatorId: data["invalidatorId"] ?? null,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      patientId: this.patientId,
      HDL: Number(this.HDL),
      LDL: Number(this.LDL),
      total: Number(this.total),
      readingDate: this.readingDate.toISOString(),
      recorderType: this.recorderType,
      recorderId: this.recorderId,
      details: this.details,
      isInvalid: this.isInvalid,
      invalidDate: this.invalidDate?.toISOString() ?? null,
      invalidatorType: this.invalidatorType,
      invalidatorId: this.invalidatorId,
      placeBasedCareProvId: this.placeBasedCareProvId
    };
  }
}
