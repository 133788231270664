import { CommunityHealthWorker } from "@oben-core-web/models/community-health-worker";
import { ServiceLocation } from "@oben-core-web/models/service-location";
import {
  // InternalTask,
  InternalTaskType
} from "@oben-core-web/models/internal-task";
import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { ServiceLocationService } from "@oben-core-web/services/service-location-service";
import { PatientService } from "@oben-core-web/services/patient-service";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { useCallback, useEffect, useState } from "react";

const useInternalSchedulingTasks = (assigneeId: string) => {
  const [internalTasks, setInternalTasks] = useState<any[]>([]);

  const fetchInternalSchedulingTasks = useCallback(
    async (assigneeId: string) => {
      const internalTaskService = new InternalTaskService();
      const tasks = await internalTaskService.getAssigneeTasks(assigneeId);
      const patientService = new PatientService();
      const chwService = new CommunityHealthWorkerService();
      const serviceLocationService = new ServiceLocationService();

      const parsed = await Promise.all(
        tasks
          .filter(
            (t) =>
              t.internalTaskType === InternalTaskType.ScheduleAppointment &&
              !t.completionDate
          )
          .map(async (t) => {
            const patient = await patientService.getPatient(t.patientId);
            let prefCHW: CommunityHealthWorker | null = null;
            let prefServiceLocation: ServiceLocation | null = null;
            if (!patient) return t;
            if (patient.prefCHWId) {
              prefCHW = await chwService.getCHW(patient.prefCHWId);
            }
            if (patient.prefServiceLocationId) {
              prefServiceLocation =
                await serviceLocationService.getServiceLocation(
                  patient.prefServiceLocationId
                );
            }
            return {
              ...t,
              patient,
              internalTask: t,
              patientName: patient?.name.fullName ?? "",
              prefApptTimes: patient.prefApptTimes.join("\n"),
              prefCHW,
              prefServiceLocation
            };
          })
      );
      setInternalTasks(parsed);
    },
    []
  );

  useEffect(() => {
    if (assigneeId) {
      fetchInternalSchedulingTasks(assigneeId);
    }
  }, [assigneeId, fetchInternalSchedulingTasks]);

  return { internalTasks, fetchInternalSchedulingTasks };
};

export default useInternalSchedulingTasks;
