import useCHWs from "@/hooks/useCHWs";
import useServiceLocations from "@/hooks/useServiceLocations";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import communityHealthWorkerSchema from "@/resolvers/communityHealthWorker";
import { EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Switch,
  Select
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CommunityHealthWorker,
  ICommunityHealthWorkerData
  // LegalEntityType
} from "@oben-core-web/models/community-health-worker";
import { AppInfo } from "@oben-core-web/models/app-info";
import { PlatformInfo } from "@oben-core-web/models/platform-info";
import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import CurrencyInput from "../atoms/CurrencyInput";
import NumberInput from "../atoms/NumberInput";
import { startCase } from "lodash";
import { parsePhoneNumber } from "@/lib/parseUtils";
import { httpsCallable } from "firebase/functions";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";
import { CloudFunctions } from "../../../firebase";
import { IUserNameData } from "@oben-core-web/models/user-name";

const AdminCHWManagement = () => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { serviceLocations } = useServiceLocations(placeBasedCareProvider!.id);
  const { chws, refetch } = useCHWs(placeBasedCareProvider!.id);
  const [selectedCHW, setSelectedCHW] = useState<CommunityHealthWorker>();
  const columnHeaders: (
    | ColDef<CommunityHealthWorker & { actions: any }>
    | (ColDef<CommunityHealthWorker & { actions: any }> & {
        children: ColDef<CommunityHealthWorker & { actions: any }>[];
      })
  )[] = [
    {
      headerName: "Community Health Worker",
      children: [
        {
          field: "actions",
          headerName: "Actions",
          flex: 0.3,
          minWidth: 80,
          cellRenderer: ({ data }: any) => (
            <IconButton
              aria-label={`edit-chw-${data.uid}`}
              icon={<EditIcon />}
              variant='ghost'
              onClick={() => {
                setSelectedCHW(data);
                onToggle();
              }}
            />
          )
        },
        {
          field: "enabled",
          headerName: "Enabled",
          flex: 0.3,
          minWidth: 80,
          cellRenderer: ({ data }: any) => {
            const updateEnabledStatus = httpsCallable<
              { uid: string; enabled: boolean },
              CloudFunctionResponse
            >(CloudFunctions, "setUserEnabled");

            return (
              <Switch
                isChecked={data.enabled}
                onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                  const result = await updateEnabledStatus({
                    uid: data.uid,
                    enabled: event.target.checked
                  });
                  if (result.data.error) {
                    toast({
                      status: "error",
                      description: "Failed to update barber enabled status"
                    });
                    return;
                  } else {
                    toast({
                      status: "success",
                      description: `CHW ${
                        !event.target.checked ? "Enabled" : "Disabled"
                      }`
                    });
                  }
                  await refetch();
                }}
              />
            );
          }
        },
        {
          field: "name.fullName",
          headerName: "Name",
          minWidth: 100,
          flex: 0.8
        },
        {
          field: "phoneNumber",
          headerName: "Phone",
          minWidth: 100,
          valueFormatter: ({ data }) => {
            return parsePhoneNumber(data?.phoneNumber ?? "");
          },
          flex: 0.7
        },
        {
          field: "email",
          headerName: "Email",
          minWidth: 100,
          flex: 0.7
        },
        {
          field: "currentServiceLocationId",
          headerName: "Service Location",
          valueGetter: ({ data }) => {
            return (
              serviceLocations.find(
                (shop) => shop.id === data!.currentServiceLocationId
              )?.businessName ?? ""
            );
          },
          minWidth: 100,
          flex: 0.8
        }
      ]
    },
    {
      headerName: "Fees",
      children: [
        {
          columnGroupShow: "closed",
          field: "serviceFee",
          headerName: "Service",
          valueFormatter: ({ data }) => {
            return "$" + ((data?.serviceFee ?? 0) / 100).toFixed(2).toString();
          },
          minWidth: 100,
          flex: 0.35
        },
        {
          columnGroupShow: "open",
          field: "serviceFee",
          headerName: "Service",
          valueFormatter: ({ data }) => {
            return "$" + ((data?.serviceFee ?? 0) / 100).toFixed(2).toString();
          },
          minWidth: 100,
          flex: 0.35
        },
        {
          columnGroupShow: "open",
          field: "noShowFee",
          headerName: "No Show",
          valueFormatter: ({ data }) => {
            return "$" + ((data?.noShowFee ?? 0) / 100).toFixed(2).toString();
          },
          minWidth: 100,
          flex: 0.35
        },
        {
          columnGroupShow: "open",
          field: "cancelFee",
          headerName: "Cancel",
          valueFormatter: ({ data }) => {
            return "$" + ((data?.cancelFee ?? 0) / 100).toFixed(2).toString();
          },
          minWidth: 100,
          flex: 0.35
        },
        {
          columnGroupShow: "open",
          field: "cancelWindow",
          headerName: "Cancellation Window",
          minWidth: 100,
          flex: 0.9
        }
      ]
    },
    {
      headerName: "Stripe Info",
      children: [
        {
          columnGroupShow: "closed",
          field: "legalEntityType",
          headerName: "Business Type",
          minWidth: 100,
          flex: 0.5
        },
        {
          columnGroupShow: "open",
          field: "legalEntityType",
          headerName: "Business Type",
          minWidth: 100,
          flex: 0.5
        },
        {
          columnGroupShow: "open",
          field: "stripeStatus",
          valueFormatter: ({ data }) => {
            return startCase(data?.stripeStatus);
          },
          headerName: "Status",
          minWidth: 100,
          flex: 0.7
        },
        {
          columnGroupShow: "open",
          field: "stripeRemediationItems",
          headerName: "Required Info",
          minWidth: 100,
          flex: 0.4,
          valueGetter: ({ data }) => {
            return data?.stripeRemediationItems?.join("; ");
          }
        }
      ]
    }
  ];

  return (
    <VStack alignItems={"flex-start"} h={"full"}>
      <HStack alignItems={"center"} justifyContent={"space-between"} w={"full"}>
        <Heading fontSize={"large"} as={"h1"}>
          Manage Community Health Workers
        </Heading>
        <Button onClick={onToggle} size='sm' colorScheme='blue'>
          Add Barber
        </Button>
      </HStack>
      <Box className='ag-theme-quartz' h={"full"} w={"full"}>
        <AgGridReact
          columnDefs={columnHeaders}
          rowData={chws as (CommunityHealthWorker & { actions: any })[]}
        />
      </Box>
      {isOpen && (
        <AdminCHWForm
          isOpen={isOpen}
          selectedCHW={selectedCHW}
          closeModal={() => {
            if (selectedCHW) {
              setSelectedCHW(undefined);
            }
            onToggle();
          }}
          onSave={() => {
            refetch();
            if (selectedCHW) {
              setSelectedCHW(undefined);
            }
            onToggle();
          }}
        />
      )}
    </VStack>
  );
};

export default AdminCHWManagement;

interface IAdminCHWForm {
  isOpen: boolean;
  selectedCHW?: CommunityHealthWorker;
  closeModal: () => void;
  onSave: () => void;
}

const AdminCHWForm = ({
  isOpen,
  selectedCHW,
  closeModal,
  onSave
}: IAdminCHWForm) => {
  const toast = useToast();
  const isEditMode = !!selectedCHW;
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { serviceLocations } = useServiceLocations(placeBasedCareProvider!.id);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, dirtyFields }
  } = useForm<ICommunityHealthWorkerData>({
    defaultValues: isEditMode
      ? { uid: selectedCHW.uid, ...selectedCHW.toJson() }
      : CommunityHealthWorker.fromMap("", {
          appInfo: AppInfo.fromMap({}),
          platformInfo: PlatformInfo.fromMap({}),
          enabled: true,
          pwdNeedsReset: true,
          placeBasedCareProvIds: [placeBasedCareProvider!.id]
        }),
    resolver: yupResolver(communityHealthWorkerSchema) as any
  });
  const submissionHandler = async (data: ICommunityHealthWorkerData) => {
    try {
      const chw = CommunityHealthWorker.fromMap(data.uid ?? "", data);
      if (!chw.serviceLocationIds.includes(chw.currentServiceLocationId)) {
        chw.serviceLocationIds.push(chw.currentServiceLocationId);
      }
      const chwService = new CommunityHealthWorkerService();
      if (isEditMode) {
        if (dirtyFields.enabled) {
          const updateEnabledStatus = httpsCallable<
            { uid: string; enabled: boolean },
            CloudFunctionResponse
          >(CloudFunctions, "setUserEnabled");
          await updateEnabledStatus({
            uid: chw.uid,
            enabled: chw.enabled
          });
        }
        await chwService.updateCHW(chw).then(() => {
          toast({
            status: "success",
            description: "Successfully updated CHW"
          });
        });
      } else {
        const addCHWCallable = httpsCallable<
          {
            name: IUserNameData;
            email: string;
            phoneNumber?: string;
            enabled: boolean;
            currentServiceLocationId: string;
            serviceLocationIds?: string[];
            placeBasedCareProvId: string;
            // legalEntityType?: LegalEntityType;
            // stripeStatus?: StripeStatus;
            // stripeRemediationItems: IStripeRemediationItemData[];
            serviceFee: number | null;
            noShowFee: number | null;
            cancelFee: number | null;
            cancelWindow: number | null;
          },
          CloudFunctionResponse
        >(CloudFunctions, "createCommunityHealthWorker");
        const response = await addCHWCallable({
          ...chw.toJson(),
          currentServiceLocationId: chw.currentServiceLocationId,
          placeBasedCareProvId: chw.placeBasedCareProvIds[0]
        } as {
          name: IUserNameData;
          email: string;
          phoneNumber?: string;
          enabled: boolean;
          currentServiceLocationId: string;
          serviceLocationIds?: string[];
          placeBasedCareProvId: string;
          // legalEntityType?: LegalEntityType;
          // stripeStatus?: StripeStatus;
          // stripeRemediationItems: IStripeRemediationItemData[];
          serviceFee: number | null;
          noShowFee: number | null;
          cancelFee: number | null;
          cancelWindow: number | null;
        });
        if (response.data.error) {
          toast({
            status: "error",
            description: "Failed created barber"
          });
        } else {
          toast({
            status: "success",
            description: "Successfully created barber"
          });
        }
        // await chwService.addCommunityHealthWorker(barber).then(() => {
        //   toast({
        //     status: "success",
        //     description: "Successfully created barber"
        //   });
        // });
      }
      onSave && onSave();
    } catch (e) {
      console.log("Admin Barber Form Error", e);
      toast({
        status: "error",
        description: isEditMode
          ? "Failed to update barber"
          : "Failed to crete barber"
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>{isEditMode ? "Edit CHW" : "Create CHW"}</ModalHeader>
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!errors.name?.first}>
                <FormLabel fontSize='small'>First Name</FormLabel>
                <Input {...register("name.first")} />
                <FormErrorMessage>
                  {errors.name?.first?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.name?.last}>
                <FormLabel fontSize='small'>Last Name</FormLabel>
                <Input {...register("name.last")} />
                <FormErrorMessage>
                  {errors.name?.last?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.name?.display}>
                <FormLabel fontSize='small'>Display Name</FormLabel>
                <Input {...register("name.display")} />
                <FormErrorMessage>
                  {errors.name?.display?.message}
                </FormErrorMessage>
              </FormControl>
              {!isEditMode && (
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel fontSize='small'>Email</FormLabel>
                  <Input {...register("email")} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel fontSize='small'>Phone Number</FormLabel>
                <Input {...register("phoneNumber")} />
                <FormErrorMessage>
                  {errors.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.currentServiceLocationId}>
                <FormLabel fontSize='small'>Current Service Location</FormLabel>
                <Select {...register("currentServiceLocationId")}>
                  {serviceLocations.map((shop) => (
                    <option
                      key={`chw-service-location-option-${shop.id}`}
                      value={shop.id}
                    >
                      {shop.businessName}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.currentServiceLocationId?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.serviceFee}>
                <FormLabel fontSize='small'>Service Fee</FormLabel>
                <CurrencyInput control={control} name='serviceFee' />
                {/* <Input {...register("serviceFee")} type='number' /> */}
                <FormErrorMessage>
                  {errors.serviceFee?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.noShowFee}>
                <FormLabel fontSize='small'>No-show Fee</FormLabel>
                <CurrencyInput control={control} name='noShowFee' />
                {/* <Input {...register("noShowFee")} type='number' /> */}
                <FormErrorMessage>{errors.noShowFee?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.cancelFee}>
                <FormLabel fontSize='small'>Cancel Fee</FormLabel>
                <CurrencyInput control={control} name='cancelFee' />
                {/* <Input {...register("cancelFee")} type='number' /> */}
                <FormErrorMessage>{errors.cancelFee?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.cancelWindow}>
                <FormLabel fontSize='small'>Cancellation Window</FormLabel>
                <NumberInput control={control} name='cancelWindow' />
                {/* <Input {...register("cancelWindow")} type='number' /> */}
                <FormErrorMessage>
                  {errors.cancelWindow?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.enabled}>
                <FormLabel fontSize='small'>Enabled</FormLabel>
                <Switch {...register("enabled")} />
                <FormErrorMessage>{errors.enabled?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              isDisabled={isSubmitting || !isValid}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
