import useCurrentUser from "@/hooks/useCurrentUser";
import UserProfileCard from "../molecules/UserProfileCard";
import { useParams } from "react-router-dom";
import useStaffMember from "@/hooks/useStaffMember";
import WorkingHourAdjustments from "@components/organisms/WorkingHourAdjustments";

const UserProfile = () => {
  const { uid } = useParams();
  const { currentUser } = useCurrentUser();
  const { user } = useStaffMember(uid);
  const canEdit = !!(user && currentUser) && user.uid === currentUser.uid;

  return (
    <div className='user-profile-view'>
      {user && (
        <>
          <UserProfileCard user={user} canEdit={canEdit} />
          <WorkingHourAdjustments canEdit={canEdit} user={user} />
        </>
      )}
    </div>
  );
};

export default UserProfile;
