import { useState, useEffect, useCallback } from "react";
import { PatientService } from "@oben-core-web/services/patient-service";
import { Patient } from "@oben-core-web/models/patient";

const usePatients = (placeBasedCareProvId?: string) => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);
  const fetchPatients = useCallback(async (placeBasedCareProvId: string) => {
    try {
      setLoading(true);
      const clientUserService = new PatientService();
      const clients = await clientUserService.getPlaceBasedCareProvPatients(
        placeBasedCareProvId
      );
      if (clients.length) {
        setPatients(clients.filter((c) => c.enabled));
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    let loading = true;
    if (loading && placeBasedCareProvId) {
      fetchPatients(placeBasedCareProvId).finally(() => {
        loading = false;
      });
    }
    return () => {
      loading = false;
    };
  }, [placeBasedCareProvId, fetchPatients]);
  return { patients, loading, error };
};

export default usePatients;
