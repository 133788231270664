import usePatientSubscription from "@/hooks/usePatientSubscription";
import useMedicationBase from "@/hooks/useMedicationBase";
import useMedications from "@/hooks/useMedications";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Text,
  Box,
  HStack,
  Button,
  Input,
  Select,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  IconButton,
  Flex,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormLabel,
  ModalHeader,
  ModalFooter
} from "@chakra-ui/react";
import { FreqPeriod, UserType } from "@oben-core-web/constants/core-enums";
import { MedAdherence } from "@oben-core-web/models/med-adherence";
import { MedDosage } from "@oben-core-web/models/med-dosage";
import { Medication } from "@oben-core-web/models/medication";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import isEqual from "lodash/isEqual";
import useMedAdherence from "@/hooks/useMedAdherence";
import {
  PatientNote,
  PatientNoteType
} from "@oben-core-web/models/patient-note";
import { StaffMember } from "@oben-core-web/models/staff-member";

interface ICPWizardStep2 {
  carePlanId: string;
  patientId: string;
  pharmacistId: string;
  currentUser: StaffMember;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface IMedicationForm {
  id: string;
  placeBasedCareProvId: string;
  name: string;
  strength: number;
  freqCount: number;
  freqPeriod: FreqPeriod;
  duration: number;
  medBaseId: string;
  adherence: "Never" | "Sometimes" | "Always";
  needsMedBase: boolean;
  isExistingMedication: boolean;
  note?: { noteType: PatientNoteType; summary: string; details: string };
}

interface ICPWizardStep2Form {
  medications: IMedicationForm[];
  unlistedMedications: IMedicationForm[];
}

const CPWizardStep2 = ({
  carePlanId,
  patientId,
  pharmacistId,
  currentUser,
  onSubmit,
  onCancel
}: ICPWizardStep2) => {
  const { isOpen, onToggle } = useDisclosure();
  const [noteIdentifier, setNoteIdentifier] = useState<
    | { section: "medications" | "unlistedMedications"; index: number }
    | undefined
  >();
  const { patient } = usePatientSubscription(patientId);
  const { medications } = useMedications({ patientId, carePlanId });
  const { medAdherences } = useMedAdherence({ patientId, carePlanId });
  const medicationsRef = useRef(medications);
  const medAdherencesRef = useRef(medAdherences);
  const { medicationBases } = useMedicationBase(
    currentUser?.placeBasedCareProvId ?? ""
  );

  const { control, register, handleSubmit, setValue, reset, formState } =
    useForm<ICPWizardStep2Form>({
      defaultValues: {
        medications: medications
          .filter((m) => !m.rxCancelDate)
          .map((m) => ({
            id: m.modelId,
            placeBasedCareProvId: m.placeBasedCareProvId,
            name: m.name,
            strength: m.newDosage.strength,
            freqCount: m.newDosage.frequencyCount,
            freqPeriod: m.newDosage.frequencyPeriod,
            duration: m.newDosage.durationCount,
            medBaseId: m.medBaseId,
            needsMedBase: false
          }))
      },
      mode: "onChange"
    });
  formState.isDirty;
  useEffect(() => {
    if (
      !isEqual(medications, medicationsRef.current) ||
      !isEqual(medAdherences, medAdherencesRef.current)
    ) {
      medicationsRef.current = medications;
      medAdherencesRef.current = medAdherences;
      reset({
        medications: medications
          .filter((m) => !m.rxCancelDate)
          .map((m) => ({
            id: m.modelId,
            placeBasedCareProvId: m.placeBasedCareProvId,
            name: m.name,
            strength: m.newDosage.strength,
            freqCount: m.newDosage.frequencyCount,
            freqPeriod: m.newDosage.frequencyPeriod,
            duration: m.newDosage.durationCount,
            medBaseId: m.medBaseId,
            needsMedBase: false,
            adherence:
              (medAdherences.find((ma) => ma.medicationId === m.modelId)
                ?.response as "Always" | "Never" | "Sometimes") ?? "Always",
            isExistingMedication: true
          })),
        unlistedMedications: []
      });
    }
  }, [reset, medications, medAdherences]);
  const onMedSelected = (index: number, selectedMedication: MedicationBase) => {
    setValue(
      `medications.${index}`,
      {
        id: "",
        placeBasedCareProvId: selectedMedication.placeBasedCareProvId,
        medBaseId: selectedMedication.id,
        name: selectedMedication.name,
        strength: selectedMedication.defaultDosage.strength,
        freqCount: selectedMedication.defaultDosage.frequencyCount,
        freqPeriod: selectedMedication.defaultDosage
          .frequencyPeriod as FreqPeriod,
        duration: selectedMedication.defaultDosage.durationCount,
        needsMedBase: false,
        adherence: "Always",
        isExistingMedication: false
      },
      { shouldDirty: true }
    );
  };
  const {
    fields: medicationFields,
    append: appendMedication,
    remove: removeCurrentMed
  } = useFieldArray({
    control,
    name: "medications"
  });
  const {
    fields: unlistedMedicationFields,
    append: appendUnlistedMedication,
    remove: removeUnlistedMed
  } = useFieldArray({
    control,
    name: "unlistedMedications"
  });

  const submissionHandler = async (data: ICPWizardStep2Form) => {
    try {
      const {
        medications: medicationData,
        unlistedMedications: unlistedMedicationData
      } = data;
      const dirtyMedications = formState.dirtyFields.medications;
      const dirtyUnlistedMedications =
        formState.dirtyFields.unlistedMedications;

      const dirtyMedicationRows = medicationData.filter(
        (_, index) => dirtyMedications?.[index]
      );

      const dirtyUnlistedMedicationRows = unlistedMedicationData.filter(
        (_, index) => dirtyUnlistedMedications?.[index]
      );

      const currentMedications = dirtyMedicationRows.map((m) => {
        const currentMedicationDosage = new MedDosage({
          frequencyCount: m.freqCount,
          frequencyPeriod: m.freqPeriod as FreqPeriod,
          durationCount: m.duration,
          strength: isNaN(m.strength) ? Number(m.strength) : m.strength
        });
        return {
          medication: new Medication({
            modelId: "",
            carePlanId,
            patientId,
            name: m.name,
            medBaseId: m.medBaseId,
            placeBasedCareProvId: m.placeBasedCareProvId,
            newDosage: currentMedicationDosage,
            oldDosage: currentMedicationDosage,
            prescriberId: pharmacistId,
            prescriberType: UserType.Pharmacist,
            rxStartDate: new Date(),
            rxCancelDate: null
          }),
          adherence: new MedAdherence({
            modelId: "",
            patientId,
            carePlanId,
            medicationId: "",
            medicationBaseId: m.medBaseId,
            placeBasedCareProvId: m.placeBasedCareProvId,
            authorId: pharmacistId,
            authorType: UserType.Pharmacist,
            questionDate: new Date(),
            response: m.adherence
          }),
          ...(m.note
            ? {
                note: new PatientNote({
                  modelId: "",
                  patientId,
                  authorId: pharmacistId,
                  authorType: UserType.Pharmacist,
                  createdDate: new Date(),
                  noteType: m.note.noteType,
                  noteSourceId: "",
                  summary: m.note.summary,
                  details: m.note.details,
                  placeBasedCareProvId: currentUser.placeBasedCareProvId
                })
              }
            : {})
        };
      });
      const unlistedMedications = dirtyUnlistedMedicationRows.map((um) => {
        const currentMedicationDosage = new MedDosage({
          frequencyCount: um.freqCount,
          frequencyPeriod: um.freqPeriod as FreqPeriod,
          durationCount: um.duration,
          strength: isNaN(um.strength) ? Number(um.strength) : um.strength
        });
        return {
          medication: new Medication({
            modelId: "",
            carePlanId,
            patientId,
            name: um.name,
            medBaseId: "",
            placeBasedCareProvId: um.placeBasedCareProvId,
            newDosage: currentMedicationDosage,
            oldDosage: currentMedicationDosage,
            prescriberId: pharmacistId,
            prescriberType: UserType.Pharmacist,
            rxStartDate: new Date(),
            rxCancelDate: null
          }),
          adherence: new MedAdherence({
            modelId: "",
            patientId,
            carePlanId,
            medicationId: "",
            medicationBaseId: "",
            placeBasedCareProvId: um.placeBasedCareProvId,
            authorId: pharmacistId,
            authorType: UserType.Pharmacist,
            questionDate: new Date(),
            response: um.adherence
          }),
          ...(um.note
            ? {
                note: new PatientNote({
                  modelId: "",
                  patientId,
                  authorId: pharmacistId,
                  authorType: UserType.Pharmacist,
                  createdDate: new Date(),
                  noteType: um.note.noteType,
                  noteSourceId: "",
                  summary: um.note.summary,
                  details: um.note.details,
                  placeBasedCareProvId: currentUser.placeBasedCareProvId
                })
              }
            : {})
        };
      });
      await CarePlanTransactionService.saveMedsAndAdherence({
        patientId,
        carePlanId,
        currentMedications,
        unlistedMedications
      }).then(() => {
        onSubmit && onSubmit();
      });
    } catch (e) {
      console.log({ error: e });
    }
  };
  const openNoteModal = (
    section: "medications" | "unlistedMedications",
    index: number
  ) => {
    setNoteIdentifier({ section, index });
    onToggle();
  };
  const closeNoteModal = () => {
    setNoteIdentifier(undefined);
    onToggle();
  };
  return (
    <>
      {patient && currentUser && (
        <form onSubmit={handleSubmit(submissionHandler)}>
          <VStack
            spacing={4}
            key='step2-1'
            alignItems={"flex-start"}
            w={"full"}
            minH={"30rem"}
          >
            <Box w={"full"}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text as={"h2"} fontSize={"large"}>
                  Current Medications
                </Text>
                <HStack>
                  <Button
                    variant='ghost'
                    fontSize={"small"}
                    onClick={() => {
                      appendMedication({
                        id: "",
                        placeBasedCareProvId:
                          currentUser.placeBasedCareProvId ?? "",
                        name: "",
                        medBaseId: "",
                        strength: 0,
                        freqCount: 0,
                        freqPeriod: "Daily" as FreqPeriod,
                        duration: 0,
                        needsMedBase: false,
                        adherence: "Always",
                        isExistingMedication: false
                      });
                    }}
                  >
                    Add Medication
                  </Button>
                  <Button
                    variant='ghost'
                    fontSize={"small"}
                    onClick={() => {
                      appendUnlistedMedication({
                        id: "",
                        placeBasedCareProvId:
                          currentUser.placeBasedCareProvId ?? "",
                        name: "",
                        medBaseId: "",
                        strength: 0,
                        freqCount: 0,
                        freqPeriod: "Daily" as FreqPeriod,
                        duration: 0,
                        needsMedBase: true,
                        adherence: "Always",
                        isExistingMedication: false
                      });
                    }}
                  >
                    Add Unlisted Medication
                  </Button>
                </HStack>
              </Flex>
              <Text fontWeight={"normal"} my={".5rem"}>
                Enter any medications the patient is currently on. If changes
                are needed, we will make adjustments later.
              </Text>
              {(medicationFields.length > 0 ||
                unlistedMedicationFields.length > 0) && (
                <Table size='xs'>
                  <Thead>
                    <Tr px={0} fontSize={"small"}>
                      <Td w={"35%"}>Medication Name</Td>
                      <Td w={"15%"}>Adherence</Td>
                      <Td w={"10%"}>Dosage (mg)</Td>
                      <Td w={"10%"}>How many?</Td>
                      <Td w={"10%"}>Over what period?</Td>
                      <Td w={"10%"}>For how long? (days)</Td>
                      <Td w={"5%"}>Actions</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {medicationFields.map((medField, index) => (
                      <Tr key={`medication-${index}`}>
                        <Td w={"35%"}>
                          <FormControl>
                            <Controller
                              name={`medications.${index}.medBaseId`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  disabled={medField.isExistingMedication}
                                  placeholder='Select a medication'
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    if (e.target.value) {
                                      const selectedMedication =
                                        medicationBases.find(
                                          (m) => m.id === e.target.value
                                        );
                                      onMedSelected(index, selectedMedication!);
                                    }
                                  }}
                                >
                                  {medicationBases.map((mb) => (
                                    <option key={mb.id} value={mb.id}>
                                      {mb.name}
                                    </option>
                                  ))}
                                  {/* <Button
                                    variant={"link"}
                                    onClick={() => {
                                      appendUnlistedMedication({
                                        id: "",
                                        placeBasedCareProvId:
                                          currentUser.placeBasedCareProvId ??
                                          "",
                                        name: "",
                                        medBaseId: "",
                                        strength: 0,
                                        freqCount: 0,
                                        freqPeriod: "Daily" as FreqPeriod,
                                        duration: 0,
                                        needsMedBase: true,
                                        adherence: "Always",
                                        isExistingMedication: false
                                      });
                                    }}
                                  >
                                    Add unlisted Medication
                                  </Button> */}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Select
                              disabled={medField.isExistingMedication}
                              {...register(`medications.${index}.adherence`)}
                            >
                              <option value={"Never"}>Never</option>
                              <option value={"Sometimes"}>Sometimes</option>
                              <option value={"Always"}>Always</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              disabled={medField.isExistingMedication}
                              type='number'
                              step={"any"}
                              {...register(`medications.${index}.strength`)}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              disabled={medField.isExistingMedication}
                              type='number'
                              {...register(`medications.${index}.freqCount`)}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Select
                              disabled={medField.isExistingMedication}
                              {...register(`medications.${index}.freqPeriod`)}
                            >
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              disabled={medField.isExistingMedication}
                              type='number'
                              {...register(`medications.${index}.duration`)}
                            />
                          </FormControl>
                        </Td>
                        <Td>
                          <HStack>
                            <IconButton
                              isDisabled={medField.isExistingMedication}
                              aria-label='delete-icon'
                              icon={<DeleteIcon />}
                              size='sm'
                              onClick={() => removeCurrentMed(index)}
                            />
                            <IconButton
                              isDisabled={medField.isExistingMedication}
                              aria-label='note-icon'
                              icon={<EditIcon />}
                              size='sm'
                              onClick={() =>
                                openNoteModal("medications", index)
                              }
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                    {unlistedMedicationFields.map((field, index) => (
                      <Tr
                        key={`unlistedMedication-${index}`}
                        // display={'flex'}
                        // alignItems={'center'}
                        // justifyContent={'space-between'}
                      >
                        <Td w={"35%"}>
                          <FormControl>
                            <Input
                              {...register(`unlistedMedications.${index}.name`)}
                              placeholder='Enter a medication name'
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Select
                              {...register(
                                `unlistedMedications.${index}.adherence`
                              )}
                            >
                              <option value={"Never"}>Never</option>
                              <option value={"Sometimes"}>Sometimes</option>
                              <option value={"Always"}>Always</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.strength`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.freqCount`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Select
                              {...register(
                                `unlistedMedications.${index}.freqPeriod`
                              )}
                            >
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.duration`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"5%"}>
                          <HStack>
                            <IconButton
                              aria-label='delete-icon'
                              icon={<DeleteIcon />}
                              size='sm'
                              onClick={() => removeUnlistedMed(index)}
                            />
                            <IconButton
                              aria-label='note-icon'
                              icon={<EditIcon />}
                              size='sm'
                              onClick={() =>
                                openNoteModal("unlistedMedications", index)
                              }
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}

              {/* {unlistedMedicationFields.length > 0 && (
                <Table size={"xs"}>
                  <Tbody>
                    {unlistedMedicationFields.map((field, index) => (
                      <Tr
                        key={`unlistedMedication-${index}`}
                        // display={'flex'}
                        // alignItems={'center'}
                        // justifyContent={'space-between'}
                      >
                        <Td w={"35%"}>
                          <FormControl>
                            <Input
                              {...register(`unlistedMedications.${index}.name`)}
                              placeholder='Enter a medication name'
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Select
                              {...register(`medications.${index}.adherence`)}
                            >
                              <option value={"Never"}>Never</option>
                              <option value={"Sometimes"}>Sometimes</option>
                              <option value={"Always"}>Always</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.strength`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.freqCount`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Select
                              {...register(
                                `unlistedMedications.${index}.freqPeriod`
                              )}
                            >
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"10%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.duration`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"5%"}>
                          <HStack>
                            <IconButton
                              aria-label='delete-icon'
                              icon={<DeleteIcon />}
                              size='sm'
                              onClick={() => removeUnlistedMed(index)}
                            />
                            <IconButton
                              aria-label='note-icon'
                              icon={<EditIcon />}
                              size='sm'
                              onClick={() =>
                                openNoteModal("unlistedMedications", index)
                              }
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )} */}
            </Box>
          </VStack>
          {noteIdentifier && (
            <Modal isOpen={isOpen} onClose={closeNoteModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create a Note</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Header</FormLabel>
                    <Input
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.summary`
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Details</FormLabel>
                    <Textarea
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.details`
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Note Type</FormLabel>
                    <Select
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.noteType`
                      )}
                    >
                      <option value={PatientNoteType.Medication}>
                        Medication
                      </option>
                      <option value={PatientNoteType.MedAdherence}>
                        Adherence
                      </option>
                    </Select>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setValue(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note`,
                        undefined
                      );
                      closeNoteModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={closeNoteModal}>Save</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
          <HStack mt={"2rem"} justifyContent={"space-between"}>
            {onCancel && <Button onClick={onCancel}>Previous</Button>}
            {onSubmit && <Button type='submit'>Next</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep2;
