import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Avatar,
  Heading,
  Text,
  Card,
  SimpleGrid,
  // useBreakpointValue,
  useDisclosure,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  ModalFooter,
  Button,
  ModalCloseButton
} from "@chakra-ui/react";
import { Patient } from "@oben-core-web/models/patient";
import { DateTime } from "luxon";
import PatientProfileForm from "./PatientProfileForm";
import { PrimaryCareProv } from "@oben-core-web/models/primary-care-prov";
import { CommunityHealthWorker } from "@oben-core-web/models/community-health-worker";
import { ServiceLocation } from "@oben-core-web/models/service-location";
import { Payer } from "@oben-core-web/models/payer";
import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { ServiceLocationService } from "@oben-core-web/services/service-location-service";
import { PayerService } from "@oben-core-web/services/payer-service";
import { PrimaryCareProvService } from "@oben-core-web/services/primary-care-prov-service";
import { parsePhoneNumber } from "@/lib/parseUtils";
import NoteDrawer from "../organisms/NoteDrawer";
import PatientOutreacher from "./PatientOutreacher";
import { OutreachPurpose } from "@oben-core-web/models/outreach";
import { FiPhone } from "react-icons/fi";
import InternalTaskForm from "./InternalTaskForm";

interface IPatientProfile {
  patient: Patient;
}

const PatientProfile = ({ patient }: IPatientProfile) => {
  const [relatedPatientData, setRelatedPatentData] = useState<{
    prefCHW: CommunityHealthWorker | null;
    prefServiceLocation: ServiceLocation | null;
    payer: Payer | null;
    primaryCareHome: PrimaryCareProv | null;
  }>({
    prefCHW: null,
    prefServiceLocation: null,
    payer: null,
    primaryCareHome: null
  });
  const { isOpen, onToggle } = useDisclosure();
  // const isMobile = useBreakpointValue({ base: true, sm: false });

  useEffect(() => {
    const fetchRelatedData = async () => {
      const chwService = new CommunityHealthWorkerService();
      const serviceLocationService = new ServiceLocationService();
      const payerService = new PayerService();
      const primaryCareProvService = new PrimaryCareProvService();
      const [prefCHW, prefServiceLocation, payer, primaryCareHome] =
        await Promise.allSettled([
          chwService.getCHW(patient.prefCHWId ?? ""),
          serviceLocationService.getServiceLocation(
            patient.prefServiceLocationId ?? ""
          ),
          payerService.getPayer(patient.payerId ?? ""),
          primaryCareProvService.getPrimaryCareProv(
            patient.primaryCareProvId ?? ""
          )
        ]).then((results) =>
          results.map((r, i) => {
            if (r.status === "fulfilled") {
              switch (i) {
                case 0:
                  return r.value as CommunityHealthWorker;
                case 1:
                  return r.value as ServiceLocation;
                case 2:
                  return r.value as Payer;
                case 3:
                  return r.value as PrimaryCareProv;
                default:
                  return null;
              }
            } else {
              return null;
            }
          })
        );
      // const prefBarber = await chwService.getCommunityHealthWorker(
      //   patient.prefBarberId ?? ""
      // );
      // const prefServiceLocation = await serviceLocationService.getBarbershop(
      //   patient.prefServiceLocationId ?? ""
      // );
      // const payer = await payerService.getPayer(patient.payerId ?? "");
      // const primaryCareHome = await primaryCareProvService.getPrimaryCareProv(
      //   patient.primaryCareProvId ?? ""
      // );
      setRelatedPatentData({
        prefCHW: prefCHW as CommunityHealthWorker | null,
        prefServiceLocation: prefServiceLocation as ServiceLocation | null,
        payer: payer as Payer | null,
        primaryCareHome: primaryCareHome as PrimaryCareProv | null
      });
    };
    fetchRelatedData();
  }, [
    patient.prefCHWId,
    patient.prefServiceLocationId,
    patient.payerId,
    patient.primaryCareProvId
  ]);

  return (
    <HStack
      className='patient-profile'
      align='center'
      justify='space-between'
      spacing={4}
      minH={"18rem"}
      bg={"gray.50"}
      mb={4}
      flexDir={["column", "row"]}
    >
      <Card w={["100%", "25%"]} h={["8rem", "26rem", "18rem"]}>
        <VStack
          align={"center"}
          justify={["space-evenly", "center"]}
          spacing={1}
          w={"full"}
          h={"full"}
          flexDir={["row", "column"]}
        >
          <Avatar size={["lg", "xl", "xl", "2xl"]} bg='blue.500' />
          <VStack align={["flex-start", "inherit"]}>
            <Heading as='h1' size='lg'>
              {patient.name?.fullName}
            </Heading>
            {patient.phoneNumber && (
              <Text>{parsePhoneNumber(patient.phoneNumber)}</Text>
            )}
            {patient.email && <Text>{patient.email}</Text>}
          </VStack>
        </VStack>
      </Card>
      <Card
        w={["100%", "70%"]}
        h={["23rem", "26rem", "18rem"]}
        justifyContent={["flex-end", "center"]}
        pb={[4, 0]}
        px={[4, 8]}
        gap={4}
      >
        <SimpleGrid columns={[1, 2, 3]} rowGap={[4, 8]}>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Sex</Text>
            <Text
              as={"b"}
              fontSize={["smaller", "small", "inherit"]}
              casing={"capitalize"}
            >
              {patient.sex}
            </Text>
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Ethnicity</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              Black
            </Text>
            {/* <Text as={"b"} fontSize={['smaller', 'inherit']}>{patient.ethnicities?.[0]}</Text> */}
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Date of Birth</Text>
            {patient.dob && (
              <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
                {DateTime.fromJSDate(patient.dob)
                  .toUTC()
                  .toFormat("MM/dd/yyyy")}
              </Text>
            )}
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Community Health Worker</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {relatedPatientData.prefCHW?.name.fullName ?? "None"}
            </Text>
          </VStack>
          {/* <VStack align={['center','start']}
          justify={['space-between', 'inherit']} flexDir={['row', 'column']} spacing={1}
          w={'full'}
          whiteSpace={'
            <Text fontSize={'small'}>Physician</Text>
            <Text as={"b"} fontSize={['smaller', 'inherit']}>Tem Woldeyesus</Text>
          </VStack> */}
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Service Location</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {relatedPatientData.prefServiceLocation?.businessName ?? "None"}
            </Text>
            {/* <Text as={"b"} fontSize={['smaller', 'inherit']}>[serviceLocation name]</Text> */}
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Last Active</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {patient.lastActivityDate
                ? DateTime.fromJSDate(patient.lastActivityDate).toFormat("DDD")
                : ""}
            </Text>
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Last BP Reading</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {patient.latestBpReading
                ? patient.latestBpReading.systolic +
                  "/" +
                  patient.latestBpReading.diastolic
                : ""}
            </Text>
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Primary Care Home</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {relatedPatientData.primaryCareHome?.businessName ?? "None"}
              {/* {primaryCareProvs?.find(
                (pcp) => pcp.id === patient.primaryCareProvId
              )?.businessName ?? "None"} */}
            </Text>
            {/* <Text as={"b"} fontSize={['smaller', 'inherit']}>[physician name]</Text> */}
          </VStack>
          <VStack
            align={["center", "start"]}
            justify={["space-between", "inherit"]}
            flexDir={["row", "column"]}
            spacing={1}
            w={"full"}
          >
            <Text fontSize={"small"}>Insurance Provider</Text>
            <Text as={"b"} fontSize={["smaller", "small", "inherit"]}>
              {relatedPatientData.payer?.businessName ?? "None"}
              {/* {payers?.find((p) => p.id === patient.payerId)?.businessName ??
                "None"} */}
            </Text>
            {/* <Text as={"b"} fontSize={['smaller', 'inherit']}>[serviceLocation name]</Text> */}
          </VStack>
        </SimpleGrid>
      </Card>
      <Card
        w={["100%", "10%", "10%", "5%"]}
        bg={"white"}
        h={["4rem", "26rem", "18rem"]}
        py={[0, 4]}
        px={[4, 0]}
        display={"flex"}
        flexDir={["row", "column"]}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        gap={2}
      >
        <PatientProfileForm
          patientId={patient.uid}
          btnProps={{ variant: "ghost" }}
          btnIconProps={{ height: 5, width: 5 }}
          useIconButton={true}
        />
        <NoteDrawer
          patientId={patient.uid}
          btnProps={{ position: "relative" }}
          btnIconProps={{ size: 20 }}
          useIconButton={true}
        />
        <>
          <Tooltip label={"Create New Outreach"}>
            <IconButton
              aria-label='log-patient-outreach'
              icon={<FiPhone size={20} />}
              onClick={onToggle}
              variant='ghost'
            />
          </Tooltip>
          <Modal isOpen={isOpen} onClose={onToggle} size={"full"}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>Log Patient Outreach</ModalHeader>
              <ModalBody>
                <PatientOutreacher
                  patientId={patient.uid}
                  outreachPurpose={OutreachPurpose.General}
                  onOutreachLogged={onToggle}
                  columns={[1, 2]}
                  gap={4}
                  w={"full"}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='blue' onClick={onToggle}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <InternalTaskForm patientId={patient.uid} />
      </Card>
    </HStack>
  );
};

export default PatientProfile;
