import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Avatar,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack
} from "@chakra-ui/react";
import useAuthentication from "@/hooks/useAuthentication";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useTourContext } from "@/hooks/useTourContext";
import useTourSteps from "@/hooks/useTourSteps";
import { FaRegMap, FaRegUser } from "react-icons/fa6";
import { TbDeviceDesktopHeart, TbLogout2 } from "react-icons/tb";
import { startCase } from "lodash";
import { useAppContext } from "@/hooks/useAppContext";
import { GrSystem } from "react-icons/gr";
import SupportEmailSender from "../organisms/SupportEmailSender";

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    startTour,
    state: { isTourOpen }
  } = useTourContext();
  const { getTourSteps } = useTourSteps();
  const { currentUser } = useCurrentUser();
  const { logout } = useAuthentication();
  const {
    state: { appMode },
    toggleAdminMode
  } = useAppContext();

  const { pathname } = location;

  const tourName = pathname.startsWith("/patient/")
    ? "patientDetail"
    : pathname.startsWith("/appointments")
    ? "appointments"
    : pathname.startsWith("/profile/")
    ? "userProfile"
    : pathname.startsWith("/patients")
    ? "patients"
    : pathname.startsWith("/")
    ? "home"
    : "";

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <MenuButton
        as={Box}
        display='flex'
        alignItems='center'
        cursor='pointer'
        flexDir={"row"}
        zIndex={10}
        p={2}
        px={3.5}
        rounded={8}
        onClick={() => setIsOpen(!isOpen)}
        className='user-profile-menu'
      >
        <HStack>
          {currentUser && (
            <Avatar
              name={`${currentUser.name?.first} ${currentUser.name?.last}`}
            />
          )}
          <VStack align='start' ml={2} spacing={0} display={["none", "inline"]}>
            <Text fontWeight='bold'>
              {currentUser?.name
                ? `${currentUser.name.first} ${currentUser.name.last}`
                : "User"}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {currentUser?.userType ? startCase(currentUser.userType) : ""}
            </Text>
          </VStack>
        </HStack>
      </MenuButton>
      <MenuList zIndex={isTourOpen ? 20 : "popover"}>
        <MenuItem
          display={"flex"}
          w={"full"}
          onClick={() => navigate(`/profile/${currentUser?.uid}`)}
          className='my-profile-btn'
          icon={<FaRegUser />}
        >
          My Profile
        </MenuItem>
        {currentUser?.isProgramAdmin && (
          <MenuItem
            display={"flex"}
            w={"full"}
            onClick={() => {
              toggleAdminMode();
            }}
            className='my-profile-btn'
            icon={appMode === "app" ? <GrSystem /> : <TbDeviceDesktopHeart />}
          >
            {appMode === "app" ? "Admin Mode" : "App Mode"}
          </MenuItem>
        )}
        <MenuItem
          display={"flex"}
          icon={<FaRegMap />}
          w={"full"}
          onClick={() => {
            startTour(
              getTourSteps({ stepSetName: tourName, withNavigation: false })
            );
          }}
        >
          Launch Page Tour
        </MenuItem>
        <SupportEmailSender />

        <MenuItem
          display={"flex"}
          w={"full"}
          onClick={logout}
          icon={<TbLogout2 />}
        >
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
