import { CholesterolReading } from "@oben-core-web/models/cholesterol-reading";
import { CholesterolReadingService } from "@oben-core-web/services/cholesterol-reading-service";
import { useEffect, useState } from "react";

const useCholesterolReadings = (patientId: string) => {
  const [cholesterolReadings, setCholesterolReadings] = useState<
    CholesterolReading[]
  >([]);

  useEffect(() => {
    const fetchReadings = async () => {
      if (patientId) {
        const cholesterolReadingService = new CholesterolReadingService();
        const readings =
          await cholesterolReadingService.getPatientCholesterolReadings(
            patientId
          );
        setCholesterolReadings(readings);
      }
    };
    fetchReadings();
  }, [patientId]);

  return { cholesterolReadings };
};

export default useCholesterolReadings;
