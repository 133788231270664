import { Card } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const AgeBarChart = ({ ages }: { ages: number[] }) => {
  const [chartData, setChartData] = useState<{
    categories: string[];
    series: { name: string; data: number[] }[];
  }>({ categories: [], series: [] });

  useEffect(() => {
    // Define the fixed 5-year intervals
    const ageRanges = [
      { label: "18-34", min: 18, max: 34 },
      { label: "35-55", min: 35, max: 55 },
      { label: "56-75", min: 56, max: 75 },
      { label: "76+", min: 76, max: Infinity }
    ];

    // Initialize counts for each age range
    const counts = ageRanges.map(() => 0);
    // Count occurrences in each age range
    ages.forEach((age: number) => {
      for (let i = 0; i < ageRanges.length; i++) {
        const { min, max } = ageRanges[i];
        if (age >= min && age <= max) {
          counts[i]++;
          break;
        }
      }
    });

    // Set chart data with categories and counts
    setChartData({
      categories: ageRanges.map((range) => range.label),
      series: [{ name: "Patient Count", data: counts }]
    });
  }, [ages]);

  // Chart options
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      zoom: { enabled: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false
      }
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "Age Distribution",
        offsetY: -20
      },
      labels: {
        rotateAlways: true
      }
    },
    yaxis: {
      title: {
        text: "Patient Count",
        offsetX: 6
      },
      stepSize: 1
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#00E396"],
    title: {
      align: "center"
    }
  };
  return (
    <Card className='age-bar-chart' w={"100%"} h={"16rem"}>
      <Chart
        options={chartOptions}
        series={chartData.series}
        type='bar'
        width='100%'
        height='250'
      />
    </Card>
  );
};

export default AgeBarChart;
