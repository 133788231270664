import {
  collection,
  collectionGroup,
  DocumentData,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
  setDoc,
  orderBy,
  QuerySnapshot,
  Transaction,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { BillableItem } from "../models/billable-item";
import { BillStatus } from "../models/bill-status-change";
import { GaLog } from "../utils/ga-log";

export class BillableItemService {
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._parentCollectionName = "billableEvents";
    this._collectionName = "billableItems";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get a stream of all BillableItems that a patient has not yet responded to approving.
  // Requires a composite index on the billableItems collection: patientId ASC, currentStatus ASC, serviceDate ASC
  streamPatientBillableItems({
    patientId,
    handleSnapshot
  }: {
    patientId: string;
    handleSnapshot: (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => void;
  }): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;

    const queryFilters = [
      where("patientId", "==", patientId),
      where("currentStatus", "==", BillStatus.New),
      orderBy("serviceDate")
    ];
    try {
      const queryRef = query(this._collectionGroup, ...queryFilters);
      unsubscribe = onSnapshot(
        queryRef,
        (snapshot) => {
          handleSnapshot(snapshot);
          GaLog.readCollection(
            this._collectionName,
            snapshot.docChanges().length,
            { isSubscription: true }
          );
        },
        (error) => {
          console.error("Error in onSnapshot:", error);
          // TODO: Do we need to throw an error here?
          GaLog.readCollection(this._collectionName, 0, {
            isSubscription: true
          });
        }
      );
    } catch (error) {
      console.log("SOME ERROR HAPPENED");
      GaLog.readError(this._collectionName, error, {
        isSubscription: true
      });
      throw error;
    }

    return { unsubscribe };
  }

  // Get all billable items for a particular Place-Based Care Provider within a specified date range. Default is current month (to date).
  // Requires a composite index on the billableItems collection: placeBasedCareProvId ASC, serviceDate ASC
  async getAllBillableItems({
    placeBasedCareProvId,
    start,
    end
  }: {
    placeBasedCareProvId: string;
    start: Date | null;
    end: Date | null;
  }): Promise<BillableItem[]> {
    const now = new Date();
    const startOfRange =
      start || new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfRange = end || now;
    const itemsQuery = query(
      this._collectionGroup,
      where("placeBasedCareProvId", "==", placeBasedCareProvId),
      where("serviceDate", ">=", startOfRange.toISOString()),
      where("serviceDate", "<", endOfRange.toISOString())
    );
    try {
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  // Get all billable items for a specified CHW
  async getCHWBillableItems(chwId: string): Promise<BillableItem[]> {
    try {
      const itemsQuery = query(
        this._collectionGroup,
        where("chwId", "==", chwId)
      );
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }


  // Get all billable items for a specified patient
  async getPatientBillableItems(patientId: string): Promise<BillableItem[]> {
    try {
      const itemsQuery = query(
        this._collectionGroup,
        where("patientId", "==", patientId)
      );
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  // Get all billable items for a specified billable event
  async getEventBillableItems(
    billableEventIdventId: string
  ): Promise<BillableItem[]> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${billableEventIdventId}/${this._collectionName}`
    );
    try {
      const itemsQuery = query(subcollectionRef);
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(subcollectionRef.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(subcollectionRef.path, error);
      throw error;
    }
  }

  async getBillableItem(itemId: string): Promise<BillableItem> {
    try {
      const itemQuery = query(
        this._collectionGroup,
        where("modelId", "==", itemId)
      );
      const qSnapshot = await getDocs(itemQuery);
      if (qSnapshot.empty) {
        throw new Error(`Billable Item not found: ${itemId}`);
      }
      const item = BillableItem.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(
        `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`,
        item.modelId
      );
      return item;
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  // When creating a new BillableItem via a firestore transaction, we need to first (outside of the transaction) create a
  // placeholder document with a unique ID, then update the document with the actual claim data.
  // Note that this is not async because it is only creating a placeholder document ID.
  createBillableItemId(eventId: string): string {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${eventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef);
      GaLog.addDocument(subcollectionRef.path, docRef.id); // log the new document ID (which is really just a placeholder)
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async addBillableItem(item: BillableItem): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef);
      await setDoc(docRef, { ...item.toJson(), modelId: docRef.id });
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateBillableItem(item: BillableItem): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, item.modelId);
      await updateDoc(docRef, item.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateBillableItemTx(item: BillableItem, transaction: Transaction): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, item.modelId);
      await transaction.update(docRef, item.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteBillableItem(item: BillableItem): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, item.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}
