import { UserType } from "@oben-core-web/constants/core-enums";
import { PatientNoteType } from "@oben-core-web/models/patient-note";
import { object, string, date } from "yup";

const patientNoteSchema = object({
  modelId: string(),
  patientId: string().required(),
  authorId: string().required(),
  authorType: string()
    .oneOf(
      [
        UserType.CHW,
        UserType.Pharmacist,
        UserType.Physician,
        UserType.ProgramManager
      ],
      "Invalid user type"
    )
    .required(),
  createdDate: date().required(),
  noteSourceId: string().required("Note must be related to a valid source"),
  noteType: string()
    .oneOf(
      [
        PatientNoteType.Appointment,
        PatientNoteType.CarePlan,
        PatientNoteType.PatientTask,
        PatientNoteType.General,
        PatientNoteType.MedAdherence,
        PatientNoteType.Medication
      ],
      "Invalid note type"
    )
    .required("Note type is required"),
  summary: string().required("Note header is required"),
  details: string().required("Note description is required")
});

export default patientNoteSchema;
