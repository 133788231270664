import { PendingPatient } from "@oben-core-web/models/pending-patient";
import { PendingPatientService } from "@oben-core-web/services/pending-patient-service";
import { useCallback, useState, useEffect } from "react";

const usePendingPatients = (placeBasedCareProvId: string) => {
  const [pendingPatients, setPendingPatients] = useState<PendingPatient[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const fetchPendingPatients = useCallback(
    async (placeBasedCareProvId: string) => {
      try {
        setLoading(true);
        const pendingPatientService = new PendingPatientService();
        const clients =
          await pendingPatientService.getPendingPatientsByPlaceBasedCareProvId(
            placeBasedCareProvId
          );
        setPendingPatients(clients);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );
  useEffect(() => {
    fetchPendingPatients(placeBasedCareProvId);
  }, [placeBasedCareProvId, fetchPendingPatients]);
  return { pendingPatients, refetch: fetchPendingPatients, loading, error };
};

export default usePendingPatients;
