import useAppointments from "@/hooks/useAppointments";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Card, Divider, Heading, HStack, Text } from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  LuCircle,
  LuCircleDashed,
  LuCalendar,
  // LuCircleSlash,
  // LuCircleDot,
  LuCircleOff
} from "react-icons/lu";

const DashboardAppointmentScheduling = () => {
  const [staffMemberIds, setStaffMemberIds] = useState<string>();

  const thirtyDaysAgo = useMemo(() => {
    return DateTime.now()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .minus({ days: 30 })
      .toJSDate();
  }, []);
  const thirtyDaysFromNow = useMemo(
    () =>
      DateTime.now()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .plus({ days: 30 })
        .toJSDate(),
    []
  );

  const { appointments } = useAppointments({
    pharmacistId: staffMemberIds,
    start: thirtyDaysAgo,
    end: thirtyDaysFromNow
    // statuses: [
    //   AppointmentStatus.New,
    //   AppointmentStatus.Confirmed,
    //   AppointmentStatus.Canceled,
    //   AppointmentStatus.LateCanceled,
    //   AppointmentStatus.NoShow,
    //   AppointmentStatus.Completed,
    // ]
  });
  const apptsByStatus = groupBy(appointments, (apt) => {
    const { statusChanges } = apt;
    return apt.currentStatus !== AppointmentStatus.Unknown
      ? apt.currentStatus
      : statusChanges[statusChanges.length - 1]?.status ??
          AppointmentStatus.Unknown;
  });
  const totalAppts = appointments.length;
  const fetchStaffMembers = useCallback(async () => {
    const staffMemberService = new StaffMemberService();
    return await staffMemberService.getAllStaffMembers([
      UserType.Pharmacist,
      UserType.Physician
    ]);
  }, []);
  useEffect(() => {
    const init = async () => {
      const users = await fetchStaffMembers();
      const staffMemberIds = users.map((wu) => wu.uid);
      setStaffMemberIds(staffMemberIds.join(","));
    };

    init();
  }, [fetchStaffMembers]);

  return (
    <Card w={["100%", "60%"]} p={5}>
      <HStack
        justifyContent={"space-between"}
        alignItems={["flex-start", "center"]}
        flexDir={["column", "row"]}
      >
        <Heading
          as={"h2"}
          mb={[0, 2]}
          fontSize={["md", "lg"]}
          display={"flex"}
          alignItems={"center"}
        >
          <LuCalendar /> &nbsp;Appointments and Scheduling
        </Heading>
        <Heading
          as={"i"}
          fontWeight={"normal"}
          mb={2}
          fontSize={["xs", "sm"]}
          display={"flex"}
        >
          {DateTime.fromJSDate(thirtyDaysAgo).toFormat("M/dd/yy")} -{" "}
          {DateTime.fromJSDate(thirtyDaysFromNow).toFormat("M/dd/yy")}
        </Heading>
      </HStack>

      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircle />
          <Text fontWeight={"normal"}>Needs Confirmation</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.New]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <CheckCircleIcon />
          <Text fontWeight={"normal"}>Confirmed Appointments</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Confirmed]?.length ?? 0}</Text>
      </HStack>
      <Divider my={1} color='lightgray' />
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleOff />
          <Text fontWeight={"normal"}>Cancellations</Text>
        </HStack>

        <Text>
          {
            +(
              ((apptsByStatus[AppointmentStatus.Canceled]?.length ?? 0) +
                (apptsByStatus[AppointmentStatus.LateCanceled]?.length ?? 0) /
                  totalAppts) *
              100
            ).toFixed(2)
          }
          %
          <Text as='span' fontWeight={"normal"}>
            &nbsp;(
            {(apptsByStatus[AppointmentStatus.Canceled]?.length ?? 0) +
              (apptsByStatus[AppointmentStatus.LateCanceled]?.length ?? 0)}
            )
          </Text>
        </Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleDashed />
          <Text fontWeight={"normal"}>No Shows</Text>
        </HStack>

        <Text>
          {
            +(
              ((apptsByStatus[AppointmentStatus.NoShow]?.length ?? 0) /
                totalAppts) *
              100
            ).toFixed(2)
          }
          %
          <Text as='span' fontWeight={"normal"}>
            &nbsp;(
            {apptsByStatus[AppointmentStatus.NoShow]?.length ?? 0})
          </Text>
        </Text>
      </HStack>
      {/* <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <CheckCircleIcon />
          <Text fontWeight={"normal"}>Completed Appointments</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Completed]?.length ?? 0}</Text>
      </HStack> */}
    </Card>
  );
};

export default DashboardAppointmentScheduling;
