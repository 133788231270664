import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  addDoc,
  updateDoc,
  query,
  where,
  documentId
} from "firebase/firestore";
import { Database } from "../../firebase";

import { ServiceLocation } from "../models/service-location";
import { GaLog } from "../utils/ga-log";

export class ServiceLocationService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "serviceLocations";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single ServiceLocation's profile
  streamServiceLocation(
    id: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return ServiceLocation.fromFirestore(docSnap);
      } else {
        return ServiceLocation.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (id) {
        const docRef = doc(this._collectionReference, id);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {
              isSubscription: true
            });
          },
          (error) => {
            console.error("Error in onSnapshot:", error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, id, {
              isSubscription: true
            });
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {
        isSubscription: true
      });
      throw error;
    }
  }

  async getAllServiceLocations(): Promise<ServiceLocation[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        ServiceLocation.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getServiceLocation(serviceLocationId: string): Promise<ServiceLocation> {
    try {
      const docRef = doc(this._collectionReference, serviceLocationId);
      const qSnapshot = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, serviceLocationId, {
        isSubscription: false
      });
      return ServiceLocation.fromFirestore(qSnapshot);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvServiceLocations(
    placeBasedCareProvId: string
  ): Promise<ServiceLocation[]> {
    try {
      const locationQuery = query(
        this._collectionReference,
        where("placeBasedCareProvIds", "array-contains", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(locationQuery);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        ServiceLocation.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvServiceLocationCount(
    placeBasedCareProvId: string
  ): Promise<number> {
    try {
      const locationQuery = query(
        this._collectionReference,
        where("placeBasedCareProvIds", "array-contains", placeBasedCareProvId)
      );
      const aqSnapshot = await getCountFromServer(locationQuery);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a ServiceLocation exists
  async validateServiceLocation(serviceLocationId: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, serviceLocationId);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getServiceLocations(serviceLocationIds: string[]): Promise<ServiceLocation[]> {
    try {
      const serviceLocationQuery = query(
        this._collectionReference,
        where(documentId(), "in", serviceLocationIds)
      );
      const serviceLocationDocs = await getDocs(serviceLocationQuery);
      const serviceLocations = serviceLocationDocs.docs.map((serviceLocation) =>
        ServiceLocation.fromFirestore(serviceLocation)
      );
      GaLog.readCollection(
        this._collectionReference.path,
        serviceLocationDocs.size,
        { isSubscription: false }
      );
      return serviceLocations;
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addServiceLocation(serviceLocation: ServiceLocation): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        serviceLocation.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateServiceLocation(serviceLocation: ServiceLocation): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, serviceLocation.id);
      await updateDoc(docRef, serviceLocation.toJson());
      GaLog.updateDocument(this._collectionReference.path, serviceLocation.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // ServiceLocations can only be updated, not deleted
  // async deleteServiceLocation(serviceLocationId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, serviceLocationId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, serviceLocationId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
