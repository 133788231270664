import { useMemo, useCallback } from "react";
import { useAuthContext } from "./useAuthContext";
import { PlaceBasedCareProvService } from "@oben-core-web/services/place-based-care-prov-service";

const usePlaceBasedCareProvider = () => {
  const {
    state: { placeBasedCareProvider },
    setPlaceBasedCareProvider
  } = useAuthContext();

  const refetch = useCallback(async () => {
    if (placeBasedCareProvider) {
      const pbcpService = new PlaceBasedCareProvService();
      const refreshedPBCP = await pbcpService.getPlaceBasedCareProv(
        placeBasedCareProvider.id
      );
      setPlaceBasedCareProvider(refreshedPBCP ?? null);
    }
  }, [placeBasedCareProvider, setPlaceBasedCareProvider]);

  return useMemo(
    () => ({ placeBasedCareProvider, refetch }),
    [placeBasedCareProvider, refetch]
  );
};

export default usePlaceBasedCareProvider;
