import { Patient } from "@oben-core-web/models/patient";
import { Outreach } from "@oben-core-web/models/outreach";
import { PendingPatient } from "@oben-core-web/models/pending-patient";
import { ProgramManagerQueryService } from "@oben-core-web/services/program-manager-query-service";
import React, { useCallback, useEffect, useState } from "react";
import PatientEnrollmentTable from "../molecules/PatientEnrollmentTable";
import EnrollmentWizard from "./EnrollmentWizard";
import { PatientType } from "@oben-core-web/constants/core-enums";

interface IPatientEnrollment {
  placeBasedCareProvId: string;
  onPatientEnrolled: () => void;
}

const PatientEnrollment = ({
  placeBasedCareProvId,
  onPatientEnrolled
}: IPatientEnrollment) => {
  const [patients, setPatients] = useState<
    { patient: Patient; enrollmentOutreach: Outreach | null }[]
  >([]);
  const [selectedPatient, setSelectedPatient] = useState<
    Patient | PendingPatient
  >();

  const fetchEnrollmentPatientData = useCallback(
    async (placeBasedCareProvId: string) => {
      const patientData =
        await ProgramManagerQueryService.getEnrollmentPatients({
          placeBasedCareProvId
        });
      setPatients(patientData);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      if (placeBasedCareProvId) {
        fetchEnrollmentPatientData(placeBasedCareProvId);
      }
    };
    fetchData();
  }, [fetchEnrollmentPatientData, placeBasedCareProvId]);

  return (
    <>
      <PatientEnrollmentTable
        enrollmentPatients={patients}
        setEnrollmentPatient={setSelectedPatient}
      />
      {selectedPatient && (
        <EnrollmentWizard
          isOpen={!!selectedPatient}
          onToggle={() => {
            setSelectedPatient(undefined);
            fetchEnrollmentPatientData(placeBasedCareProvId);
            onPatientEnrolled();
          }}
          patientId={
            selectedPatient instanceof Patient
              ? selectedPatient.uid
              : selectedPatient.id
          }
          patientType={
            selectedPatient instanceof Patient
              ? PatientType.Patient
              : selectedPatient instanceof PendingPatient
              ? PatientType.PendingPatient
              : PatientType.Patient
          }
        />
      )}
    </>
  );
};

export default PatientEnrollment;
