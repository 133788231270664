import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import staffMemberSchema from "@/resolvers/staffMember";
import { AddIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Switch,
  Select,
  Checkbox,
  Center
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IStaffMemberData,
  StaffMember
} from "@oben-core-web/models/staff-member";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { startCase } from "lodash";
import { parsePhoneNumber } from "@/lib/parseUtils";
import useProgramStaffMembers from "@/hooks/useProgramStaffMembers";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { UserType } from "@oben-core-web/constants/core-enums";
import { DateTime } from "luxon";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";
import { CloudFunctions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
// import { DefaultWorkingHours } from "@oben-core-web/models/default-working-hours";

const AdminStaffMemberManagement = () => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { staffMembers, refetch } = useProgramStaffMembers(
    placeBasedCareProvider!.id
  );
  const [selectedUser, setSelectedUser] = useState<StaffMember>();
  const columnHeaders: (
    | ColDef<StaffMember & { actions: any }>
    | (ColDef<StaffMember & { actions: any }> & {
        children: ColDef<StaffMember & { actions: any }>[];
      })
  )[] = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      minWidth: 80,
      cellRenderer: ({ data }: any) => (
        <IconButton
          aria-label={`edit-staff-member-${data.uid}`}
          icon={<EditIcon />}
          variant='ghost'
          onClick={() => {
            setSelectedUser(data);
            onToggle();
          }}
        />
      )
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 0.3,
      minWidth: 80,
      cellRenderer: ({ data }: any) => {
        const updateEnabledStatus = httpsCallable<
          { uid: string; enabled: boolean },
          CloudFunctionResponse
        >(CloudFunctions, "setUserEnabled");
        return (
          <Switch
            isChecked={data.enabled}
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              await updateEnabledStatus({
                uid: data.uid,
                enabled: event.target.checked
              });
              await refetch();
            }}
          />
        );
      }
    },
    {
      field: "name.fullName",
      headerName: "Name",
      minWidth: 100,
      flex: 0.8
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      minWidth: 100,
      valueFormatter: ({ data }) => {
        return parsePhoneNumber(data?.phoneNumber ?? "");
      },
      flex: 0.7
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100,
      flex: 0.7
    },
    {
      field: "userType",
      headerName: "Role",
      valueFormatter: ({ data }) => {
        return startCase(data?.userType);
      },
      minWidth: 100,
      flex: 0.8
    },
    {
      field: "address",
      headerName: "Address",
      valueGetter: ({ data }) => {
        return data!.address?.completeAddress ?? "";
      },
      flex: 0.8
    },
    {
      field: "npi",
      headerName: "NPI",
      flex: 0.5
    },
    {
      field: "isProgramAdmin",
      headerName: "Program Admin",
      flex: 0.65,
      cellRenderer: ({ data }: any) => {
        const updateAdminStatusCallable = httpsCallable<
          { uid: string; status: boolean },
          CloudFunctionResponse
        >(CloudFunctions, "setUserProgramAdminStatus");

        return (
          <Switch
            isChecked={data.isProgramAdmin}
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              const webUserService = new StaffMemberService();
              const webUser = StaffMember.fromMap(data.uid, data);
              webUser.isProgramAdmin = event.target.checked;
              await updateAdminStatusCallable({
                uid: webUser.uid,
                status: webUser.isProgramAdmin
              }).then(async () => {
                await webUserService
                  .updateStaffMember(webUser)
                  .then(async () => {
                    toast({
                      status: "success",
                      description: `User ${
                        !event.target.checked ? "Enabled" : "Disabled"
                      }`
                    });
                    await refetch();
                  });
              });
            }}
          />
        );
      }
    },
    {
      field: "defaultWorkingHours",
      headerName: "Working Hours Set",
      flex: 0.7,
      valueGetter: ({ data }) => data!.defaultWorkingHours.length > 0,
      cellRenderer: ({ data }: any) => {
        return (
          <Center h={"full"}>
            <Checkbox
              isChecked={data.defaultWorkingHours ?? false}
              alignSelf={"center"}
              justifySelf={"center"}
            />
          </Center>
        );
      }
    }
  ];

  return (
    <VStack alignItems={"flex-start"} h={"full"}>
      <HStack alignItems={"center"} justifyContent={"space-between"} w={"full"}>
        <Heading fontSize={"large"} as={"h1"}>
          Manage Users
        </Heading>
        <Button onClick={onToggle} size='sm' colorScheme='blue'>
          Add User
        </Button>
      </HStack>
      <Box className='ag-theme-quartz' h={"full"} w={"full"}>
        <AgGridReact
          columnDefs={columnHeaders}
          rowData={staffMembers as (StaffMember & { actions: any })[]}
        />
      </Box>
      {isOpen && (
        <AdminStaffMemberForm
          isOpen={isOpen}
          selectedUser={selectedUser}
          closeModal={() => {
            if (selectedUser) {
              setSelectedUser(undefined);
            }
            onToggle();
          }}
          onSave={() => {
            refetch();
            if (selectedUser) {
              setSelectedUser(undefined);
            }
            onToggle();
          }}
        />
      )}
    </VStack>
  );
};

export default AdminStaffMemberManagement;

interface IAdminStaffMemberForm {
  isOpen: boolean;
  selectedUser?: StaffMember;
  closeModal: () => void;
  onSave: () => void;
}

const AdminStaffMemberForm = ({
  isOpen,
  selectedUser,
  closeModal,
  onSave
}: IAdminStaffMemberForm) => {
  const toast = useToast();
  const isEditMode = !!selectedUser;
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, dirtyFields }
  } = useForm<
    Omit<IStaffMemberData, "defaultWorkingHours"> & {
      defaultWorkingHours: {
        dayOfWeek: number;
        startTime: string;
        endTime: string;
      }[];
    }
  >({
    defaultValues: isEditMode
      ? ({
          uid: selectedUser.uid,
          ...selectedUser.toJson(),
          defaultWorkingHours:
            selectedUser.defaultWorkingHours?.map((dwh) => ({
              ...dwh,
              startTime: DateTime.now()
                .set({
                  hour: dwh.startTime.getHours(),
                  minute: dwh.startTime.getMinutes()
                })
                .toFormat("HH':'mm") as unknown as string,
              endTime: DateTime.now()
                .set({
                  hour: dwh.endTime.getHours(),
                  minute: dwh.endTime.getMinutes()
                })
                .toFormat("HH':'mm") as unknown as string
            })) ?? []
        } as any)
      : StaffMember.fromMap("", {
          enabled: true,
          pwdNeedsReset: true,
          placeBasedCareProvId: placeBasedCareProvider!.id,
          defaultWorkingHours: [] as {
            dayOfWeek: number;
            startTime: string;
            endTime: string;
          }[]
        }),
    resolver: yupResolver(staffMemberSchema) as any
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "defaultWorkingHours"
  });
  const submissionHandler = async (
    data: Omit<IStaffMemberData, "defaultWorkingHours"> & {
      defaultWorkingHours: {
        dayOfWeek: number;
        startTime: string;
        endTime: string;
      }[];
    }
  ) => {
    // if (!isDirty) return;
    try {
      const parsedWorkingHours = data.defaultWorkingHours.map((dwh) => {
        const [startHour, startMin] = dwh.startTime.split(":");
        const [endHour, endMin] = dwh.endTime.split(":");
        return {
          ...dwh,
          startTime: new Date(
            1970,
            0,
            1,
            parseInt(startHour),
            parseInt(startMin),
            0,
            0
          ),
          endTime: new Date(
            1970,
            0,
            1,
            parseInt(endHour),
            parseInt(endMin),
            0,
            0
          )
        };
      });
      const webUser = StaffMember.fromMap(data.uid ?? "", {
        ...data,
        defaultWorkingHours: parsedWorkingHours
      });
      const webUserService = new StaffMemberService();
      if (isEditMode) {
        if (dirtyFields.isProgramAdmin) {
          const updateAdminStatusCallable = httpsCallable<
            { uid: string; status: boolean },
            CloudFunctionResponse
          >(CloudFunctions, "setUserProgramAdminStatus");
          await updateAdminStatusCallable({
            uid: webUser.uid,
            status: webUser.isProgramAdmin
          });
        }
        if (dirtyFields.enabled) {
          const updateEnabledStatus = httpsCallable<
            { uid: string; enabled: boolean },
            CloudFunctionResponse
          >(CloudFunctions, "setUserEnabled");
          await updateEnabledStatus({
            uid: webUser.uid,
            enabled: webUser.enabled
          });
        }
        await webUserService.updateStaffMember(webUser).then(() => {
          toast({
            status: "success",
            description: "Successfully updated user"
          });
        });
      } else {
        const addStaffMemberCallable = httpsCallable<
          IStaffMemberData,
          CloudFunctionResponse
        >(CloudFunctions, "createStaffMember");
        await addStaffMemberCallable(webUser.toJson() as IStaffMemberData).then(
          () => {
            toast({
              status: "success",
              description: "Successfully created user"
            });
          }
        );
      }
      onSave && onSave();
    } catch (e) {
      console.log("Admin StaffMember Form Error", e);
      toast({
        status: "error",
        description: isEditMode
          ? "Failed to update user"
          : "Failed to create user"
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>{isEditMode ? "Edit User" : "Create User"}</ModalHeader>
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!errors.name?.first}>
                <FormLabel fontSize='small'>First Name</FormLabel>
                <Input {...register("name.first")} />
                <FormErrorMessage>
                  {errors.name?.first?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.name?.last}>
                <FormLabel fontSize='small'>Last Name</FormLabel>
                <Input {...register("name.last")} />
                <FormErrorMessage>
                  {errors.name?.last?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.name?.display}>
                <FormLabel fontSize='small'>Display Name</FormLabel>
                <Input {...register("name.display")} />
                <FormErrorMessage>
                  {errors.name?.display?.message}
                </FormErrorMessage>
              </FormControl>
              {!isEditMode && (
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel fontSize='small'>Email</FormLabel>
                  <Input {...register("email")} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel fontSize='small'>Phone Number</FormLabel>
                <Input {...register("phoneNumber")} />
                <FormErrorMessage>
                  {errors.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <FormControl isInvalid={!!errors.userType}>
                  <FormLabel fontSize='small'>Role</FormLabel>
                  <Select {...register("userType")}>
                    <option value={UserType.Pharmacist}>
                      {UserType.Pharmacist}
                    </option>
                    <option value={UserType.Physician}>
                      {UserType.Physician}
                    </option>
                    <option value={UserType.ProgramManager}>
                      {startCase(UserType.ProgramManager)}
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {errors.userType?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.npi}>
                  <FormLabel fontSize='small'>NPI</FormLabel>
                  <Input {...register("npi")} />
                  <FormErrorMessage>{errors.npi?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <Box border={"1px solid lightgray"} p={4} rounded={"md"}>
                <Heading as='h2' fontSize='medium' alignSelf={"flex-start"}>
                  Address
                </Heading>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"full"}
                >
                  <FormControl isInvalid={!!errors.address?.street1}>
                    <FormLabel fontSize='small'>Line 1</FormLabel>
                    <Input {...register("address.street1")} />
                    <FormErrorMessage>
                      {errors.address?.street1?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.address?.street2}>
                    <FormLabel fontSize='small'>Line 2</FormLabel>
                    <Input {...register("address.street2")} />
                    <FormErrorMessage>
                      {errors.address?.street2?.message}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"full"}
                >
                  <FormControl isInvalid={!!errors.address?.city}>
                    <FormLabel fontSize='small'>City</FormLabel>
                    <Input {...register("address.city")} />
                    <FormErrorMessage>
                      {errors.address?.city?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.address?.state}>
                    <FormLabel fontSize='small'>State</FormLabel>
                    <Input {...register("address.state")} />
                    <FormErrorMessage>
                      {errors.address?.state?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.address?.zip}>
                    <FormLabel fontSize='small'>Zip Code</FormLabel>
                    <Input {...register("address.zip")} />
                    <FormErrorMessage>
                      {errors.address?.zip?.message}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </Box>

              <VStack w={"full"} border={"1px solid lightgray"} p={4}>
                <Heading as='h2' fontSize='medium' alignSelf={"flex-start"}>
                  Default Working Hours
                </Heading>
                {fields.map((field, index) => (
                  <HStack
                    key={field.id}
                    spacing={4}
                    mb={2}
                    alignItems={"flex-end"}
                    display={"flex"}
                    w={"full"}
                  >
                    <FormControl
                      isInvalid={
                        !!errors.defaultWorkingHours?.[index]?.dayOfWeek
                      }
                    >
                      <FormLabel
                        fontSize='sm'
                        htmlFor={`defaultWorkingHours.${index}.dayOfWeek`}
                      >
                        Day
                      </FormLabel>
                      <Controller
                        name={`defaultWorkingHours.${index}.dayOfWeek`}
                        control={control}
                        render={({ field }) => (
                          <Select {...field}>
                            {daysOfWeek.map((day) => (
                              <option key={day.day} value={day.day}>
                                {day.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>
                        {
                          errors.defaultWorkingHours?.[index]?.dayOfWeek
                            ?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!errors.defaultWorkingHours?.[index]?.startTime
                      }
                    >
                      <FormLabel
                        fontSize='sm'
                        htmlFor={`defaultWorkingHours.${index}.startTime`}
                      >
                        Start Time
                      </FormLabel>
                      <Controller
                        name={`defaultWorkingHours.${index}.startTime`}
                        control={control}
                        render={({ field }) => <Input {...field} type='time' />}
                      />
                      <FormErrorMessage>
                        {
                          errors.defaultWorkingHours?.[index]?.startTime
                            ?.message
                        }
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!errors.defaultWorkingHours?.[index]?.endTime}
                    >
                      <FormLabel
                        fontSize='sm'
                        htmlFor={`defaultWorkingHours.${index}.endTime`}
                      >
                        End Time
                      </FormLabel>
                      <Controller
                        name={`defaultWorkingHours.${index}.endTime`}
                        control={control}
                        render={({ field }) => <Input {...field} type='time' />}
                      />
                      <FormErrorMessage>
                        {errors.defaultWorkingHours?.[index]?.endTime?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <IconButton
                      aria-label='Remove working hour'
                      icon={<CloseIcon />}
                      onClick={() => remove(index)}
                    />
                  </HStack>
                ))}
                <Button
                  leftIcon={<AddIcon />}
                  onClick={() =>
                    append({ dayOfWeek: 0, startTime: "", endTime: "" })
                  }
                  size='sm'
                  alignSelf={"flex-start"}
                >
                  Add Working Hour
                </Button>
              </VStack>

              <FormControl isInvalid={!!errors.isProgramAdmin}>
                <FormLabel fontSize='small'>Program Admin</FormLabel>
                <Switch {...register("isProgramAdmin")} />
                <FormErrorMessage>
                  {errors.isProgramAdmin?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.enabled}>
                <FormLabel fontSize='small'>Enabled</FormLabel>
                <Switch {...register("enabled")} />
                <FormErrorMessage>{errors.enabled?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              isDisabled={isSubmitting || !isValid}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const daysOfWeek = [
  { day: 0, name: "Sunday" },
  { day: 1, name: "Monday" },
  { day: 2, name: "Tuesday" },
  { day: 3, name: "Wednesday" },
  { day: 4, name: "Thursday" },
  { day: 5, name: "Friday" },
  { day: 6, name: "Saturday" }
];
