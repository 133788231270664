import { useCallback, useEffect, useState } from "react";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { MedicationBaseService } from "@oben-core-web/services/medication-base-service";

const useMedicationBase = (placeBasedCareProvId: string) => {
  const [medicationBases, setMedicationBases] = useState<MedicationBase[]>([]);
  const [error, setError] = useState<unknown>();

  const fetchMedBases = useCallback(async () => {
    try {
      const medBaseService = new MedicationBaseService();
      const medBases = await medBaseService.getAllMedicationBases(
        placeBasedCareProvId
      );
      setMedicationBases(medBases);
    } catch (e) {
      setError(e);
    }
  }, [placeBasedCareProvId]);

  useEffect(() => {
    fetchMedBases();
  }, [fetchMedBases]);
  return { medicationBases, error, refetch: fetchMedBases };
};

export default useMedicationBase;
