import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import { object, string, number } from "yup";

const dosageSchema = object({
  strength: number().min(0).required(),
  frequencyCount: number().min(0).required(),
  frequencyPeriod: string()
    .oneOf([FreqPeriod.Daily, FreqPeriod.Weekly], "Invalid frequency period")
    .required(),
  durationCount: number().min(0).required()
});

export default dosageSchema;
