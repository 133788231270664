import PatientProfile from "../molecules/PatientProfile";
import usePatientSubscription from "@/hooks/usePatientSubscription";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Center,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import CarePlanWizard from "../organisms/CarePlanWizard";
import usePatientCarePlans from "@/hooks/usePatientCarePlans";
import { PlanStatus } from "@oben-core-web/models/care-plan";
import { CarePlan } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import PatientNotes from "../organisms/PatientNotes";
import CarePlanCard from "../organisms/CarePlanCard";
import { PatientService } from "@oben-core-web/services/patient-service";
import BPChart from "../molecules/BPChart";
import MedicationChart from "../molecules/MedicationChart";
import MedAdherenceChart from "../molecules/MedAdherenceChart";
import PatientRecommendationTable from "../molecules/PatientRecommendationTable";
import usePatientAppointments from "@/hooks/usePatientAppointments";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";
import { Patient } from "@oben-core-web/models/patient";
import { Appointment } from "@oben-core-web/models/appointment";
import PatientAppointment from "../organisms/PatientAppointment";
import PatientOutreachTable from "../molecules/PatientOutreachTable";
import { UserType } from "@oben-core-web/constants/core-enums";
import useCurrentUser from "@/hooks/useCurrentUser";
import PatientAppointmentTable from "../molecules/PatientAppointmentTable";

// TODO: add current bp reading -- add where it was recorded too
const PatientDetail = () => {
  const { id } = useParams();
  const { currentUser } = useCurrentUser();
  const [newCarePlanId, setNewCarePlanId] = useState<string>();
  const { patient } = usePatientSubscription(id);
  const [showMedAdherences, setShowMedAdherences] = useState(false);
  const { isOpen: patientApptIsOpen, onToggle: togglePatientAppt } =
    useDisclosure();
  const [apptData, setApptData] = useState<{
    patient: Patient;
    appointment: Appointment;
  }>();
  const { isOpen, onToggle } = useDisclosure();
  const { currentCarePlan } = usePatientCarePlans(id);
  const { appointments } = usePatientAppointments({
    patientId: id ?? "",
    statuses: [AppointmentStatus.Confirmed]
  });
  const createDraftCarePlan = async () => {
    if (!id || !patient) return;
    const carePlanService = new CarePlanService();
    const carePlan = new CarePlan({
      modelId: "",
      patientId: id,
      createdDate: new Date(),
      status: PlanStatus.Draft,
      bpGoals: [],
      diagnosis: "",
      history: "",
      placeBasedCareProvId: patient.placeBasedCareProvId
    });
    const draftCarePlan = await carePlanService
      .addCarePlan(carePlan)
      .then((carePlanId) => {
        const patientService = new PatientService();
        patient.currentCarePlanId = carePlanId;
        patientService.updatePatient(patient);
        return carePlanId;
      });
    setNewCarePlanId(draftCarePlan);
    onToggle();
  };
  const startAppointment = (appointment: Appointment) => {
    if (patient) {
      setApptData({ patient: patient, appointment });
      togglePatientAppt();
    }
  };
  return (
    <Box>
      {patient && (
        <>
          {isOpen ? (
            <CarePlanWizard
              patient={patient}
              exitWizard={onToggle}
              // isEditMode={currentCarePlan.status === PlanStatus.Current}
              carePlanId={currentCarePlan?.modelId ?? newCarePlanId}
            />
          ) : (
            <Box>
              {apptData?.patient && apptData.appointment && (
                <PatientAppointment
                  patient={apptData.patient}
                  appointmentStarted={patientApptIsOpen}
                  toggleApptStarted={togglePatientAppt}
                  appointment={apptData.appointment}
                  exitWizard={() => {
                    setApptData(undefined);
                    togglePatientAppt();
                  }}
                />
              )}
              <PatientProfile patient={patient} />
              {appointments.length > 0 &&
                appointments.every((a) => !!a.date) && (
                  <Card alignItems={"flex-start"} gap={2} p={4} w={"full"}>
                    Patient Appointments
                    {appointments.map((appt) => (
                      <HStack
                        w={"full"}
                        alignItems={["flex-start", "center"]}
                        justifyContent={"space-between"}
                        border={"1px solid lightgray"}
                        rounded={"md"}
                        p={2}
                        flexDir={["column", "row"]}
                        key={`appt-${appt.id}`}
                      >
                        <HStack
                          flexDir={["column", "row"]}
                          spacing={0}
                          alignItems={"flex-start"}
                        >
                          <Text
                            as='b'
                            display={"flex"}
                            alignItems={["flex-start", "center"]}
                            justifyContent={["flex-start", "inherit"]}
                          >
                            {DateTime.fromJSDate(appt.date!).toFormat(
                              "M/d/yy - h:mma"
                            )}
                          </Text>
                          <Text>&nbsp;@&nbsp;</Text>
                          <Text fontWeight={"normal"}>
                            {appt.serviceLocationName}
                          </Text>
                        </HStack>
                        <Tooltip
                          label={
                            currentUser!.userType !== UserType.Pharmacist &&
                            currentUser!.userType !== UserType.Physician
                              ? "Must be medical staff"
                              : "Start a patient appointment"
                          }
                        >
                          <Button
                            colorScheme='blue'
                            onClick={() => {
                              startAppointment(appt);
                            }}
                            size={"sm"}
                            w={["full", "auto"]}
                            isDisabled={
                              currentUser!.userType !== UserType.Pharmacist &&
                              currentUser!.userType !== UserType.Physician
                            }
                          >
                            Start Appointment
                          </Button>
                        </Tooltip>
                      </HStack>
                    ))}
                  </Card>
                )}
              <Tabs
                mt={4}
                isLazy
                isFitted
                variant='enclosed-colored'
                bg={"white"}
                borderRadius={"lg"}
                className='patient-profile-tabs'
              >
                <TabList mb='1em'>
                  <Tab fontSize={["xs", "inherit"]}>Charts</Tab>
                  {currentUser?.userType !== UserType.ProgramManager && (
                    <Tab fontSize={["xs", "inherit"]} className='care-plan-tab'>
                      Care Plan
                    </Tab>
                  )}
                  <Tab
                    fontSize={["xs", "inherit"]}
                    className='patient-notes-tab'
                  >
                    Patient Notes
                  </Tab>
                  {currentUser?.userType === UserType.Physician && (
                    <Tab
                      fontSize={["xs", "inherit"]}
                      className='chw-recommendations'
                    >
                      Recommendation Letters
                    </Tab>
                  )}
                  <Tab
                    fontSize={["xs", "inherit"]}
                    className='appointment-history'
                  >
                    Appointment History
                  </Tab>
                  <Tab
                    fontSize={["xs", "inherit"]}
                    className='outreach-history'
                  >
                    Outreach History
                  </Tab>
                </TabList>
                <TabPanels>
                  {id && (
                    <TabPanel minH={"20rem"}>
                      <BPChart
                        patientId={id}
                        height={400}
                        width={"100%"}
                        canEditGoal={false}
                        containerProps={{ marginBottom: 12 }}
                      />
                      <Box>
                        {showMedAdherences ? (
                          <MedAdherenceChart
                            patientId={id}
                            isEditable={false}
                            toggleChart={() => setShowMedAdherences((c) => !c)}
                            height={250}
                          />
                        ) : (
                          <MedicationChart
                            patientId={id}
                            isEditable={false}
                            toggleChart={() => setShowMedAdherences((c) => !c)}
                            height={250}
                          />
                        )}
                      </Box>
                    </TabPanel>
                  )}
                  <TabPanel className='care-plan-tab' minH={"20rem"}>
                    {currentCarePlan ? (
                      <CarePlanCard
                        patientId={patient.uid}
                        openCarePlanWizard={onToggle}
                      />
                    ) : (
                      <SimpleGrid
                        columns={2}
                        spacing={52}
                        justifyContent={"center"}
                        alignItems={"center"}
                        h={"25rem"}
                        px={12}
                      >
                        <Center>
                          <Text fontSize={"xx-large"}>
                            It's easy to get your blood pressure on track
                          </Text>
                        </Center>
                        <VStack
                          justifyContent={"center"}
                          textAlign={"start"}
                          alignItems={"flex-start"}
                          h={"full"}
                        >
                          <b style={{ fontSize: "1.25rem" }}>
                            1. We will talk about your numbers
                          </b>
                          <b style={{ fontSize: "1.25rem" }}>
                            2. Create a plan and set goals
                          </b>
                          <b style={{ fontSize: "1.25rem" }}>
                            3. Share the plan and schedule follow up
                          </b>
                          <Button
                            alignSelf={"center"}
                            onClick={createDraftCarePlan} // Clicking this button should create a draft careplan if one doesn't already exist
                            size={"lg"}
                            w={"full"}
                            mt={8}
                            colorScheme='blue'
                          >
                            Get Started
                          </Button>
                        </VStack>
                      </SimpleGrid>
                    )}
                  </TabPanel>
                  <TabPanel minH={"20rem"} pt={0}>
                    <PatientNotes patientId={patient.uid} />
                  </TabPanel>
                  {currentUser?.userType === UserType.Physician && (
                    <TabPanel minH={"20rem"}>
                      <PatientRecommendationTable
                        patientId={patient.uid}
                        h={"20rem"}
                      />
                    </TabPanel>
                  )}
                  <TabPanel minH={"20rem"} pt={0}>
                    <PatientAppointmentTable
                      patientId={patient.uid}
                      h={"20rem"}
                    />
                  </TabPanel>
                  <TabPanel minH={"20rem"} pt={0}>
                    <PatientOutreachTable patientId={patient.uid} h={"20rem"} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PatientDetail;
