import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  addDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { CommunityHealthWorker } from "../models/community-health-worker";
import { GaLog } from "../utils/ga-log";

export class CommunityHealthWorkerService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "communityHealthWorkers";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single CommunityHealthWorker's profile
  streamCHW(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return CommunityHealthWorker.fromFirestore(docSnap);
      } else {
        return CommunityHealthWorker.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {isSubscription: true});
          },
          (error) => {
            console.error("Error in onSnapshot:", error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, uid, {isSubscription: true});
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {isSubscription: true});
      throw error;
    }
  }

  async getAllCHWs(): Promise<CommunityHealthWorker[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => CommunityHealthWorker.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getCHWsByPlaceBasedCareProvId(placeBasedCareProvId: string): Promise<CommunityHealthWorker[]> {
    try {
      const chwQuery = query(
        this._collectionReference,
        where("placeBasedCareProvIds", "array-contains", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(chwQuery);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => CommunityHealthWorker.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getServiceLocationCHWs(serviceLocationId: string): Promise<CommunityHealthWorker[]> {
    try {
      const chwQuery = query(
        this._collectionReference,
        where("serviceLocationIds", "array-contains", serviceLocationId)
      );
      const qSnapshot = await getDocs(chwQuery);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => CommunityHealthWorker.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getServiceLocationCHWCount(serviceLocationId: string): Promise<number> {
    try {
      const chwQuery = query(
        this._collectionReference,
        where("serviceLocationIds", "array-contains", serviceLocationId)
      );
      const aqSnapshot = await getCountFromServer(chwQuery);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a CommunityHealthWorker exists
  async validateCHW(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getCHW(uid: string): Promise<CommunityHealthWorker> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Community Health Worker not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return CommunityHealthWorker.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addCHW(chw: CommunityHealthWorker): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        chw.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateCHW(chw: CommunityHealthWorker): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, chw.uid);
      await updateDoc(docRef, chw.toJson());
      GaLog.updateDocument(this._collectionReference.path, chw.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // async deleteCHW(uid: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, uid);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, uid);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
