import { PatientType } from "@oben-core-web/constants/core-enums";
import { Patient } from "@oben-core-web/models/patient";
import { PendingPatient } from "@oben-core-web/models/pending-patient";
import { PatientService } from "@oben-core-web/services/patient-service";
import { PendingPatientService } from "@oben-core-web/services/pending-patient-service";
import { useCallback, useEffect, useRef, useState } from "react";

const useEnrollmentPatient = ({
  patientId,
  patientType,
  fetchOnMount = false
}: {
  patientId: string;
  patientType: PatientType;
  fetchOnMount?: boolean;
}) => {
  const [enrollmentPatient, setEnrollmentPatient] = useState<
    Patient | PendingPatient
  >();
  const wasFetched = useRef<boolean>(false);
  const fetchEnrollmentPatient = useCallback(async () => {
    let patient: Patient | PendingPatient;
    if (patientType === PatientType.PendingPatient) {
      const screeningPatientService = new PendingPatientService();
      patient = await screeningPatientService.getPendingPatient(patientId);
      setEnrollmentPatient(patient);
      return patient;
    } else {
      const patientUserService = new PatientService();
      patient = await patientUserService.getPatient(patientId);
      setEnrollmentPatient(patient);
      return patient;
    }
  }, [patientId, patientType]);

  useEffect(() => {
    if (fetchOnMount && !wasFetched.current) {
      wasFetched.current = true;
      fetchEnrollmentPatient();
    }
  }, [fetchEnrollmentPatient, fetchOnMount, wasFetched]);

  return { enrollmentPatient, fetchEnrollmentPatient };
};

export default useEnrollmentPatient;
