import { Input, InputGroup } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useState } from "react";

const NumberInput = ({ control, name }: { control: any; name: string }) => {
  const [rawInput, setRawInput] = useState<string>("");

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0} // Default number value
      render={({ field: { onChange, value } }) => (
        <InputGroup>
          <Input
            type='text' // Using text to allow better control
            value={
              rawInput !== ""
                ? rawInput
                : value !== null
                ? value.toString()
                : ""
            } // Preserve user input
            onChange={(e) => {
              const input = e.target.value;

              // Allow only valid numbers, "-", and "."
              if (/^-?\d*\.?\d*$/.test(input) || input === "") {
                setRawInput(input); // Update visible input
                onChange(input === "" ? null : parseFloat(input) || 0); // Store in form
              }
            }}
            onBlur={() => {
              if (rawInput === "" || rawInput === "-") {
                onChange(null); // Set form value to null if empty
                setRawInput(""); // Keep field visually empty
              } else {
                const parsedValue = parseFloat(rawInput) || 0;
                onChange(parsedValue); // Store valid number or 0
                setRawInput(""); // Reset for clean display
              }
            }}
            placeholder='Enter a number'
          />
        </InputGroup>
      )}
    />
  );
};

export default NumberInput;
