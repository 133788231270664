import { ColDef } from "ag-grid-community";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Appointment } from "@oben-core-web/models/appointment";
import { HStack, IconButton, Tooltip, VStack } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { PhoneIcon } from "@chakra-ui/icons";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { Outreach } from "@oben-core-web/models/outreach";
import { ProgramManagerQueryService } from "@oben-core-web/services/program-manager-query-service";
import { startCase } from "lodash";

interface IApptConfirmationTable {
  setConfirmationAppt: (appt: Appointment) => void;
}

const ApptConfirmationTable = ({
  setConfirmationAppt
}: IApptConfirmationTable) => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();

  const [apptConfirmationData, setApptConfirmationData] = useState<
    { appointment: Appointment; outreach: Outreach | null }[]
  >([]);

  const fetchApptConfData = useCallback(
    async (placeBasedCareProvId: string) => {
      const appointmentData =
        await ProgramManagerQueryService.getApptConfirmationData({
          placeBasedCareProvId
        });
      setApptConfirmationData(appointmentData);
    },
    []
  );

  useEffect(() => {
    if (placeBasedCareProvider) {
      fetchApptConfData(placeBasedCareProvider.id);
    }
  }, [placeBasedCareProvider, fetchApptConfData]);

  const columnHeaders: ColDef<
    { appointment: Appointment; outreach: Outreach | null } & { actions: any }
  >[] = useMemo(
    () => [
      {
        field: "appointment.patientName.fullName",
        headerName: "Patient",
        minWidth: 100,
        flex: 0.9
      },
      {
        field: "appointment.date",
        headerName: "Date",
        valueFormatter: (row) =>
          row.data?.appointment.date
            ? DateTime.fromJSDate(row.data.appointment.date).toFormat(
                "DD 'at' hh:mma"
              )
            : "00/00/00",
        tooltipValueGetter: (row) => {
          return row.data?.appointment.date
            ? DateTime.fromJSDate(row.data.appointment.date).toFormat(
                "DD 'at' hh:mma"
              )
            : "00/00/00";
        },
        minWidth: 100,
        flex: 0.7
      },
      {
        field: "appointment.chwName.fullName",
        headerName: "Barber",
        minWidth: 100,
        flex: 0.8
      },
      {
        field: "appointment.serviceLocationName",
        headerName: "Barbershop",
        minWidth: 100,
        flex: 1
      },
      {
        field: "appointment.pharmacistName.fullName",
        headerName: "Pharmacist",
        minWidth: 100,
        flex: 1
      },
      {
        field: "outreach.status",
        headerName: "Outreach Status",
        valueGetter: (row) => {
          return row.data?.outreach ? startCase(row.data.outreach.status) : "";
        },
        minWidth: 100,
        flex: 0.8
      },
      {
        field: "outreach.attempts",
        headerName: "Last Outreach Attempt Result",
        valueGetter: (row) => {
          const lastAttemptStatus = row.data?.outreach
            ? row.data.outreach.attempts[row.data.outreach.attempts.length - 1]
                ?.result
            : "";

          return lastAttemptStatus ? startCase(lastAttemptStatus) : "";
        },
        minWidth: 100,
        flex: 1
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        cellRenderer: (row: any) => {
          return (
            <HStack alignItems={"center"} h={"full"}>
              <Tooltip label={"Confirm Appointment"}>
                <IconButton
                  variant={"link"}
                  aria-label={`complete-task-btn-${row.data.appointment.id}`}
                  icon={<PhoneIcon />}
                  onClick={() => {
                    setConfirmationAppt(row.data.appointment);
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
        minWidth: 100,
        flex: 0.5
      }
    ],
    [setConfirmationAppt]
  );
  return (
    <VStack p={2} h={"full"} w={"full"}>
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={apptConfirmationData}
          columnDefs={columnHeaders as any}
        />
      </div>
    </VStack>
  );
};

export default ApptConfirmationTable;
