import React, { useMemo } from "react";
import useAuthListener from "../hooks/useAuthListener";
import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";
import { RouterProvider } from "react-router-dom";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useAppContext } from "@/hooks/useAppContext";
import AdminRouter from "./AdminRouter";

const App: React.FC = () => {
  useAuthListener(); // This hook should set up auth status listeners
  const {
    state: { isLoggedIn }
  } = useAuthContext();
  const {
    state: { appMode }
  } = useAppContext();

  const isAdminMode = appMode === "admin";
  // Determine which router to use based on authentication status
  const router = useMemo(
    () => (isLoggedIn ? (isAdminMode ? AdminRouter : AppRouter) : AuthRouter),
    [isLoggedIn, isAdminMode]
  );

  return (
    <RouterProvider
      key={isLoggedIn ? (isAdminMode ? "admin" : "app") : "auth"}
      router={router}
    />
  );
};

export default App;
