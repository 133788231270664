import usePatientUserSubscription from "@/hooks/usePatientSubscription";
import useCurrentUser from "@/hooks/useCurrentUser";
import usePatients from "@/hooks/usePatients";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  Card,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  useToast,
  IconButton,
  FormErrorMessage,
  ButtonProps,
  Tooltip
} from "@chakra-ui/react";
import {
  PatientNote,
  PatientNoteType,
  IPatientNoteData
} from "@oben-core-web/models/patient-note";
import { ReactElement, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PatientNotes from "./PatientNotes";
import { PatientTaskService } from "@oben-core-web/services/patient-task-service";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { PatientNoteService } from "@oben-core-web/services/patient-note-service";
import { SlNotebook } from "react-icons/sl";
import { startCase, uniqBy } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import patientNoteSchema from "@/resolvers/patientNote";

interface INoteDrawer {
  patientId?: string;
  noteTypes?: PatientNoteType[]; // allowable types of notes that can be created
  placement?: "left" | "right" | "top" | "bottom";
  btnProps?: ButtonProps;
  btnIconProps?: any;
  useIconButton?: boolean;
  showNoteHistory?: boolean;
}

const NoteDrawer = ({
  patientId,
  noteTypes = [PatientNoteType.General],
  placement = "left",
  btnProps = {
    position: "absolute",
    left: 4,
    zIndex: 4
  },
  btnIconProps,
  useIconButton = true,
  showNoteHistory = true
}: INoteDrawer) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [selectedPatientId, setSelectedPatientId] = useState(patientId);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { patient } = usePatientUserSubscription(selectedPatientId);
  const { patients } = usePatients(currentUser?.placeBasedCareProvId ?? "");
  const [sourceOptions, setSourceOptions] = useState<ReactElement[]>([]);

  const { formState, register, handleSubmit, control, setValue, reset } =
    useForm<IPatientNoteData>({
      defaultValues: {
        modelId: "",
        patientId: selectedPatientId,
        authorId: currentUser!.uid,
        authorType: currentUser!.userType,
        createdDate: new Date(),
        noteSourceId: "",
        noteType:
          noteTypes.length === 1 ? noteTypes[0] : PatientNoteType.Unknown,
        summary: "",
        details: ""
      },
      resolver: yupResolver(patientNoteSchema) as any
    });

  const fetchSourceOptions = async (noteType: PatientNoteType) => {
    if (!patient || !patient.currentCarePlanId) return;

    switch (noteType) {
      case PatientNoteType.PatientTask: {
        const patientTaskService = new PatientTaskService();
        const tasks = await patientTaskService.getPatientTasks(
          patient.uid,
          patient.currentCarePlanId
        );
        if (tasks.length)
          setSourceOptions(
            uniqBy(tasks, "patientTaskBaseId").map((t) => (
              <option value={t.modelId} key={t.modelId}>
                {`${t.name} - Due ${t.dueDate?.toDateString()}`}
              </option>
            ))
          );
        return;
      }
      case PatientNoteType.Medication: {
        const medicationService = new MedicationService();
        const meds = await medicationService.getPatientMedications(
          patient.uid,
          patient.currentCarePlanId
        );
        if (meds.length)
          setSourceOptions(
            uniqBy(
              meds
                .filter((m) => !m.rxCancelDate)
                .sort((a, b) => {
                  // ignore upper and lowercase
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                }),
              "medBaseId"
            ).map((m) => (
              <option value={m.modelId} key={m.modelId}>
                {m.name} - {m.newDosage.strength}mg
              </option>
            ))
          );
        return;
      }
      default:
        setSourceOptions([]);
        return;
    }
  };

  const onSubmit = async (data: IPatientNoteData) => {
    setIsLoading(true);
    const patientNote = new PatientNote(data);
    const patientNoteService = new PatientNoteService();
    await patientNoteService
      .addPatientNote(patientNote)
      .then(() => {
        toast({ status: "success", description: "Note added successfully" });
        reset();
      })
      .catch((e) => {
        console.log("Error adding patient note:", e);
        toast({ status: "error", description: "Failed to add patient note" });
      })
      .finally(() => setIsLoading(false));
  };

  const filteredNoteTypes = noteTypes.filter((nt) => {
    switch (nt) {
      case PatientNoteType.CarePlan:
      case PatientNoteType.PatientTask:
      case PatientNoteType.MedAdherence:
      case PatientNoteType.Medication:
        return !!patient?.currentCarePlanId;
      case PatientNoteType.General:
      case PatientNoteType.ChartPrep:
        return true;
      default:
        return false;
    }
  });

  return (
    <>
      {!isOpen ? (
        <Tooltip label={"Create Patient Note"}>
          {useIconButton ? (
            <IconButton
              aria-label='Patient Notes'
              icon={<SlNotebook {...btnIconProps} />}
              onClick={onToggle}
              variant={"ghost"}
              {...btnProps}
            />
          ) : (
            <Button
              onClick={onToggle}
              leftIcon={<SlNotebook {...btnIconProps} />}
              colorScheme='blue'
              size='sm'
            >
              Create Patient Note
            </Button>
          )}
        </Tooltip>
      ) : (
        <div></div>
      )}
      <Drawer
        isOpen={isOpen}
        onClose={onToggle}
        size={"md"}
        placement={placement}
      >
        <DrawerOverlay />
        <DrawerContent bg={"gray.50"}>
          <DrawerHeader
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Heading as={"h1"}>
              {patient ? `${patient.name.fullName} ` : "Patient "}Notes
            </Heading>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            {!patient ? (
              <Select
                onChange={(e) => {
                  if (!e) {
                    setSelectedPatientId("");
                  } else {
                    setSelectedPatientId(e.target.value);
                  }
                }}
              >
                {patients.map((cu) => {
                  return <option value={cu.uid}>{cu.name.fullName}</option>;
                })}
              </Select>
            ) : (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Card p={4} variant={"outline"}>
                    <VStack spacing={2}>
                      <FormControl isInvalid={!!formState.errors.noteType}>
                        {filteredNoteTypes.length === 1 ? (
                          <FormLabel fontWeight={"bold"}>
                            General Note
                          </FormLabel>
                        ) : (
                          <>
                            <FormLabel>Type</FormLabel>
                            <Controller
                              control={control}
                              name='noteType'
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  onChange={(e) => {
                                    switch (e.target.value) {
                                      case PatientNoteType.CarePlan:
                                        setValue(
                                          "noteSourceId",
                                          patient!.currentCarePlanId!
                                        );
                                        setSourceOptions([]);
                                        break;
                                      case PatientNoteType.General:
                                        setValue("noteSourceId", patient!.uid);
                                        setSourceOptions([]);
                                        break;
                                      case PatientNoteType.Medication:
                                        fetchSourceOptions(
                                          PatientNoteType.Medication
                                        );
                                        break;
                                      case PatientNoteType.PatientTask:
                                        fetchSourceOptions(
                                          PatientNoteType.PatientTask
                                        );
                                        break;
                                      default:
                                        setValue("noteSourceId", "");
                                        fetchSourceOptions(
                                          PatientNoteType.Unknown
                                        );
                                        break;
                                    }
                                    field.onChange(e);
                                  }}
                                >
                                  <option value={PatientNoteType.Unknown}>
                                    Select an option
                                  </option>
                                  {filteredNoteTypes.map((nt) => (
                                    <option
                                      value={nt}
                                      key={`note-drawer-type-opt-${nt}`}
                                    >
                                      {startCase(nt) + " Note"}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            />
                          </>
                        )}
                        <FormErrorMessage>
                          {formState.errors.noteType?.message}
                        </FormErrorMessage>
                      </FormControl>
                      {sourceOptions.length > 0 && (
                        <FormControl
                          isInvalid={!!formState.errors.noteSourceId}
                        >
                          <FormLabel>Related to</FormLabel>
                          <Select {...register("noteSourceId")}>
                            {sourceOptions.map((o) => o)}
                          </Select>
                          <FormErrorMessage>
                            {formState.errors.noteSourceId?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      <FormControl isInvalid={!!formState.errors.summary}>
                        <FormLabel>Header</FormLabel>
                        <Input {...register("summary")} />
                        <FormErrorMessage>
                          {formState.errors.summary?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formState.errors.details}>
                        <FormLabel>Description</FormLabel>
                        <Textarea {...register("details")} />
                        <FormErrorMessage>
                          {formState.errors.details?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Button
                        type='submit'
                        colorScheme='blue'
                        w={"full"}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                      >
                        Save Note
                      </Button>
                    </VStack>
                  </Card>
                </form>
                {showNoteHistory && (
                  <Card mt={4}>
                    <Accordion allowToggle variant={"ghost"}>
                      <AccordionItem
                        sx={{
                          borderTop: "none",
                          borderBottom: "none"
                        }}
                      >
                        <AccordionButton>
                          <Box
                            as='h2'
                            flex='1'
                            textAlign='left'
                            fontWeight={"bold"}
                            fontSize={"large"}
                          >
                            Note History
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                          <PatientNotes patientId={patient.uid} />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Card>
                )}
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NoteDrawer;
