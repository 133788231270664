import { PatientTask } from "@oben-core-web/models/patient-task";
import { PatientTaskService } from "@oben-core-web/services/patient-task-service";
import { useState, useEffect } from "react";

const usePatientTasks = ({
  patientId,
  carePlanId
}: {
  patientId: string;
  carePlanId: string;
}) => {
  const [patientTasks, setPatientTasks] = useState<PatientTask[]>([]);

  useEffect(() => {
    let mounted = true;
    const fetchPatientTasks = async () => {
      if (!mounted || !patientId || !carePlanId) return;
      const patientTaskService = new PatientTaskService();
      const tasks = await patientTaskService.getPatientTasks(
        patientId,
        carePlanId
      );
      setPatientTasks(tasks);
    };
    fetchPatientTasks();
    return () => {
      mounted = false;
    };
  }, [patientId, carePlanId]);

  return patientTasks;
};

export default usePatientTasks;
