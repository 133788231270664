import {
  IconButton,
  VStack,
  SlideFade,
  useOutsideClick,
  useDisclosure
} from "@chakra-ui/react";
import { FaBars, FaUsers } from "react-icons/fa";
import { useRef } from "react";
import { FiCalendar, FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const MobileFabNav = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const fabRef = useRef(null);
  useOutsideClick({
    ref: fabRef,
    handler: () => {
      isOpen && onToggle();
    }
  });
  return (
    <VStack
      position='fixed'
      bottom='20px'
      left='20px'
      alignItems='flex-start'
      spacing={2}
      zIndex={500}
      ref={fabRef}
    >
      {/* Menu Items - Animate Up */}
      <SlideFade in={isOpen} offsetY='20px'>
        <VStack spacing={2} align='flex-start'>
          <IconButton
            aria-label='Home'
            icon={<FiHome />}
            colorScheme='blue'
            onClick={() => navigate("/")}
          />
          <IconButton
            aria-label='Patients'
            icon={<FaUsers />}
            colorScheme='blue'
            onClick={() => navigate("/patients")}
          />
          <IconButton
            aria-label='Appointments'
            icon={<FiCalendar />}
            colorScheme='blue'
            onClick={() => navigate("/appointments")}
          />
        </VStack>
      </SlideFade>

      {/* FAB Toggle Button */}
      <IconButton
        aria-label='Open Menu'
        icon={<FaBars />}
        size='lg'
        isRound={true}
        colorScheme='blue'
        onClick={onToggle}
      />
    </VStack>
  );
};

export default MobileFabNav;
