import dosageSchema from "./dosage";
import { object, string, boolean } from "yup";

const medicationBaseSchema = object({
  id: string().optional(),
  placeBasedCareProvId: string().required(),
  name: string().required(),
  defaultDosage: dosageSchema,
  enabled: boolean().required()
});

export default medicationBaseSchema;
