import { useEffectOnce } from "./useEffectOnce";
import { useRef } from "react";
import { useAppContext } from "./useAppContext";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { SystemStatusInfoService } from "../../oben-core-web/services/system-status-info-service";
import { SystemStatusInfo } from "../../oben-core-web/models/system-status-info";

const useMaintenanceListener = () => {
  const { setSystemStatus, setDetachSystemListener } = useAppContext();
  const hasRun = useRef<boolean>(false);

  // watch for systemState changes
  useEffectOnce(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      const systemStatusInfoService = new SystemStatusInfoService();

      const { unsubscribe } = systemStatusInfoService.streamSystemStatusInfo(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => {
          const systemStatusInfo = SystemStatusInfo.fromFirestore(snapshot);
          setSystemStatus(systemStatusInfo ?? null);
        }
      );
      unsubscribe && setDetachSystemListener(unsubscribe);
    }
  });
};

export default useMaintenanceListener;
