import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { DateTime } from "luxon";
import {
  OutreachAttempt,
  OutreachAttemptResult
} from "@oben-core-web/models/outreach-attempt";
import { Controller, useForm } from "react-hook-form";
import {
  Outreach,
  OutreachPurpose,
  OutreachStatus
} from "@oben-core-web/models/outreach";
import useCurrentUser from "@/hooks/useCurrentUser";
import {
  useToast,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Button,
  Text,
  Select,
  Card,
  Heading
} from "@chakra-ui/react";
import { UserName } from "@oben-core-web/models/user-name";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import { useState, useEffect, useCallback } from "react";
import { startCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import outreachAttemptResultSchema from "@/resolvers/outreachAttempt";
import {
  AppointmentStatus,
  AppointmentStatusChange
} from "@oben-core-web/models/appointment-status-change";
import { BillingTransactionService } from "@oben-core-web/services/billing-transaction-service";
import { useNavigate } from "react-router-dom";

interface IPatientOutreachForm {
  outreachItem?: Outreach;
  appointmentId?: string;
  onAttemptSaved: () => void;
  // onOutreachStatusChanged?: (status: OutreachStatus) => void;
  fetchOutreach: (outreachId: string) => void;
}

interface IOutreachAttemptForm {
  staffId: string;
  staffMemberName: UserName;
  date: Date;
  result: OutreachAttemptResult;
  note: string;
}

const PatientOutreachForm = ({
  outreachItem,
  appointmentId,
  onAttemptSaved,
  // onOutreachStatusChanged,
  fetchOutreach
}: IPatientOutreachForm) => {
  const { currentUser } = useCurrentUser();
  const toast = useToast();
  const navigate = useNavigate();
  const [outreachAppt, setOutreachAppt] = useState<Appointment>();
  const outreachPurpose = outreachItem?.purpose;
  const outreachPatientId = outreachItem?.patientId;
  const fetchOutreachAppt = useCallback(async (appointmentId: string) => {
    const apptService = new AppointmentService();
    const outreachAppt = await apptService.getAppointment(appointmentId);
    outreachAppt && setOutreachAppt(outreachAppt);
  }, []);
  useEffect(() => {
    if (outreachPurpose === OutreachPurpose.Appointment && appointmentId) {
      fetchOutreachAppt(appointmentId);
    }
  }, [outreachPurpose, outreachPatientId, fetchOutreachAppt, appointmentId]);

  const { formState, handleSubmit, register, control, reset } =
    useForm<IOutreachAttemptForm>({
      defaultValues: {
        staffId: currentUser?.uid ?? "",
        staffMemberName: currentUser?.name,
        date: new Date(),
        result: OutreachAttemptResult.Unknown,
        note: ""
      },
      resolver: yupResolver(outreachAttemptResultSchema) as any,
      mode: "onChange"
    });
  const submitForm = async (data: IOutreachAttemptForm) => {
    const { result, note } = data;
    if (!currentUser) {
      toast({
        status: "error",
        description: "You must be logged in to take this action"
      });
      return;
    } else if (!outreachItem) {
      toast({ status: "error", description: "Cannot update outreach item" });
      return;
    }
    const outreachService = new OutreachService();
    const outreachAttemptResultChange = new OutreachAttempt({
      date: new Date(),
      staffId: currentUser.uid,
      staffMemberName: currentUser.name,
      result,
      note
    });
    outreachItem?.attempts.push(outreachAttemptResultChange);
    if (
      outreachItem.status === OutreachStatus.New ||
      outreachItem.status === OutreachStatus.Unknown
    ) {
      outreachItem.status = OutreachStatus.InProgress;
    }
    await outreachService
      .updateOutreach(outreachItem)
      .then(() => {
        toast({
          status: "success",
          description: "Successfully logged Outreach attempt"
        });
        fetchOutreach(outreachItem.id);
        if (result == OutreachAttemptResult.SpokeToPatient) {
          onAttemptSaved && onAttemptSaved();
        }
        reset();
      })
      .catch((err) => {
        console.log("Update Outreach Attempt Error:", err);
        toast({
          status: "error",
          description: "Failed to create outreach attempt"
        });
      });
  };

  const handleApptStatusChange = async (status: AppointmentStatus) => {
    if (!outreachAppt || !currentUser) return;
    const appointmentService = new AppointmentService();
    outreachAppt.statusChanges = [
      ...outreachAppt.statusChanges,
      new AppointmentStatusChange({
        status,
        date: new Date(),
        editorId: currentUser?.uid,
        editorType: currentUser?.userType,
        details: ""
      })
    ];
    outreachAppt.currentStatus = status;
    if (status === AppointmentStatus.Canceled) {
      await BillingTransactionService.submitToProgramManager({
        appointmentId: outreachAppt.id,
        placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
        editorId: currentUser.uid,
        statusChange: status
      }).catch((err) => {
        toast({ status: "error", description: err.message });
      });
      // await AppointmentTransactionService.createBillableFromApptStatus({
      //   appointmentId: outreachAppt.id,
      //   placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
      //   editorId: currentUser.uid,
      //   statusChange: status,
      //   approveImmediately: false
      // }).catch((err) => {
      //   toast({ status: "error", description: err.message });
      // });
    }
    await appointmentService.updateAppointment(outreachAppt).then(async () => {
      await fetchOutreachAppt(outreachAppt.id);
      toast({ status: "success", description: "Appointment status updated" });
    });
  };

  // const handleOutreachStatusChange = async (status: OutreachStatus) => {
  //   if (!outreachItem) return;
  //   const outreachService = new OutreachService();
  //   outreachItem.status = status;
  //   await outreachService.updateOutreach(outreachItem).then(() => {
  //     toast({ status: "success", description: "Outreach status updated" });
  //     fetchOutreach(outreachItem.id);
  //   });
  //   onOutreachStatusChanged && onOutreachStatusChanged(status);
  // };

  return (
    <form onSubmit={handleSubmit(submitForm)} style={{ width: "100%" }}>
      <VStack w={"full"}>
        {/* <Card w={"full"} p={4} variant={"outline"} gap={2}>
          <Heading as={"h3"} fontSize='md'>
            Outreach status
          </Heading>
          <Select
            onChange={(e) =>
              handleOutreachStatusChange(e.target.value as OutreachStatus)
            }
            value={outreachItem?.status}
            placeholder='Make a selection'
          >
            <option value={OutreachStatus.InProgress}>In Progress</option>
            <option value={OutreachStatus.Completed}>Completed</option>
            <option value={OutreachStatus.Canceled}>Cancelled</option>
          </Select>
        </Card> */}
        <Card w={"full"} p={4} variant={"outline"}>
          <Heading as={"h3"} fontSize={"md"} mb={2}>
            Patient Information
          </Heading>
          <HStack
            w={"full"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Patient</Text>
            <Text fontWeight={"bold"}>
              {outreachItem?.patientName?.fullName}
            </Text>
          </HStack>
          <HStack
            w={"full"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Preferred contact method</Text>
            <Text fontWeight={"bold"}>{outreachItem?.prefContactMethod}</Text>
          </HStack>
          <HStack
            w={"full"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Phone</Text>
            <Text fontWeight={"bold"}>{outreachItem?.patientPhone}</Text>
          </HStack>
          <HStack
            w={"full"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Email</Text>
            <Text fontWeight={"bold"}>{outreachItem?.patientEmail}</Text>
          </HStack>
        </Card>

        <Card variant={"outline"} p={4} w={"full"}>
          <HStack
            alignItems={"center"}
            justifyContent={"space-between"}
            w={"full"}
          >
            <Heading as={"h3"} fontSize={"md"} alignSelf={"start"} mb={2}>
              Outreach Attempt
            </Heading>
            <Button
              colorScheme='blue'
              type='submit'
              isDisabled={!formState.isValid}
              size={"xs"}
            >
              Save Attempt
            </Button>
          </HStack>
          <Controller
            control={control}
            name='result'
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel>Attempt Result</FormLabel>
                <Select placeholder='Make a selection' {...field}>
                  <option value={OutreachAttemptResult.SpokeToPatient}>
                    {startCase(OutreachAttemptResult.SpokeToPatient)}
                  </option>
                  <option value={OutreachAttemptResult.SentTextMessage}>
                    {startCase(OutreachAttemptResult.SentTextMessage)}
                  </option>
                  <option value={OutreachAttemptResult.NoAnswer}>
                    {startCase(OutreachAttemptResult.NoAnswer)}
                  </option>
                  <option value={OutreachAttemptResult.LeftMessage}>
                    {startCase(OutreachAttemptResult.LeftMessage)}
                  </option>
                  <option value={OutreachAttemptResult.WrongContactInfo}>
                    {startCase(OutreachAttemptResult.WrongContactInfo)}
                  </option>
                </Select>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          ></Controller>
          <FormControl>
            <FormLabel>Note</FormLabel>
            <Textarea
              {...register("note")}
              placeholder='Note'
              resize={"none"}
            ></Textarea>
          </FormControl>
        </Card>
        {outreachItem?.purpose === OutreachPurpose.Appointment &&
          outreachAppt && (
            <Card
              gap={0}
              p={4}
              variant='outline'
              w={"full"}
              alignItems={"flex-start"}
            >
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <Text fontWeight={"bold"}>Next Appointment</Text>
                <Button
                  variant='link'
                  colorScheme='blue'
                  size='sm'
                  onClick={() => {
                    if (outreachAppt.date) {
                      navigate("/appointments", {
                        state: {
                          appointment: {
                            ...outreachAppt,
                            startDateTime: outreachAppt.date,
                            endDateTime: DateTime.fromJSDate(outreachAppt.date)
                              .plus({ minutes: outreachAppt.length })
                              .toJSDate()
                          },
                          isOpen: true
                        },
                        replace: true
                      });
                    }
                  }}
                >
                  Edit
                </Button>
              </HStack>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Text>Date</Text>
                <Text fontWeight={"bold"}>
                  {DateTime.fromJSDate(outreachAppt.date!).toFormat(
                    "cccc - ff"
                  )}
                </Text>
              </HStack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <Text>Service Location</Text>
                <Text fontWeight={"bold"}>
                  {outreachAppt.serviceLocationName}
                </Text>
              </HStack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <Text>Community Health Worker</Text>
                <Text fontWeight={"bold"}>{outreachAppt.chwName.fullName}</Text>
              </HStack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <Text>Pharmacist</Text>
                <Text fontWeight={"bold"}>
                  {outreachAppt.pharmacistName.fullName}
                </Text>
              </HStack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"full"}
              >
                <Text>Status</Text>
                <Select
                  value={
                    outreachAppt.statusChanges[
                      outreachAppt.statusChanges.length - 1
                    ]?.status
                  }
                  onChange={(e) =>
                    handleApptStatusChange(e.target.value as AppointmentStatus)
                  }
                  size='xs'
                  fontSize={"md"}
                  w={"30%"}
                >
                  <option value={AppointmentStatus.New}>Unconfirmed</option>
                  <option value={AppointmentStatus.Confirmed}>
                    {AppointmentStatus.Confirmed}
                  </option>
                  <option
                    disabled={
                      !outreachAppt.date || new Date() > outreachAppt.date
                    }
                    value={AppointmentStatus.Canceled}
                  >
                    {AppointmentStatus.Canceled}
                  </option>
                </Select>
              </HStack>
            </Card>
          )}
      </VStack>
    </form>
  );
};

export default PatientOutreachForm;
