import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useCallback } from "react";
import { CHWRecommendation } from "@oben-core-web/models/chw-recommendation";
import { CHWRecommendationService } from "@oben-core-web/services/chw-recommendation-service";

const useRecommendationLetter = () => {
  const generate = useCallback(
    async ({
      chwRecommendation,
      shouldDownload = false
    }: {
      chwRecommendation: CHWRecommendation;
      shouldDownload?: boolean;
    }) => {
      const docDefinition = {
        info: {
          title: `CHW Recommendation Letter for ${chwRecommendation.patientName.fullName}`,
          author: chwRecommendation.supervisingPhysName.fullName,
          creator: "Oben Health",
          producer: "Oben Health"
        },
        content: [
          { text: "Recommendation Letter", style: "heading" },
          { text: recommendationText[0], style: "line" },
          { text: recommendationText[1], style: "line" },
          { text: recommendationText[2], style: "line" },
          {
            text: `Patient Name: ${chwRecommendation.patientName.fullName}`,
            style: "data"
          },
          {
            text: `Member Insurance Number: ${chwRecommendation.patientPolicyNumber}`,
            style: "data"
          },
          {
            text: `Supervising Provider Name: ${chwRecommendation.supervisingPhysName.fullName}`,
            style: "data"
          },
          { text: `Provider Type: ${"Physician"}`, style: "data" },
          {
            text: `Attestation Date: ${chwRecommendation.createdDate}`,
            style: "data"
          }
        ],
        styles: {
          heading: {
            fontSize: 18,
            bold: true,
            marginBottom: 16
          },
          line: {
            marginBottom: 8
          },
          data: {
            marginBottom: 4
          }
        }
      };

      pdfMake.vfs = pdfFonts.vfs;
      const letter = pdfMake.createPdf(docDefinition);
      if (shouldDownload) {
        letter.download(
          `Recommendation Letter for ${chwRecommendation.patientName.fullName}`
        );
        const chwRecommendationService = new CHWRecommendationService();
        chwRecommendation.latestDownloadDate = new Date();
        await chwRecommendationService.updateCHWRecommendation(
          chwRecommendation
        );
      }
      return letter;
    },
    []
  );

  return { generate };
};

export default useRecommendationLetter;

const recommendationText = [
  "I declare that the following information is true and correct:",
  "1. I am a Physician, Clinical Nurse Specialist (CNS), Pharmacist, Dentist, Licensed Clinical Social Worker (LCSW), Licensed Educational Psychologist (LEP), Licensed Marriage & Family Therapist (LMFT), Licensed Midwife, Licensed Professional Clinical Counselor (LPCC), Licensed Vocational Nurse (LVN), Nurse Midwife, Nurse Practitioner (NP), Pharmacist, Physician Assistant (PA), Podiatrist, Registered Nurse (RN), Psychologist, Public Health Nurse (PHN), or Registered Dental Hygienist (RDH).",
  "2. I attest that the Medi-Cal Member listed below would benefit from Community Health Worker services."
];
