/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: remove above es-lint rule if/when we remove unused functions

import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { Patient } from "../models/patient";
import { GaLog } from "../utils/ga-log";

export class PatientService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "patients";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single Patient's profile
  streamPatient(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return Patient.fromFirestore(docSnap);
      } else {
        return Patient.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {isSubscription: true});
          },
          (error) => {
            console.error("Error in onSnapshot:", error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, uid, {isSubscription: true});
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {isSubscription: true});
      throw error;
    }
  }

  async getAllPatients(): Promise<Patient[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Patient.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPayerPatients(payerId: string): Promise<Patient[]> {
    const queryRef = query(
      this._collectionReference,
      where("payerId", "==", payerId)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Patient.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPayerPatientCount(payerId: string): Promise<number> {
    const queryRef = query(
      this._collectionReference,
      where("payerId", "==", payerId)
    );
    try {
      const aqSnapshot = await getCountFromServer(queryRef);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPrimaryCareProvPatients(primaryCareProvId: string): Promise<Patient[]> {
    const queryRef = query(
      this._collectionReference,
      where("primaryCareProvId", "==", primaryCareProvId)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Patient.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPrimaryCareProvPatientCount(primaryCareProvId: string): Promise<number> {
    const queryRef = query(
      this._collectionReference,
      where("primaryCareProvId", "==", primaryCareProvId)
    );
    try {
      const aqSnapshot = await getCountFromServer(queryRef);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvPatients(placeBasedCareProvId: string): Promise<Patient[]> {
    const queryRef = query(
      this._collectionReference,
      where("placeBasedCareProvId", "==", placeBasedCareProvId)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Patient.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvPatientCount(placeBasedCareProvId: string): Promise<number> {
    const queryRef = query(
      this._collectionReference,
      where("placeBasedCareProvId", "==", placeBasedCareProvId)
    );
    try {
      const aqSnapshot = await getCountFromServer(queryRef);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a Patient exists
  async validatePatient(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPatient(uid: string): Promise<Patient> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Patient not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Patient.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addPatient(patient: Patient): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        patient.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updatePatient(patient: Patient): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, patient.uid);
      await updateDoc(docRef, patient.toJson());
      GaLog.updateDocument(this._collectionReference.path, patient.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  async deletePatient(uid: string): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, uid);
      await deleteDoc(docRef);
      GaLog.deleteDocument(this._collectionReference.path, uid);
      return;
    } catch (error) {
      GaLog.deleteError(this._collectionReference.path, error);
      throw error;
    }
  }

  //   // not used by web portal
  // async lookupByNameDob(lastName: string, dob: Date): Promise<Patient[] | any> {
  // }

  //   // not used by web portal
  // async saveRecentlyViewedVideo(uid: string, journeyContentId: string): Promise<void> {
  // }
}
