import {
  Card,
  Flex,
  Badge,
  Box,
  Text,
  Spinner,
  Center,
  Skeleton
} from "@chakra-ui/react";
import { PlanStatus } from "@oben-core-web/models/care-plan";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import TaskCompletionChart from "../molecules/TaskCompletionChart";
import usePatientCarePlans from "@/hooks/usePatientCarePlans";

interface ICarePlanCard {
  patientId: string;
  openCarePlanWizard: () => void;
}

const CarePlanCard = ({ patientId, openCarePlanWizard }: ICarePlanCard) => {
  const { currentCarePlan } = usePatientCarePlans(patientId);
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (currentCarePlan.status === PlanStatus.Draft) {
      openCarePlanWizard();
    } else {
      navigate(`careplan/${currentCarePlan.modelId}`);
    }
  };

  return (
    <Skeleton isLoaded={!!currentCarePlan} h={"12rem"}>
      {!currentCarePlan ? (
        <Center w={"full"}>
          <Spinner />
        </Center>
      ) : (
        <Card
          display={"flex"}
          flexDirection={"row"}
          variant='outline'
          p={6}
          justifyContent={"space-between"}
          w={"full"}
          h={currentCarePlan.status !== PlanStatus.Draft ? "12rem" : "auto"}
          onClick={handleCardClick}
          cursor={"pointer"}
        >
          <Flex
            direction='column'
            alignSelf={"center"}
            // border={"1px solid"}
            justifyContent={"space-between"}
            h={"full"}
          >
            <Box>
              <Badge
                variant={"outline"}
                w={"fit-content"}
                colorScheme={
                  currentCarePlan.status === PlanStatus.Current
                    ? "green"
                    : currentCarePlan.status === PlanStatus.Draft
                    ? "yellow"
                    : "red"
                }
              >
                {currentCarePlan.status}
              </Badge>
              <Text fontSize='x-large'>Care Plan</Text>
            </Box>
            {/* {currentCarePlan.status === PlanStatus.Current ? (
              <Link to={`careplan/${currentCarePlan.modelId}`}>
                <Button>Manage</Button>
              </Link>
            ) : currentCarePlan.status === PlanStatus.Draft ? (
              <Button onClick={openCarePlanWizard}>Edit</Button>
            ) : (
              <></>
            )} */}
            <Text as={"i"} fontWeight={"light"} fontSize={"smaller"}>
              Created on:{" "}
              {DateTime.fromJSDate(currentCarePlan.createdDate).toFormat(
                "MM/dd/yyyy"
              )}
            </Text>
          </Flex>
          <Flex direction='column'>
            {currentCarePlan.status !== PlanStatus.Draft && (
              <Box
                w={"9rem"}
                cursor={"default"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <TaskCompletionChart
                  patientId={patientId}
                  carePlanId={currentCarePlan.modelId}
                />
              </Box>
            )}
          </Flex>
        </Card>
      )}
    </Skeleton>
  );
};

export default CarePlanCard;
