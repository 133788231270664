import { DocumentSnapshot } from "firebase/firestore";

import { CarePlanService } from "../services/care-plan-service";
import { BpGoal } from "./bp-goal";

export enum PlanStatus {
  Unknown = "Unknown",
  Draft = "Draft",
  Current = "Current",
  Closed = "Closed"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class PlanStatusHelper {
  static valueOf(searchString: string | null): PlanStatus | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(PlanStatus).includes(searchString as PlanStatus) //
      ? (searchString as PlanStatus)
      : null;
  }
}

/// CarePlan records are maintained in a subcollection on Patient.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface ICarePlanData {
  modelId: string;
  patientId: string;
  createdDate: Date;
  status: PlanStatus;
  bpGoals: BpGoal[];
  diagnosis: string;
  history: string;
  placeBasedCareProvId: string;
}

export class CarePlan {
  modelId: string;
  patientId: string;
  createdDate: Date;
  status: PlanStatus;
  bpGoals: BpGoal[];
  diagnosis: string;
  history: string;
  placeBasedCareProvId: string;

  constructor({
    modelId,
    patientId,
    createdDate,
    status,
    bpGoals,
    diagnosis,
    history,
    placeBasedCareProvId
  }: ICarePlanData) {
    this.modelId = modelId;
    this.patientId = patientId;
    this.createdDate = createdDate;
    this.status = status;
    this.bpGoals = bpGoals;
    this.diagnosis = diagnosis;
    this.history = history;
    this.placeBasedCareProvId = placeBasedCareProvId
  }

  async updateDb(): Promise<void> {
    const db = new CarePlanService();
    await db.updateCarePlan(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): CarePlan {
    const data = docSnap.data() as { [key: string]: any };
    return CarePlan.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): CarePlan {
    return new CarePlan({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      patientId: data["patientId"] ?? "",
      createdDate: data["createdDate"]
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1901-Jan-01
      status:
        PlanStatusHelper.valueOf(data["status"] ?? "") ?? PlanStatus.Unknown,
      bpGoals: data["bpGoals"] //
        ? data["bpGoals"].map((element: any) => BpGoal.fromMap(element))
        : [],
      diagnosis: data["diagnosis"] ?? "",
      history: data["history"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId, // with subcollections we must save ID within the Firestore document itself
      patientId: this.patientId,
      createdDate: this.createdDate?.toISOString() ?? null,
      status: this.status,
      bpGoals: this.bpGoals.map((element) => element.toJson()),
      diagnosis: this.diagnosis,
      history: this.history,
      placeBasedCareProvId: this.placeBasedCareProvId
    };
  }
}
