import { DocumentSnapshot } from "firebase/firestore";

import { OutreachService } from "../services/outreach-service";
import { Colors, Color } from "../constants/color";
import {
  PatientType,
  PatientTypeHelper,
  ContactMethod,
  ContactMethodHelper
} from "../constants/core-enums";
import { OutreachAttempt } from "./outreach-attempt";
import { UserName } from "./user-name";

export enum OutreachPurpose {
  Unknown = "Unknown",
  NewEnrollment = "NewEnrollment",
  Appointment = "Appointment",
  General = "General"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachPurposeHelper {
  static valueOf(searchString: string | null): OutreachPurpose | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(OutreachPurpose).includes(
      searchString as OutreachPurpose
    ) //
      ? (searchString as OutreachPurpose)
      : null;
  }
}

export enum OutreachStatus {
  Unknown = "Unknown",
  New = "New",
  InProgress = "InProgress",
  Completed = "Completed",
  Canceled = "Canceled"
}

interface IOutreachStatusData {
  color: Color;
  label: string;
}

export const outreachStatusData: Record<OutreachStatus, IOutreachStatusData> = {
  [OutreachStatus.Unknown]: {
    color: Colors.red,
    label: "label_unknown"
  },
  [OutreachStatus.New]: {
    color: Colors.yellow,
    label: "label_new"
  },
  [OutreachStatus.InProgress]: {
    color: Colors.white,
    label: "label_in_progress"
  },
  [OutreachStatus.Completed]: {
    color: Colors.green,
    label: "label_completed"
  },
  [OutreachStatus.Canceled]: {
    color: Colors.black,
    label: "label_canceled"
  }
};

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachStatusHelper {
  static valueOf(searchString: string | null): OutreachStatus | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(OutreachStatus).includes(
      searchString as OutreachStatus
    ) //
      ? (searchString as OutreachStatus)
      : null;
  }
}

/// Outreach records are maintained in a subcollection on Patient.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IOutreachData {
  id: string;
  placeBasedCareProvId: string;
  patientId: string;
  patientType: PatientType;
  patientName: UserName;
  patientEmail: string;
  patientPhone: string;
  prefContactMethod: ContactMethod;
  staffId: string;
  staffMemberName: UserName;
  createdDate: Date;
  purpose: OutreachPurpose;
  status: OutreachStatus;
  attempts: OutreachAttempt[];
}

export class Outreach {
  id: string;
  placeBasedCareProvId: string;
  patientId: string;
  patientType: PatientType;
  patientName: UserName;
  patientEmail: string;
  patientPhone: string;
  prefContactMethod: ContactMethod;
  staffId: string;
  staffMemberName: UserName;
  createdDate: Date;
  purpose: OutreachPurpose;
  status: OutreachStatus;
  attempts: OutreachAttempt[];

  constructor({
    id,
    placeBasedCareProvId,
    patientId,
    patientType,
    patientName,
    patientEmail,
    patientPhone,
    prefContactMethod,
    staffId,
    staffMemberName,
    createdDate,
    purpose,
    status,
    attempts
  }: IOutreachData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.patientId = patientId;
    this.patientType = patientType;
    this.patientName = patientName;
    this.patientEmail = patientEmail;
    this.patientPhone = patientPhone;
    this.prefContactMethod = prefContactMethod;
    this.staffId = staffId;
    this.staffMemberName = staffMemberName;
    this.createdDate = createdDate;
    this.purpose = purpose;
    this.status = status;
    this.attempts = attempts;
  }

  async updateDb(): Promise<void> {
    const db = new OutreachService();
    await db.updateOutreach(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Outreach {
    const data = docSnap.data() as { [key: string]: any };
    return Outreach.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Outreach {
    return new Outreach({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      patientId: data["patientId"] ?? "",
      patientType:
        PatientTypeHelper.valueOf(data["patientType"] ?? "") ??
        PatientType.Patient,
      patientName: UserName.fromMap(data["patientName"] ?? {}),
      patientEmail: data["patientEmail"] ?? "",
      patientPhone: data["patientPhone"] ?? "",
      prefContactMethod:
        ContactMethodHelper.valueOf(data["prefContactMethod"] ?? "") ??
        ContactMethod.Email,
      staffId: data["staffId"] ?? "",
      staffMemberName: UserName.fromMap(data["staffMemberName"] ?? {}),
      createdDate: data["createdDate"]
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1901-Jan-01
      purpose:
        OutreachPurposeHelper.valueOf(data["purpose"] ?? "") ??
        OutreachPurpose.Unknown,
      status:
        OutreachStatusHelper.valueOf(data["status"] ?? "") ??
        OutreachStatus.Unknown,
      attempts: (data["attempts"] ?? []).map(
        (element: { [key: string]: any }) => OutreachAttempt.fromMap(element)
      )
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      placeBasedCareProvId: this.placeBasedCareProvId,
      patientId: this.patientId,
      patientType: this.patientType,
      patientName: this.patientName.toJson(),
      patientEmail: this.patientEmail,
      patientPhone: this.patientPhone,
      prefContactMethod: this.prefContactMethod,
      staffId: this.staffId,
      staffMemberName: this.staffMemberName.toJson(),
      createdDate: this.createdDate.toISOString(),
      purpose: this.purpose,
      status: this.status,
      attempts: this.attempts.map((element) => element.toJson())
    };
  }
}
