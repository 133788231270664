import usePatient from "@/hooks/usePatient";
import useCurrentUser from "@/hooks/useCurrentUser";
import claimStatusSchema from "@/resolvers/claimStatus";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  VStack,
  Heading,
  ModalBody,
  Card,
  HStack,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserType } from "@oben-core-web/constants/core-enums";
import { Claim } from "@oben-core-web/models/claim";
import {
  ClaimStatus,
  ClaimStatusChange
} from "@oben-core-web/models/claim-status-change";
import { ClaimService } from "@oben-core-web/services/claim-service";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TbCircleCheck } from "react-icons/tb";
import { httpsCallable } from "firebase/functions";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";
import { CloudFunctions } from "../../../firebase";

interface ICHWClaimReview {
  claim?: Claim;
  isOpen: boolean;
  toggleModal: () => void;
  onInvoiceProcessed: () => void;
  refetchClaims: () => void;
}

const CHWClaimReview = ({
  claim,
  isOpen,
  toggleModal,
  onInvoiceProcessed,
  refetchClaims
}: ICHWClaimReview) => {
  const toast = useToast();
  const { patient, fetchPatient } = usePatient(claim?.patientId);
  const { isOpen: rejectionModalOpen, onToggle: toggleRejectionModal } =
    useDisclosure();

  const [submissionPending, setSubmissionPending] = useState<boolean>(false);
  const [claimSubmitted, setClaimSubmitted] = useState<boolean>();

  useEffect(() => {
    if (claim?.patientId && !patient) {
      fetchPatient(claim.patientId);
    }
  }, [claim?.patientId, patient, fetchPatient]);

  const canSubmitClaim =
    claim?.currentStatus &&
    [
      ClaimStatus.ApprovedByPatient,
      ClaimStatus.ApprovedByPBCP,
      ClaimStatus.NeedsFurtherReview
    ].includes(claim.currentStatus);

  const submitClaim = async () => {
    if (!claim) {
      toast({ status: "error", description: "No Claim Provided" });
      return;
    }
    setSubmissionPending(true);
    const submitStediClaim = httpsCallable<
      { claimId: string },
      CloudFunctionResponse
    >(CloudFunctions, "submitStediClaim");
    await submitStediClaim({ claimId: claim.id })
      .then((result) => {
        if (result.data.success) {
          toast({
            status: "success",
            description: "Claim submitted successfully!"
          });
          onInvoiceProcessed(); // refetch claim count in grandparent component
          refetchClaims(); // refetch claims on table
          setClaimSubmitted(true); // show completion modal
        } else {
          toast({
            status: "warning",
            description:
              "Failed to submit claim.  Please try again.  If you continue to have issues, please contact Oben support."
          });
        }
      })
      .catch((err) => {
        console.log("Claim submission failed:", err);
        toast({
          status: "error",
          description: "Failed to submit claim.  Please contact Oben support."
        });
      })
      .finally(() => {
        setSubmissionPending(false);
      });
  };

  if (!claim) {
    return <></>;
  }
  return (
    <Modal isOpen={isOpen} onClose={toggleModal} size='full'>
      <ModalOverlay />
      {!claimSubmitted ? (
        <ModalContent position={"relative"}>
          <RejectionModal
            claim={claim}
            isOpen={rejectionModalOpen}
            onToggle={toggleRejectionModal}
            onStatusChangeSaved={() => {
              toggleModal();
              refetchClaims();
            }}
          />
          <ModalCloseButton />
          <ModalHeader>
            <VStack>
              <Heading>Community Health Worker Invoice Details</Heading>
              <Text>Invoice Summary</Text>
            </VStack>
          </ModalHeader>
          <ModalBody w={"60%"} alignSelf={"center"}>
            {
              <Card
                position={"absolute"}
                w={"25%"}
                right={12}
                alignItems={"flex-start"}
                p={3}
              >
                <Heading as={"h2"} fontSize={"normal"}>
                  History
                </Heading>
                {claim.claimStatusChanges.toReversed().map((sc) => (
                  <Card w={"full"} p={3} variant='outline' my={2}>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"full"}
                      fontSize={"sm"}
                    >
                      <Text>
                        {sc.status === ClaimStatus.New
                          ? "Created"
                          : startCase(sc.status)}
                      </Text>
                      <Text>{DateTime.fromJSDate(sc.date).toFormat("D")}</Text>
                    </HStack>
                    <Text>{sc.details}</Text>
                  </Card>
                ))}
              </Card>
            }
            <VStack w={"full"} display='flex' flexDir={"column"}>
              <VStack w={"full"} alignItems={"flex-start"} spacing={0}>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text as='b'>Billing Provider Details</Text>
                </HStack>
                <Text>Name: {claim.billingProvBusinessName}</Text>
                <Text>NPI: {claim.billingProvNPI}</Text>
                <Text>TIN: {claim.billingProvTIN}</Text>
                <HStack alignItems={"flex-start"}>
                  <Text>Address: </Text>
                  <VStack alignItems={"flex-start"} spacing={0}>
                    <Text>{claim.billingProvAddress.street1}</Text>
                    {claim.billingProvAddress.street2 && (
                      <Text>{claim.billingProvAddress.street2}</Text>
                    )}
                    <Text>
                      {claim.billingProvAddress.city},{" "}
                      {claim.billingProvAddress.state},{" "}
                      {claim.billingProvAddress.zip}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>

              <VStack w={"full"} alignItems={"flex-start"} spacing={0}>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text as='b'>Rendering Provider Details</Text>
                </HStack>
                <Text>Name: {claim.renderingProvName.fullName}</Text>
                <Text>NPI: {claim.renderingProvNPI}</Text>
                <HStack alignItems={"flex-start"}>
                  <Text>Address: </Text>
                  <VStack alignItems={"flex-start"} spacing={0}>
                    <Text>{claim.serviceLocationAddress.street1}</Text>
                    {claim.serviceLocationAddress.street2 && (
                      <Text>{claim.serviceLocationAddress.street2}</Text>
                    )}
                    <Text>
                      {claim.serviceLocationAddress.city},{" "}
                      {claim.serviceLocationAddress.state},{" "}
                      {claim.serviceLocationAddress.zip}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>

              <VStack w={"full"} alignItems={"flex-start"} spacing={0}>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text as='b'>Supervising Provider Details</Text>
                </HStack>
                <Text>Name: {claim.supervisingPhysName.fullName}</Text>
                <Text>NPI: {claim.supervisingPhysNPI}</Text>
                <HStack alignItems={"flex-start"}>
                  <Text>Address: </Text>
                  <VStack alignItems={"flex-start"} spacing={0}>
                    <Text>{claim.supervisingPhysAddress.street1}</Text>
                    {claim.supervisingPhysAddress.street2 && (
                      <Text>{claim.supervisingPhysAddress.street2}</Text>
                    )}
                    {claim.supervisingPhysAddress.city &&
                      claim.supervisingPhysAddress.state && (
                        <Text>
                          {claim.supervisingPhysAddress.city},{" "}
                          {claim.supervisingPhysAddress.state},{" "}
                          {claim.supervisingPhysAddress.zip}
                        </Text>
                      )}
                  </VStack>
                </HStack>
              </VStack>

              {patient && (
                <VStack w={"full"} alignItems={"flex-start"} spacing={0}>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text as='b'>Patient Details</Text>
                  </HStack>
                  <Text>Name: {claim.patientName.fullName}</Text>
                  {patient.dob && (
                    <Text>
                      DOB:&nbsp;
                      {DateTime.fromJSDate(patient.dob).toFormat("D")}
                    </Text>
                  )}
                  <Text>Insurance Provider: {claim.payerBusinessName}</Text>
                  <Text>
                    Patient is Unhoused: {claim.patientIsHomeless.valueOf()}
                  </Text>

                  <HStack alignItems={"flex-start"}>
                    <Text>Address: </Text>
                    <VStack alignItems={"flex-start"} spacing={0}>
                      <Text>{claim.patientAddress?.street1}</Text>
                      {claim.patientAddress?.street2 && (
                        <Text>{claim.patientAddress?.street2}</Text>
                      )}
                      {claim.patientAddress?.city &&
                        claim.patientAddress?.state &&
                        claim.patientAddress?.zip && (
                          <Text>
                            {claim.patientAddress?.city},{" "}
                            {claim.patientAddress?.state},{" "}
                            {claim.patientAddress?.zip}
                          </Text>
                        )}
                    </VStack>
                  </HStack>
                </VStack>
              )}

              <VStack w={"full"} alignItems={"flex-start"} spacing={0}>
                <HStack w={"full"} justifyContent={"space-between"}>
                  <Text as='b'>Payer and Service Details</Text>
                </HStack>
                <Text>Name: {claim.payerBusinessName}</Text>
                <Text>
                  Payer ID: {claim.billingProvIdentifierAtClearinghouse}
                </Text>
                <Text>
                  Service Date:{" "}
                  {DateTime.fromJSDate(claim.serviceDate).toFormat("D")} -{" "}
                  {DateTime.fromJSDate(claim.serviceDate)
                    .plus({ minutes: claim.serviceDuration })
                    .toFormat("D")}
                </Text>
                <Text>Diagnosis Code: {claim.icd10Code}</Text>
                <Text>CPT Code: {claim.billingCode}</Text>
                <Text>Modifier: {claim.billingModifier}</Text>
                <Text># of Units: {claim.serviceUnitCount}</Text>
                <Text>Cost per Unit: {claim.serviceCostPerUnit}</Text>
                <Text>Total Cost: ${claim.amount / 100}</Text>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter
            w={"full"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/* <Button
            onClick={() => {
              setClaim(undefined);
              toggleModal();
            }}
          >
            Cancel
          </Button> */}
            <Button
              onClick={() => {
                // setClaim(undefined);
                toggleRejectionModal();
              }}
              isLoading={submissionPending}
              isDisabled={submissionPending}
            >
              Reject with reason
            </Button>

            <Button
              colorScheme='teal'
              onClick={submitClaim}
              isLoading={submissionPending}
              isDisabled={submissionPending || !canSubmitClaim}
            >
              Submit Claim
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <VStack
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            alignSelf={"center"}
            py={"20%"}
            h={"full"}
            w={"30%"}
            spacing={20}
          >
            <VStack spacing={4}>
              <TbCircleCheck size={32} />
              <Text>
                This Claim has been submitted. Sit tight while we think of
                something clever to put here.
              </Text>
            </VStack>
            <Button
              onClick={() => {
                toggleModal();
                setClaimSubmitted(false);
              }}
              colorScheme='teal'
              px={"4rem"}
            >
              Done
            </Button>
          </VStack>
        </ModalContent>
      )}
    </Modal>
  );
};

export default CHWClaimReview;

interface IClaimStatusForm {
  status: ClaimStatus;
  date: Date;
  editorType: UserType;
  editorId: string;
  details: string;
}

const RejectionModal = ({
  claim,
  isOpen,
  onToggle,
  onStatusChangeSaved
}: {
  claim: Claim;
  isOpen: boolean;
  onToggle: () => void;
  onStatusChangeSaved: () => void;
}) => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { register, handleSubmit, watch, setValue } = useForm<IClaimStatusForm>(
    {
      defaultValues: {
        status: ClaimStatus.Unknown,
        date: new Date(),
        editorType: currentUser!.userType,
        editorId: currentUser!.uid,
        details: ""
      },
      resolver: yupResolver(claimStatusSchema) as any
    }
  );

  const onSubmit = async (data: IClaimStatusForm) => {
    const claimService = new ClaimService();
    console.log({ data }); // 3-21/25 debugging why date seems to be wrong
    const claimStatusChange = new ClaimStatusChange(data);
    claim.claimStatusChanges.push(claimStatusChange);
    claim.currentStatus = data.status;
    await claimService
      .updateClaim(claim)
      .then(() => {
        toast({
          status: "success",
          description: "Rejection reason was logged"
        });
        onStatusChangeSaved();
      })
      .catch((e) => {
        console.log(e);
        toast({
          status: "error",
          description: "Failed to log rejection reason"
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Reject Claim</ModalHeader>
          <ModalBody>
            <Text>Please provide a reason for rejecting this claim.</Text>
            <RadioGroup
              my={2}
              value={watch("status")}
              onChange={(v) => {
                if (
                  v === ClaimStatus.NeedsFurtherReview ||
                  v === ClaimStatus.RejectedByPBCP
                )
                  setValue("status", v);
              }}
            >
              <VStack w={"full"} alignItems={"flex-start"}>
                <Radio value={ClaimStatus.NeedsFurtherReview}>
                  Needs review
                </Radio>
                <Radio value={ClaimStatus.RejectedByPBCP}>
                  Will not submit
                </Radio>
              </VStack>
            </RadioGroup>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea {...register("details")} />
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={onToggle}>Cancel</Button>
            <Button type='submit' colorScheme='teal'>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
