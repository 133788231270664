import usePayers from "@/hooks/usePayers";
import { CHWRecommendation } from "@oben-core-web/models/chw-recommendation";
import { CHWRecommendationService } from "@oben-core-web/services/chw-recommendation-service";
import React, { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import { DateTime } from "luxon";
import { Box, BoxProps, Center, IconButton, Text } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { DownloadIcon } from "@chakra-ui/icons";
import useRecommendationLetter from "@/hooks/useRecommendationLetter";

interface IPatientRecommendationTable extends BoxProps {
  patientId: string;
}

const PatientRecommendationTable = ({
  patientId,
  ...props
}: IPatientRecommendationTable) => {
  const { generate } = useRecommendationLetter();
  const { payers } = usePayers();
  const [recommendationLetters, setRecommendationLetters] = useState<
    CHWRecommendation[]
  >([]);
  useEffect(() => {
    const fetchRecommendationLetters = async () => {
      if (patientId) {
        const chwRecommendationService = new CHWRecommendationService();
        const letters =
          await chwRecommendationService.getPatientCHWRecommendations(
            patientId
          );
        setRecommendationLetters(
          letters.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
        );
      }
    };
    fetchRecommendationLetters();
  }, [patientId]);

  const colHeaders: ColDef[] = [
    {
      field: "supervisingPhysName",
      headerName: "Supervising Physician",
      valueFormatter: ({ data }) => data.supervisingPhysName.fullName,
      minWidth: 100,
      flex: 1
    },
    {
      field: "payerId",
      headerName: "Insurance Provider",
      valueFormatter: ({ data }) =>
        payers.find((p) => p.id === data.payerId)?.businessName ?? "",
      minWidth: 100,
      flex: 1
    },
    {
      field: "patientPolicyNumber",
      headerName: "Patient Policy Number",
      minWidth: 100,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueFormatter: ({ data }) =>
        DateTime.fromJSDate(data.createdDate).toFormat("D"),
      minWidth: 100,
      flex: 0.6
    },
    {
      field: "latestDownloadDate",
      headerName: "Last Download Date",
      valueFormatter: ({ data }) =>
        data.latestDownloadDate
          ? DateTime.fromJSDate(data.latestDownloadDate).toFormat("D")
          : "",
      minWidth: 100,
      flex: 0.6
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ({ data }: { data: CHWRecommendation }) => {
        return (
          <IconButton
            variant={"link"}
            aria-label={`download-recommendation-${data.modelId}`}
            icon={<DownloadIcon />}
            onClick={async () => {
              await generate({ chwRecommendation: data, shouldDownload: true });
            }}
          />
        );
      },
      minWidth: 100,
      flex: 0.4
    }
  ];

  return (
    <Box
      className='ag-theme-quartz'
      // style={{ height: "100%", width: "100%" }}
      {...props}
    >
      {recommendationLetters.length === 0 ? (
        <Center h={"20rem"}>
          <Text>No Data</Text>
        </Center>
      ) : (
        <AgGridReact
          rowData={recommendationLetters}
          columnDefs={colHeaders as any}
          rowSelection='single'
        />
      )}
    </Box>
  );
};

export default PatientRecommendationTable;
