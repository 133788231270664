import { useCallback, useEffect, useState } from "react";
import { ServiceLocation } from "@oben-core-web/models/service-location";
import { ServiceLocationService } from "@oben-core-web/services/service-location-service";

const useServiceLocations = (placeBasedCareProvId?: string) => {
  const [serviceLocations, setServiceLocations] = useState<ServiceLocation[]>(
    []
  );
  const [error, setError] = useState<unknown>();

  const fetchServiceLocations = useCallback(
    async (placeBasedCareProvId: string) => {
      try {
        const serviceLocationService = new ServiceLocationService();
        const shops = await serviceLocationService.getAllServiceLocations();
        if (placeBasedCareProvId) {
          setServiceLocations(
            shops.filter((s) =>
              s.placeBasedCareProvIds.includes(placeBasedCareProvId)
            )
          );
          return;
        }
        setServiceLocations(shops);
      } catch (e) {
        setError(e);
      }
    },
    []
  );

  useEffect(() => {
    placeBasedCareProvId && fetchServiceLocations(placeBasedCareProvId);
  }, [placeBasedCareProvId, fetchServiceLocations]);
  return {
    serviceLocations,
    error,
    refetch: () =>
      placeBasedCareProvId && fetchServiceLocations(placeBasedCareProvId)
  };
};

export default useServiceLocations;
