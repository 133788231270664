import { useEffect, useState } from "react";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { StaffMember } from "@oben-core-web/models/staff-member";

const useStaffMember = (id?: string) => {
  const [user, setUser] = useState<StaffMember | null>(null);

  useEffect(() => {
    const staffMemberService = new StaffMemberService();
    const { unsubscribe } = staffMemberService.streamStaffMember(
      id ?? "",
      (docSnap) => {
        if (docSnap.exists()) {
          setUser(StaffMember.fromFirestore(docSnap));
        } else {
          setUser(null);
        }
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe && unsubscribe();
      }
    };
  }, [id]);

  return { user };
};

export default useStaffMember;
