import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
  orderBy,
  setDoc,
  onSnapshot,
  QuerySnapshot
} from "firebase/firestore";
import { Database } from "../../firebase";

import { PatientTask } from "../models/patient-task";
import { GaLog } from "../utils/ga-log";

export class PatientTaskService {
  private _grandParentCollectionName: string;
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._grandParentCollectionName = "patients";
    this._parentCollectionName = "carePlans";
    this._collectionName = "patientTasks";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all tasks for a specified care plan (for an individual patient)
  async getPatientTasks(
    patientId: string,
    carePlanId: string
  ): Promise<PatientTask[]> {
    const path = `${this._grandParentCollectionName}/${patientId}/${this._parentCollectionName}/${carePlanId}/${this._collectionName}`;
    try {
      const tasksQuery = query(
        collection(Database, path),
        orderBy("createdDate", "desc")
      );
      const qSnapshot = await getDocs(tasksQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => PatientTask.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  streamPatientTasks({
    patientId,
    carePlanId,
    handleSnapshot
  }: {
    patientId: string;
    carePlanId: string;
    handleSnapshot: (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => void;
  }) {
    const path = `${this._grandParentCollectionName}/${patientId}/${this._parentCollectionName}/${carePlanId}/${this._collectionName}`;
    let unsubscribe: (() => void) | undefined;
    try {
      const tasksQuery = query(
        collection(Database, path),
        orderBy("createdDate", "desc")
      );
      unsubscribe = onSnapshot(
        tasksQuery,
        (snapshot) => {
          handleSnapshot(snapshot);
          GaLog.readCollection(path, snapshot.docs.length, { isSubscription: true });
        },
        (error) => {
          console.error("Error in onSnapshot:", error);
          GaLog.readCollection(`${path}`, 0, { isSubscription: true });
        }
      );
    } catch (error) {
      GaLog.readError(path, error, { isSubscription: true });
      throw error;
    }
    return { unsubscribe };
  }

  async getTask(patientTaskId: string): Promise<PatientTask> {
    try {
      const taskQuery = query(
        this._collectionGroup,
        where("modelId", "==", patientTaskId)
      );
      const qSnapshot = await getDocs(taskQuery);
      if (qSnapshot.empty) {
        throw new Error(`PatientTask not found: ${patientTaskId}`);
      }
      const task = PatientTask.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(`${this._grandParentCollectionName}/${task.patientId}/${this._parentCollectionName}/${task.carePlanId}/${this._collectionName}`, task.modelId);
      return task;
    } catch (error) {
      GaLog.readError(`${this._grandParentCollectionName}/?????/${this._parentCollectionName}/?????/${this._collectionName}`, error);
      throw error;
    }
  }

  async addTask(patientTask: PatientTask): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${patientTask.patientId}/${this._parentCollectionName}/${patientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef);
      await setDoc(docRef, { ...patientTask.toJson(), modelId: docRef.id });
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateTask(patientTask: PatientTask): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${patientTask.patientId}/${this._parentCollectionName}/${patientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, patientTask.modelId);
      await updateDoc(docRef, patientTask.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteTask(patientTask: PatientTask): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${patientTask.patientId}/${this._parentCollectionName}/${patientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, patientTask.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, patientTask.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}
