import { Patient } from "@oben-core-web/models/patient";
import { PatientService } from "@oben-core-web/services/patient-service";
import { useCallback, useEffect, useState } from "react";

const usePatientUser = (
  patientId: string = "",
  fetchInitially: boolean = false
) => {
  const [patient, setPatientUser] = useState<Patient>();
  const fetchPatient = useCallback(async (patientId: string) => {
    const patientService = new PatientService();
    const patient = await patientService.getPatient(patientId);
    setPatientUser(patient);
    return patient;
  }, []);
  useEffect(() => {
    if (fetchInitially) {
      fetchPatient(patientId);
    }
  }, [patientId, fetchInitially, fetchPatient]);
  return { patient, fetchPatient };
};

export default usePatientUser;
