import { object, string, number, boolean, array, date } from "yup";
import userNameSchema from "./userName";
import {
  LegalEntityType,
  StripeStatus
} from "@oben-core-web/models/community-health-worker";

const appInfoSchema = object({
  buildNumber: string(),
  version: string(),
  upgradeDate: date().nullable()
});
const platformInfoSchema = object({
  platform: string(),
  version: string(),
  upgradeDate: date().nullable()
});

const communityHealthWorkerSchema = object({
  uid: string().optional(),
  appInfo: appInfoSchema,
  platformInfo: platformInfoSchema,
  name: userNameSchema,
  email: string().email().required(),
  phoneNumber: string().nullable(),
  enabled: boolean().required(),
  pwdNeedsReset: boolean().required(),
  currentServiceLocationId: string().required(),
  serviceLocationIds: array().min(0).of(string()).required(),
  placeBasedCareProvIds: array().min(0).of(string()).required(),
  legalEntityType: string().oneOf([
    LegalEntityType.Company,
    LegalEntityType.Individual,
    LegalEntityType.Nonprofit
  ]),
  stripeStatus: string().oneOf([
    StripeStatus.Disabled,
    StripeStatus.RemediationNeeded,
    StripeStatus.Started,
    StripeStatus.TransfersEnabled,
    StripeStatus.Unknown
  ]),
  stripeRemediationItems: array()
    .min(0)
    .of(object({ description: string(), dueDate: date().nullable() })),
  serviceFee: number().nullable(),
  noShowFee: number().nullable(),
  cancelFee: number().nullable(),
  cancelWindow: number().nullable()
});

export default communityHealthWorkerSchema;
