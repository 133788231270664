import { useCallback, useEffect, useState } from "react";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { PatientTask } from "@oben-core-web/models/patient-task";
import { PatientTaskService } from "@oben-core-web/services/patient-task-service";

const usePatientTasks = ({
  patientId,
  carePlanId
}: {
  patientId: string;
  carePlanId: string;
}) => {
  const [patientTasks, setPatientTasks] = useState<PatientTask[]>([]);

  const handleSubscription = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const tasks: PatientTask[] = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          tasks.push(PatientTask.fromFirestore(docSnap));
        }
      });
      setPatientTasks(tasks);
    },
    []
  );
  useEffect(() => {
    let unsubscribeFunc: (() => void) | undefined;
    if (patientId && carePlanId) {
      const patientTaskService = new PatientTaskService();
      const { unsubscribe } = patientTaskService.streamPatientTasks({
        patientId: patientId,
        carePlanId: carePlanId,
        handleSnapshot: handleSubscription
      });
      unsubscribeFunc = unsubscribe;
    }

    return () => {
      if (unsubscribeFunc) {
        unsubscribeFunc();
      }
    };
  }, [handleSubscription, patientId, carePlanId]);

  return patientTasks;
};

export default usePatientTasks;
