import useCurrentUser from "@/hooks/useCurrentUser";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import {
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSupport } from "react-icons/bi";
import { httpsCallable } from "firebase/functions";
import { CloudFunctions } from "../../../firebase";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";

interface ISupportEmail {
  userId: string;
  userEmail: string;
  userName: string;
  userRole: string;
  organizationId: string;
  organizationName: string;
  emailBody: string;
}

const SupportEmailSender = () => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { isOpen, onToggle } = useDisclosure();
  const { register, handleSubmit } = useForm<ISupportEmail>({
    defaultValues: {
      userId: currentUser!.uid,
      userEmail: currentUser!.email,
      userName: currentUser!.name.fullName,
      userRole: currentUser!.userType,
      organizationId: placeBasedCareProvider?.id,
      organizationName: placeBasedCareProvider?.businessName,
      emailBody: ""
    }
  });

  const submissionHandler = async (data: ISupportEmail) => {
    const sendEmail = httpsCallable<ISupportEmail, CloudFunctionResponse>(
      CloudFunctions,
      "sendSupportEmail"
    );
    await sendEmail(data)
      .then(() => {
        toast({ status: "success", description: "Support request submitted" });
        onToggle();
      })
      .catch(() => {
        toast({
          status: "error",
          description: "Failed to submit support request"
        });
      });
  };

  return (
    <>
      <MenuItem
        onClick={onToggle}
        icon={<BiSupport />}
        w={"full"}
        display={"flex"}
      >
        Get Support
      </MenuItem>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onToggle}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmit(submissionHandler)}>
              <ModalCloseButton />
              <ModalHeader>Get Support</ModalHeader>
              <ModalBody>
                <VStack spacing={4}>
                  <Text>
                    Reach out to Oben support with any issues you may be
                    encountering.
                  </Text>
                  <Text>
                    Please describe the action you are trying to take, any
                    errors that were displayed, and any additional context that
                    might be helpful for us to know. To assist us in supporting
                    you please be as descriptive as possible.
                  </Text>
                  <Textarea {...register("emailBody")} />
                  <Text>
                    Once your request is received we will reach back out via
                    email as soon as possible.
                  </Text>
                  <Text fontSize={"small"}>
                    If you're having issues using this form you can reach out to
                    us directly at support@joinoben.com
                  </Text>
                </VStack>
              </ModalBody>
              <ModalFooter display={"flex"} justifyContent={"space-between"}>
                <Button onClick={onToggle} colorScheme='red'>
                  Cancel
                </Button>
                <Button type='submit' colorScheme='blue'>
                  Submit
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SupportEmailSender;
