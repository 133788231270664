import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  where,
  query
} from "firebase/firestore";
import { Database } from "../../firebase";

import { PendingPatient } from "../models/pending-patient";
import { GaLog } from "../utils/ga-log";

export class PendingPatientService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "pendingPatients";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs
  async getAllPendingPatients(): Promise<PendingPatient[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        PendingPatient.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPendingPatient(id: string): Promise<PendingPatient> {
    try {
      const docRef = doc(this._collectionReference, id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`PendingPatient not found: ${id}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return PendingPatient.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addPendingPatient(pendingPatient: PendingPatient): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        pendingPatient.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updatePendingPatient(pendingPatient: PendingPatient): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, pendingPatient.id);
      await updateDoc(docRef, pendingPatient.toJson());
      GaLog.updateDocument(this._collectionReference.path, pendingPatient.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  async deletePendingPatient(id: string): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, id);
      await deleteDoc(docRef);
      GaLog.deleteDocument(this._collectionReference.path, id);
      return;
    } catch (error) {
      GaLog.deleteError(this._collectionReference.path, error);
      throw error;
    }
  }

  //   // not used by web portal
  // async lookupByNameDob(lastName: string, dob: Date): Promise<PendingPatient[] | any> {
  // }

  //* ===========================================================================
  //* Methods below are custom queries only present on web version of the service
  //* ===========================================================================

  async getPendingPatientsByPlaceBasedCareProvId(
    placeBasedCareProvId: string
  ): Promise<PendingPatient[]> {
    try {
      const pendingPatientQuery = query(
        this._collectionReference,
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(pendingPatientQuery);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        PendingPatient.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }
}
