import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useLayoutEffect
} from "react";
import FullCalendar from "@fullcalendar/react";
import {
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Box,
  Icon,
  useToast,
  Text,
  useDisclosure,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  IconButton,
  ButtonGroup,
  useBreakpointValue,
  Tooltip
} from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import {
  Appointment,
  IAppointmentData
} from "@oben-core-web/models/appointment";
import _, { startCase } from "lodash";
import { StaffMember } from "@oben-core-web/models/staff-member";
// import { IDefaultWorkingHoursData } from '@oben-core-web/models/default-working-hours';
import { convertToLocalTZ, convertToUTC } from "@/lib/timezone";
import useAppointments from "@/hooks/useAppointments";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours";
import { UserType } from "@oben-core-web/constants/core-enums";
import { CustomWorkingHours } from "@oben-core-web/models/custom-working-hours";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { MdDone, MdDoneAll, MdOutlineReport } from "react-icons/md";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  LiaQuestionCircle,
  LiaUserSlashSolid,
  LiaUserTimesSolid
} from "react-icons/lia";
// import { LuCalendarPlus } from "react-icons/lu";

interface IScheduler {
  selectedStaffMember: StaffMember | null;
  onEventClick: (event: any) => void;
  onDateClick: (date: { start: Date; end: Date }) => void;
  height?: number;
}

const SchedulerComponent = React.memo(
  ({
    selectedStaffMember,
    onEventClick,
    onDateClick,
    height = 655
  }: IScheduler) => {
    const calendarRef = useRef<FullCalendar | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const [currentView, setCurrentView] = useState<
      "timeGridDay" | "timeGridWeek" | "dayGridMonth"
    >(isMobile ? "timeGridDay" : "timeGridWeek");
    const { isOpen, onToggle } = useDisclosure(); // for listView create appt modal
    const [weekStart, setWeekStart] = useState(
      DateTime.now().startOf("week").toJSDate()
    );
    const [weekEnd, setWeekEnd] = useState(
      DateTime.now().endOf("week").toJSDate()
    );
    // const [businessHours, setBusinessHours] = useState<any[]>([]);
    // const [events, setEvents] = useState([]);
    const [staffMemberIds, setStaffMemberIds] = useState<string[]>([]);
    const toast = useToast();

    const customHourParams = useMemo(
      () => ({
        userId: selectedStaffMember?.uid ?? "",
        start: convertToUTC(weekStart),
        end: convertToUTC(weekEnd)
      }),
      [selectedStaffMember, weekStart, weekEnd]
    );
    const { customWorkingHours } = useCustomWorkingHours(customHourParams);
    // const { customWorkingHours } = useCustomWorkingHours({
    //   userId: selectedStaffMember?.uid ?? "",
    //   start: convertToUTC(weekStart),
    //   end: convertToUTC(weekEnd)
    // });

    const fetchStaffMembers = useCallback(async () => {
      const staffMemberService = new StaffMemberService();
      return await staffMemberService.getAllStaffMembers([
        UserType.Pharmacist,
        UserType.Physician
      ]);
    }, []);

    useEffect(() => {
      const init = async () => {
        const users = await fetchStaffMembers();
        const staffMemberIds = users.map((wu) => wu.uid);
        // refetchAppointments(staffMemberIds);
        setStaffMemberIds(staffMemberIds);
      };

      init();
    }, [
      fetchStaffMembers
      // refetchAppointments
    ]);

    // useLayoutEffect ensures this runs after DOM is updated
    useLayoutEffect(() => {
      if (!calendarRef.current) return; // Early return if the calendar isn't mounted yet

      const calendarApi = calendarRef.current.getApi();
      if (!calendarApi) return; // Ensure FullCalendar API is available

      const observer = new ResizeObserver(() => {
        calendarApi.updateSize(); // Update the calendar size on resize
      });

      if (containerRef.current) {
        observer.observe(containerRef.current); // Start observing the container
      }

      // Cleanup observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    }, []); // Empty dependency array to ensure this runs only once on mount

    useLayoutEffect(() => {
      if (isMobile) {
        setCurrentView("timeGridDay");
        calendarRef.current?.getApi().changeView("timeGridDay");
      } else {
        setCurrentView("timeGridWeek");
        calendarRef.current?.getApi().changeView("timeGridWeek");
      }
    }, [isMobile]);

    const { appointments } = useAppointments({
      pharmacistId: selectedStaffMember?.uid ?? staffMemberIds.join(","),
      start: weekStart,
      end: weekEnd
      // statuses: [
      //   AppointmentStatus.New,
      //   AppointmentStatus.Canceled,
      //   AppointmentStatus.NoShow,
      //   AppointmentStatus.LateCanceled,
      //   AppointmentStatus.Completed,
      //   AppointmentStatus.Confirmed
      // ]
    });
    const pharmacistColors = useMemo(
      () =>
        appointments.reduce((acc, current) => {
          if (!acc[current.pharmacistId]) {
            acc[current.pharmacistId] =
              eventColors[
                staffMemberIds.findIndex((u) => u === current.pharmacistId)
              ];
          }
          return acc;
        }, {} as Record<string, string>),
      [appointments, staffMemberIds]
    );

    const events = useMemo(() => {
      const allAppts = appointments.map((a) => ({
        ...a,
        start: a.date!,
        end: DateTime.fromJSDate(a.date!)
          .plus({ minutes: a.length })
          .toJSDate(),
        title: `${a.patientName.first} ${a.patientName.last} - ${a.serviceLocationName}`,
        displayEventTime: false,
        backgroundColor: hexToRgba(
          pharmacistColors[a.pharmacistId],
          a.currentStatus !== AppointmentStatus.Unknown
            ? a.currentStatus === AppointmentStatus.New
              ? 0.8
              : a.statusChanges[a.statusChanges.length - 1]?.status ===
                AppointmentStatus.New
              ? 0.8
              : 1
            : 1
        ),
        borderColor: hexToRgba(
          pharmacistColors[a.pharmacistId],
          a.statusChanges[a.statusChanges.length - 1]?.status ===
            AppointmentStatus.New
            ? 0.8
            : 1
        ),
        extendedProps: {
          isUnconfirmed:
            a.statusChanges[a.statusChanges.length - 1]?.status ===
            AppointmentStatus.New,
          isEditable: !a.billableEventId
        }
        // backgroundColor:
        //   pharmacistColors[a.pharmacistId] +
        //     a.statusChanges[a.statusChanges.length - 1].status ===
        //   AppointmentStatus.New
        //     ? "80"
        //     : "",
        // borderColor:
        //   pharmacistColors[a.pharmacistId] +
        //     a.statusChanges[a.statusChanges.length - 1].status ===
        //   AppointmentStatus.New
        //     ? "80"
        //     : ""
      }));
      return allAppts;
    }, [appointments, pharmacistColors]);

    const businessHours = useMemo(() => {
      const defaultWorkingHours =
        selectedStaffMember?.defaultWorkingHours.map((dwh) => {
          const { dayOfWeek, startTime, endTime } = dwh;
          return {
            daysOfWeek: [dayOfWeek],
            startTime: DateTime.fromJSDate(weekStart)
              .set({
                hour: startTime.getHours(),
                minute: startTime.getMinutes()
              })
              .toFormat("HH:mm"),
            endTime: DateTime.fromJSDate(weekStart)
              .set({ hour: endTime.getHours(), minute: endTime.getMinutes() })
              .toFormat("HH:mm")
          };
        }) ?? [];

      const customBusinessHours = customWorkingHours.map(
        (cwh: CustomWorkingHours) => {
          const { start, end } = cwh;
          if (!start || !end) {
            console.log("Missing start or end date");
            return;
          }
          const dayOfStart = DateTime.fromJSDate(start).weekday;
          return {
            id: cwh.modelId,
            daysOfWeek: [dayOfStart],
            startTime: DateTime.fromJSDate(convertToLocalTZ(start))
              .set({ hour: start.getHours(), minute: start.getMinutes() })
              .toFormat("HH:mm"),
            endTime: DateTime.fromJSDate(convertToLocalTZ(end))
              .set({ hour: end.getHours(), minute: end.getMinutes() })
              .toFormat("HH:mm")
          };
        }
      );

      const groupedWorkingHours = _.groupBy(
        [
          ...customBusinessHours,
          ...defaultWorkingHours.map((dwh) => ({ ...dwh, id: undefined }))
        ],
        (h) => h?.daysOfWeek[0]
      );

      const newBusinessHours = _.reduce(
        groupedWorkingHours,
        (a, c) => {
          const dayWorkingHours = c.some((wh) => wh?.id)
            ? c.filter((wh) => !!wh?.id)
            : c;
          a.push(...dayWorkingHours);
          return a;
        },
        [] as any[]
      );

      // setBusinessHours(newBusinessHours);
      return newBusinessHours;
    }, [selectedStaffMember, weekStart, customWorkingHours]);

    // useEffect(() => {
    //   updateBusinessHours();
    // }, [updateBusinessHours]);

    const handleDatesSet = useCallback((dateRange: DatesSetArg) => {
      setWeekStart(dateRange.start);
      setWeekEnd(dateRange.end);
      // refetchAppointments();
      // refetchCustomHours({
      //   userId: selectedStaffMember?.uid,
      //   start: dateRange.start,
      //   end: dateRange.end,
      // });
    }, []);

    const handleDateSelect = useCallback(
      (selectInfo: DateSelectArg) => {
        // Handle date select logic
        onDateClick({
          start: selectInfo.start,
          end: selectInfo.end
        });
      },
      [onDateClick]
    );

    const handleEventClick = useCallback(
      (clickInfo: EventClickArg) => {
        // Handle event click logic
        // console.log({ clickInfo });
        const { start, end } = clickInfo.event;
        if (!start || !end) {
          console.log("Click event error: Missing start or end date");
          return;
        }
        onEventClick({
          ...clickInfo.event.extendedProps,
          id: clickInfo.event.id,
          startDateTime: new Date(start),
          endDateTime: new Date(end)
        });
      },
      [onEventClick]
    );

    const handleEventDrop = useCallback(
      async (change: EventDropArg) => {
        const appointmentService = new AppointmentService();
        if (change.event.extendedProps?.isEditable === false) return;
        const oldStart = change.oldEvent.start;
        const oldEnd = change.oldEvent.end;
        const start = change.event.start;
        const end = change.event.end;
        if (!start || !end || !oldStart || !oldEnd) {
          console.log("Event drop error: Missing start or end");
          change.revert();
          return;
        }

        const pharmacistWorkingHours = businessHours ?? [];
        if (pharmacistWorkingHours.length) {
          const workingHours = pharmacistWorkingHours.filter(
            (wh) => wh.daysOfWeek[0] === DateTime.fromJSDate(start).weekday
          );
          if (!workingHours.length) {
            toast({
              title: "Pharmacist is not working that day",
              status: "error"
            });
            change.revert();
            return;
          }
          let hasValidAppointmentTimes = false;
          workingHours.forEach((wh) => {
            const [whStartHour, whStartMin] = wh.startTime.split(":");
            const [whEndHour, whEndMin] = wh.endTime.split(":");

            const workingHourInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(start).set({
                hour: whStartHour,
                minute: whStartMin
              }),
              DateTime.fromJSDate(end).set({
                hour: whEndHour,
                minute: whEndMin
              })
            );
            const appointmentInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(start),
              DateTime.fromJSDate(end)
            );
            const isWithinWorkingHours =
              workingHourInterval.start! <= appointmentInterval.start! &&
              workingHourInterval.end! >= appointmentInterval.end!;

            if (isWithinWorkingHours) {
              hasValidAppointmentTimes = true;
            }
          });
          if (!hasValidAppointmentTimes) {
            toast({
              title: "Pharmacist is not working at that time",
              status: "error"
            });
            change.revert();
            return;
          }
        }
        const updatedAppointment = new Appointment({
          ...(change.event.extendedProps as IAppointmentData),
          id: change.event.id
        });
        const newDuration = DateTime.fromJSDate(end).diff(
          DateTime.fromJSDate(start),
          "minutes"
        ).minutes;

        updatedAppointment.date = start;
        updatedAppointment.length = newDuration;

        appointmentService.updateAppointment(updatedAppointment).then(() => {
          toast({
            title: "Appointment Rescheduled!",
            description: "Click here to undo",
            status: "success",
            isClosable: true
            // onClick: () => {
            //   // Implement undo functionality
            // },
          });
        });
        return;
      },
      [businessHours, toast]
    );

    const setView = (view: "timeGridDay" | "timeGridWeek" | "dayGridMonth") => {
      const calendar = calendarRef.current?.getApi();
      if (!calendar) return;
      calendar.changeView(view);
      setCurrentView(view);
    };
    const shiftDates = (direction: "forward" | "backward") => {
      const calendar = calendarRef.current?.getApi();
      if (!calendar) return;
      if (direction === "forward") calendar.next();
      if (direction === "backward") calendar.prev();
    };
    const goToToday = () => {
      const calendar = calendarRef.current?.getApi();
      if (!calendar) return;
      calendar.today();
    };

    const calendarOptions: CalendarOptions = useMemo(() => {
      return {
        plugins: [
          dayGridPlugin,
          listPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin
        ],
        initialView: currentView,
        headerToolbar: false,
        // headerToolbar: {
        //   right: "today prev,next",
        //   center: "title",
        //   left: "dayGridMonth,timeGridWeek,listWeek"
        // },
        allDaySlot: false,
        slotMinTime: "07:00",
        displayEventTime: false,
        // slotDuration: "00:15:00",
        // slotMaxTime: "00:00",
        eventOverlap: false,
        datesSet: handleDatesSet,
        events: events as EventInput,
        eventContent: (eventInfo) => {
          // Access icon from extendedProps
          const {
            isUnconfirmed,
            patientName,
            serviceLocationName,
            currentStatus
          } = eventInfo.event.extendedProps;
          return (
            <Tooltip
              label={`${
                patientName.fullName
              } @ ${serviceLocationName} - Appointment ${
                isUnconfirmed ? "Unconfirmed" : startCase(currentStatus)
              }`}
            >
              <Box
                style={{
                  display: "flex",
                  height: "100%",
                  overflow: "hidden"
                }}
              >
                <Icon
                  as={
                    isUnconfirmed
                      ? MdOutlineReport
                      : currentStatus === AppointmentStatus.Confirmed
                      ? MdDone
                      : currentStatus === AppointmentStatus.Canceled ||
                        currentStatus === AppointmentStatus.LateCanceled
                      ? LiaUserTimesSolid
                      : currentStatus === AppointmentStatus.NoShow
                      ? LiaUserSlashSolid
                      : currentStatus === AppointmentStatus.Completed
                      ? MdDoneAll
                      : LiaQuestionCircle
                  }
                  h={5}
                  w={5}
                  ml={1}
                  alignSelf={"flex-start"}
                />

                <Text
                  as={"span"}
                  fontStyle={isUnconfirmed ? "italic" : "normal"}
                >
                  &nbsp;{eventInfo.event.title}
                </Text>
              </Box>
            </Tooltip>
          );
        },
        select: handleDateSelect,
        selectConstraint: "businessHours",
        eventClick: handleEventClick,
        editable: businessHours.length > 0,
        selectable: businessHours.length > 0,
        eventDrop: handleEventDrop, // TODO: find a workaround for this.  See github issue: https://github.com/fullcalendar/fullcalendar/issues/7619
        businessHours,
        height
      };
    }, [
      height,
      currentView,
      businessHours,
      events,
      handleDatesSet,
      handleDateSelect,
      handleEventClick,
      handleEventDrop
    ]);

    return (
      <Box mt={2} ref={containerRef} w={"full"}>
        <Modal isOpen={isOpen} onClose={onToggle} isCentered={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <form
              onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();
                const target = e.target as typeof e.target & {
                  apptDateTime: { value: string };
                  duration: { value: string };
                };
                if (!target.apptDateTime || !target.duration) return;
                const apptDateTime = new Date(target.apptDateTime.value);
                const duration = Number(target.duration.value);
                onDateClick({
                  start: apptDateTime,
                  end: DateTime.fromJSDate(apptDateTime)
                    .plus({ minutes: duration })
                    .toJSDate()
                });
                onToggle();
              }}
            >
              <ModalHeader>Create an Appointment</ModalHeader>
              <ModalBody>
                <FormControl>
                  <FormLabel>Appointment Time</FormLabel>
                  <Input name='apptDateTime' type={"datetime-local"} />
                </FormControl>
                <FormControl>
                  <FormLabel>Duration</FormLabel>
                  <Input name='duration' type={"number"} />
                </FormControl>
              </ModalBody>
              <ModalFooter
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Button colorScheme='red' onClick={onToggle}>
                  Cancel
                </Button>
                <Button colorScheme='blue' type={"submit"}>
                  Submit
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <HStack
          alignItems={"center"}
          justifyContent={"space-between"}
          my={2}
          mt={4}
        >
          <HStack gap={0}>
            {/* {currentView === "timeGridDay" && (
              <IconButton
                aria-label={"add-appt"}
                icon={<LuCalendarPlus />}
                onClick={onToggle}
                variant={"outline"}
                colorScheme='blue'
                h={8}
              />
            )} */}
            {!isMobile && (
              <>
                <ButtonGroup gap={0} spacing={0}>
                  <Button
                    size='sm'
                    color={"white"}
                    bg={"black"}
                    border={"1px solid white"}
                    rounded={"unset"}
                    onClick={() => {
                      setView("timeGridDay");
                    }}
                    isDisabled={currentView === "timeGridDay"}
                  >
                    Day
                  </Button>
                  <Button
                    size='sm'
                    color={"white"}
                    bg={"black"}
                    border={"1px solid white"}
                    rounded={"unset"}
                    onClick={() => {
                      setView("dayGridMonth");
                    }}
                    isDisabled={currentView === "dayGridMonth"}
                  >
                    Month
                  </Button>
                  <Button
                    size='sm'
                    color={"white"}
                    bg={"black"}
                    border={"1px solid white"}
                    rounded={"unset"}
                    onClick={() => {
                      setView("timeGridWeek");
                    }}
                    isDisabled={currentView === "timeGridWeek"}
                  >
                    Week
                  </Button>
                  <Button
                    size='sm'
                    color={"white"}
                    bg={"black"}
                    border={"1px solid white"}
                    rounded={"unset"}
                    onClick={goToToday}
                  >
                    Today
                  </Button>
                </ButtonGroup>
              </>
            )}
          </HStack>
          <Text fontSize={["small", "medium"]}>
            {DateTime.fromJSDate(weekStart).toFormat("LLL d yyyy")} -{" "}
            {DateTime.fromJSDate(weekEnd).toFormat("LLL d yyyy")}
          </Text>
          <HStack alignItems={"center"} justifyContent={"center"} gap={0}>
            <IconButton
              onClick={() => {
                shiftDates("backward");
              }}
              aria-label='scheduler-go-prev'
              h={8}
              icon={<ChevronLeftIcon />}
              color={"white"}
              bg={"black"}
              rounded={"unset"}
              border={".5px solid white"}
            />
            <IconButton
              onClick={() => {
                shiftDates("forward");
              }}
              aria-label='scheduler-go-next'
              h={8}
              icon={<ChevronRightIcon />}
              color={"white"}
              bg={"black"}
              rounded={"unset"}
              border={".5px solid white"}
            />
          </HStack>
        </HStack>
        <FullCalendar ref={calendarRef} {...calendarOptions} />
        {/* Additional UI elements and form handling */}
      </Box>
    );
  }
);

export default SchedulerComponent;

const eventColors = [
  // "#FFAF00",
  "#B38012",
  "#19FF9B",
  "#C019FF",
  "#8409B3",
  "#19E3FF"
];

const hexToRgba = (hex: string, alpha: number) => {
  if (!hex) return "";
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
