import UserMenu from "../molecules/UserProfileMenu";
import { Box, Card, Flex, Heading, Text } from "@chakra-ui/react";
import IndexSearch from "../molecules/IndexSearch";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useAppContext } from "@/hooks/useAppContext";

const AppHeader = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const {
    state: { appMode }
  } = useAppContext();
  const isAdminMode = appMode === "admin";
  return (
    <Card
      pl={4}
      py={8}
      h={"full"}
      display={"flex"}
      flexDir={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className='app-header'
    >
      {isAdminMode ? (
        <Heading as='h1' fontSize='md'>
          Admin Mode
        </Heading>
      ) : (
        <IndexSearch
          searchIndex='patients'
          prefilledText=''
          w={["70%", "30rem"]}
          transformItems={(items: any[]) => {
            return items.map((item) => {
              return {
                ...item,
                id: item.objectID,
                name: item.name.first + " " + item.name.last
              };
            });
          }}
          hitComponent={({ item }: any) => {
            return (
              <Flex direction={"column"}>
                <Box>
                  <Text as='b'>{item.name}</Text>{" "}
                  <Text as='i' fontSize={"sm"}>
                    {item.email ? ` - ${item.email}` : ""}
                  </Text>
                </Box>
                {item.dob && (
                  <Text as='i' fontSize='xs'>
                    Date of birth:{" "}
                    {DateTime.fromJSDate(new Date(item.dob))
                      .toUTC()
                      .toFormat("DD")}
                  </Text>
                )}
              </Flex>
            );
          }}
          filters={
            currentUser?.placeBasedCareProvId
              ? `placeBasedCareProvId:${currentUser.placeBasedCareProvId}`
              : ""
          }
          onResultClick={(item) => {
            navigate(`/patient/${item.id}`);
          }}
          placeholderText='Search by patient name or email'
        />
      )}
      <UserMenu />
    </Card>
  );
};

export default AppHeader;
