import usePatientCarePlans from "@/hooks/usePatientCarePlans";
import usePatientSubscription from "@/hooks/usePatientSubscription";
import useMedications from "@/hooks/useMedications";
import useTasks from "@/hooks/useTasks";
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  HStack,
  SimpleGrid,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack
} from "@chakra-ui/react";
import { PatientTaskType } from "@oben-core-web/models/patient-task";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { DateTime } from "luxon";
import BPChart from "./BPChart";
import { InternalTask } from "@oben-core-web/models/internal-task";
import { useEffect, useRef, useState } from "react";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { PlanStatus } from "@oben-core-web/models/care-plan";

interface ICPWizardSummary {
  carePlanId: string;
  patientId: string;
  internalTaskId?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const CPWizardSummary = ({
  carePlanId,
  patientId,
  internalTaskId,
  onSubmit,
  onCancel
}: ICPWizardSummary) => {
  const { patient } = usePatientSubscription(patientId);
  const { carePlans } = usePatientCarePlans(patientId);
  const [internalTask, setInternalTask] = useState<InternalTask>();
  const internalTaskFetched = useRef(false);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  const latestBPGoal = carePlan?.bpGoals.sort((a, b) =>
    a.setDate >= b.setDate ? -1 : 1
  )[0];
  const { medications } = useMedications({ patientId, carePlanId });
  const patientTasks = useTasks({ patientId, carePlanId });

  const smartGoalTasks = patientTasks.filter(
    (t) => t.taskType === PatientTaskType.SmartGoal
  );
  const healthMaintenanceTasks = patientTasks.filter(
    (t) => t.taskType === PatientTaskType.HealthcareAction
  );
  const homeMonitoringTask = patientTasks
    .sort((a, b) => (a.createdDate! >= b.createdDate! ? -1 : 1))
    .filter((t) => t.taskType === PatientTaskType.HomeBPMonitoring)[0];
  const pcpFollowUpTask = patientTasks
    .sort((a, b) => (a.createdDate! >= b.createdDate! ? -1 : 1))
    .filter((t) => t.taskType === PatientTaskType.HealthcareAction)[0];

  const setCarePlanAsActive = async () => {
    await CarePlanTransactionService.activateCarePlan({
      patientId,
      carePlanId
    }).then(() => {
      onSubmit && onSubmit();
    });
  };
  const latestAscvdRiskScore = patient?.latestASCVDRisk?.score;

  useEffect(() => {
    const fetchInternalTask = async (internalTaskId: string) => {
      const internalTaskService = new InternalTaskService();
      const task = await internalTaskService.getTask(internalTaskId);
      setInternalTask(task);
    };
    if (!internalTaskFetched.current && internalTaskId) {
      internalTaskFetched.current = true;
      fetchInternalTask(internalTaskId);
    }
  }, [internalTaskId]);
  return (
    <VStack alignItems={"flex-start"} w={"full"}>
      <HStack
        w={"full"}
        alignItems={"center"}
        justifyContent={"space-between"}
        fontSize={"smaller"}
      >
        <Card variant='outline' p={3}>
          Last BP Reading: {patient?.latestBpReading?.systolic}/
          {patient?.latestBpReading?.diastolic}
        </Card>
        <Card variant='outline' p={3}>
          Blood Pressure Goal: {latestBPGoal?.systolic}/
          {latestBPGoal?.diastolic}
        </Card>
        {latestAscvdRiskScore && (
          <Badge
            p={3}
            // variant={"outline"}
            colorScheme={
              latestAscvdRiskScore > 20
                ? "red"
                : latestAscvdRiskScore > 7.5
                ? "orange"
                : latestAscvdRiskScore > 5
                ? "yellow"
                : "green"
            }
            fontSize={"medium"}
            textAlign={"center"}
          >
            ASCVD Risk Score: {patient?.latestASCVDRisk?.score}
          </Badge>
        )}
      </HStack>
      <Box w={"full"} h={300} mb={4}>
        <BPChart
          patientId={patientId}
          canEditGoal={false}
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Text fontSize={"x-large"} as={"h2"}>
        Medications
      </Text>
      <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
        <SimpleGrid columns={6} spacing={5}>
          {medications
            .filter((m) => !m.rxCancelDate)
            .map((med, i) => (
              <Stat
                key={`summary-med-${i}`}
                borderWidth={2}
                rounded={"md"}
                p={3}
                fontSize={["smaller", "normal"]}
              >
                <StatLabel>{med.name}</StatLabel>
                <StatNumber>
                  {med.newDosage.strength}mg{" "}
                  {med.newDosage.strength !== med.oldDosage.strength ? (
                    <StatArrow
                      type={
                        med.newDosage.strength > med.oldDosage.strength
                          ? "increase"
                          : "decrease"
                      }
                    />
                  ) : (
                    <></>
                  )}
                </StatNumber>
                <StatHelpText>{`${med.newDosage.frequencyCount} / ${
                  med.newDosage.frequencyPeriod === "Daily" ? "day" : "week"
                } for ${med.newDosage.durationCount} days`}</StatHelpText>
              </Stat>
            ))}
        </SimpleGrid>
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Healthcare Actions
      </Text>
      <VStack alignItems={"flex-start"} w={"full"}>
        {healthMaintenanceTasks.map((task, i) => (
          <Card
            key={`summary-hm-task-${i}`}
            w={"full"}
            px={4}
            py={2}
            variant={"outline"}
          >
            <Flex
              direction={"row"}
              // borderLeft={"1px solid"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <VStack alignItems={"flex-start"}>
                <Text fontSize={"large"} lineHeight={"1.5em"} as='b'>
                  {task.name}
                </Text>

                <Text
                  fontSize={"medium"}
                  fontWeight={"normal"}
                >{`${task.description}`}</Text>
              </VStack>
              <Text>
                {task.dueDate
                  ? `Due ${DateTime.fromJSDate(task.dueDate).toFormat(
                      "MM/dd/yyyy"
                    )}`
                  : ""}
              </Text>
            </Flex>
          </Card>
        ))}
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Smart Goals
      </Text>
      <VStack w={"full"}>
        {smartGoalTasks.map((task, i) => (
          <Card
            key={`summary-sg-task-${i}`}
            w={"full"}
            px={4}
            py={2}
            variant={"outline"}
          >
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <VStack alignItems={"flex-start"}>
                <Text fontSize={"large"}>{`${task.name}`}</Text>
                <Text
                  fontSize={"medium"}
                  fontWeight={"normal"}
                >{`${task.description}`}</Text>
              </VStack>
              <Text>{`${
                task.frequencyCount == 1
                  ? task.frequencyCount + " time"
                  : task.frequencyCount + " times"
              } per ${task.frequencyPeriod === "Daily" ? "day" : "week"} ${
                task.dueDate
                  ? "until " +
                    DateTime.fromJSDate(task.dueDate).toFormat("MM/dd/yyyy")
                  : ""
              }`}</Text>
            </Flex>
          </Card>
        ))}
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Follow up and Monitoring
      </Text>
      <VStack alignItems={"flex-start"} w={"full"}>
        {!!homeMonitoringTask && (
          <Card
            w={"full"}
            px={4}
            py={2}
            variant={"outline"}
            display='flex'
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"large"}>Home blood pressure monitoring</Text>
            <Text fontSize={"small"}>{`${
              homeMonitoringTask.frequencyCount == 1
                ? homeMonitoringTask.frequencyCount + " time"
                : homeMonitoringTask.frequencyCount + " times"
            } per ${
              homeMonitoringTask.frequencyPeriod === "Daily" ? "day" : "week"
            } ${
              homeMonitoringTask.dueDate
                ? "until " +
                  DateTime.fromJSDate(homeMonitoringTask.dueDate).toFormat(
                    "MM/dd/yyyy"
                  )
                : ""
            }`}</Text>
          </Card>
        )}

        {!!pcpFollowUpTask && (
          <Card
            w={"full"}
            px={4}
            py={2}
            variant={"outline"}
            display='flex'
            flexDir={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text fontSize={"large"}>Visit your primary care physician</Text>
            <Text>
              Due{" "}
              {DateTime.fromJSDate(pcpFollowUpTask.dueDate!).toFormat(
                "MM/dd/yyyy"
              )}
            </Text>
          </Card>
        )}

        {internalTask && internalTask.dueDate && (
          <Card
            w={"full"}
            px={4}
            py={2}
            variant={"outline"}
            display='flex'
            flexDir={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text fontSize={"large"}>
              A follow-up pharmacist appointment will be scheduled
            </Text>
            <Text>
              {Math.ceil(
                DateTime.fromJSDate(internalTask.dueDate)
                  .diffNow("weeks")
                  .as("weeks")
              )}{" "}
              Weeks from now
            </Text>
          </Card>
        )}
      </VStack>

      <HStack mt={"2rem"} w={"full"} justifyContent={"space-between"}>
        {onCancel && <Button onClick={onCancel}>Previous</Button>}
        {onSubmit && (
          <Button
            onClick={() => {
              if (carePlan?.status === PlanStatus.Current) {
                onSubmit();
              } else {
                setCarePlanAsActive();
              }
            }}
          >
            Done
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

export default CPWizardSummary;
