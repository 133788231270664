import { DocumentSnapshot } from "firebase/firestore";
import { CHWRecommendationService } from "../services/chw-recommendation-service";
import { UserName } from "./user-name";

export interface ICHWRecommendationData {
  modelId: string;
  patientId: string;
  patientName: UserName;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  placeBasedCareProvId: string;
  payerId: string;
  patientPolicyNumber: string;
  createdDate: Date;
  latestDownloadDate: Date | null;
  details: string;
}

export class CHWRecommendation {
  modelId: string;
  patientId: string;
  patientName: UserName;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  placeBasedCareProvId: string;
  payerId: string;
  patientPolicyNumber: string;
  createdDate: Date;
  latestDownloadDate: Date | null;
  details: string;

  constructor({
    modelId,
    patientId,
    patientName,
    supervisingPhysId,
    supervisingPhysName,
    placeBasedCareProvId,
    payerId,
    patientPolicyNumber,
    createdDate,
    latestDownloadDate,
    details
  }: ICHWRecommendationData) {
    this.modelId = modelId;
    this.patientId = patientId;
    this.patientName = patientName;
    this.supervisingPhysId = supervisingPhysId;
    this.supervisingPhysName = supervisingPhysName;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.payerId = payerId;
    this.patientPolicyNumber = patientPolicyNumber;
    this.createdDate = createdDate;
    this.latestDownloadDate = latestDownloadDate;
    this.details = details;
  }

  async updateDb(): Promise<void> {
    const db = new CHWRecommendationService();
    await db.updateCHWRecommendation(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): CHWRecommendation {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return CHWRecommendation.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): CHWRecommendation {
    return new CHWRecommendation({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      patientId: data["patientId"] ?? "",
      patientName: UserName.fromMap(data["patientName"] ?? {}),
      supervisingPhysId: data["supervisingPhysId"] ?? "",
      supervisingPhysName: UserName.fromMap(data["supervisingPhysName"] ?? {}),
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      payerId: data["payerId"] ?? "",
      patientPolicyNumber: data["patientPolicyNumber"] ?? "",
      createdDate: data["createdDate"] //
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1901-Jan-01
      latestDownloadDate: data["latestDownloadDate"]
        ? new Date(data["latestDownloadDate"])
        : null,
      details: data["details"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId,
      patientId: this.patientId,
      patientName: this.patientName.toJson(),
      supervisingPhysId: this.supervisingPhysId,
      supervisingPhysName: this.supervisingPhysName.toJson(),
      placeBasedCareProvId: this.placeBasedCareProvId,
      payerId: this.payerId,
      patientPolicyNumber: this.patientPolicyNumber,
      createdDate: this.createdDate.toISOString(),
      latestDownloadDate: this.latestDownloadDate?.toISOString() ?? null,
      details: this.details
    };
  }
}
