import Scheduler from "../molecules/Scheduler";
import SchedulerAppointmentForm from "../molecules/SchedulerAppointmentForm";
import { Box, Select, useDisclosure } from "@chakra-ui/react";
import useStaffMembers from "@/hooks/useStaffMembers";
import { UserType } from "@oben-core-web/constants/core-enums";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { DateTime } from "luxon";
import { Patient } from "@oben-core-web/models/patient";
import { PatientService } from "@oben-core-web/services/patient-service";
import PatientAppointment from "../organisms/PatientAppointment";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentUser from "@/hooks/useCurrentUser";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import {
  InternalTask,
  InternalTaskType
} from "@oben-core-web/models/internal-task";
import { Appointment } from "@oben-core-web/models/appointment";

// TODO: Refetch appointment after appointment ends.  Requires passing 'onAppointmentComplete' callback from here instead of defining it in PatientAppointment

const Appointments = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { state } = useLocation();
  const apptSetByStateRef = useRef(false);
  const [selectedStaffMember, setSelectedStaffMember] =
    useState<StaffMember | null>(
      currentUser?.userType === UserType.Pharmacist ||
        currentUser?.userType === UserType.Physician
        ? currentUser
        : null
    );
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: state?.isOpen ?? false
  });
  const { isOpen: appointmentStarted, onToggle: toggleApptStarted } =
    useDisclosure();
  const [appointment, setAppointment] = useState<any>();
  const [appointmentPatient, setAppointmentPatient] = useState<Patient>();
  const staffMemberParams = useMemo(
    () => [UserType.Pharmacist, UserType.Physician],
    []
  );
  const { staffMembers } = useStaffMembers(staffMemberParams);

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value;
      if (staffMembers && staffMembers.length) {
        setSelectedStaffMember(staffMembers.find((wu) => wu.uid === id)!);
      }
    },
    [staffMembers]
  );
  useEffect(() => {
    if (state?.appointment && !apptSetByStateRef.current) {
      setAppointment(state.appointment);
      apptSetByStateRef.current = true;
    }
  }, [state?.appointment]);
  const setSelectedAppointment = useCallback(
    (appt: any) => {
      setAppointment({
        ...appt,
        startDateTime: new Date(appt.startDateTime),
        endDateTime: new Date(appt.endDateTime)
      });
      onToggle();
    },
    [onToggle]
  );
  const setSelectedDate = useCallback(
    (timeRange: { start: Date; end: Date }) => {
      const length = DateTime.fromJSDate(timeRange.end).diff(
        DateTime.fromJSDate(timeRange.start),
        "minutes"
      ).minutes;
      const emptyAppt = {
        startDateTime: timeRange.start,
        length,
        pharmacistId: selectedStaffMember?.uid,
        ...(state?.patientId && state?.patientName
          ? { patientId: state.patientId, patientName: state.patientName }
          : {}),
        ...(state?.prefCHW
          ? {
              chwId: state.prefCHW.uid,
              chwName: state.prefCHW.name
            }
          : {}),
        ...(state?.prefServiceLocation
          ? {
              serviceLocationId: state.prefServiceLocation.id,
              serviceLocationName: state.prefServiceLocation.businessName
            }
          : {})
      };
      setAppointment(emptyAppt);
      onToggle();
    },
    [onToggle, selectedStaffMember, state]
  );

  const userOptions = useMemo(
    () =>
      staffMembers.map((wu) => (
        <option key={wu.uid} value={wu.uid}>
          {wu.name.fullName}
        </option>
      )),
    [staffMembers]
  );

  const startPatientAppointment = async (patientId: string) => {
    // fetch patient record
    const patientService = new PatientService();
    const patient = await patientService.getPatient(patientId);
    setAppointmentPatient(patient);
    // toggle off schedulerAppointmentForm modal
    onToggle();
    // toggle on patientAppointment modal
    toggleApptStarted();
  };

  const exitWizard = () => {
    // reset appointment to previously selected appointment
    setAppointment(appointment);
    onToggle(); // open up schedulerAppointmentForm modal
    setAppointmentPatient(undefined); // clear appointmentPatient
    toggleApptStarted(); // close PatientAppointment modal
  };

  return (
    <Box py={4} px={4} bg={"white"} h={"full"} w={"full"}>
      <Select
        mt={2}
        placeholder='Select a Pharmacist'
        onChange={handleSelect}
        value={selectedStaffMember?.uid}
        className='appointments-pharmacist-select'
      >
        {userOptions}
      </Select>
      <Scheduler
        selectedStaffMember={selectedStaffMember}
        onEventClick={setSelectedAppointment}
        onDateClick={setSelectedDate}
      />
      {appointment && (
        <SchedulerAppointmentForm
          dialogOpen={isOpen}
          appointment={appointment}
          disableFormActions={false}
          toggleDialog={() => {
            setAppointment(undefined);
            onToggle();
          }}
          onAppointmentScheduled={async (appointment: Appointment) => {
            // check if this action was completed for an internal task
            if (state?.internalTask) {
              const internalTask: InternalTask = state.internalTask;
              if (internalTask && appointment) {
                // check if patient of the task is the same as the patient on scheduled appt
                if (
                  internalTask.patientId === appointment.patientId &&
                  internalTask.internalTaskType ===
                    InternalTaskType.ScheduleAppointment
                ) {
                  // update the task if there is a match
                  const internalTaskService = new InternalTaskService();
                  internalTask.completionDate = new Date();
                  await internalTaskService.updateTask(internalTask);
                }
              }
            }
            if (state?.from) {
              navigate(state.from, { state: null, replace: true });
            }
          }}
          startPatientAppointment={startPatientAppointment}
        />
      )}
      {!!appointmentPatient && appointmentStarted && !!appointment && (
        <PatientAppointment
          appointmentStarted={appointmentStarted}
          toggleApptStarted={toggleApptStarted}
          patient={appointmentPatient}
          appointment={appointment}
          exitWizard={exitWizard}
        />
      )}
    </Box>
  );
};

export default Appointments;
