// import { UserType } from "@oben-core-web/constants/core-enums";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { useState, useEffect, useCallback } from "react";

const useProgramStaffMembers = (placeBasedCareProvId: string) => {
  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  // const wasFetched = useRef(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const fetchStaffMembers = useCallback(async () => {
    try {
      setLoading(true);
      // wasFetched.current = true;
      const webUserService = new StaffMemberService();
      const wus = await webUserService.getPlaceBasedCareProvStaffMembers(
        placeBasedCareProvId
      );
      setStaffMembers(wus);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [placeBasedCareProvId]);

  useEffect(() => {
    fetchStaffMembers();
  }, [fetchStaffMembers]);

  return { staffMembers, loading, error, refetch: fetchStaffMembers };
};

export default useProgramStaffMembers;
