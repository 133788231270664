export enum TaskStatus {
  Unknown = 'Unknown',
  New = 'New',
  InProgress = 'InProgress',
  OnHold = 'OnHold',
  Canceled = 'Canceled',
  Finished = 'Finished',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class TaskStatusHelper {
  static valueOf(searchString: string | null): TaskStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(TaskStatus).includes(searchString as TaskStatus)) //
      ? searchString as TaskStatus
      : null;
  }
}

export interface ITaskStatusChangeData {
  status: TaskStatus;
  date: Date;
}

// TaskStatusChange records are maintained in a list on PatientTask
export class TaskStatusChange {
  status: TaskStatus;
  date: Date;

  constructor({ 
    status,
    date,
  }: ITaskStatusChangeData) {
    this.status = status;
    this.date = date;
  }

  static fromMap(data: { [key: string]: any }): TaskStatusChange {
    return new TaskStatusChange({
      status: TaskStatusHelper.valueOf(data["status"] ?? "") ?? TaskStatus.Unknown,
      date: data["date"] ? new Date(data["date"]) : new Date(1, 0, 1), // default to 1901-Jan-01

    });
  }

  toJson(): { [key: string]: any } {
    return {
      "status": this.status,
      "date": this.date.toISOString(),

    };
  }
}
