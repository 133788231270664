import usePatientCarePlans from "@/hooks/usePatientCarePlans";
import usePatientSubscription from "@/hooks/usePatientSubscription";
import {
  VStack,
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  // Flex,
  Textarea,
  SimpleGrid
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import useCholesterolReadings from "@/hooks/useCholesterolReadings";
import { CholesterolReading } from "@oben-core-web/models/cholesterol-reading";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { DateTime } from "luxon";

interface ICPWizardStep5 {
  carePlanId: string;
  patientId: string;
  currentUser: StaffMember;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface ICPWizardStep5Form {
  hdl: number;
  ldl: number;
  total: number;
  readingDate: string;
  details?: string;
}

// TODO: Add new lab readings, and add history of lab results ???

const CPWizardStep5 = ({
  carePlanId,
  patientId,
  currentUser,
  onSubmit,
  onCancel
}: ICPWizardStep5) => {
  const { patient } = usePatientSubscription(patientId);
  const { carePlans } = usePatientCarePlans(patientId);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  const { cholesterolReadings } = useCholesterolReadings(patientId);
  const lastReading = cholesterolReadings[0];
  const lastReadingRef = useRef(lastReading);
  const { register, handleSubmit, formState, reset } =
    useForm<ICPWizardStep5Form>({
      defaultValues: {
        hdl: lastReading?.HDL ?? 0,
        ldl: lastReading?.LDL ?? 0,
        total: lastReading?.total ?? 0,
        readingDate: lastReading?.readingDate
          ? DateTime.fromJSDate(lastReading.readingDate).toFormat("yyyy-MM-dd")
          : "",
        details: lastReading?.details ?? ""
      }
    });
  useEffect(() => {
    if (!isEqual(lastReading, lastReadingRef.current)) {
      lastReadingRef.current = lastReading;
      lastReading &&
        reset({
          hdl: lastReading.HDL,
          ldl: lastReading.LDL,
          total: lastReading.total,
          readingDate: lastReading?.readingDate
            ? DateTime.fromJSDate(lastReading.readingDate).toFormat(
                "yyyy-MM-dd"
              )
            : "",
          details: lastReading.details
        });
    }
  }, [reset, lastReading]);
  formState.isDirty;
  const submissionHandler = async (data: ICPWizardStep5Form) => {
    if (!carePlan) {
      return;
    }
    try {
      const { ldl, hdl, total, details, readingDate } = data;
      const { dirtyFields } = formState;
      if (Object.values(dirtyFields).length > 0) {
        const reading = new CholesterolReading({
          id: "",
          patientId,
          HDL: hdl,
          LDL: ldl,
          total: total,
          readingDate:
            DateTime.fromJSDate(new Date(readingDate)).toUTC().toJSDate() ??
            new Date(),
          recorderType: currentUser!.userType,
          recorderId: currentUser!.uid,
          details: details ?? "",
          isInvalid: false,
          invalidDate: null,
          invalidatorType: null,
          invalidatorId: null,
          placeBasedCareProvId: currentUser!.placeBasedCareProvId
        });

        await CarePlanTransactionService.saveCholesterolReadingData({
          reading
        }).then(() => {
          onSubmit && onSubmit();
        });
      } else {
        onSubmit && onSubmit();
      }
    } catch (e) {
      console.log({ error: e });
    }
  };
  return (
    <>
      {patient && carePlan && (
        <form
          onSubmit={handleSubmit(submissionHandler, (errors) => {
            console.log({ errors });
          })}
          style={{ minHeight: "30rem", position: "relative" }}
        >
          <VStack
            spacing={4}
            key='step5-1'
            alignItems={"flex-start"}
            w={"100%"}
            h={"full"}
          >
            {/* <Flex
              // direction={["column", "row"]}
              justifyContent={"space-evenly"}
              flexWrap={["wrap", "wrap", "nowrap"]}
              w={"full"}
            > */}
            <SimpleGrid columns={[1, 2, 4]}>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  HDL
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("hdl")}
                />
              </FormControl>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  LDL
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("ldl")}
                />
              </FormControl>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  Total
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("total")}
                />
              </FormControl>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  Reading Date
                </FormLabel>
                <Input type='date' {...register("readingDate")} />
              </FormControl>
            </SimpleGrid>
            {/* </Flex> */}
            <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
              <FormLabel fontSize={"sm"} w={"5rem"}>
                Details
              </FormLabel>
              <Textarea size={"md"} {...register("details")} />
            </FormControl>
          </VStack>
          <HStack
            mt={"2rem"}
            justifyContent={"space-between"}
            position='absolute'
            w={"full"}
            bottom={0}
          >
            {onCancel && <Button onClick={onCancel}>Previous</Button>}
            {onSubmit && <Button type='submit'>Next</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep5;
