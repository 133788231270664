import { object, string, number, boolean, array } from "yup";
import userNameSchema from "./userName";
import { UserType } from "@oben-core-web/constants/core-enums";
import addressSchema from "./address";

const workingHourSchema = object({
  dayOfWeek: number().required(),
  startTime: string().required(),
  endTime: string().required()
});
const pharmacistWorkingHourSchema = array()
  .of(workingHourSchema)
  .min(1, "Must have at least one working hour set");

const pharmacistOnboardingSchema = object({
  uid: string().required(),
  name: userNameSchema,
  email: string().email().required(),
  phoneNumber: string().nullable(),
  enabled: boolean().required(),
  pwdNeedsReset: boolean().required(),
  userType: string().oneOf([UserType.Pharmacist]),
  address: addressSchema.nullable(),
  npi: string().nullable(),
  placeBasedCareProvId: string().required(),
  defaultWorkingHours: array()
    .of(
      object({
        dayOfWeek: number().required(),
        startTime: string().required(),
        endTime: string().required()
      })
    )
    .min(1, "Must have at least one working hour set"),
  isProgramAdmin: boolean().required()
});

const physicianOnboardingSchema = object({
  uid: string().required(),
  name: userNameSchema,
  email: string().email().required(),
  phoneNumber: string().nullable(),
  enabled: boolean().required(),
  pwdNeedsReset: boolean().required(),
  userType: string().oneOf([UserType.Physician]),
  address: object({
    street1: string().required("This is a required field"),
    street2: string().nullable(),
    city: string().required("This is a required field"),
    state: string().required("This is a required field"),
    zip: string().required("This is a required field")
  }),
  npi: string().required("This is a required field"),
  placeBasedCareProvId: string().required(),
  defaultWorkingHours: array()
    .of(
      object({
        dayOfWeek: number().required("This is a required field"),
        startTime: string().required("This is a required field"),
        endTime: string().required("This is a required field")
      })
    )
    .min(0),
  isProgramAdmin: boolean().required()
});

export {
  workingHourSchema,
  pharmacistOnboardingSchema,
  pharmacistWorkingHourSchema,
  physicianOnboardingSchema
};
