import { UserType } from "@oben-core-web/constants/core-enums";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { useState, useEffect, useMemo, useRef } from "react";

const useStaffMembers = (userTypes?: UserType[]) => {
  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  const wasFetched = useRef(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        setLoading(true);
        wasFetched.current = true;
        const staffMemberService = new StaffMemberService();
        const wus = await staffMemberService.getAllStaffMembers(userTypes);
        setStaffMembers(wus);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    if (!wasFetched.current) fetchStaffMembers();
  }, [userTypes]);

  return useMemo(
    () => ({ staffMembers, loading, error }),
    [staffMembers, loading, error]
  );
};

export default useStaffMembers;
