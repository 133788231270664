import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy
  // getCountFromServer
} from "firebase/firestore";
import { Database } from "../../firebase";

import { BpReading } from "../models/bp-reading";
import { GaLog } from "../utils/ga-log";
import { uniqBy } from "lodash";

export class BpReadingService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "bpReadings";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all BP readings for a specified patient
  // TODO: Implement optional filtering
  async getPatientBpReadings(patientId: string): Promise<BpReading[]> {
    const queryRef = query(
      this._collectionReference,
      where("patientId", "==", patientId),
      orderBy("readingDate", "desc")
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BpReading.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBpReading(bpReadingId: string): Promise<BpReading> {
    try {
      const docRef = doc(this._collectionReference, bpReadingId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`BP Reading not found: ${bpReadingId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return BpReading.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addBpReading(bpReading: BpReading): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        bpReading.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateBpReading(bpReading: BpReading): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, bpReading.id);
      await updateDoc(docRef, bpReading.toJson());
      GaLog.updateDocument(this._collectionReference.path, bpReading.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }
  // // BpReadings can only be updated, not deleted
  // async deleteBpReading(bpReadingId: string): Promise<void> {}

  //* ===========================================================================
  //* Methods below are custom queries only present on web version of the service
  //* ===========================================================================
  async getBpReadingCountByCHWs({
    chwIds,
    start,
    end
  }: {
    chwIds: string[];
    start?: Date;
    end?: Date;
  }): Promise<number> {
    const filters = [
      where("recorderType", "==", "CHW"),
      where("recorderId", "in", chwIds)
    ];
    if (start) {
      filters.push(where("readingDate", ">=", start.toISOString()));
    }
    if (end) {
      filters.push(where("readingDate", "<=", end.toISOString()));
    }
    try {
      const queryRef = query(this._collectionReference, ...filters);
      // TODO: refactored this to get the actual docs instead of the counts because we need a unique count of chws instead of the number of readings.  Would be good to refactor this again later to be a bit more efficient query wise
      const bpReadingDocs = await getDocs(queryRef);
      const bpReadings = bpReadingDocs.docs.map((doc) =>
        BpReading.fromFirestore(doc)
      );
      return uniqBy(bpReadings, "recorderId").length;
      // const readingSnapshot = await getCountFromServer(queryRef);
      // return readingSnapshot.data().count;
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPBCPReadings({
    placeBasedCareProvId,
    start,
    end
  }: {
    placeBasedCareProvId: string;
    start: Date;
    end: Date;
  }) {
    const filters = [where("placeBasedCareProvId", "==", placeBasedCareProvId)];
    if (start) {
      filters.push(where("readingDate", ">=", start.toISOString()));
    }
    if (end) {
      filters.push(where("readingDate", "<=", end.toISOString()));
    }
    try {
      const queryRef = query(this._collectionReference, ...filters);
      const bpReadingDocs = await getDocs(queryRef);
      const bpReadings = bpReadingDocs.docs.map((doc) =>
        BpReading.fromFirestore(doc)
      );
      return bpReadings;
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }
}
