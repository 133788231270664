import useServiceLocations from "@/hooks/useServiceLocations";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import serviceLocationSchema from "@/resolvers/serviceLocation";
import { EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Switch
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ServiceLocation,
  IServiceLocationData
} from "@oben-core-web/models/service-location";
import { ServiceLocationService } from "@oben-core-web/services/service-location-service";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";

const AdminServiceLocationManagement = () => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { serviceLocations, refetch } = useServiceLocations(
    placeBasedCareProvider!.id
  );
  const [selectedServiceLocation, setSelectedServiceLocation] =
    useState<ServiceLocation>();
  const columnHeaders: (
    | ColDef<ServiceLocation & { actions: any }>
    | (ColDef<ServiceLocation & { actions: any }> & {
        children: ColDef<ServiceLocation & { actions: any }>[];
      })
  )[] = [
    {
      headerName: "ServiceLocation",
      children: [
        {
          field: "actions",
          headerName: "Actions",
          flex: 0.25,
          cellRenderer: ({ data }: any) => (
            <IconButton
              aria-label={`edit-serviceLocation-${data.id}`}
              icon={<EditIcon />}
              variant='ghost'
              onClick={() => {
                setSelectedServiceLocation(data);
                onToggle();
              }}
            />
          )
        },
        {
          field: "enabled",
          headerName: "Enabled",
          flex: 0.25,
          cellRenderer: ({ data }: any) => (
            <Switch
              isChecked={data.enabled}
              onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                const serviceLocationService = new ServiceLocationService();
                const serviceLocation = ServiceLocation.fromMap(data.id, data);
                serviceLocation.enabled = event.target.checked;
                await serviceLocationService
                  .updateServiceLocation(serviceLocation)
                  .then(async () => {
                    toast({
                      status: "success",
                      description: `ServiceLocation ${
                        !event.target.checked ? "Enabled" : "Disabled"
                      }`
                    });
                    await refetch();
                  });
              }}
            />
            // <IconButton
            //   aria-label={`edit-serviceLocation-${data.id}`}
            //   icon={<EditIcon />}
            //   variant='ghost'
            //   onClick={() => {
            //     setSelectedServiceLocation(data);
            //     onToggle();
            //   }}
            // />
          )
        },
        { field: "businessName", headerName: "Name", flex: 0.8 },
        {
          field: "address",
          headerName: "Address",
          valueGetter: ({ data }) => {
            return data!.address.completeAddress ?? "";
          },
          flex: 0.8
        }
      ]
    },
    {
      headerName: "Current Month",
      children: [
        {
          field: "currentMonthScreenings",
          headerName: "Screenings",
          flex: 0.35
        },
        {
          field: "currentMonthQualified",
          headerName: "Qualifications",
          flex: 0.35
        }
      ]
    },
    {
      headerName: "Rankings",
      children: [
        { field: "screeningsGoal", headerName: "Screenings Goal", flex: 0.4 },
        { field: "currentRanking", headerName: "Shop Ranking", flex: 0.4 },
        { field: "totalScreenings", headerName: "Total Screenings", flex: 0.4 }
      ]
    }
  ];

  return (
    <VStack alignItems={"flex-start"} h={"full"}>
      <HStack alignItems={"center"} justifyContent={"space-between"} w={"full"}>
        <Heading fontSize={"large"} as={"h1"}>
          Manage Service Locations
        </Heading>
        <Button onClick={onToggle} size='sm' colorScheme='blue'>
          Add Service Location
        </Button>
      </HStack>
      <Box className='ag-theme-quartz' h={"full"} w={"full"}>
        <AgGridReact
          columnDefs={columnHeaders}
          rowData={serviceLocations as (ServiceLocation & { actions: any })[]}
        />
      </Box>
      {isOpen && (
        <AdminServiceLocationForm
          isOpen={isOpen}
          selectedServiceLocation={selectedServiceLocation}
          closeModal={() => {
            if (selectedServiceLocation) {
              setSelectedServiceLocation(undefined);
            }
            onToggle();
          }}
          onSave={() => {
            refetch();
            if (selectedServiceLocation) {
              setSelectedServiceLocation(undefined);
            }
            onToggle();
          }}
        />
      )}
    </VStack>
  );
};

export default AdminServiceLocationManagement;

interface IAdminServiceLocationForm {
  isOpen: boolean;
  selectedServiceLocation?: ServiceLocation;
  closeModal: () => void;
  onSave: () => void;
}

const AdminServiceLocationForm = ({
  isOpen,
  selectedServiceLocation,
  closeModal,
  onSave
}: IAdminServiceLocationForm) => {
  const toast = useToast();
  const isEditMode = !!selectedServiceLocation;
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
      isValid
      // isDirty
    }
  } = useForm<IServiceLocationData>({
    defaultValues: isEditMode
      ? { id: selectedServiceLocation.id, ...selectedServiceLocation.toJson() }
      : ServiceLocation.fromMap("", {
          enabled: true,
          placeBasedCareProvIds: [placeBasedCareProvider!.id]
        }),
    resolver: yupResolver(serviceLocationSchema) as any
  });

  const submissionHandler = async (data: IServiceLocationData) => {
    // if (!isDirty) return;
    try {
      const serviceLocation = ServiceLocation.fromMap(data.id ?? "", data);
      const serviceLocationService = new ServiceLocationService();
      if (isEditMode) {
        await serviceLocationService
          .updateServiceLocation(serviceLocation)
          .then(() => {
            toast({
              status: "success",
              description: "Successfully updated serviceLocation"
            });
          });
      } else {
        await serviceLocationService
          .addServiceLocation(serviceLocation)
          .then(() => {
            toast({
              status: "success",
              description: "Successfully created serviceLocation"
            });
          });
      }
      onSave && onSave();
    } catch (e) {
      console.log("Admin ServiceLocation Form Error", e);
      toast({
        status: "error",
        description: isEditMode
          ? "Failed to update serviceLocation"
          : "Failed to crete serviceLocation"
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>
            {isEditMode ? "Edit Service Location" : "Create Service Location"}
          </ModalHeader>
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!errors.businessName}>
                <FormLabel fontSize='small'>Business Name</FormLabel>
                <Input {...register("businessName")} />
                <FormErrorMessage>
                  {errors.businessName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.address?.street1}>
                <FormLabel fontSize='small'>Address Line 1</FormLabel>
                <Input {...register("address.street1")} />
                <FormErrorMessage>
                  {errors.address?.street1?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.address?.street2}>
                <FormLabel fontSize='small'>Address Line 2</FormLabel>
                <Input {...register("address.street2")} />
                <FormErrorMessage>
                  {errors.address?.street2?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.address?.city}>
                <FormLabel fontSize='small'>City</FormLabel>
                <Input {...register("address.city")} />
                <FormErrorMessage>
                  {errors.address?.city?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.address?.state}>
                <FormLabel fontSize='small'>State</FormLabel>
                <Input {...register("address.state")} />
                <FormErrorMessage>
                  {errors.address?.state?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.address?.zip}>
                <FormLabel fontSize='small'>Zip Code</FormLabel>
                <Input {...register("address.zip")} />
                <FormErrorMessage>
                  {errors.address?.zip?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.screeningsGoal}>
                <FormLabel fontSize='small'>Screenings Goal</FormLabel>
                <Input {...register("screeningsGoal")} type='number' />
                <FormErrorMessage>
                  {errors.screeningsGoal?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.enabled}>
                <FormLabel fontSize='small'>Enabled</FormLabel>
                <Switch {...register("enabled")} />
                <FormErrorMessage>{errors.enabled?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              isDisabled={isSubmitting || !isValid}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
