import React, { useState } from "react";
import useProgramBpReadings from "@/hooks/useProgramBpReadings";
import { ColDef } from "ag-grid-community";
import usePatients from "@/hooks/usePatients";
import usePendingPatients from "@/hooks/usePendingPatients";
import useCHWs from "@/hooks/useCHWs";
import { UserType } from "@oben-core-web/constants/core-enums";
import useServiceLocations from "@/hooks/useServiceLocations";
import { AgGridReact } from "ag-grid-react";
import { DateTime } from "luxon";
import { startCase } from "lodash";
import { Button, HStack, Input, Text, useToast } from "@chakra-ui/react";

interface IBpReadingTable {
  placeBasedCareProvId: string;
}

const BpReadingTable = ({ placeBasedCareProvId }: IBpReadingTable) => {
  const toast = useToast();
  const { bpReadings, refetch, loading } =
    useProgramBpReadings(placeBasedCareProvId);
  const { patients } = usePatients(placeBasedCareProvId);
  const { pendingPatients } = usePendingPatients(placeBasedCareProvId);
  const { chws } = useCHWs(placeBasedCareProvId);
  const { serviceLocations } = useServiceLocations(placeBasedCareProvId);
  const [startDateStr, setStartDateStr] = useState(
    DateTime.now().startOf("day").minus({ days: 30 }).toFormat("yyyy-MM-dd")
  );
  const [endDateStr, setEndDateStr] = useState(
    DateTime.now().toFormat("yyyy-MM-dd")
  );

  const setDateRange = async () => {
    if (startDateStr && endDateStr) {
      const startDate = DateTime.fromFormat(startDateStr, "yyyy-MM-dd");
      const endDate = DateTime.fromFormat(endDateStr, "yyyy-MM-dd");
      if (startDate <= endDate) {
        await refetch(
          placeBasedCareProvId,
          DateTime.fromFormat(startDateStr, "yyyy-MM-dd").toJSDate(),
          DateTime.fromFormat(endDateStr, "yyyy-MM-dd").toJSDate()
        );
      } else {
        toast({
          status: "error",
          description: "Start date must come before end date"
        });
      }
    }
  };

  const parsedReadingData = bpReadings.map((bp) => ({
    ...bp,
    clientName:
      patients.find((cl) => cl.uid === bp.patientId)?.name.fullName ??
      pendingPatients.find((sc) => sc.id === bp.patientId)?.name.fullName ??
      "",
    patientEnrollmentDate: bp.isPending
      ? ""
      : patients.find((cl) => cl.uid === bp.patientId)?.enrollmentDate
      ? DateTime.fromJSDate(
          patients.find((cl) => cl.uid === bp.patientId)!.enrollmentDate!
        ).toFormat("MM/dd/yy")
      : "",
    barberName: chws?.find((b) => b.uid === bp.recorderId),
    recordedBy:
      bp.recorderType === UserType.CHW
        ? chws?.find((b) => b.uid === bp.recorderId)?.name.fullName ?? ""
        : bp.recorderType === UserType.Patient
        ? patients.find((cl) => cl.uid === bp.patientId)?.name.fullName ??
          pendingPatients.find((sc) => sc.id === bp.patientId)?.name.fullName ??
          ""
        : "",
    readingType:
      bp.recorderType === UserType.CHW
        ? "Community Health Worker"
        : bp.recorderType === UserType.Patient
        ? "Patient"
        : "",
    bpReading: bp.systolic + "/" + bp.diastolic,
    bpReadingRange: startCase(bp.range),
    location: bp.locationId
      ? serviceLocations.find((bs) => bs.id === bp.locationId)?.businessName
      : "Home Reading",
    readingDate: DateTime.fromJSDate(bp.readingDate).toFormat("MM/dd/yy")
  }));

  const columnHeaders: ColDef[] = [
    {
      field: "readingDate",
      headerName: "Date",
      minWidth: 100,
      flex: 0.6
    },
    { field: "patientName", headerName: "Patient", minWidth: 100, flex: 1 },
    { field: "bpReading", headerName: "BP Reading", minWidth: 100, flex: 0.6 },
    {
      field: "heartRate",
      headerName: "Heart Rate",
      minWidth: 100,
      flex: 0.6,
      valueFormatter: ({ value }) => (value ? value + " BPM" : "")
    },
    {
      field: "bpReadingRange",
      headerName: "Reading Range",
      minWidth: 100,
      flex: 0.8
    },
    {
      field: "recordedBy",
      headerName: "Recorded By",
      minWidth: 100,
      flex: 1
    },
    {
      field: "readingType",
      headerName: "Recorder Type",
      minWidth: 100,
      flex: 0.7
    },
    {
      field: "location",
      headerName: "Reading Location",
      minWidth: 100,
      flex: 1
    },
    {
      field: "patientEnrollmentDate",
      headerName: "Enrollment Date",
      minWidth: 100,
      flex: 0.7
    }
  ];
  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <HStack mb={2}>
        <Text>Date Range Filter</Text>
        <Input
          type='date'
          value={startDateStr}
          onChange={(e) => {
            setStartDateStr(e.target.value);
          }}
          w={"15rem"}
        />
        <Input
          type='date'
          value={endDateStr}
          onChange={(e) => {
            setEndDateStr(e.target.value);
          }}
          w={"15rem"}
        />
        <Button
          size='sm'
          onClick={setDateRange}
          isDisabled={!startDateStr || !endDateStr}
          isLoading={loading}
          colorScheme='blue'
        >
          Filter
        </Button>
        {startDateStr && endDateStr && (
          <Button
            size='sm'
            onClick={async () => {
              setStartDateStr("");
              setEndDateStr("");
              await refetch(placeBasedCareProvId);
            }}
          >
            Clear Date Filter
          </Button>
        )}
      </HStack>
      <AgGridReact
        className='bp-reading-table'
        rowData={parsedReadingData}
        columnDefs={columnHeaders}
      />
    </div>
  );
};

export default BpReadingTable;
