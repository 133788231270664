import { Claim } from "@oben-core-web/models/claim";
// import { ClaimStatus } from "@oben-core-web/models/claim-status-change";
import { ClaimService } from "@oben-core-web/services/claim-service";
import { useCallback, useEffect, useRef, useState } from "react";

const useClaims = ({
  placeBasedCareProvId,
  fetchInitially = false
}: {
  placeBasedCareProvId: string;
  fetchInitially?: boolean;
}) => {
  const wasFetched = useRef(false);
  const [claims, setClaims] = useState<Claim[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const fetchClaims = useCallback(async () => {
    try {
      setLoading(true);
      if (!placeBasedCareProvId) return;
      const claimService = new ClaimService();
      const claimList = await claimService.getAllPatientClaimsFiltered({
        placeBasedCareProvId,
        statuses: [],
        // statuses: [
        //   ClaimStatus.New,
        //   ClaimStatus.NeedsFurtherReview,
        //   ClaimStatus.ApprovedByPBCP,
        //   ClaimStatus.ApprovedByPatient,
        //   ClaimStatus.ClaimPaidByInsurer,
        //   ClaimStatus.Denied,
        //   ClaimStatus.Submitted
        // ],
        start: null,
        end: null
      });
      setClaims(claimList);
      return claimList;
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [placeBasedCareProvId]);

  useEffect(() => {
    if (fetchInitially && !wasFetched.current) {
      wasFetched.current = true;
      fetchClaims();
    }
  }, [fetchClaims, fetchInitially]);

  return { claims, fetchClaims, loading, error };
};

export default useClaims;
