import { CommunityHealthWorker } from "@oben-core-web/models/community-health-worker";
import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { useState, useEffect, useCallback } from "react";

const useCHWs = (placeBasedCareProvId: string) => {
  const [chws, setBarbers] = useState<CommunityHealthWorker[]>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCHWs = useCallback(async () => {
    try {
      setLoading(true);
      const barberUserService = new CommunityHealthWorkerService();
      if (placeBasedCareProvId) {
        const chws = await barberUserService.getCHWsByPlaceBasedCareProvId(
          placeBasedCareProvId
        );
        setBarbers(chws);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [placeBasedCareProvId]);

  useEffect(() => {
    fetchCHWs();
  }, [fetchCHWs]);
  return { chws, error, loading, refetch: fetchCHWs };
};

export default useCHWs;
