import React from "react";
import {
  Avatar,
  Container,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Heading,
  TableContainer,
  Card
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { StaffMember } from "@oben-core-web/models/staff-member"; // Adjust the import path as needed
import UserProfileForm from "./CurrentUserProfileForm";
import { parsePhoneNumber } from "@/lib/parseUtils";

interface IUserCardProps {
  user: StaffMember;
  canEdit: boolean;
}

const UserCard = ({ user, canEdit }: IUserCardProps) => {
  const daysOfWeek: Record<number, string> = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    0: "Sunday"
  };
  return (
    <Card minWidth='75%' w={"full"} p={4} display='flex' flexDirection='row'>
      <Container
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        className='user-profile-contact-info'
      >
        <Avatar
          name={user?.name.display as string | undefined}
          size='2xl'
          mr={4}
        />
        <Container display='flex' flexDirection='column'>
          <Heading as='h1'>{user?.name.fullName}</Heading>
          <Text as='h2' fontStyle='italic'>
            {user?.userType}
          </Text>
          {user?.email && <Text>{user.email}</Text>}
          {user?.phoneNumber && (
            <Text>{parsePhoneNumber(user.phoneNumber)}</Text>
          )}
          {user?.npi && <Text>NPI: {user.npi}</Text>}
        </Container>
      </Container>
      <Container p={0} className='default-working-hours'>
        <Container
          pr={0}
          w={"full"}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Heading as='h3' fontSize={28}>
            Working Hours
          </Heading>
          {canEdit && <UserProfileForm user={user} />}
        </Container>
        {user?.defaultWorkingHours && (
          <TableContainer>
            <Table size='sm' fontSize={"medium"} variant={"simple"}>
              <Thead>
                <Tr color={"gray.500"}>
                  <Td>Day</Td>
                  <Td>Start Time</Td>
                  <Td>End Time</Td>
                </Tr>
              </Thead>
              <Tbody>
                {user.defaultWorkingHours
                  .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                  .map((day) => (
                    <Tr key={day.dayOfWeek}>
                      <Td fontSize={"medium"}>{daysOfWeek[day.dayOfWeek]}</Td>
                      <Td fontSize={"medium"}>
                        {DateTime.fromJSDate(day.startTime).toFormat(
                          "h':'mm a"
                        )}
                      </Td>
                      <Td fontSize={"medium"}>
                        {DateTime.fromJSDate(day.endTime).toFormat("h':'mm a")}
                      </Td>
                      {/* <Td fontSize={"medium"}>
                        {DateTime.fromJSDate(
                          convertToLocalTZ(day.startTime)
                        ).toFormat("h':'mm a")}
                      </Td>
                      <Td fontSize={"medium"}>
                        {DateTime.fromJSDate(
                          convertToLocalTZ(day.endTime)
                        ).toFormat("h':'mm a")}
                      </Td> */}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Card>
  );
};

export default UserCard;
