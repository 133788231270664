import { Card } from "@chakra-ui/react";
import { Race } from "@oben-core-web/constants/core-enums";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const RaceBarChart = ({ races }: { races: (Race | null)[] }) => {
  const [chartData, setChartData] = useState<{
    categories: string[];
    series: { name: string; data: number[] }[];
  }>({ categories: [], series: [] });

  useEffect(() => {
    // Define the fixed 5-year intervals
    const raceLabels = [
      { label: Race.Black },
      { label: Race.White },
      { label: Race.Other },
      { label: Race.Unknown }
    ];

    // Initialize counts for each age range
    const counts = raceLabels.map(() => 0);
    // Count occurrences in each age range
    races.forEach((race: Race | null) => {
      for (let i = 0; i < raceLabels.length; i++) {
        if (race === raceLabels[i].label) {
          counts[i]++;
          break;
        }
      }
    });

    // Set chart data with categories and counts
    setChartData({
      categories: raceLabels.map((race) =>
        race.label === Race.Unknown ? "Blank" : race.label
      ),
      series: [{ name: "Patient Count", data: counts }]
    });
  }, [races]);

  // Chart options
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      zoom: { enabled: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false
      }
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "Race Distribution",
        offsetY: -20
      },
      labels: {
        rotateAlways: true
      }
    },
    yaxis: {
      title: {
        text: "Patient Count",
        offsetX: 6
      },
      stepSize: 1
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#00E396"],
    title: {
      align: "center"
    }
  };
  return (
    <Card className='age-bar-chart' w={"100%"} h={"16rem"}>
      <Chart
        options={chartOptions}
        series={chartData.series}
        type='bar'
        width='100%'
        height='250'
      />
    </Card>
  );
};

export default RaceBarChart;
