import { BpReading } from "@oben-core-web/models/bp-reading";
import { BpReadingService } from "@oben-core-web/services/bp-reading-service";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";

const useProgramBpReadings = (placeBasedCareProvId: string) => {
  const [bpReadings, setBpReadings] = useState<BpReading[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const fetchBpReadings = useCallback(
    async (placeBasedCareProvId: string, start?: Date, end?: Date) => {
      try {
        setLoading(true);
        const bpReadingService = new BpReadingService();
        const readings = await bpReadingService.getPBCPReadings({
          placeBasedCareProvId,
          start:
            start ??
            DateTime.now().startOf("day").minus({ days: 30 }).toJSDate(),
          end: end ?? DateTime.now().toJSDate()
        });
        setBpReadings(readings);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchBpReadings(placeBasedCareProvId);
  }, [placeBasedCareProvId, fetchBpReadings]);

  return { bpReadings, refetch: fetchBpReadings, loading, error };
};

export default useProgramBpReadings;
