import { useState, useEffect, useCallback } from "react";
import { PatientNoteService } from "@oben-core-web/services/patient-note-service";
import { PatientNote } from "@oben-core-web/models/patient-note";

const usePatientNotes = (patientId: string) => {
  const [patientNotes, setPatientNotes] = useState<PatientNote[]>([]);

  const fetchPatientNotes = useCallback(async () => {
    const patientNoteService = new PatientNoteService();
    const notes = await patientNoteService.getAllPatientNotes(patientId);
    setPatientNotes(
      notes.sort((a, b) => (a.createdDate <= b.createdDate ? 1 : -1))
    );
  }, [patientId]);

  useEffect(() => {
    fetchPatientNotes();
  }, [fetchPatientNotes]);

  return { patientNotes, refetch: fetchPatientNotes };
};

export default usePatientNotes;
