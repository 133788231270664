import usePatientOutreach from "@/hooks/usePatientOutreach";
import {
  BoxProps,
  Box,
  Center,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { startCase } from "lodash";
import { ColDef } from "ag-grid-community";
import { MdPhone, MdRefresh } from "react-icons/md";
import { useCallback, useState } from "react";
import { Outreach } from "@oben-core-web/models/outreach";
import PatientOutreacher from "./PatientOutreacher";
import { DateTime } from "luxon";

interface IPatientOutreachTable extends BoxProps {
  patientId: string;
}

const PatientOutreachTable = ({
  patientId,
  ...props
}: IPatientOutreachTable) => {
  const { outreaches, fetchOutreaches } = usePatientOutreach(patientId);
  const [selectedOutreach, setSelectedOutreach] = useState<Outreach>();
  const refetch = useCallback(async () => {
    await fetchOutreaches(patientId);
  }, [patientId, fetchOutreaches]);
  const columnHeaders: ColDef[] = [
    {
      field: "createdDate",
      headerName: "Date Created",
      valueFormatter: ({ data }: any) => {
        return DateTime.fromJSDate(data.createdDate).toFormat("MM/dd/yy");
      },
      flex: 0.35,
      minWidth: 100
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 0.6,
      valueFormatter: ({ data }: any) => {
        return startCase(data.purpose);
      },
      minWidth: 100
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      valueFormatter: ({ data }: any) => {
        return startCase(data.status);
      },
      minWidth: 100
    },
    {
      field: "lastAttemptDate",
      headerName: "Last Attempt Date",
      valueFormatter: ({ data }: any) => {
        const lastAttemptDate = data.attempts[data.attempts.length - 1]?.date;
        return lastAttemptDate
          ? DateTime.fromJSDate(lastAttemptDate).toFormat("MM/dd/yy")
          : "";
      },
      flex: 0.4,
      minWidth: 100
    },
    {
      field: "attempts",
      headerName: "Last Result",
      flex: 0.5,
      valueGetter: ({ data }: any) => {
        const lastResult = data.attempts[data.attempts.length - 1]?.result;
        return lastResult ? startCase(lastResult) : "";
      },
      minWidth: 100
    },
    {
      field: "attempts",
      headerName: "Last Note",
      flex: 1,
      valueGetter: ({ data }: any) => {
        const lastNote = data.attempts[data.attempts.length - 1]?.note;
        return lastNote ?? "";
      },
      minWidth: 100
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.25,
      cellRenderer: ({ data }: any) => {
        return (
          <IconButton
            aria-label={`log-outreach-${data.id}`}
            icon={<MdPhone size={20} />}
            variant='ghost'
            onClick={() => setSelectedOutreach(data)}
          />
        );
      },
      minWidth: 100
    }
  ];
  return (
    <Box className='ag-theme-quartz' {...props}>
      <Modal
        isOpen={!!selectedOutreach}
        onClose={() => setSelectedOutreach(undefined)}
        size='full'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            Log an Outreach
            {selectedOutreach && `: ${startCase(selectedOutreach?.purpose)}`}
          </ModalHeader>
          <ModalBody>
            <PatientOutreacher
              patientId={patientId}
              patientOutreach={selectedOutreach}
              outreachPurpose={selectedOutreach?.purpose}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              columns={[1, 2]}
              spacing={2}
              w={"full"}
              my={3}
              historyContainerProps={{
                maxHeight: "70vh",
                overflowY: "scroll"
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setSelectedOutreach(undefined)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {outreaches.length === 0 ? (
        <Center h={"20rem"}>
          <Text>No Data</Text>
        </Center>
      ) : (
        <>
          <IconButton
            onClick={refetch}
            icon={<MdRefresh size={16} />}
            variant='ghost'
            size='xs'
            rounded={"full"}
            aria-label={"refresh-button"}
            mb={2}
          />
          <AgGridReact rowData={outreaches} columnDefs={columnHeaders as any} />
        </>
      )}
    </Box>
  );
};

export default PatientOutreachTable;
