import { Badge, Box, HStack, VStack, Text } from "@chakra-ui/react";
import PatientDashboardStatistics from "./PatientDashboardStatistics";
import UpcomingAppointments from "../molecules/UpcomingAppointmentsTable";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PatientRecommendations from "./PatientRecommendations";
import { PhysicianQueryService } from "@oben-core-web/services/physician-query-service";
import CHWInvoices from "./CHWInvoices";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

type TPharmacistDashboardTabs =
  | "allActivities"
  | "recommendations"
  | "invoices";

const PharmacistDashboard = () => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get("page");
  const [activeTab, setActiveTab] = useState<TPharmacistDashboardTabs>(
    (params as TPharmacistDashboardTabs) ?? "allActivities"
  );
  const [recommendationCount, setRecommendationCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);

  useEffect(() => {
    setActiveTab((params as TPharmacistDashboardTabs) ?? "allActivities");
  }, [params]);

  const fetchRecommendationCountData = useCallback(
    async (placeBasedCareProvId: string) => {
      if (placeBasedCareProvId) {
        const count = await PhysicianQueryService.getNeedRecommendationCount({
          placeBasedCareProvId
        });
        setRecommendationCount(count);
      }
    },
    []
  );

  const fetchInvoiceCountData = useCallback(
    async (placeBasedCareProvId: string) => {
      if (placeBasedCareProvId) {
        const count = await PhysicianQueryService.getInvoiceCount({
          placeBasedCareProvId
        });
        setInvoiceCount(count);
      }
    },
    []
  );

  useEffect(() => {
    if (placeBasedCareProvider) {
      fetchRecommendationCountData(placeBasedCareProvider!.id ?? "");
      fetchInvoiceCountData(placeBasedCareProvider!.id ?? "");
    }
  }, [
    placeBasedCareProvider,
    fetchRecommendationCountData,
    fetchInvoiceCountData
  ]);

  // const [invoiceCount, setInvoiceCount] = useState(0);
  return (
    <Box w={"full"} h={"full"} flex={"1 1 0px"} className='pharmacist-overview'>
      <VStack h={"full"} w={"full"} flex={"1 1 0px"} display={"flex"}>
        <HStack
          mb={4}
          w={"50%"}
          display={"flex"}
          alignItems={"center"}
          // justifyContent={"space-between"}
          alignSelf={"flex-start"}
          className='pharmacist-dashboard-controls'
        >
          <Badge
            cursor={"pointer"}
            zIndex={100}
            onClick={() => {
              setActiveTab("allActivities");
              setSearchParams("page=allActivities");
            }}
            variant={activeTab === "allActivities" ? "solid" : "outline"}
            colorScheme='blue'
            className='pharmacist-dashboard-badge pharmacist-home-badge'
            borderRadius={12}
            py={1}
            px={3}
          >
            All Activities
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            onClick={() => {
              setActiveTab("recommendations");
              setSearchParams("page=recommendations");
            }}
            variant={activeTab === "recommendations" ? "solid" : "outline"}
            colorScheme='blue'
            className='pharmacist-dashboard-badge pharmacist-recommendation-badge'
            borderRadius={12}
            py={1}
            px={3}
            display='flex'
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            Recommendations
            {recommendationCount > 0 && (
              <Text>&nbsp;{`(${recommendationCount})`}</Text>
            )}
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            onClick={() => {
              setActiveTab("invoices");
              setSearchParams("page=invoices");
            }}
            variant={activeTab === "invoices" ? "solid" : "outline"}
            colorScheme='blue'
            className='pharmacist-dashboard-badge pharmacist-recommendation-badge'
            borderRadius={12}
            py={1}
            px={3}
            display='flex'
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            Invoices
            {invoiceCount > 0 && <Text>&nbsp;{`(${invoiceCount})`}</Text>}
          </Badge>
        </HStack>
        {activeTab === "allActivities" && (
          <VStack w={"full"} h={"full"}>
            <PatientDashboardStatistics />
            <UpcomingAppointments h={"50%"} />
          </VStack>
        )}
        {activeTab === "recommendations" && (
          <PatientRecommendations
            onRecommendationCreated={() =>
              fetchRecommendationCountData(placeBasedCareProvider?.id ?? "")
            }
          />
        )}
        {activeTab === "invoices" && (
          <CHWInvoices
            onInvoiceProcessed={() =>
              fetchInvoiceCountData(placeBasedCareProvider?.id ?? "")
            }
          />
        )}
      </VStack>
    </Box>
  );
};

export default PharmacistDashboard;
