import React, { createContext, ReactNode, useReducer } from "react";
import { appReducer, IAppState, initialState } from "@/reducers/appReducer";
import { SystemStatusInfo } from "@oben-core-web/models/system-status-info";

interface IAppContext {
  state: IAppState;
  setSystemStatus: (systemStatusInfo: SystemStatusInfo) => void;
  dismissMaintenanceNotice: () => void;
  setDetachSystemListener: (detach: () => void) => void;
  toggleAdminMode: () => void;
}

// Create AppContext
export const AppContext = createContext<IAppContext | undefined>(undefined);

// AppProvider component
export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const setSystemStatus = (systemStatusInfo: SystemStatusInfo) => {
    dispatch({ type: "SET_SYSTEM_STATUS", payload: { systemStatusInfo } });
  };

  const dismissMaintenanceNotice = () => {
    dispatch({ type: "DISMISS_MAINTENANCE_NOTICE" });
  };

  const setDetachSystemListener = (detach: () => void) => {
    dispatch({
      type: "SET_DETACH_SYSTEM_LISTENER",
      payload: { detachSystemListener: detach }
    });
  };

  const toggleAdminMode = () => {
    dispatch({
      type: "SET_APP_MODE",
      payload: { appMode: state.appMode === "app" ? "admin" : "app" }
    });
  };

  return (
    <AppContext.Provider
      value={{
        state,
        setSystemStatus,
        dismissMaintenanceNotice,
        setDetachSystemListener,
        toggleAdminMode
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
