import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import useProgramManagerQueries, {
  IEngagedBarberByCityMetric
} from "@/hooks/useProgramManagerQueries";
import { Card, Heading, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsScissors } from "react-icons/bs";

const DashboardCHWMetrics = () => {
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { getCHWEngagementByCity } = useProgramManagerQueries(
    placeBasedCareProvider?.id ?? ""
  );
  const [data, setData] =
    useState<Record<string, IEngagedBarberByCityMetric>>();

  useEffect(() => {
    const fetchData = async () => {
      const chwEngagement = await getCHWEngagementByCity();
      setData(chwEngagement);
    };
    fetchData();
  }, [getCHWEngagementByCity]);

  const programCHWCounts = {
    engaged: data
      ? Object.values(data).reduce((a, c) => a + c.engagedCHWCount, 0)
      : 0,
    total: data
      ? Object.values(data).reduce((a, c) => a + c.totalCHWCount, 0)
      : 0
  };

  return (
    <Card display={"flex"} flexDirection={"column"} p={5} w={["100%", "40%"]}>
      <HStack mb={2} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <BsScissors size={24} />
          <VStack alignItems={"flex-start"} spacing={0}>
            <Heading fontSize={["medium", "large"]}>Active</Heading>
            <Text fontSize={["small", "medium"]}>Community Health Workers</Text>
          </VStack>
        </HStack>
        <HStack spacing={4}>
          <Tooltip
            label={"Percentage of engaged CHWs in Program"}
            placement={"top"}
            hasArrow
          >
            <Text fontSize={["medium", "x-large"]} lineHeight={"1.5em"}>
              {data &&
                Number(
                  (
                    (programCHWCounts.engaged / programCHWCounts.total) *
                    100
                  ).toFixed(2)
                )}
              %
            </Text>
          </Tooltip>
          {/* <Text fontSize={"small"}>
            {programCHWCounts.engaged} / {programCHWCounts.total}
          </Text> */}
        </HStack>
      </HStack>
      {data &&
        Object.entries(data).map(([city, cityData]) => (
          <HStack
            key={`eb-metrics-${city}`}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontWeight={"normal"}>{city}</Text>
            <Tooltip
              label={`Percentage of engaged CHWs in ${city}`}
              placement='left'
              hasArrow
            >
              <Text>
                {!isNaN(
                  Number(
                    (
                      (cityData.engagedCHWCount / cityData.totalCHWCount) *
                      100
                    ).toFixed(2)
                  )
                )
                  ? Number(
                      (
                        (cityData.engagedCHWCount / cityData.totalCHWCount) *
                        100
                      ).toFixed(2)
                    )
                  : 0}
                %
              </Text>
            </Tooltip>
          </HStack>
        ))}
    </Card>
  );
};

export default DashboardCHWMetrics;
