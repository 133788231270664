import { useState, useEffect } from "react";
import { PatientService } from "@oben-core-web/services/patient-service";
import { Patient } from "@oben-core-web/models/patient";

const usePatientSubscription = (patientId?: string) => {
  const [patient, setPatient] = useState<Patient>();
  useEffect(() => {
    let unsubscribeFunc: (() => void) | undefined;
    if (patientId) {
      const patientService = new PatientService();
      const { unsubscribe } = patientService.streamPatient(
        patientId,
        (snap) => {
          if (snap.exists()) {
            setPatient(Patient.fromFirestore(snap));
          }
        }
      );
      unsubscribeFunc = unsubscribe;
    }
    return () => {
      unsubscribeFunc && unsubscribeFunc();
    };
  }, [patientId]);
  return { patient };
};

export default usePatientSubscription;
