import { CommunityHealthWorker } from "@oben-core-web/models/community-health-worker";
import { CommunityHealthWorkerService } from "@oben-core-web/services/community-health-worker-service";
import { useState, useEffect, useCallback } from "react";

const useCHW = ({
  uid,
  initialFetch = false
}: {
  uid?: string;
  initialFetch: boolean;
}) => {
  const [chw, setCHW] = useState<CommunityHealthWorker>();
  const [shouldFetch, setShouldFetch] = useState(initialFetch);
  const fetchCHW = useCallback(async (uid: string) => {
    const chwUserService = new CommunityHealthWorkerService();
    const chw = await chwUserService.getCHW(uid);
    setCHW(chw);
    setShouldFetch(false);
    return chw;
  }, []);
  useEffect(() => {
    shouldFetch && uid && fetchCHW(uid);
  }, [fetchCHW, shouldFetch, uid]);
  return { chw, fetchCHW };
};

export default useCHW;
