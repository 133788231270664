import {
  InternalTaskType,
  TaskCreatorType
} from "@oben-core-web/models/internal-task";
import { object, string, date } from "yup";

const internalTaskSchema = object({
  id: string(),
  placeBasedCareProvId: string().required(),
  internalTaskType: string()
    .oneOf(
      [InternalTaskType.ScheduleAppointment, InternalTaskType.Unknown],
      "Invalid task type"
    )
    .required("Task type is required"),
  name: string().required("Task name is required"),
  description: string().required("Task description is required"),
  patientId: string().required(),
  assigneeId: string().required(),
  creatorId: string().required(),
  creatorType: string()
    .oneOf(
      [TaskCreatorType.StaffMember, TaskCreatorType.System],
      "Invalid task creator type"
    )
    .required(),
  createdDate: date().required(),
  dueDate: date().required(),
  completionDate: date().nullable()
});

export default internalTaskSchema;
