import useProgramTaskBases from "@/hooks/useProgramTaskBases";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Switch,
  Select
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Barbershop, IBarbershopData } from "@oben-core-web/models/barbershop";
import {
  PatientTaskBase,
  IPatientTaskBaseData
} from "@oben-core-web/models/patient-task-base";
import { PatientTaskBaseService } from "@oben-core-web/services/patient-task-base-service";
// import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import {
  PatientTaskCategory,
  PatientTaskType
} from "@oben-core-web/models/patient-task";
import { startCase } from "lodash";
import NumberInput from "../atoms/NumberInput";
import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import patientTaskBaseSchema from "@/resolvers/patientTaskBase";

const AdminTaskBaseManagement = () => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { patientTaskBases, refetch } = useProgramTaskBases(
    placeBasedCareProvider!.id
  );
  const [selectedPatientTaskBase, setSelectedPatientTaskBase] =
    useState<PatientTaskBase>();
  const columnHeaders: (
    | ColDef<PatientTaskBase & { actions: any }>
    | (ColDef<PatientTaskBase & { actions: any }> & {
        children: ColDef<PatientTaskBase & { actions: any }>[];
      })
  )[] = [
    {
      headerName: "Patient Task Base",
      children: [
        {
          field: "actions",
          headerName: "Actions",
          flex: 0.25,
          cellRenderer: ({ data }: any) => (
            <IconButton
              aria-label={`edit-task-base-${data.id}`}
              icon={<EditIcon />}
              variant='ghost'
              onClick={() => {
                setSelectedPatientTaskBase(data);
                onToggle();
              }}
            />
          )
        },
        {
          field: "enabled",
          headerName: "Enabled",
          flex: 0.25,
          cellRenderer: ({ data }: any) => (
            <Switch
              isChecked={data.enabled}
              onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                const patientTaskBaseService = new PatientTaskBaseService();
                const taskBase = PatientTaskBase.fromMap(data.id, data);
                taskBase.enabled = event.target.checked;
                await patientTaskBaseService
                  .updateTaskBase(taskBase)
                  .then(async () => {
                    toast({
                      status: "success",
                      description: `PatientTaskBase ${
                        !event.target.checked ? "Enabled" : "Disabled"
                      }`
                    });
                    await refetch();
                  });
              }}
            />
          )
        },
        {
          field: "taskType",
          headerName: "Type",
          flex: 0.8
        },
        {
          field: "taskCategory",
          headerName: "Category",
          flex: 0.35
        },
        { field: "name", headerName: "Name", flex: 0.8 },
        { field: "description", headerName: "Description", flex: 0.8 }
      ]
    },
    {
      headerName: "Defaults",
      children: [
        {
          field: "defaultFreqCount",
          headerName: "Frequency Count",
          flex: 0.35
        },
        {
          field: "defaultFreqPeriod",
          headerName: "Frequency Period",
          flex: 0.35
        },
        {
          field: "defaultDuration",
          headerName: "Duration",
          flex: 0.35
        }
      ]
    }
  ];

  return (
    <VStack alignItems={"flex-start"} h={"full"}>
      <HStack alignItems={"center"} justifyContent={"space-between"} w={"full"}>
        <Heading fontSize={"large"} as={"h1"}>
          Manage Patient Task Bases
        </Heading>
        <Button onClick={onToggle} size='sm' colorScheme='blue'>
          Add Patient Task Base
        </Button>
      </HStack>
      <Box className='ag-theme-quartz' h={"full"} w={"full"}>
        <AgGridReact
          columnDefs={columnHeaders}
          rowData={patientTaskBases as (PatientTaskBase & { actions: any })[]}
        />
      </Box>
      {isOpen && (
        <AdminTaskBaseForm
          isOpen={isOpen}
          selectedPatientTaskBase={selectedPatientTaskBase}
          closeModal={() => {
            if (selectedPatientTaskBase) {
              setSelectedPatientTaskBase(undefined);
            }
            onToggle();
          }}
          onSave={() => {
            refetch();
            if (selectedPatientTaskBase) {
              setSelectedPatientTaskBase(undefined);
            }
            onToggle();
          }}
        />
      )}
    </VStack>
  );
};

export default AdminTaskBaseManagement;

interface IAdminTaskBaseForm {
  isOpen: boolean;
  selectedPatientTaskBase?: PatientTaskBase;
  closeModal: () => void;
  onSave: () => void;
}

const AdminTaskBaseForm = ({
  isOpen,
  selectedPatientTaskBase,
  closeModal,
  onSave
}: IAdminTaskBaseForm) => {
  const toast = useToast();
  const isEditMode = !!selectedPatientTaskBase;
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty }
  } = useForm<IPatientTaskBaseData>({
    defaultValues: isEditMode
      ? { id: selectedPatientTaskBase.id, ...selectedPatientTaskBase.toJson() }
      : PatientTaskBase.fromMap("", {
          enabled: true,
          id: "",
          placeBasedCareProvId: placeBasedCareProvider!.id
        }).toJson(),
    resolver: yupResolver(patientTaskBaseSchema) as any
  });
  const submissionHandler = async (data: IPatientTaskBaseData) => {
    // if (!isDirty) return;
    try {
      const taskBase = PatientTaskBase.fromMap(data.id ?? "", data);
      const taskBaseService = new PatientTaskBaseService();
      if (isEditMode) {
        await taskBaseService.updateTaskBase(taskBase).then(() => {
          toast({
            status: "success",
            description: "Successfully updated Patient Task Base"
          });
        });
      } else {
        await taskBaseService.addTaskBase(taskBase).then(() => {
          toast({
            status: "success",
            description: "Successfully created Task Base"
          });
        });
      }
      onSave && onSave();
    } catch (e) {
      console.log("Admin PatientTaskBase Form Error", e);
      toast({
        status: "error",
        description: isEditMode
          ? "Failed to update Task Base"
          : "Failed to create Task Base"
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>
            {isEditMode ? "Edit Patient Task Base" : "Create Patient Task Base"}
          </ModalHeader>
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel fontSize='small'>Task Name</FormLabel>
                <Input {...register("name")} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <FormLabel fontSize='small'>Task Description</FormLabel>
                <Input {...register("description")} />
                <FormErrorMessage>
                  {errors.description?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.taskType}>
                <FormLabel fontSize='small'>Task Type</FormLabel>
                <Select
                  placeholder='Select a Task Type'
                  {...register("taskType")}
                >
                  <option value={PatientTaskType.HealthcareAction}>
                    {PatientTaskType.HealthcareAction}
                  </option>
                  <option value={PatientTaskType.SmartGoal}>
                    {PatientTaskType.SmartGoal}
                  </option>
                  <option value={PatientTaskType.HomeEducation}>
                    {PatientTaskType.HomeEducation}
                  </option>
                </Select>
                <FormErrorMessage>{errors.taskType?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.taskCategory}>
                <FormLabel fontSize='small'>Task Category</FormLabel>
                <Select
                  placeholder='Select a Task Category'
                  {...register("taskCategory")}
                >
                  <option value={PatientTaskCategory.PrimaryCareAgenda}>
                    {startCase(PatientTaskCategory.PrimaryCareAgenda)}
                  </option>
                  <option value={PatientTaskCategory.Labs}>
                    {PatientTaskCategory.Labs}
                  </option>
                  <option value={PatientTaskCategory.Vaccines}>
                    {PatientTaskCategory.Vaccines}
                  </option>
                  <option value={PatientTaskCategory.Movement}>
                    {PatientTaskCategory.Movement}
                  </option>
                  <option value={PatientTaskCategory.Nutrition}>
                    {PatientTaskCategory.Nutrition}
                  </option>
                  <option value={PatientTaskCategory.Stress}>
                    {PatientTaskCategory.Stress}
                  </option>
                </Select>
                <FormErrorMessage>
                  {errors.taskCategory?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.defaultFreqCount}>
                <FormLabel fontSize='small'>Default Frequency Count</FormLabel>
                <NumberInput control={control} name='defaultFreqCount' />
                <FormErrorMessage>
                  {errors.defaultFreqCount?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <FormLabel fontSize='small'>Task Description</FormLabel>
                <Select {...register("defaultFreqPeriod")}>
                  <option value={FreqPeriod.Daily}>{FreqPeriod.Daily}</option>
                  <option value={FreqPeriod.Weekly}>{FreqPeriod.Weekly}</option>
                </Select>
                <FormErrorMessage>
                  {errors.description?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.defaultDuration}>
                <FormLabel fontSize='small'>Default Duration</FormLabel>
                <NumberInput control={control} name='defaultDuration' />
                <FormErrorMessage>
                  {errors.defaultDuration?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.enabled}>
                <FormLabel fontSize='small'>Enabled</FormLabel>
                <Switch {...register("enabled")} />
                <FormErrorMessage>{errors.enabled?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button onClick={closeModal} colorScheme='red'>
              Cancel
            </Button>
            <Button
              type='submit'
              isDisabled={isSubmitting || !isValid || !isDirty}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
