import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useState } from "react";

const CurrencyInput = ({ control, name }: { control: any; name: string }) => {
  const [rawInput, setRawInput] = useState(""); // Holds user input for smooth typing

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0} // Stored in cents
      render={({ field: { onChange, value } }) => (
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.400'
            fontSize='1.2em'
          >
            $
          </InputLeftElement>
          <Input
            type='text'
            value={rawInput || (value ? (value / 100).toFixed(2) : "")} // Display dollars (if not actively typing)
            onChange={(e) => {
              const input = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers and dot
              setRawInput(input); // Preserve live input for smooth UX
            }}
            onBlur={() => {
              const parsedValue = parseFloat(rawInput || "0") * 100; // Convert to cents
              onChange(Math.round(parsedValue)); // Save formatted cents to form
              setRawInput(""); // Reset live input to allow display of formatted value
            }}
            placeholder='Enter amount'
          />
        </InputGroup>
      )}
    />
  );
};

export default CurrencyInput;
