import { DocumentSnapshot } from "firebase/firestore";

// import { WebAvatar, WebAvatarExtension } from 'oben_images/oben_images'; // TODO: Uncomment this line after creating the WebAvatar class
import { UserType, UserTypeHelper } from "../constants/core-enums";
import { StaffMemberService } from "../services/staff-member-service";
import { Address } from "./address";
import { DefaultWorkingHours } from "./default-working-hours";
import { UserName } from "./user-name";

export interface IStaffMemberData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  trackingConsent: boolean | null;
  // web-specific fields
  // avatar: WebAvatar; // TODO: Uncomment this line after creating the WebAvatar class
  userType: UserType;
  address?: Address | null; // only needed for certain web/StaffMember user types (Physician, etc.)
  npi?: string | null; // only relevant for certain web/StaffMember user types (Physician, etc.)
  placeBasedCareProvId: string;
  defaultWorkingHours: DefaultWorkingHours[];
  isProgramAdmin: boolean;
}

export class StaffMember {
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  trackingConsent: boolean | null;
  // avatar: WebAvatar; // TODO: Uncomment this line after creating the WebAvatar class
  userType: UserType;
  address?: Address | null;
  npi?: string | null;
  placeBasedCareProvId: string;
  defaultWorkingHours: DefaultWorkingHours[];
  isProgramAdmin: boolean;

  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    trackingConsent,
    // avatar, // TODO: Uncomment this line after creating the WebAvatar class
    userType,
    address,
    npi,
    placeBasedCareProvId,
    defaultWorkingHours = [],
    isProgramAdmin
  }: IStaffMemberData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    this.trackingConsent = trackingConsent;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the WebAvatar class
    this.userType = userType;
    this.address = address;
    this.npi = npi;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.defaultWorkingHours = defaultWorkingHours;
    this.isProgramAdmin = isProgramAdmin;
  }

  get isOnboardingComplete(): boolean {
    if (
      this.name.display == null ||
      this.name.first == null ||
      this.name.last == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  async updateDb(): Promise<void> {
    const db = new StaffMemberService();
    await db.updateStaffMember(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): StaffMember {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return StaffMember.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): StaffMember {
    const name: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string | null = data["phoneNumber"] ?? null;
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset: boolean = data["pwdNeedsReset"] ?? true;
    const trackingConsent: boolean | null = data["trackingConsent"] ?? null;
    // const avatar: WebAvatar = WebAvatar.valueOf(data["avatar"] ?? "") ?? WebAvatar.zero; // TODO: Uncomment this line after creating the WebAvatar class
    const userType: UserType =
      UserTypeHelper.valueOf(data["userType"] ?? "") ?? UserType.Unknown;
    const address: Address | null = (data["address"] === null || data["address"] === undefined)//
      ? null 
      : Address.fromMap(data["address"]);
    const npi: string | null = data["npi"] ?? null;
    const placeBasedCareProvId: string = data["placeBasedCareProvId"] ?? "";
    const defaultWorkingHours: DefaultWorkingHours[] =
      data["defaultWorkingHours"] === null ||
      data["defaultWorkingHours"].length === 0
        ? []
        : data["defaultWorkingHours"].map((element: any) => DefaultWorkingHours.fromMap(element ?? {}));
    const isProgramAdmin: boolean = data["isProgramAdmin"] ?? false;
    
    return new StaffMember({
      uid: uid,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      trackingConsent: trackingConsent,
      // avatar: avatar, // TODO: Uncomment this line after creating the WebAvatar class
      userType: userType,
      address: address,
      npi: npi,
      placeBasedCareProvId: placeBasedCareProvId,
      defaultWorkingHours: defaultWorkingHours,
      isProgramAdmin: isProgramAdmin
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "uid": uid, // not necessary to save UID (it is already part of the Firestore document)
      "name": this.name.toJson(),
      "email": this.email,
      "phoneNumber": this.phoneNumber,
      "enabled": this.enabled,
      "pwdNeedsReset": this.pwdNeedsReset,
      "trackingConsent": this.trackingConsent,
      // "avatar": this.avatar, // TODO: Uncomment this line after creating the WebAvatar class
      "userType": this.userType,
      "address": this.address?.toJson() ?? null,
      "npi": this.npi ?? null,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "defaultWorkingHours": this.defaultWorkingHours.map((element) => element.toJson()),
      "isProgramAdmin": this.isProgramAdmin
    };
  }
}
