import React, { useCallback, useEffect, useState } from "react";
import DashboardCHWMetrics from "../molecules/DashboardCHWMetrics";
import DashboardPendingPayments from "../molecules/DashboardPendingPayments";
import {
  Badge,
  Box,
  HStack,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton
} from "@chakra-ui/react";
import DashboardEnrolledPatients from "../molecules/DashboardEnrolledPatients";
import DashboardEnrollmentAndScheduling from "../molecules/DashboardEnrollmentAndScheduling";
import { Outreach, OutreachPurpose } from "@oben-core-web/models/outreach";
import PaymentsTable from "../molecules/PaymentsTable";
import { ProgramManagerQueryService } from "@oben-core-web/services/program-manager-query-service";
import { useSearchParams } from "react-router-dom";
import ApptConfirmationTable from "../molecules/ApptConfirmationTable";
import PatientSchedulingTable from "../molecules/PatientSchedulingTable";
import { Appointment } from "@oben-core-web/models/appointment";
import PatientOutreacher from "../molecules/PatientOutreacher";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import PatientEnrollment from "./PatientEnrollment";
import UpcomingAppointments from "../molecules/UpcomingAppointmentsTable";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import useCurrentUser from "@/hooks/useCurrentUser";
import BpReadingTable from "../molecules/BpReadingTable";

// TODO: 'Need to Verify' list -- screening clients whose last bp reading with systolic between 130-139 OR diastolic between 80-89

type TProgramManagerDashboardTabs =
  | "allActivities"
  | "enrollment"
  | "scheduling"
  | "apptConfirmation"
  | "payments"
  | "invoices"
  | "bpReadings";

const ProgramManagerDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get("page");
  const [activeTab, setActiveTab] = useState<TProgramManagerDashboardTabs>(
    (params as TProgramManagerDashboardTabs) ?? "allActivities"
  );
  const [enrollmentCount, setEnrollmentCount] = useState(0);
  const [scheduleCount, setScheduleCount] = useState(0);
  const [confirmationCount, setConfirmationCount] = useState(0);
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { currentUser } = useCurrentUser();
  const [confirmingAppt, setConfirmingAppt] = useState<Appointment>();
  const [confirmApptOutreach, setConfirmApptOutreach] = useState<Outreach>();

  useEffect(() => {
    setActiveTab((params as TProgramManagerDashboardTabs) ?? "allActivities");
  }, [params]);

  const fetchEnrollmentCountData = useCallback(
    async (placeBasedCareProvId: string) => {
      if (placeBasedCareProvId) {
        const count = await ProgramManagerQueryService.getEnrollmentCounts({
          placeBasedCareProvId
        });
        setEnrollmentCount(count);
      }
    },
    []
  );

  const fetchScheduleCountData = useCallback(async (assigneeId: string) => {
    if (assigneeId) {
      const count = await ProgramManagerQueryService.getNeedToScheduleCount({
        assigneeId
      });
      setScheduleCount(count);
    }
  }, []);

  const fetchApptConfirmationCountData = useCallback(
    async (placeBasedCareProvId: string) => {
      if (placeBasedCareProvId) {
        const count = await ProgramManagerQueryService.getNeedToConfirmCount({
          placeBasedCareProvId
        });
        setConfirmationCount(count);
      }
    },
    []
  );

  useEffect(() => {
    if (placeBasedCareProvider) {
      fetchEnrollmentCountData(placeBasedCareProvider!.id ?? "");
      fetchApptConfirmationCountData(placeBasedCareProvider!.id ?? "");
    }
    if (currentUser) {
      fetchScheduleCountData(currentUser.uid ?? "");
    }
  }, [
    currentUser,
    placeBasedCareProvider,
    fetchEnrollmentCountData,
    fetchScheduleCountData,
    fetchApptConfirmationCountData
  ]);

  return (
    <Box
      w={"full"}
      flex={"1 1 0px"}
      className='program-manager-overview'
      h={"fit-content"}
    >
      <VStack h={"full"} flex={"1 1 0px"} display={"flex"} w={"full"}>
        <HStack
          my={4}
          w={["100%", "50%"]}
          display={"flex"}
          alignItems={"center"}
          justifyContent={["left", "space-between"]}
          alignSelf={"flex-start"}
          className='program-manager-dashboard-controls'
          flexDir={["column", "row"]}
          flexFlow={["wrap", "initial"]}
        >
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("allActivities");
              setSearchParams("page=allActivities");
            }}
            variant={activeTab === "allActivities" ? "solid" : "outline"}
            colorScheme='blue'
            className='program-manager-badge program-manager-home-badge'
            borderRadius={12}
            py={1}
            px={3}
          >
            All Activities
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("enrollment");
              setSearchParams("page=enrollment");
            }}
            variant={activeTab === "enrollment" ? "solid" : "outline"}
            className='program-manager-badge program-manager-enrollment-badge'
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
            display={"flex"}
          >
            Enrollment
            {enrollmentCount > 0 && <Text>&nbsp;{`(${enrollmentCount})`}</Text>}
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("scheduling");
              setSearchParams("page=scheduling");
            }}
            variant={activeTab === "scheduling" ? "solid" : "outline"}
            className='program-manager-badge program-manager-scheduling-badge'
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
            display={"flex"}
          >
            Scheduling
            {scheduleCount > 0 && <Text>&nbsp;{`(${scheduleCount})`}</Text>}
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("apptConfirmation");
              setSearchParams("page=apptConfirmation");
            }}
            variant={activeTab === "apptConfirmation" ? "solid" : "outline"}
            className='program-manager-badge program-manager-appt-confirmation-badge'
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
            display={"flex"}
          >
            Appointment Confirmation
            {confirmationCount > 0 && (
              <Text>&nbsp;{`(${confirmationCount})`}</Text>
            )}
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("payments");
              setSearchParams("page=payments");
            }}
            variant={activeTab === "payments" ? "solid" : "outline"}
            className='program-manager-badge program-manager-payments-badge'
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Payments
          </Badge>
          <Badge
            cursor={"pointer"}
            zIndex={100}
            fontSize={["xx-small", "small"]}
            onClick={() => {
              setActiveTab("bpReadings");
              setSearchParams("page=bpReadings");
            }}
            variant={activeTab === "bpReadings" ? "solid" : "outline"}
            className='program-manager-badge program-manager-bpReadings-badge'
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            BP Readings
          </Badge>
          {/* <Badge
            cursor={"pointer"}
            fontSize={['xx-small','nmedium']}
            onClick={() => {
              setActiveTab("invoices");
              setSearchParams("page=invoices");
            }}
            variant={activeTab === "invoices" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Invoices
          </Badge> */}
        </HStack>
        {activeTab === "allActivities" && (
          <VStack spacing={4} w={"full"} h={"full"}>
            <HStack
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              w={"full"}
              spacing={4}
              flexDir={["column", "row"]}
            >
              <DashboardEnrolledPatients />
              <DashboardEnrollmentAndScheduling />
            </HStack>
            <HStack
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              w={"full"}
              spacing={4}
              flexDir={["column", "row"]}
            >
              <DashboardCHWMetrics />
              <DashboardPendingPayments />
            </HStack>
            <div
              style={{
                display: "flex",
                flex: "1 1 0px",
                flexDirection: "column",
                width: "100%",
                height: "fit-content",
                minHeight: "50%"
              }}
            >
              <UpcomingAppointments h={"full"} />
            </div>
          </VStack>
        )}
        {activeTab === "enrollment" && (
          <PatientEnrollment
            placeBasedCareProvId={placeBasedCareProvider?.id ?? ""}
            onPatientEnrolled={() => {
              fetchEnrollmentCountData(placeBasedCareProvider?.id ?? "");
              fetchScheduleCountData(placeBasedCareProvider?.id ?? "");
            }}
          />
        )}
        {activeTab === "scheduling" && (
          <PatientSchedulingTable
            onTaskCompleted={async () => {
              await fetchScheduleCountData(placeBasedCareProvider?.id ?? "");
              await fetchApptConfirmationCountData(
                placeBasedCareProvider?.id ?? ""
              );
            }}
          />
        )}
        {activeTab === "apptConfirmation" && (
          <>
            <ApptConfirmationTable
              setConfirmationAppt={async (appt) => {
                setConfirmingAppt(appt);
                if (appt.outreachId) {
                  const outreachService = new OutreachService();
                  const outreach = await outreachService.getOutreach(
                    appt.outreachId
                  );
                  setConfirmApptOutreach(outreach);
                }
              }}
            />
            {confirmingAppt && (
              <Modal
                isOpen={!!confirmingAppt}
                onClose={() => {
                  setConfirmingAppt(undefined);
                  setConfirmApptOutreach(undefined);
                }}
                size={"full"}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Confirm Appointment <ModalCloseButton />
                  </ModalHeader>
                  <ModalBody>
                    <PatientOutreacher
                      patientId={confirmingAppt!.patientId}
                      outreachPurpose={OutreachPurpose.Appointment}
                      appointmentId={confirmingAppt.id}
                      patientOutreach={confirmApptOutreach}
                      onOutreachLogged={async (outreachId?: string) => {
                        if (!confirmingAppt.outreachId && outreachId) {
                          const apptService = new AppointmentService();
                          confirmingAppt.outreachId = outreachId;
                          await apptService.updateAppointment(confirmingAppt);
                        }
                      }}
                      columns={[1, 2]}
                      gap={4}
                      w={"full"}
                    />
                  </ModalBody>
                  <ModalFooter
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Button
                      onClick={() => {
                        setConfirmingAppt(undefined);
                        setConfirmApptOutreach(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setConfirmingAppt(undefined);
                        setConfirmApptOutreach(undefined);
                        fetchApptConfirmationCountData(
                          placeBasedCareProvider?.id ?? ""
                        );
                      }}
                      colorScheme='blue'
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </>
        )}
        {activeTab === "payments" && <PaymentsTable />}
        {activeTab === "bpReadings" && (
          <BpReadingTable placeBasedCareProvId={placeBasedCareProvider!.id} />
        )}
      </VStack>
    </Box>
  );
};

export default ProgramManagerDashboard;
