import useCurrentUser from "@/hooks/useCurrentUser";
import useInternalTasks from "@/hooks/useInternalSchedulingTasks";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  IconButton,
  Spinner,
  Tooltip
} from "@chakra-ui/react";
import { Patient } from "@oben-core-web/models/patient";
import { PatientService } from "@oben-core-web/services/patient-service";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { BsClock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface IPatientSchedulingTable {
  onTaskCompleted: () => void;
}
const PatientSchedulingTable = ({
  onTaskCompleted
}: IPatientSchedulingTable) => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { internalTasks, fetchInternalSchedulingTasks } = useInternalTasks(
    currentUser?.uid ?? ""
  );
  const [completionConfirmTask, setCompletionConfirmTask] = useState<any>();

  const columnHeaders: ColDef[] = [
    {
      field: "internalTask.dueDate",
      headerName: "Schedule By",
      valueFormatter: (row) =>
        DateTime.fromJSDate(row.data.internalTask.dueDate).toFormat("D"),
      minWidth: 100,
      flex: 0.45
    },
    { field: "patientName", headerName: "Patient", minWidth: 100, flex: 0.8 },
    {
      field: "patient.latestBpReading",
      headerName: "Last BP",
      valueGetter: (row) => {
        const patient = row.data.patient as Patient;
        const lastBp = patient.latestBpReading;
        return lastBp ? `${lastBp.systolic}/${lastBp.diastolic}` : "No Reading";
      },
      minWidth: 100,
      flex: 0.4
    },
    {
      field: "patient.latestBpReading",
      headerName: "Reading Date",
      valueGetter: (row) => {
        const patient = row.data.patient as Patient;
        const lastBp = patient.latestBpReading;
        return lastBp
          ? DateTime.fromJSDate(lastBp.readingDate).toFormat("MM/dd/yyyy")
          : "";
      },
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "prefCHW.name.fullName",
      headerName: "Preferred Barber",
      minWidth: 100,
      flex: 0.7
    },
    {
      field: "prefServiceLocation.businessName",
      headerName: "Preferred Barbershop",
      minWidth: 100,
      flex: 0.7
    },
    {
      field: "prefApptTimes",
      headerName: "Preferred Appt Times",
      cellStyle: {
        whiteSpace: "pre",
        height: "fit-content",
        lineHeight: "2.5em"
      },
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      flex: 0.4,
      cellRenderer: (row: any) => {
        return (
          <HStack alignItems={"center"} h={"full"}>
            <Tooltip label={"Schedule Appointment"}>
              <IconButton
                variant={"link"}
                aria-label={`scheduling-task-btn-${row.data.id}`}
                icon={<BsClock />}
                onClick={async () => {
                  navigate("/appointments", {
                    state: {
                      from: "/?page=scheduling",
                      patientId: row.data?.patientId ?? "NO ID",
                      patientName: row.data?.patient.name,
                      prefCHW: row.data?.prefCHW,
                      prefServiceLocation: row.data?.prefServiceLocation,
                      internalTask: row.data.internalTask
                    },
                    replace: true
                  });
                }}
              />
            </Tooltip>
            <Tooltip label={"Mark Task as Completed"}>
              <IconButton
                variant={"link"}
                aria-label={`complete-task-btn-${row.data.id}`}
                icon={<CheckCircleIcon />}
                onClick={() => {
                  setCompletionConfirmTask(row.data);
                }}
              />
            </Tooltip>
          </HStack>
        );
      }
    }
  ];

  const completeTask = useCallback(async () => {
    if (completionConfirmTask) {
      const internalTaskService = new InternalTaskService();
      completionConfirmTask.internalTask.completionDate = new Date();
      await internalTaskService
        .updateTask(completionConfirmTask.internalTask)
        .then(async () => {
          await fetchInternalSchedulingTasks(currentUser!.uid);
          onTaskCompleted();
        });
    }
  }, [
    completionConfirmTask,
    currentUser,
    onTaskCompleted,
    fetchInternalSchedulingTasks
  ]);

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        className='program-manager-patient-scheduling-table'
        rowData={internalTasks}
        columnDefs={columnHeaders as any}
        rowSelection='single'
        // onRowClicked={(row) =>
        //   navigate("/appointments", {
        //     state: {
        //       from: "/?page=scheduling",
        //       patientId: row.data?.patientId ?? "NO ID",
        //       patientName: row.data?.patient.name,
        //       prefCHW: row.data?.prefCHW,
        //       prefServiceLocation: row.data?.prefServiceLocation
        //     },
        //     replace: true
        //   })
        // }
        // onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
        // autoSizeStrategy={{ type: "fitProvidedWidth", width: 1215 }}
      />
      {completionConfirmTask && (
        <CompletionConfirmationDialog
          patientId={completionConfirmTask.patientId}
          onClose={() => setCompletionConfirmTask(undefined)}
          onConfirm={async () =>
            await completeTask().then(() => {
              setCompletionConfirmTask(undefined);
            })
          }
        />
      )}
    </div>
  );
};

export default PatientSchedulingTable;

const CompletionConfirmationDialog = ({
  patientId,
  onClose,
  onConfirm
}: {
  patientId?: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  useEffect(() => {
    const fetchPatient = async () => {
      if (patientId) {
        setLoading(true);
        const patientUserService = new PatientService();
        const patient = await patientUserService.getPatient(patientId);
        setPatient(patient);
        setLoading(false);
      }
    };
    fetchPatient();
  }, [patientId]);
  return (
    <AlertDialog
      isOpen={!!patientId}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Scheduling Confirmation
              </AlertDialogHeader>

              <AlertDialogBody>
                Have you scheduled an appointment for {patient?.name.fullName}?
              </AlertDialogBody>

              <AlertDialogFooter
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='blue' onClick={onConfirm} ml={3}>
                  Confirm
                </Button>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
