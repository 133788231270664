import { DocumentSnapshot } from "firebase/firestore";

import { PatientType, PatientTypeHelper } from "../constants/core-enums";
import { BillableEventService } from "../services/billable-event-service";

export enum BillableEventType {
  Unknown = "Unknown",
  Screening = "Screening",
  RxConsult = "RxConsult",
  InternalDiscussion = "InternalDiscussion",
  Incentive = "Incentive"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class BillableEventTypeHelper {
  static valueOf(searchString: string | null): BillableEventType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(BillableEventType).includes(
      searchString as BillableEventType
    ) //
      ? (searchString as BillableEventType)
      : null;
  }
}

export interface IBillableEventData {
  id: string;
  placeBasedCareProvId: string;
  patientId: string;
  patientType: PatientType;
  date: Date | null;
}

export interface IApptBillableEventData extends IBillableEventData {
  appointmentId: string;
  eventType: Exclude<BillableEventType, BillableEventType.Incentive>;
}

export interface IIncentiveBillableEventData extends IBillableEventData {
  appointmentId: string | null;
  eventType: BillableEventType.Incentive;
}

export class BillableEvent {
  id: string;
  appointmentId: string | null;
  placeBasedCareProvId: string;
  patientId: string;
  patientType: PatientType;
  date: Date | null;
  eventType: BillableEventType;

  constructor({
    id,
    appointmentId,
    placeBasedCareProvId,
    patientId,
    patientType,
    date,
    eventType
  }: IApptBillableEventData | IIncentiveBillableEventData) {
    this.id = id;
    this.appointmentId = appointmentId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.patientId = patientId;
    this.patientType = patientType;
    this.date = date;
    this.eventType = eventType;
  }

  async updateDb(): Promise<void> {
    const db = new BillableEventService();
    await db.updateBillableEvent(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BillableEvent {
    const data = docSnap.data() as { [key: string]: any };
    return BillableEvent.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): BillableEvent {
    return new BillableEvent({
      id: id,
      appointmentId: data["appointmentId"] ?? null,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      patientId: data["patientId"] ?? "",
      patientType:
        PatientTypeHelper.valueOf(data["patientType"] ?? "") ??
        PatientType.PendingPatient,
      date: data["date"] ? new Date(data["date"]) : null,
      eventType:
        BillableEventTypeHelper.valueOf(data["eventType"] ?? "") ??
        BillableEventType.Unknown
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      appointmentId: this.appointmentId,
      placeBasedCareProvId: this.placeBasedCareProvId,
      patientId: this.patientId,
      patientType: this.patientType,
      date: this.date?.toISOString() ?? null,
      eventType: this.eventType
    };
  }
}
