import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import {
  PatientTaskCategory,
  PatientTaskType
} from "@oben-core-web/models/patient-task";
import { object, string, number, boolean } from "yup";

const clientTaskBaseSchema = object({
  id: string().optional(),
  placeBasedCareProvId: string().required(),
  name: string().required(),
  description: string().required(),
  defaultFreqCount: number().min(1).required(),
  defaultDuration: number().min(1).required(),
  defaultFreqPeriod: string()
    .oneOf([FreqPeriod.Daily, FreqPeriod.Weekly], "Invalid frequency period")
    .required(),
  taskType: string()
    .oneOf(
      [
        PatientTaskType.HealthcareAction,
        PatientTaskType.SmartGoal,
        PatientTaskType.HomeEducation
      ],
      "Invalid task type"
    )
    .required(),
  taskCategory: string()
    .oneOf(
      [
        PatientTaskCategory.PrimaryCareAgenda,
        PatientTaskCategory.Labs,
        PatientTaskCategory.Vaccines,
        PatientTaskCategory.Movement,
        PatientTaskCategory.Nutrition,
        PatientTaskCategory.Stress
      ],
      "Invalid task category"
    )
    .required(),
  enabled: boolean().required()
});

export default clientTaskBaseSchema;
