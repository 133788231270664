import usePatientAppointments from "@/hooks/usePatientAppointments";
import React from "react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Appointment } from "@oben-core-web/models/appointment";
import { DateTime } from "luxon";
import { BoxProps, Text, VStack } from "@chakra-ui/react";

interface IPatientAppointmentTable extends BoxProps {
  patientId: string;
}

const PatientAppointmentTable = ({
  patientId,
  ...boxProps
}: IPatientAppointmentTable) => {
  const { appointments } = usePatientAppointments({
    patientId,
    start: DateTime.now().startOf("year").toJSDate(),
    end: DateTime.now().endOf("year").toJSDate()
  });
  const colDefs: ColDef<Appointment>[] = [
    {
      field: "date",
      valueFormatter: ({ data }) =>
        data?.date ? DateTime.fromJSDate(data.date).toFormat("MM/dd/yy") : "",
      flex: 0.5,
      sort: "desc"
    },
    { field: "pharmacistName.fullName", headerName: "Pharmacist", flex: 0.75 },
    {
      field: "chwName.fullName",
      headerName: "Community Health Worker",
      flex: 1
    },
    { field: "serviceLocationName", headerName: "Service Location", flex: 1 },
    { field: "currentStatus", headerName: "Status", flex: 0.5 }
  ];

  return (
    <VStack h={"full"} w={"full"} {...boxProps}>
      <Text
        as='i'
        alignSelf={"flex-end"}
        fontWeight={"normal"}
        fontSize={"smaller"}
      >
        Year to date
      </Text>
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact columnDefs={colDefs} rowData={appointments} />
      </div>
    </VStack>
  );
};

export default PatientAppointmentTable;
