import { useCallback, useEffect, useState } from "react";
import { PrimaryCareProv } from "@oben-core-web/models/primary-care-prov";
import { PrimaryCareProvService } from "@oben-core-web/services/primary-care-prov-service";

const usePrimaryCareProvs = () => {
  const [primaryCareProvs, setPrimaryCareProvs] = useState<PrimaryCareProv[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const fetchPrimaryCareProvs = useCallback(async () => {
    try {
      setLoading(true);
      const primaryCareProvService = new PrimaryCareProvService();
      const careProvs = await primaryCareProvService.getAllPrimaryCareProvs();
      setPrimaryCareProvs(careProvs);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPrimaryCareProvs();
  }, [fetchPrimaryCareProvs]);

  return { primaryCareProvs, fetchPrimaryCareProvs, loading, error };
};

export default usePrimaryCareProvs;
