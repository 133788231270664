import { DocumentSnapshot } from "firebase/firestore";

import { FreqPeriod, FreqPeriodHelper } from "../constants/core-enums";
import { PatientTaskBaseService } from "../services/patient-task-base-service";
import {
  PatientTaskType,
  PatientTaskTypeHelper,
  PatientTaskCategory,
  PatientTaskCategoryHelper
} from "./patient-task";

// This class is used to represent a table of "base" tasks (which are provider-specific)
// that can be assigned to patients. When one of these tasks is assigned to a patient, a new
// PatientTask is created with the same data as the PatientTaskBase, but with the addition of
// patient-specific data (e.g. due date, status, etc.)
export interface IPatientTaskBaseData {
  id: string;
  placeBasedCareProvId: string;
  taskType: PatientTaskType;
  taskCategory: PatientTaskCategory;
  name: string;
  description: string;
  defaultFreqCount: number;
  defaultFreqPeriod: FreqPeriod;
  defaultDuration: number;
  enabled: boolean;
}

export class PatientTaskBase {
  id: string;
  placeBasedCareProvId: string;
  taskType: PatientTaskType;
  taskCategory: PatientTaskCategory;
  name: string;
  description: string;
  defaultFreqCount: number;
  defaultFreqPeriod: FreqPeriod;
  defaultDuration: number;
  enabled: boolean;

  constructor({
    id,
    placeBasedCareProvId,
    taskType,
    taskCategory,
    name,
    description,
    defaultFreqCount,
    defaultFreqPeriod,
    defaultDuration,
    enabled
  }: IPatientTaskBaseData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.taskType = taskType;
    this.taskCategory = taskCategory;
    this.name = name;
    this.description = description;
    this.defaultFreqCount = defaultFreqCount;
    this.defaultFreqPeriod = defaultFreqPeriod;
    this.defaultDuration = defaultDuration;
    this.enabled = enabled;
  }
  async updateDb(): Promise<void> {
    const db = new PatientTaskBaseService();
    await db.updateTaskBase(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): PatientTaskBase {
    const data = docSnap.data() as { [key: string]: any };
    return PatientTaskBase.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): PatientTaskBase {
    return new PatientTaskBase({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      taskType:
        PatientTaskTypeHelper.valueOf(data["taskType"] ?? "") ??
        PatientTaskType.Unknown,
      taskCategory:
        PatientTaskCategoryHelper.valueOf(data["taskCategory"] ?? "") ??
        PatientTaskCategory.Unknown,
      name: data["name"] ?? "",
      description: data["description"] ?? "",
      defaultFreqCount: data["defaultFreqCount"] ?? 0,
      defaultFreqPeriod:
        FreqPeriodHelper.valueOf(data["defaultFreqPeriod"] ?? "") ??
        FreqPeriod.Unknown,
      defaultDuration: data["defaultDuration"] ?? 0,
      enabled: data["enabled"] ?? false
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      placeBasedCareProvId: this.placeBasedCareProvId,
      taskType: this.taskType,
      taskCategory: this.taskCategory,
      name: this.name,
      description: this.description,
      defaultFreqCount: Number(this.defaultFreqCount),
      defaultFreqPeriod: this.defaultFreqPeriod,
      defaultDuration: Number(this.defaultDuration),
      enabled: this.enabled
    };
  }
}
