import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";

const usePatientAppointments = (queryOptions: {
  patientId: string;
  statuses?: AppointmentStatus[] | null;
  start?: Date;
  end?: Date;
}) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const prevQueryOptionsRef = useRef<{
    patientId: string;
    statuses?: AppointmentStatus[] | null;
  }>({ patientId: "", statuses: null });
  const memoizedQueryOptions = useMemo(
    () => ({
      patientId: queryOptions.patientId,
      statuses: queryOptions.statuses ?? null,
      start: queryOptions.start,
      end: queryOptions.end
    }),
    [
      queryOptions.patientId,
      queryOptions.statuses,
      queryOptions.start,
      queryOptions.end
    ]
  );

  const fetchAppts = useCallback(async () => {
    if (
      typeof memoizedQueryOptions.patientId === "string" &&
      !!memoizedQueryOptions.patientId
    ) {
      const appointmentService = new AppointmentService();
      const queryStart =
        memoizedQueryOptions.start ??
        DateTime.now()
          .set({ hour: 0, minute: 0, second: 0 })
          .minus({ days: 7 })
          .toJSDate();
      const queryEnd =
        memoizedQueryOptions.end ??
        DateTime.now()
          .set({ hour: 0, minute: 0, second: 0 })
          .plus({ days: 7 })
          .toJSDate();

      const appts = await appointmentService.getAllPatientAppointmentsFiltered({
        patientId: memoizedQueryOptions.patientId,
        start: queryStart,
        end: queryEnd,
        ...(memoizedQueryOptions.statuses
          ? { statuses: memoizedQueryOptions.statuses }
          : { statuses: [] })
      });
      setAppointments(appts);
    }
  }, [memoizedQueryOptions]);

  useEffect(() => {
    if (!isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)) {
      prevQueryOptionsRef.current = memoizedQueryOptions;
      fetchAppts();
    }
  }, [fetchAppts, memoizedQueryOptions]);

  return { appointments };
};

export default usePatientAppointments;
