import { object, string, number, date } from "yup";
import userNameSchema from "./userName";

// const userNameSchema = object({
//   first: string().nullable().optional(),
//   last: string().nullable().optional(),
//   display: string().nullable().optional(),
// });

const appointmentSchema = object({
  id: string().optional(),
  startDateTime: date().required(),
  length: number().required(),
  pharmacistId: string().required(),
  serviceLocationId: string().required(),
  chwId: string().required(),
  patientId: string().required(),
  patientName: userNameSchema,
  chwName: userNameSchema
});

export default appointmentSchema;
