import { Medication } from "@oben-core-web/models/medication";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { useEffect, useState, useCallback } from "react";
import { groupBy, uniqBy } from "lodash";

const useMedications = ({
  patientId,
  carePlanId
}: {
  carePlanId: string;
  patientId: string;
}) => {
  const [medications, setMedications] = useState<Medication[]>([]);
  const fetchMedications = useCallback(async () => {
    if (carePlanId && patientId) {
      const medicationService = new MedicationService();
      const meds = await medicationService.getPatientMedications(
        patientId,
        carePlanId
      );
      const medGroups = groupBy(meds, "medBaseId");
      const mostRecentMeds = Object.values(medGroups).map(
        (medGroup) =>
          medGroup.sort((a, b) => {
            if (!a.rxCancelDate && !!b.rxCancelDate) {
              return -1;
            } else if (!b.rxCancelDate && !!a.rxCancelDate) {
              return 1;
            } else if (a.rxStartDate! < b.rxStartDate!) {
              return -1;
            } else if (b.rxStartDate! < a.rxStartDate!) {
              return 1;
            } else {
              return 0;
            }
          })[0]
      );
      setMedications(
        uniqBy(mostRecentMeds, "medBaseId").sort((a, b) => {
          // ignore upper and lowercase
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      );
    }
  }, [carePlanId, patientId]);
  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);
  return { medications, refetch: fetchMedications };
};

export default useMedications;
