import {
  and,
  collection,
  collectionGroup,
  getCountFromServer,
  getDocs,
  or,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { Database } from "../../firebase";
// import { PendingPatient } from "../models/pending-patient";
import { Patient } from "../models/patient";
import { Outreach, OutreachPurpose } from "@oben-core-web/models/outreach";
// import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";
import { AppointmentService } from "./appointment-service";
import { InternalTaskType } from "@oben-core-web/models/internal-task";
import { StaffMemberService } from "./staff-member-service";
import { OutreachService } from "./outreach-service";

export class ProgramManagerQueryService {
  static async getEnrollmentCounts({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      // const pendingPatientQuery = query(
      //   collection(Database, "pendingPatients"),
      //   where("placeBasedCareProvId", "==", placeBasedCareProvId)
      // );
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          where("needsEnrollment", "==", true),
          or(
            where("enrollmentDate", "==", ""),
            where("enrollmentDate", "==", null)
          )
        )
      );
      // const pendingPatientCounts = await getCountFromServer(
      //   pendingPatientQuery
      // );
      const patientCounts = await getCountFromServer(patientQuery);
      return patientCounts.data().count;
      // return pendingPatientCounts.data().count + patientCounts.data().count;
    } catch (e) {
      console.log("Error getting enrollment counts", e);
      throw e;
    }
  }

  static async getEnrollmentPatients({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<{ patient: Patient; enrollmentOutreach: Outreach | null }[]> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      // const pendingPatientQuery = query(
      //   collection(Database, "pendingPatients"),
      //   where("placeBasedCareProvId", "==", placeBasedCareProvId)
      // );
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          where("needsEnrollment", "==", true),
          or(
            where("enrollmentDate", "==", ""),
            where("enrollmentDate", "==", null)
          )
        )
      );
      // const pendingPatientDocs = await getDocs(pendingPatientQuery);
      const patientDocs = await getDocs(patientQuery);

      if (patientDocs.empty) {
        return [];
      }

      // const pendingPatients = pendingPatientDocs.docs.map((doc) =>
      //   PendingPatient.fromFirestore(doc)
      // );
      const patients = patientDocs.docs.map((doc) =>
        Patient.fromFirestore(doc)
      );

      const enrollmentOutreachQuery = query(
        collection(Database, "outreaches"),
        where(
          "patientId",
          "in",
          patients.map((u) => u.uid)
        ),
        where("purpose", "==", OutreachPurpose.NewEnrollment),
        orderBy("createdDate", "desc")
      );

      const enrollmentOutreachDocs = await getDocs(enrollmentOutreachQuery);
      const outreaches = enrollmentOutreachDocs.docs.map((doc) =>
        Outreach.fromFirestore(doc)
      );

      const matchedPatients = patients.map((patient) => {
        const matchingOutreach = outreaches.find(
          (o) => o.patientId === patient.uid
        );
        return {
          patient,
          enrollmentOutreach: matchingOutreach ?? null
        };
      });

      return matchedPatients.sort((a, b) =>
        a.patient.registrationDate && b.patient.registrationDate
          ? a.patient.registrationDate < b.patient.registrationDate
            ? -1
            : 1
          : 1
      );
      // return [
      //   ...patients
      //   // ...pendingPatients
      // ].sort((a, b) =>
      //   a.registrationDate && b.registrationDate
      //     ? a.registrationDate < b.registrationDate
      //       ? -1
      //       : 1
      //     : 1
      // );
    } catch (e) {
      console.log("Error getting enrollment counts", e);
      throw e;
    }
  }

  static async getApptConfirmationData({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }) {
    const appointmentService = new AppointmentService();
    const staffMemberService = new StaffMemberService();
    const staffMembers = await staffMemberService.getPlaceBasedCareProvStaffMembers(
      placeBasedCareProvId
    );
    const staffMemberIds = staffMembers.map((wu) => wu.uid);
    const appointments =
      await appointmentService.getAllPatientAppointmentsFiltered({
        pharmacistIds: staffMemberIds,
        start: DateTime.now().startOf("day").toJSDate(),
        end: DateTime.now().startOf("day").plus({ days: 30 }).toJSDate(),
        statuses: [AppointmentStatus.New]
      });
    const outreachService = new OutreachService();
    const apptConfData = await Promise.all(
      appointments.map(async (appt) => {
        if (appt.outreachId) {
          const outreach = await outreachService.getOutreach(appt.outreachId);
          return { appointment: appt, outreach };
        } else {
          return { appointment: appt, outreach: null };
        }
      })
    );
    return apptConfData;
  }

  static async getNeedToScheduleCount({
    assigneeId
  }: {
    assigneeId: string;
  }): Promise<number> {
    if (!assigneeId) {
      throw new Error("Missing required parameters");
    }
    try {
      const internalTaskQuery = query(
        collection(Database, "internalTasks"),
        where("assigneeId", "==", assigneeId),
        where("internalTaskType", "==", InternalTaskType.ScheduleAppointment),
        where("completionDate", "==", null)
      );
      const internalTaskCount = await getCountFromServer(internalTaskQuery);

      return internalTaskCount.data().count;
    } catch (e) {
      console.log("Error getting scheduling counts", e);
      throw e;
    }
  }

  static async getNeedToConfirmCount({
    placeBasedCareProvId,
    startOfRange = DateTime.now().startOf("day").toJSDate(),
    endOfRange = DateTime.now().startOf("day").plus({ days: 30 }).toJSDate()
  }: {
    placeBasedCareProvId: string;
    startOfRange?: Date;
    endOfRange?: Date;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const staffQuery = query(
        collection(Database, "staffMembers"),
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const staffMembers = await getDocs(staffQuery);
      if (staffMembers.empty) return 0;

      const appointmentService = new AppointmentService();

      const queryParams: {
        pharmacistIds: string[];
        start: Date | null;
        end: Date | null;
        statuses: AppointmentStatus[];
      } = {
        pharmacistIds: staffMembers.docs.map((d) => d.id),
        start: null,
        end: null,
        statuses: [AppointmentStatus.New]
      };

      // const queryFilters = [
      //   where(
      //     "pharmacistId",
      //     "in",
      //     staffMembers.docs.map((u) => u.id)
      //   )
      // ];

      if (startOfRange) {
        queryParams.start = startOfRange;
        // queryFilters.push(where("date", ">=", startOfRange.toISOString()));
      }
      if (endOfRange) {
        queryParams.end = endOfRange;
        // queryFilters.push(where("date", "<", endOfRange.toISOString()));
      }

      // const appointmentQuery = query(
      //   collection(Database, "appointments"),
      //   ...queryFilters
      // );

      // const newAppts = (await getDocs(appointmentQuery)).docs
      //   .map((a) => Appointment.fromFirestore(a))
      //   .filter(
      //     (a) =>
      //       a.statusChanges[a.statusChanges.length - 1]?.status ===
      //       AppointmentStatus.New
      //   );

      const newAppts =
        await appointmentService.getAllPatientAppointmentsFiltered(queryParams);

      return newAppts.length ?? 0;
    } catch (e) {
      console.log("Error getting appointment confirmation counts", e);
      throw e;
    }
  }

  static async getPendingPaymentCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const internalTaskQuery = query(
        collectionGroup(Database, "billableItems"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          or(
            where("internalTaskType", "==", "ScheduleAppointment"),
            where("completionDate", "==", null)
          )
        )
      );
      const internalTaskCount = await getCountFromServer(internalTaskQuery);

      return internalTaskCount.data().count;
    } catch (e) {
      console.log("Error getting scheduling counts", e);
      throw e;
    }
  }
}
