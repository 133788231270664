import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  addDoc,
  updateDoc,
  query,
  where,
  or,
  QuerySnapshot,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { UserType } from "../constants/core-enums";
import { StaffMember } from "../models/staff-member";
import { GaLog } from "../utils/ga-log";

export class StaffMemberService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "staffMembers";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single StaffMember's profile
  streamStaffMember(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return StaffMember.fromFirestore(docSnap);
      } else {
        return StaffMember.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribeFunc: (() => void) | undefined;
    let isUnsubscribed = false;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribeFunc = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {isSubscription: true});
          },
          (error) => {
            if (!isUnsubscribed) {
              console.error("Error in onSnapshot:", error, uid);
              // TODO: Do we need to throw an error here?
              GaLog.readDocument(this._collectionReference.path, uid, {isSubscription: true});
            }
          }
        );
      }
      return {
        unsubscribe: () => {
          isUnsubscribed = true;
          unsubscribeFunc && unsubscribeFunc();
        }
      };
    } catch (error) {
      if (isUnsubscribed) {
        GaLog.readError(this._collectionReference.path, error, {isSubscription: true});
        throw error;
      }
      throw undefined;
    }
  }

  async getAllStaffMembers(userTypeFilters?: UserType[]): Promise<StaffMember[]> {
    let qSnapshot: QuerySnapshot<DocumentData>;
    try {
      if (userTypeFilters?.length) {
        const filters =
          userTypeFilters?.map((type) => {
            return where("userType", "==", type);
          }) ?? [];
        qSnapshot = await getDocs(
          query(this._collectionReference, or(...filters))
        );
      } else {
        qSnapshot = await getDocs(this._collectionReference);
      }
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => StaffMember.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvStaffMembers(placeBasedCareProvId: string): Promise<StaffMember[]> {
    try {
      const staffQuery = query(
        this._collectionReference,
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(staffQuery);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => StaffMember.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getPlaceBasedCareProvStaffMemberCount(placeBasedCareProvId: string): Promise<number> {
    try {
      const staffQuery = query(
        this._collectionReference,
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const aqSnapshot = await getCountFromServer(staffQuery);
      const count = aqSnapshot.data().count ?? 0;
      GaLog.countCollection(this._collectionReference.path, count);
      return count;
    } catch (error) {
      GaLog.countError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a StaffMember exists
  async validateStaffMember(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      console.log({ error });
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // NOTE:  Not extensively used by Web Portal app (Instead, data is retrieved via streamStaffMember() above).
  async getStaffMember(uid: string): Promise<StaffMember> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Staff Member not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return StaffMember.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addStaffMember(staffMember: StaffMember): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, staffMember.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateStaffMember(staffMember: StaffMember): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, staffMember.uid);
      await updateDoc(docRef, staffMember.toJson());
      GaLog.updateDocument(this._collectionReference.path, staffMember.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // async deleteStaffMember(uid: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, uid);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, uid);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
