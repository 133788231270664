import {
  and,
  collection,
  getCountFromServer,
  getDocs,
  or,
  query,
  where
} from "firebase/firestore";
import { Database } from "../../firebase";
import { Patient } from "../models/patient";
import { ClaimStatus } from "@oben-core-web/models/claim-status-change";
import { SourceForCHWFunds } from "@oben-core-web/constants/core-enums";

export class PhysicianQueryService {
  static async getEnrolledPatientCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) throw new Error("Missing required parameters");
    try {
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          where("enrollmentDate", "!=", null)
        )
      );
      const patientCounts = await getCountFromServer(patientQuery);
      return patientCounts.data().count;
    } catch (e) {
      console.log("Error fetching enrolled patient count", e);
      throw e;
    }
  }
  static async getNeedRecommendationCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          or(
            where("hasRecommendationLetter", "==", false),
            where("hasRecommendationLetter", "==", null)
          ),
          where("sourceForCHWFunds", "==", SourceForCHWFunds.Payer)
        )
      );
      const patientCounts = await getCountFromServer(patientQuery);
      return patientCounts.data().count;
    } catch (e) {
      console.log("Error fetching need recommendation count", e);
      throw e;
    }
  }
  static async getHasRecommendationCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          where("hasRecommendationLetter", "==", true)
        )
      );
      const patientCounts = await getCountFromServer(patientQuery);
      return patientCounts.data().count;
    } catch (e) {
      console.log("Error fetching has recommendation counts", e);
      throw e;
    }
  }

  static async getInvoiceCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const claimsQuery = query(
        collection(Database, "claims"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          or(
            where("currentStatus", "==", ClaimStatus.New),
            where("currentStatus", "==", ClaimStatus.NeedsFurtherReview)
          )
        )
      );
      const claimCounts = await getCountFromServer(claimsQuery);
      return claimCounts.data().count;
    } catch (e) {
      console.log("Error fetching claim counts", e);
      throw e;
    }
  }

  static async getPatientsWithoutRecs({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<Patient[]> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const patientQuery = query(
        collection(Database, "patients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          where("sourceForCHWFunds", "==", SourceForCHWFunds.Payer),
          or(
            where("hasRecommendationLetter", "==", false),
            where("hasRecommendationLetter", "==", null)
          )
        )
      );
      const patientDocs = await getDocs(patientQuery);

      if (patientDocs.empty) {
        return [];
      }

      const patients = patientDocs.docs.map((doc) =>
        Patient.fromFirestore(doc)
      );

      return patients;
    } catch (e) {
      console.log("Error getting patients without recs", e);
      throw e;
    }
  }
}
