import usePatientCarePlans from "@/hooks/usePatientCarePlans";
import useMedications from "@/hooks/useMedications";
import {
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure
} from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CPWizardStep6 from "./CPWizardStep6";
import useCurrentUser from "@/hooks/useCurrentUser";

interface IMedicationChart {
  patientId: string;
  toggleChart: () => void;
  isEditable?: boolean;
  height?: number | string;
  width?: number | string;
}

const MedicationChart = ({
  patientId,
  toggleChart,
  isEditable,
  ...rest
}: IMedicationChart) => {
  const { isOpen, onToggle } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const { currentCarePlan } = usePatientCarePlans(patientId);
  const { currentUser } = useCurrentUser();

  const { medications } = useMedications({
    patientId,
    carePlanId: currentCarePlan?.modelId
  });

  const parsedMedData = medications
    .map((med) => {
      return {
        medName: med.name, // must match x axis 'category'
        dosage: med.newDosage.strength, // used to build data labels
        x: med.name,
        y: [
          Date.parse(med.rxStartDate?.toString() ?? new Date().toString()),
          Date.parse(med.rxCancelDate?.toString() ?? new Date().toString())
        ]
      };
    })
    .reduce((a, c) => {
      if (!a[c.medName]) {
        a[c.medName] = { name: c.medName, data: [] };
      }
      const { x, y } = c;
      a[c.medName].data.push({ x, y, dosage: c.dosage });
      // a[c.medName].dosages.push(c.dosage);
      return a;
    }, {} as Record<string, any>);

  const medSeries = Object.entries(parsedMedData).map(([name, data]) => {
    return { name, data: data.data };
  });

  const options: ApexOptions = {
    chart: {
      type: "rangeBar",
      toolbar: {
        show: true,
        tools: {
          selection: false,
          download: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: isEditable
            ? [
                {
                  icon: '<div style="display: flex; width: 7.5rem; position: absolute; right: 7.5rem; font-size:smaller;">Edit Medications</div>',
                  index: 0,
                  title: "Edit Medications",
                  class: "custom-icon",
                  click: function () {
                    onToggle();
                  }
                },
                {
                  icon: '<div style="display: flex; width: 7.5rem; position: absolute; right: 0; font-size:smaller;">View Adherence</div>',
                  index: 1,
                  title: "View Med Adherences",
                  class: "custom-icon",
                  click: function () {
                    toggleChart();
                  }
                }
              ]
            : [
                {
                  icon: '<div style="display: flex; width: 7.5rem; position: absolute; right: 0; font-size:smaller;">View Adherence</div>',
                  index: 0,
                  title: "View Med Adherences",
                  class: "custom-icon",
                  click: function () {
                    toggleChart();
                  }
                }
              ]
        }
      },
      zoom: {
        enabled: false
      }
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "75%",
        rangeBarGroupRows: true,

        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900
            },
            formatter: function (val, opts) {
              if (opts !== undefined) {
                // const group = adherenceSeries[opts.seriesIndex];
                const bar =
                  medSeries[opts.seriesIndex].data[opts.dataPointIndex];
                return bar.dosage ? bar.dosage + "mg" : "[Error]";
              }
              return "";
            }
          }
        }
      }
    },
    title: {
      text: "Current Medications"
    },
    xaxis: {
      categories: medications.reduce((a, c) => {
        if (!a.includes(c.name)) {
          a.push(c.name);
        }
        return a;
      }, [] as string[]),
      type: "datetime",
      labels: {
        format: "M/d/y"
      }
    },
    tooltip: {
      // x: {
      //   formatter(val, opts) {
      //     if (opts !== undefined) {
      //       // const group = adherenceSeries[opts.seriesIndex];
      //       const bar = medSeries[opts.seriesIndex].data[opts.dataPointIndex];
      //       return bar.dosage ? bar.dosage + "mg" : "[Error]";
      //     }
      //     return "";
      //   }
      // }
      // y: {
      // formatter(val, opts) {
      //   if (opts !== undefined) {
      //     // const group = adherenceSeries[opts.seriesIndex];
      //     console.log(new Date(val));
      //     const bar = medSeries[opts.seriesIndex].data[opts.dataPointIndex];
      //     return bar.dosage ? bar.dosage + "mg" : "[Error]";
      //   }
      //   return "";
      // }
      // }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (opts !== undefined) {
          const group = medSeries[opts.seriesIndex];
          const bar = medSeries[opts.seriesIndex].data[opts.dataPointIndex];
          // console.log({ group, bar });
          const med = medications.find(
            (m) => m.name === group.name && m.newDosage.strength === bar.dosage
          );
          if (med !== undefined) {
            const label = (med.newDosage.strength ?? 0) + "mg";
            return label;
          }
        }
        return "[Error]";
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  if (loading) {
    return (
      <Center w={"full"} h={"10rem"}>
        <Spinner />
      </Center>
    );
  }
  return (
    <>
      <Chart
        options={options}
        series={medSeries}
        type='rangeBar'
        width='100%'
        height={"50%"}
        {...rest}
      />
      {currentCarePlan && currentUser && (
        <Modal isOpen={isOpen} onClose={onToggle}>
          <ModalOverlay />
          <ModalContent minW={"80rem"} p={8}>
            <CPWizardStep6
              currentUser={currentUser}
              carePlanId={currentCarePlan.modelId}
              patientId={patientId}
              pharmacistId={currentUser.uid}
              onCancel={onToggle}
              onSubmit={onToggle}
              submitText='Save'
              cancelText='Cancel'
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default MedicationChart;
