import {
  SystemStatus,
  SystemStatusInfo
} from "../../oben-core-web/models/system-status-info";
import { DateTime } from "luxon";
// Define action types
type TAppReducer =
  | {
      type: "SET_SYSTEM_STATUS";
      payload: { systemStatusInfo: SystemStatusInfo };
    }
  | { type: "DISMISS_MAINTENANCE_NOTICE" }
  | {
      type: "SET_DETACH_SYSTEM_LISTENER";
      payload: { detachSystemListener: () => void };
    }
  | {
      type: "SET_APP_MODE";
      payload: { appMode: "app" | "admin" };
    }
  | { type: "RESET" };

const storedMaintenanceDate = localStorage.getItem("maintenance-date");

// Define the Auth state structure
export interface IAppState {
  status: SystemStatus;
  maintenanceDate: Date | null;
  detachSystemListener: (() => void) | undefined;
  inMaintenanceMode: boolean;
  maintenanceNoticeDismissed: boolean;
  appMode: "app" | "admin";
}

// Initial state
export const initialState: IAppState = {
  status: SystemStatus.Up,
  maintenanceDate: null,
  detachSystemListener: undefined,
  inMaintenanceMode: false,
  maintenanceNoticeDismissed: storedMaintenanceDate
    ? JSON.parse(storedMaintenanceDate).dismissed
    : false,
  appMode:
    localStorage.getItem("isAdminModeActive") === "true" ? "admin" : "app"
};

interface ILocalStorageMaintenance {
  date: string;
  dismissed: boolean;
}

// Reducer function
export const appReducer = (
  state: IAppState,
  action: TAppReducer
): IAppState => {
  switch (action.type) {
    case "SET_SYSTEM_STATUS": {
      const { systemStatusInfo } = action.payload;
      if (systemStatusInfo) {
        const { status, maintenanceStartDate } = systemStatusInfo;
        // get local storage
        const storedMaintenanceDate = localStorage.getItem("maintenance-date");
        const storedData: ILocalStorageMaintenance | null =
          storedMaintenanceDate ? JSON.parse(storedMaintenanceDate) : null;
        // check if local storage needs to be updated
        if (
          storedData &&
          maintenanceStartDate &&
          DateTime.fromJSDate(new Date(storedData.date)).toMillis() !==
            DateTime.fromJSDate(maintenanceStartDate).toMillis()
        ) {
          localStorage.setItem(
            "maintenance-date",
            JSON.stringify({
              date: DateTime.fromJSDate(maintenanceStartDate).toMillis(),
              dismissed: false
            })
          );
        } else if (!storedData && maintenanceStartDate) {
          localStorage.setItem(
            "maintenance-date",
            JSON.stringify({
              date: DateTime.fromJSDate(maintenanceStartDate).toMillis(),
              dismissed: false
            })
          );
        }
        // check if was already dismissed
        const alreadyDismissed =
          (storedData?.dismissed &&
            new Date(storedData.date).toLocaleString() ===
              maintenanceStartDate?.toLocaleString()) ??
          false;
        // merge in provided systemStatusInfo
        return {
          ...state,
          status,
          inMaintenanceMode: status === SystemStatus.DownForMaintenance,
          maintenanceDate: maintenanceStartDate,
          maintenanceNoticeDismissed: alreadyDismissed
        };
      } else {
        return initialState;
      }
    }
    case "DISMISS_MAINTENANCE_NOTICE": {
      const storedMaintenanceDate = localStorage.getItem("maintenance-date");
      const storedData = storedMaintenanceDate
        ? JSON.parse(storedMaintenanceDate)
        : null;
      if (storedData) {
        localStorage.setItem(
          "maintenance-date",
          JSON.stringify({ ...storedData, dismissed: true })
        );
      }
      return { ...state, maintenanceNoticeDismissed: true };
    }
    case "SET_DETACH_SYSTEM_LISTENER": {
      return {
        ...state,
        detachSystemListener: action.payload.detachSystemListener
      };
    }
    case "SET_APP_MODE": {
      localStorage.setItem(
        "isAdminModeActive",
        action.payload.appMode === "admin" ? "true" : "false"
      );
      return {
        ...state,
        appMode: action.payload.appMode
      };
    }
    case "RESET": {
      state.detachSystemListener?.();
      localStorage.removeItem("maintenance-date");
      return initialState;
    }
    default:
      return state;
  }
};
