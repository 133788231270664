import { useEffect, useState } from "react";
import { PatientTaskBase } from "@oben-core-web/models/patient-task-base";
import { PatientTaskBaseService } from "@oben-core-web/services/patient-task-base-service";
import { PatientTaskType } from "@oben-core-web/models/patient-task";

const useTaskBase = ({
  placeBasedCareProvId,
  taskTypes
}: {
  placeBasedCareProvId?: string;
  taskTypes?: PatientTaskType[];
}) => {
  const [loading, setLoading] = useState(false);
  const [taskBases, setTaskBases] = useState<PatientTaskBase[]>([]);
  const [error, setError] = useState<unknown>();
  const stringifiedTypes = taskTypes?.length ? JSON.stringify(taskTypes) : "";
  useEffect(() => {
    let mounted = false;
    const fetchTaskBases = async () => {
      try {
        setLoading(true);
        if (!mounted) {
          const taskBaseService = new PatientTaskBaseService();
          setLoading(true);
          const taskBases = await taskBaseService.getAllPatientTaskBases(
            placeBasedCareProvId ?? ""
          );
          setTaskBases(taskBases);
          setLoading(false);
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetchTaskBases();
    return () => {
      mounted = true;
    };
  }, [placeBasedCareProvId, stringifiedTypes]);
  return { taskBases, loading, error };
};

export default useTaskBase;
