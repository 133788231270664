import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import {
  signInWithEmailAndPassword,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider
  // updatePassword
} from "firebase/auth";
import { Authentication, CloudFunctions } from "../../firebase";
import { useToast } from "@chakra-ui/react";
import { useAuthContext } from "./useAuthContext";
import useCurrentUser from "./useCurrentUser";
import { useAppContext } from "./useAppContext";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";

const useAuthentication = () => {
  const { beforeLogout } = useAuthContext();
  const {
    state: { detachSystemListener }
  } = useAppContext();
  const toast = useToast();
  const { currentUser } = useCurrentUser();

  const login = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    if (!Authentication) {
      toast({
        status: "error",
        description: "Missing authentication provider"
      });
      return;
    }
    return signInWithEmailAndPassword(Authentication, email, password)
      .then(async (userCred) => {
        const sms = new StaffMemberService();
        const isValidUser = await sms.validateStaffMember(userCred.user.uid);
        if (!isValidUser) {
          detachSystemListener && detachSystemListener();
          beforeLogout();
          signOut(Authentication);
          toast({
            description:
              "Unable to log in.  If you believe this is an error please contact the Oben team",
            status: "error"
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        throw err;
      });
  };

  const logout = async () => {
    if (!Authentication) {
      console.log("Logout error: No authentication");
      return;
    }
    beforeLogout();
    return await signOut(Authentication)
      .then(() => {
        console.log("Signed out");
      })
      .catch(() => {
        console.log("Unable to sign out");
      });
  };

  // TODO: call reauthenticateWithCredential in cases where current session token has expired
  const changePassword = async (
    oldPassword: string,
    password: string,
    updatePasswordResetFlag: boolean = false
  ) => {
    if (!currentUser && updatePasswordResetFlag) {
      console.log("Current User not set");
    }
    try {
      if (!Authentication) return;
      const authUser = Authentication.currentUser;
      if (!authUser || !authUser.email) return;
      const credential = EmailAuthProvider.credential(
        authUser.email,
        oldPassword
      );
      await reauthenticateWithCredential(authUser, credential).catch((err) => {
        throw err;
      });
      const callUpdatePassword = httpsCallable<
        { password: string },
        CloudFunctionResponse
      >(CloudFunctions, "changePassword");
      const result = await callUpdatePassword({ password });
      if (result.data?.error) {
        throw result.data.error;
      } else {
        if (currentUser && updatePasswordResetFlag) {
          const sms = new StaffMemberService();
          currentUser.pwdNeedsReset = false;
          await sms.updateStaffMember(currentUser);
        }
      }
    } catch (error) {
      console.log("Error changing user password", error);
      if (typeof error === "string") {
        toast({
          status: "error",
          description: error
        });
      } else if (error instanceof FirebaseError) {
        if (error.code === "auth/wrong-password") {
          toast({
            status: "error",
            description: "Incorrect password provided"
          });
        }
      } else {
        toast({
          status: "error",
          description: "Something went wrong.  Please reach out to Oben Support"
        });
      }
      throw error;
    }
  };
  // const changePassword = async (
  //   password: string,
  //   updatePasswordResetFlag: boolean = false
  // ) => {
  //   if (!Authentication) {
  //     console.log("Change password error: No authentication");
  //     return;
  //   } else if (!Authentication.currentUser) {
  //     console.log("User is not logged in");
  //     return;
  //   } else if (!currentUser && updatePasswordResetFlag) {
  //     console.log("Current User not set");
  //   }
  //   try {
  //     await updatePassword(Authentication.currentUser, password).then(
  //       async () => {
  //         if (currentUser && updatePasswordResetFlag) {
  //           const wus = new WebUserService();
  //           currentUser.pwdNeedsReset = false;
  //           await wus.updateWebUser(currentUser);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     console.log("Error changing user password", error);
  //     toast({
  //       status: "error",
  //       description: "Something went wrong.  Please reach out to Oben Support"
  //     });
  //   }
  // };

  return { login, logout, changePassword };
};

export default useAuthentication;
