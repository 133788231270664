import useServiceLocations from "@/hooks/useServiceLocations";
import usePayers from "@/hooks/usePayers";
import { Box, BoxProps, HStack, IconButton } from "@chakra-ui/react";
import { Patient } from "@oben-core-web/models/patient";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { MdOutlineEditNote } from "react-icons/md";
import { PhysicianQueryService } from "@oben-core-web/services/physician-query-service";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

interface INeedsRecommendationTable extends BoxProps {
  onPatientSelected: (patient: Patient) => void;
}
const NeedsRecommendationTable = ({
  onPatientSelected,
  ...props
}: INeedsRecommendationTable) => {
  const needRecsFetched = useRef(false);
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const [patientsWithoutRecs, setPatientsWithoutRecs] = useState<Patient[]>([]);
  // const { clientUsers } = usePatients(currentUser?.placeBasedCareProvId ?? "");
  const { serviceLocations } = useServiceLocations(placeBasedCareProvider!.id);
  const { payers } = usePayers();

  useEffect(() => {
    if (!needRecsFetched.current && placeBasedCareProvider) {
      needRecsFetched.current = true;
      PhysicianQueryService.getPatientsWithoutRecs({
        placeBasedCareProvId: placeBasedCareProvider.id
      }).then((patients) => {
        setPatientsWithoutRecs(patients);
      });
    }
  }, [placeBasedCareProvider]);

  const columnHeaders: ColDef[] = [
    { field: "name.fullName", headerName: "Name", minWidth: 100, flex: 1 },
    {
      field: "latestBpReading",
      headerName: "Last BP",
      valueFormatter: (row) => {
        if (!row.data.latestBpReading) return "";
        return (
          row.data.latestBpReading.systolic +
          "/" +
          row.data.latestBpReading.diastolic
        );
      },
      minWidth: 100,
      flex: 0.4
    },
    {
      field: "enrollmentDate",
      headerName: "Enrollment Date",
      valueFormatter: (row) => {
        if (!row.data.enrollmentDate) return "-";
        return DateTime.fromJSDate(row.data.enrollmentDate).toFormat("D");
      },
      minWidth: 100,
      flex: 0.5
    },
    {
      field: "prefBarbershopId",
      headerName: "Barbershop",
      valueFormatter: (row) => {
        return (
          serviceLocations.find((b) => b.id === row.data.prefBarbershopId)
            ?.businessName ?? ""
        );
      },
      minWidth: 100,
      flex: 1
    },
    {
      field: "payerId",
      headerName: "Payer",
      valueFormatter: (row) => {
        return (
          payers.find((p) => p.id === row.data.payerId)?.businessName ?? ""
        );
      },
      minWidth: 100,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ({ data }: { data: any }) => {
        return (
          <HStack w={"full"} h={"full"}>
            <IconButton
              alignSelf={"center"}
              justifySelf={"flex-start"}
              icon={<MdOutlineEditNote size={28} color='black' />}
              aria-label='create-rec-btn'
              variant='link'
              onClick={() => {
                onPatientSelected(data);
              }}
            />
          </HStack>
        );
      },
      minWidth: 100,
      flex: 0.3
    }
  ];
  return (
    <Box
      className='ag-theme-quartz'
      // style={{ height: "100%", width: "100%" }}
      {...props}
    >
      <AgGridReact
        className='program-manager-patient-scheduling-table'
        rowData={patientsWithoutRecs}
        columnDefs={columnHeaders as any}
        rowSelection='single'
      />
    </Box>
  );
};

export default NeedsRecommendationTable;
