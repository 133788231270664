import { DocumentSnapshot } from "firebase/firestore";

import { ClaimService } from "../services/claim-service";
import { Address } from "./address";
import {
  ClaimStatus,
  ClaimStatusHelper,
  ClaimStatusChange
} from "./claim-status-change";
import { UserName } from "./user-name";
import { Sex } from "@oben-core-web/constants/core-enums";

export enum ClaimCHWServiceType {
  BpReading = "BpReading",
  BpEducation = "BpEducation",
  HealthEducation = "HealthEducation",
  ProgramReferral = "ProgramReferral"
}

interface IClaimCHWServiceTypeData {
  localizedLabel: string;
  englishDescription: string;
}

export const claimCHWServiceTypeData: Record<
  ClaimCHWServiceType,
  IClaimCHWServiceTypeData
> = {
  [ClaimCHWServiceType.BpReading]: {
    localizedLabel: "core_label_bp_reading",
    englishDescription: "Screened for Blood Pressure" // english only
  },
  [ClaimCHWServiceType.BpEducation]: {
    localizedLabel: "core_label_bp_coaching",
    englishDescription: "Health coaching about their Blood Pressure numbers" // english only
  },
  [ClaimCHWServiceType.HealthEducation]: {
    localizedLabel: "core_label_health_coaching",
    englishDescription:
      "Health coaching about lifestyle, nutrition, and exercise" // english only
  },
  [ClaimCHWServiceType.ProgramReferral]: {
    localizedLabel: "core_label_referral",
    englishDescription:
      "Referred them to the Cut Hypertension Program and pharmacist" // english only
  }
};

export class ClaimCHWServiceTypeHelper {
  static valueOf(searchString: string | null): ClaimCHWServiceType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(ClaimCHWServiceType).includes(
      searchString as ClaimCHWServiceType
    ) //
      ? (searchString as ClaimCHWServiceType)
      : null;
  }
}

export interface IClaimData {
  id: string;
  placeBasedCareProvId: string;
  payerId: string;
  payerBusinessName: string;
  payerIdentifierAtClearinghouse: string;
  payerParticipationCode: string;
  patientId: string;
  patientName: UserName;
  patientSex: Sex;
  patientDob: Date;
  patientAddress: Address;
  patientIsHomeless: boolean;
  patientPolicyNumber: string;
  patientPolicyGroupNumber: string;
  billingProvBusinessName: string;
  billingProvAddress: Address;
  billingProvPhoneNumber: string;
  billingProvIdentifierAtClearinghouse: string;
  billingProvTIN: string;
  billingProvNPI: string;
  billingProvTaxonomyCode: string;
  referringProvName: UserName;
  referringProvNPI: string;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  supervisingPhysAddress: Address;
  supervisingPhysNPI: string;
  renderingProvId: string; // barber-CHW ID
  renderingProvName: UserName; // barber-CHW name
  renderingProvNPI: string; // barber-CHW doesn't have NPI, so will normally use billingProvNPI
  serviceLocationName: string;
  serviceLocationAddress: Address;
  billableEventId: string;
  billableItemId: string;
  services: ClaimCHWServiceType[];
  serviceDetails: string; // in English (from the english localization of the ClaimCHWServiceType)
  serviceDate: Date;
  serviceDuration: number; // in minutes
  serviceUnitCount: number;
  serviceCostPerUnit: number; // in cents (e.g. 1250 = $12.50)
  icd10Code: string;
  billingCode: string;
  billingModifier: string;
  chwAttestationDate: Date;
  latestEDIDownloadDate?: Date | null;
  invoiceNumber?: string | null;
  invoiceDate?: Date | null;
  currentStatus: ClaimStatus;
  claimStatusChanges: ClaimStatusChange[];
  patientControlNumber: string;
}

export class Claim {
  id: string;
  placeBasedCareProvId: string;
  payerId: string;
  payerBusinessName: string;
  payerIdentifierAtClearinghouse: string;
  payerParticipationCode: string;
  patientId: string;
  patientName: UserName;
  patientSex: Sex;
  patientDob: Date;
  patientAddress: Address;
  patientIsHomeless: boolean;
  patientPolicyNumber: string;
  patientPolicyGroupNumber: string;
  billingProvBusinessName: string;
  billingProvAddress: Address;
  billingProvPhoneNumber: string;
  billingProvIdentifierAtClearinghouse: string;
  billingProvTIN: string;
  billingProvNPI: string;
  billingProvTaxonomyCode: string;
  referringProvName: UserName;
  referringProvNPI: string;
  supervisingPhysId: string;
  supervisingPhysName: UserName;
  supervisingPhysAddress: Address;
  supervisingPhysNPI: string;
  renderingProvId: string;
  renderingProvName: UserName;
  renderingProvNPI: string;
  serviceLocationName: string;
  serviceLocationAddress: Address;
  billableEventId: string;
  billableItemId: string;
  services: ClaimCHWServiceType[];
  serviceDetails: string;
  serviceDate: Date;
  serviceDuration: number;
  serviceUnitCount: number;
  serviceCostPerUnit: number;
  icd10Code: string;
  billingCode: string;
  billingModifier: string;
  chwAttestationDate: Date;
  latestEDIDownloadDate?: Date | null;
  invoiceNumber?: string | null;
  invoiceDate?: Date | null;
  currentStatus: ClaimStatus;
  claimStatusChanges: ClaimStatusChange[];
  patientControlNumber: string;

  constructor({
    id,
    placeBasedCareProvId,
    payerId,
    payerBusinessName,
    payerIdentifierAtClearinghouse,
    payerParticipationCode,
    patientId,
    patientName,
    patientSex,
    patientDob,
    patientAddress,
    patientIsHomeless,
    patientPolicyNumber,
    patientPolicyGroupNumber,
    billingProvBusinessName,
    billingProvAddress,
    billingProvPhoneNumber,
    billingProvIdentifierAtClearinghouse,
    billingProvTIN,
    billingProvNPI,
    billingProvTaxonomyCode,
    referringProvName,
    referringProvNPI,
    supervisingPhysId,
    supervisingPhysName,
    supervisingPhysAddress,
    supervisingPhysNPI,
    renderingProvId,
    renderingProvName,
    renderingProvNPI,
    serviceLocationName,
    serviceLocationAddress,
    billableEventId,
    billableItemId,
    services,
    serviceDetails,
    serviceDate,
    serviceDuration,
    serviceUnitCount,
    serviceCostPerUnit,
    icd10Code,
    billingCode,
    billingModifier,
    chwAttestationDate,
    latestEDIDownloadDate,
    invoiceNumber,
    invoiceDate,
    currentStatus,
    claimStatusChanges,
    patientControlNumber
  }: IClaimData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.payerId = payerId;
    this.payerBusinessName = payerBusinessName;
    this.payerIdentifierAtClearinghouse = payerIdentifierAtClearinghouse;
    this.payerParticipationCode = payerParticipationCode;
    this.patientId = patientId;
    this.patientName = patientName;
    this.patientSex = patientSex;
    this.patientDob = patientDob;
    this.patientAddress = patientAddress;
    this.patientIsHomeless = patientIsHomeless;
    this.patientPolicyNumber = patientPolicyNumber;
    this.patientPolicyGroupNumber = patientPolicyGroupNumber;
    this.billingProvBusinessName = billingProvBusinessName;
    this.billingProvAddress = billingProvAddress;
    this.billingProvPhoneNumber = billingProvPhoneNumber;
    this.billingProvIdentifierAtClearinghouse =
      billingProvIdentifierAtClearinghouse;
    this.billingProvTIN = billingProvTIN;
    this.billingProvNPI = billingProvNPI;
    this.billingProvTaxonomyCode = billingProvTaxonomyCode;
    this.referringProvName = referringProvName;
    this.referringProvNPI = referringProvNPI;
    this.supervisingPhysId = supervisingPhysId;
    this.supervisingPhysName = supervisingPhysName;
    this.supervisingPhysAddress = supervisingPhysAddress;
    this.supervisingPhysNPI = supervisingPhysNPI;
    this.renderingProvId = renderingProvId;
    this.renderingProvName = renderingProvName;
    this.renderingProvNPI = renderingProvNPI;
    this.serviceLocationName = serviceLocationName;
    this.serviceLocationAddress = serviceLocationAddress;
    this.billableEventId = billableEventId;
    this.billableItemId = billableItemId;
    this.services = services;
    this.serviceDetails = serviceDetails;
    this.serviceDate = serviceDate;
    this.serviceDuration = serviceDuration;
    this.serviceUnitCount = serviceUnitCount;
    this.serviceCostPerUnit = serviceCostPerUnit;
    this.icd10Code = icd10Code;
    this.billingCode = billingCode;
    this.billingModifier = billingModifier;
    this.chwAttestationDate = chwAttestationDate;
    this.latestEDIDownloadDate = latestEDIDownloadDate;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.currentStatus = currentStatus;
    this.claimStatusChanges = claimStatusChanges;
    this.patientControlNumber = patientControlNumber;
  }

  //
  // Getters
  //

  get amount(): number {
    return this.serviceUnitCount * this.serviceCostPerUnit; // in cents (e.g. 1250 = $12.50)
  }

  async updateDb(): Promise<void> {
    const db = new ClaimService();
    await db.updateClaim(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Claim {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return Claim.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Claim {
    return new Claim({
      id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      payerId: data["payerId"] ?? "",
      payerBusinessName: data["payerBusinessName"] ?? "",
      payerIdentifierAtClearinghouse:
        data["payerIdentifierAtClearinghouse"] ?? "",
      payerParticipationCode: data["payerParticipationCode"] ?? "",
      patientId: data["patientId"] ?? "",
      patientName: UserName.fromMap(data["patientName"] ?? {}),
      patientSex: data["patientSex"] ?? Sex.Unknown,
      patientDob: new Date(data["patientDob"]),
      patientAddress: Address.fromMap(data["patientAddress"] ?? {}),
      patientIsHomeless: data["patientIsHomeless"] ?? false,
      patientPolicyNumber: data["patientPolicyNumber"] ?? "",
      patientPolicyGroupNumber: data["patientPolicyGroupNumber"] ?? "",
      billingProvBusinessName: data["billingProvBusinessName"] ?? "",
      billingProvAddress: Address.fromMap(data["billingProvAddress"] ?? {}),
      billingProvPhoneNumber: data["billingProvPhoneNumber"] ?? "",
      billingProvIdentifierAtClearinghouse:
        data["billingProvIdentifierAtClearinghouse"] ?? "",
      billingProvTIN: data["billingProvTIN"] ?? "",
      billingProvNPI: data["billingProvNPI"] ?? "",
      billingProvTaxonomyCode: data["billingProvTaxonomyCode"] ?? "",
      referringProvName: UserName.fromMap(data["referringProvName"] ?? {}),
      referringProvNPI: data["referringProvNPI"] ?? "",
      supervisingPhysId: data["supervisingPhysId"] ?? "",
      supervisingPhysName: UserName.fromMap(data["supervisingPhysName"] ?? {}),
      supervisingPhysAddress: Address.fromMap(
        data["supervisingPhysAddress"] ?? {}
      ),
      supervisingPhysNPI: data["supervisingPhysNPI"] ?? "",
      renderingProvId: data["renderingProvId"] ?? "",
      renderingProvName: UserName.fromMap(data["renderingProvName"] ?? {}),
      renderingProvNPI: data["renderingProvNPI"] ?? "",
      serviceLocationName: data["serviceLocationName"] ?? "",
      serviceLocationAddress: Address.fromMap(
        data["serviceLocationAddress"] ?? {}
      ),
      billableEventId: data["billableEventId"] ?? "",
      billableItemId: data["billableItemId"] ?? "",
      services: data["services"]
        ? data["services"].map((element: string) =>
            ClaimCHWServiceTypeHelper.valueOf(element)
          )
        : [],
      serviceDetails: data["serviceDetails"] ?? "",
      serviceDate: new Date(data["serviceDate"] ?? ""),
      serviceDuration: data["serviceDuration"] ?? 30,
      serviceUnitCount: data["serviceUnitCount"] ?? 1,
      serviceCostPerUnit: data["serviceCostPerUnit"] ?? 0,
      icd10Code: data["icd10Code"] ?? "I10", //! Temporary defaults
      billingCode: data["billingCode"] ?? "98960", //! Temporary defaults
      billingModifier: data["billingModifier"] ?? "U2", //! Temporary defaults
      chwAttestationDate: new Date(data["chwAttestationDate"] ?? ""),
      latestEDIDownloadDate: data["latestEDIDownloadDate"]
        ? new Date(data["latestEDIDownloadDate"])
        : null,
      invoiceNumber: data["invoiceNumber"] ?? null,
      invoiceDate: data["invoiceDate"] ? new Date(data["invoiceDate"]) : null,
      currentStatus:
        ClaimStatusHelper.valueOf(data["currentStatus"]) ?? ClaimStatus.Unknown,
      claimStatusChanges: data["claimStatusChanges"]
        ? data["claimStatusChanges"].map((element: any) =>
            ClaimStatusChange.fromMap(element)
          )
        : [],
      patientControlNumber: data["patientControlNumber"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      placeBasedCareProvId: this.placeBasedCareProvId,
      payerId: this.payerId,
      payerBusinessName: this.payerBusinessName,
      payerIdentifierAtClearinghouse: this.payerIdentifierAtClearinghouse,
      payerParticipationCode: this.payerParticipationCode,
      patientId: this.patientId,
      patientName: this.patientName.toJson(),
      patientSex: this.patientSex,
      patientDob: this.patientDob.toISOString(),
      patientAddress: this.patientAddress.toJson(),
      patientIsHomeless: this.patientIsHomeless,
      patientPolicyNumber: this.patientPolicyNumber,
      patientPolicyGroupNumber: this.patientPolicyGroupNumber,
      billingProvBusinessName: this.billingProvBusinessName,
      billingProvAddress: this.billingProvAddress.toJson(),
      billingProvPhoneNumber: this.billingProvPhoneNumber,
      billingProvIdentifierAtClearinghouse:
        this.billingProvIdentifierAtClearinghouse,
      billingProvTIN: this.billingProvTIN,
      billingProvNPI: this.billingProvNPI,
      billingProvTaxonomyCode: this.billingProvTaxonomyCode,
      referringProvName: this.referringProvName.toJson(),
      referringProvNPI: this.referringProvNPI,
      supervisingPhysId: this.supervisingPhysId,
      supervisingPhysName: this.supervisingPhysName.toJson(),
      supervisingPhysAddress: this.supervisingPhysAddress.toJson(),
      supervisingPhysNPI: this.supervisingPhysNPI,
      renderingProvId: this.renderingProvId,
      renderingProvName: this.renderingProvName.toJson(),
      renderingProvNPI: this.renderingProvNPI,
      serviceLocationName: this.serviceLocationName,
      serviceLocationAddress: this.serviceLocationAddress.toJson(),
      billableEventId: this.billableEventId,
      billableItemId: this.billableItemId,
      services: this.services.map((e) => e.toString()),
      serviceDetails: this.serviceDetails,
      serviceDate: this.serviceDate.toISOString(),
      serviceDuration: Number(this.serviceDuration),
      serviceUnitCount: Number(this.serviceUnitCount),
      serviceCostPerUnit: Number(this.serviceCostPerUnit),
      icd10Code: this.icd10Code,
      billingCode: this.billingCode,
      billingModifier: this.billingModifier,
      chwAttestationDate: this.chwAttestationDate.toISOString(),
      latestEDIDownloadDate: this.latestEDIDownloadDate?.toISOString() ?? null,
      invoiceNumber: this.invoiceNumber ?? null,
      invoiceDate: this.invoiceDate?.toISOString() ?? null,
      currentStatus: this.currentStatus,
      claimStatusChanges: this.claimStatusChanges.map((element) =>
        element.toJson()
      ),
      patientControlNumber: this.patientControlNumber
    };
  }
}
