import React, { createContext, ReactNode, useReducer } from "react";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { authReducer, IAuthState, initialState } from "@/reducers/authReducer";
import { PlaceBasedCareProvService } from "@oben-core-web/services/place-based-care-prov-service";
import { datadogRum } from "../../datadog";
import { PlaceBasedCareProv } from "@oben-core-web/models/place-based-care-prov";

interface AuthContextType {
  state: IAuthState;
  onLogin: (user: StaffMember) => void;
  beforeLogout: () => void;
  setDetachAuthListener: (detach: () => void) => void;
  setFirstTimeLogin: (isFirstTimeLogin: boolean) => void;
  setPlaceBasedCareProvider: (
    placeBasedCareProvider: PlaceBasedCareProv | null
  ) => void;
}

// Create AuthContext
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

// AuthProvider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const onLogin = async (user: StaffMember) => {
    datadogRum.setUser({
      id: user.uid,
      email: user.email,
      name: user.name.fullName,
      userType: user.userType
    });
    const placeBasedCareProviderService = new PlaceBasedCareProvService();
    const placeBasedCareProvider =
      await placeBasedCareProviderService.getPlaceBasedCareProv(
        user.placeBasedCareProvId
      );
    dispatch({
      type: "SET_PLACE_BASED_CARE_PROVIDER",
      payload: { placeBasedCareProvider }
    });
    dispatch({ type: "ON_LOGIN", payload: { currentUser: user } });
  };

  const setFirstTimeLogin = (isFirstTimeLogin: boolean) => {
    dispatch({
      type: "SET_FIRST_TIME_LOGIN",
      payload: {
        isFirstTimeLogin
      }
    });
  };
  const beforeLogout = () => {
    dispatch({ type: "BEFORE_LOGOUT" });
    datadogRum.clearUser();
    datadogRum.stopSession();
  };

  const setDetachAuthListener = (detach: () => void) => {
    dispatch({
      type: "SET_DETACH_LISTENER",
      payload: { detachAuthListener: detach }
    });
  };

  const setPlaceBasedCareProvider = (
    placeBasedCareProvider: PlaceBasedCareProv | null = null
  ) => {
    dispatch({
      type: "SET_PLACE_BASED_CARE_PROVIDER",
      payload: {
        placeBasedCareProvider
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        onLogin,
        setFirstTimeLogin,
        beforeLogout,
        setDetachAuthListener,
        setPlaceBasedCareProvider
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
