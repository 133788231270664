import { DocumentSnapshot } from "firebase/firestore";

// import { CoreEnums, ProfileTag, TagChangeAction } from '../constants/core-enums';
import {
  ContactMethod,
  ContactMethodHelper,
  Race,
  RaceHelper,
  Sex,
  SexHelper,
  SourceForCHWFunds,
  SourceForCHWFundsHelper
} from "../constants/core-enums";
// import { TagEnums } from '../constants/tag-enums';
// import { WELLNESS-CATEGORIES-LIST } from '../selection-lists/wellness-categories-list';
// import { BpReadingService } from '../services/bp-reading-service';
import { PatientService } from "../services/patient-service";
// import { JournalService } from '../services/journal-service';
// import { JourneyService } from '../services/journey-service';
// import { CoreUtils } from '../utils/core-utils';
import { Address } from "./address";
import { AppInfo } from "./app-info";
import { ASCVDRisk } from "./ascvd-risk";
import { BpReading } from "./bp-reading";
import { CholesterolReading } from "./cholesterol-reading";
// import { PatientBpReminder } from './patient-user-bp-reminder';
// import { PatientCurrentJourney } from './patient-user-current-journey';
// import { PatientWellnessProfiles } from './patient-user-wellness-profiles';
// import { JourneyContent } from './journey-content';
import { PlatformInfo } from "./platform-info";
// import { TagJournalEntry } from './tag-journal-entry';
import { UserName } from "./user-name";
import { BpGoal } from "./bp-goal";
// import { WellnessCategoryDetail } from './wellness-category-detail';

export interface IPatientData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string;
  enabled: boolean;
  pwdNeedsReset: boolean;
  trackingConsent: boolean | null;
  // mobile-specific fields
  appInfo: AppInfo;
  platformInfo: PlatformInfo;
  // patient-specific fields
  // avatar: PatientAvatar; // TODO: Uncomment this line after creating the PatientAvatar class
  sex: Sex | null;
  race: Race | null;
  dob: Date;
  address: Address | null;
  isHomeless: boolean;
  registrationDate: Date | null;
  onboardingDate: Date | null; // date that the user completed their onboarding
  enrollmentDate: Date | null; // date that the user completed their onboarding
  currentProgramStartDate: Date | null; // midnight-based; date of first "activity" in the current 2-week cycle
  lastActivityDate: Date | null;
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string;
  payerPolicyNumber: string | null;
  payerGroupNumber: string | null;
  approvedBenefitsAssignmentDate: Date | null;
  primaryCareMRN: string | null;
  placeBasedCareMRN: string | null;
  medicaidNumber: string | null;
  sourceForCHWFunds: SourceForCHWFunds | null;
  availableCHWUnits: number | null;
  // currentJourney: PatientCurrentJourney;
  journeyIsComplete: boolean;
  // currentWellnessProfiles: PatientWellnessProfiles;
  latestBpReading: BpReading | null; // de-normalized from BpReading collection
  latestCholesterolReading: CholesterolReading | null; // de-normalized from CholesterolReading collection
  latestASCVDRisk: ASCVDRisk | null; // de-normalized from ASCVDRisk collection
  currentCarePlanId: string | null;
  currentBpGoal: BpGoal | null;
  // profileTags: ProfileTag[];
  // bpReminder: PatientBpReminder;
  // shownBpSummaryDates: string[]; // logged/shown bp duration dates(2 week data)
  firstTimeLoginPopup: boolean;
  hasShownWelcomeVideoOnce: boolean;
  viewedContent: string[];
  // focusArea: WellnessCategory | null;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  prefContactMethod: ContactMethod;
  prefApptTimes: string[];
  prefServiceLocationId: string | null;
  prefCHWId: string | null;
  hasRecommendationLetter: boolean;
  needsEnrollment: boolean;
}

export class Patient {
  // common fields
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string;
  enabled: boolean;
  pwdNeedsReset: boolean;
  trackingConsent: boolean | null;
  // mobile-specific fields
  appInfo: AppInfo;
  platformInfo: PlatformInfo;
  // patient-specific fields
  // avatar: PatientAvatar; // TODO: Uncomment this line after creating the PatientAvatar class
  sex: Sex | null;
  race: Race | null;
  dob: Date;
  address: Address | null;
  isHomeless: boolean;
  registrationDate: Date | null;
  onboardingDate: Date | null; // date that the user completed their onboarding
  enrollmentDate: Date | null; // date that the user completed their onboarding
  currentProgramStartDate: Date | null; // midnight-based; date of first "activity" in the current 2-week cycle
  lastActivityDate: Date | null;
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string;
  payerPolicyNumber: string | null;
  payerGroupNumber: string | null;
  approvedBenefitsAssignmentDate: Date | null;
  primaryCareMRN: string | null;
  placeBasedCareMRN: string | null;
  medicaidNumber: string | null;
  sourceForCHWFunds: SourceForCHWFunds | null;
  availableCHWUnits: number | null;
  // currentJourney: PatientCurrentJourney;
  journeyIsComplete: boolean;
  // currentWellnessProfiles: PatientWellnessProfiles;
  latestBpReading: BpReading | null; // latest BP reading
  latestCholesterolReading: CholesterolReading | null;
  latestASCVDRisk: ASCVDRisk | null;
  currentCarePlanId: string | null;
  currentBpGoal: BpGoal | null;
  // profileTags: ProfileTag[];
  // bpReminder: PatientBpReminder;
  // shownBpSummaryDates: string[]; // logged/shown bp duration dates(2 week data)
  firstTimeLoginPopup: boolean;
  hasShownWelcomeVideoOnce: boolean;
  viewedContent: string[];
  // focusArea: WellnessCategory | null;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  prefContactMethod: ContactMethod;
  prefApptTimes: string[];
  prefServiceLocationId: string | null;
  prefCHWId: string | null;
  hasRecommendationLetter: boolean;
  needsEnrollment: boolean;
  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    trackingConsent,
    appInfo,
    platformInfo,
    // avatar, // TODO: Uncomment this line after creating the PatientAvatar class
    sex,
    race,
    dob,
    address,
    isHomeless,
    registrationDate,
    onboardingDate,
    enrollmentDate,
    currentProgramStartDate,
    lastActivityDate,
    payerId,
    primaryCareProvId,
    placeBasedCareProvId,
    payerPolicyNumber,
    payerGroupNumber,
    approvedBenefitsAssignmentDate,
    primaryCareMRN,
    placeBasedCareMRN,
    medicaidNumber,
    sourceForCHWFunds,
    availableCHWUnits,
    // currentJourney,
    journeyIsComplete,
    // currentWellnessProfiles,
    latestBpReading,
    latestCholesterolReading,
    latestASCVDRisk,
    currentCarePlanId,
    currentBpGoal,
    // profileTags,
    // bpReminder,
    // shownBpSummaryDates,
    firstTimeLoginPopup,
    hasShownWelcomeVideoOnce,
    viewedContent,
    // focusArea,
    isTreatedForHypertension,
    isDiabetic,
    isSmoker,
    prefContactMethod,
    prefApptTimes,
    prefServiceLocationId,
    prefCHWId,
    hasRecommendationLetter,
    needsEnrollment
  }: IPatientData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    this.trackingConsent = trackingConsent;
    this.appInfo = appInfo;
    this.platformInfo = platformInfo;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the PatientAvatar class
    this.sex = sex;
    this.race = race;
    this.dob = dob;
    this.address = address;
    this.isHomeless = isHomeless;
    this.registrationDate = registrationDate;
    this.onboardingDate = onboardingDate;
    this.enrollmentDate = enrollmentDate;
    this.currentProgramStartDate = currentProgramStartDate;
    this.lastActivityDate = lastActivityDate;
    this.payerId = payerId;
    this.primaryCareProvId = primaryCareProvId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.payerPolicyNumber = payerPolicyNumber;
    this.payerGroupNumber = payerGroupNumber;
    this.approvedBenefitsAssignmentDate = approvedBenefitsAssignmentDate;
    this.primaryCareMRN = primaryCareMRN;
    this.placeBasedCareMRN = placeBasedCareMRN;
    this.medicaidNumber = medicaidNumber;
    this.sourceForCHWFunds = sourceForCHWFunds;
    this.availableCHWUnits = availableCHWUnits;
    // this.currentJourney = currentJourney;
    this.journeyIsComplete = journeyIsComplete;
    // this.currentWellnessProfiles = currentWellnessProfiles;
    this.latestBpReading = latestBpReading;
    this.latestCholesterolReading = latestCholesterolReading;
    this.latestASCVDRisk = latestASCVDRisk;
    this.currentCarePlanId = currentCarePlanId;
    this.currentBpGoal = currentBpGoal;
    // this.profileTags = profileTags;
    // this.bpReminder = bpReminder;
    // this.shownBpSummaryDates = shownBpSummaryDates;
    this.firstTimeLoginPopup = firstTimeLoginPopup;
    this.hasShownWelcomeVideoOnce = hasShownWelcomeVideoOnce;
    this.viewedContent = viewedContent;
    // this.focusArea = focusArea;
    this.isTreatedForHypertension = isTreatedForHypertension;
    this.isDiabetic = isDiabetic;
    this.isSmoker = isSmoker;
    this.prefContactMethod = prefContactMethod;
    this.prefApptTimes = prefApptTimes;
    this.prefServiceLocationId = prefServiceLocationId;
    this.prefCHWId = prefCHWId;
    this.hasRecommendationLetter = hasRecommendationLetter;
    this.needsEnrollment = needsEnrollment;
  }

  //
  // Getters
  //

  get isMeetingBpGoal(): boolean {
    if (this.latestBpReading === null || this.currentBpGoal === null)
      return false;
    return (
      this.latestBpReading.systolic <= this.currentBpGoal.systolic &&
      this.latestBpReading.diastolic <= this.currentBpGoal.diastolic
    );
  }

  // TODO: Remove? Currently not used (also see 'numberOfDaysEnrolled' below for similar calculation)
  get dayInProgram(): number {
    if (!this.currentProgramStartDate) {
      return 0;
    } else {
      const startDate = new Date(this.currentProgramStartDate); // should be midnight-based
      if (isNaN(startDate.getTime())) {
        return 0;
      } else {
        return (
          Math.floor(
            (Date.now() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          ) + 1
        ); // add one because .floor returns whole days
      }
    }
  }

  get numberOfDaysEnrolled(): number {
    if (!this.enrollmentDate) {
      return 0;
    } else {
      const startDate = new Date(this.enrollmentDate); // should be midnight-based
      if (isNaN(startDate.getTime())) {
        return 0;
      } else {
        return (
          Math.floor(
            (Date.now() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          ) + 1
        ); // add one because .floor returns whole days
      }
    }
  }

  get isOnboardingComplete(): boolean {
    return !(
      (
        !this.name.display ||
        !this.name.first ||
        !this.name.last ||
        !this.sex || // simple field
        !this.race || // simple field
        !this.dob || // simple field
        !this.phoneNumber
      ) // simple field // complex field (list)
      // TODO: re-enable these checks when the fields are added back or remove them if they are obsolete
      // ||
      // !(this.currentWellnessProfiles?.medical.onboardingIsComplete ?? false) ||
    );
  }

  // get selectedGoals(): string[] {
  //   const goals: string[] = [];
  //   WELLNESS_CATEGORIES_LIST.forEach(detail => {
  //     switch (detail.category) {
  //       case WellnessCategory.Exercise:
  //         if (this.currentWellnessProfiles?.exercise.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //       case WellnessCategory.Nutrition:
  //         if (this.currentWellnessProfiles?.nutrition.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //       case WellnessCategory.Stress:
  //         if (this.currentWellnessProfiles?.stress.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //     }
  //   });
  //   return goals;
  // }

  get programDurationIsComplete(): boolean {
    // TODO: select which date to use as "start date"
    return false;
  }

  // Determine if bp has been entered today.
  get hasEnteredBpForToday(): boolean {
    return this.latestBpReading?.wasToday ?? false;
  }

  // // Method is not currently used
  // hasCondition(value: string): boolean {
  //   return this.currentWellnessProfiles?.medical.a1medicalConditions?.includes(value) ?? false;
  // }

  // Delete the user (and all their data) from the Firestore database
  async delete(): Promise<void> {
    const db = new PatientService();
    await db.deletePatient(this.uid);
  }

  // // Methods for manipulating (setting/clearing) profile tags
  // clearTag(tag: ProfileTag): void {
  //   if (this.profileTags.includes(tag)) {
  //     this.profileTags = this.profileTags.filter(item => item !== tag);
  //     this._logTagChange(tag, TagChangeAction.Clear);
  //   }
  // }

  // setTag(tag: ProfileTag): void {
  //   if (!this.profileTags.includes(tag)) {
  //     this.profileTags.push(tag);
  //     this._logTagChange(tag, TagChangeAction.Set);
  //   }
  // }

  // _logTagChange(tag: ProfileTag, action: TagChangeAction): void {
  //   const _journalDb = new JournalService();
  //   const journalEntry = new TagJournalEntry({
  //     timestamp: new Date().toISOString(),
  //     tag: CoreUtils.enumToString(tag),
  //     action: CoreUtils.enumToString(action),
  //   });
  //   _journalDb.addJournalEntry(this.uid, journalEntry); // will complete asynchronously
  // }

  async updateDb(): Promise<void> {
    const db = new PatientService();
    await db.updatePatient(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Patient {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return Patient.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): Patient {
    const userName: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string = data["phoneNumber"] ?? "";
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset: boolean = data["pwdNeedsReset"] ?? false;
    const trackingConsent: boolean | null = data["trackingConsent"] ?? null;
    const appInfo: AppInfo = AppInfo.fromMap(data["appInfo"] ?? {});
    const platformInfo: PlatformInfo = PlatformInfo.fromMap(data["platformInfo"] ?? {});
    // const avatar: PatientAvatar = PatientAvatarHelper.valueOf(data["avatar"] ?? "") ?? PatientAvatar.zero;
    const sex: Sex | null = SexHelper.valueOf(data["sex"] ?? null) ?? null;
    const race: Race | null = RaceHelper.valueOf(data["race"] ?? null) ?? null;
    const dob: Date = data["dob"] // special case for DOB (stored w/o time)
      ? new Date(data["dob"])
      : new Date(1, 0, 1); // default to 1901-Jan-01
    const address: Address | null = data["address"]
      ? Address.fromMap(data["address"])
      : null;
    const isHomeless: boolean = data["isHomeless"] ?? false;
    const registrationDate: Date | null = data["registrationDate"]
      ? new Date(data["registrationDate"])
      : null;
    const onboardingDate: Date | null = data["onboardingDate"]
      ? new Date(data["onboardingDate"])
      : null;
    const enrollmentDate: Date | null = data["enrollmentDate"]
      ? new Date(data["enrollmentDate"])
      : null;
    const currentProgramStartDate: Date | null = data["currentProgramStartDate"]
      ? new Date(data["currentProgramStartDate"])
      : null;
    const lastActivityDate: Date | null = data["lastActivityDate"]
      ? new Date(data["lastActivityDate"])
      : null;
    const payerId: string | null = data["payerId"] ?? null;
    const primaryCareProvId: string | null = data["primaryCareProvId"] ?? null;
    const placeBasedCareProvId: string = data["placeBasedCareProvId"] ?? "";
    const payerPolicyNumber: string | null = data["payerPolicyNumber"] ?? null;
    const payerGroupNumber: string | null = data["payerGroupNumber"] ?? null;
    const approvedBenefitsAssignmentDate: Date | null = data["approvedBenefitsAssignmentDate"] ?? null;
    const primaryCareMRN: string | null = data["primaryCareMRN"] ?? null;
    const placeBasedCareMRN: string | null = data["placeBasedCareMRN"] ?? null;
    const medicaidNumber: string | null = data["medicaidNumber"] ?? null;
    const sourceForCHWFunds: SourceForCHWFunds | null =
      SourceForCHWFundsHelper.valueOf(data["sourceForCHWFunds"] ?? null) ??
      null;
    const availableCHWUnits: number | null = data["availableCHWUnits"] ?? null;
    // const currentJourney: PatientCurrentJourney = PatientCurrentJourney.fromMap(data["currentJourney"] ?? {});
    const journeyIsComplete: boolean = data["journeyIsComplete"] ?? false;
    // const currentWellnessProfiles: PatientWellnessProfiles = PatientWellnessProfiles.fromMap(data["currentWellnessProfiles"] ?? {});
    const latestBpReading: BpReading | null = data["latestBpReading"]
      ? BpReading.fromMap("unusedField", data["latestBpReading"])
      : null;
    const latestCholesterolReading: CholesterolReading | null = data["latestCholesterolReading"]
      ? CholesterolReading.fromMap("unusedField", data["latestCholesterolReading"])
      : null;
    const latestASCVDRisk: ASCVDRisk | null = data["latestASCVDRisk"]
      ? ASCVDRisk.fromMap("unusedField", data["latestASCVDRisk"])
      : null;
    const currentCarePlanId: string | null = data["currentCarePlanId"] ?? null;
    const currentBpGoal: BpGoal | null = data["currentBpGoal"]
        ? BpGoal.fromMap(data["currentBpGoal"])
        : null;
    // const profileTagsStrings: string[] = data["profileTags"] ?? [];
    // const profileTags: ProfileTag[] = [];
    // profileTagsStrings.forEach((element: string) => {
    //   const tag = ProfileTagHelper.valueOf(element);
    //   if (tag) {
    //     profileTags.push(tag);
    //   }
    // });
    // const bpReminder: PatientBpReminder | null = data["bpReminder"] ? PatientBpReminder.fromMap(data["bpReminder"]) : null;
    // const shownBpSummaryDates: string[] = data["shownBpSummaryDates"] ?? [];
    const firstTimeLoginPopup: boolean = data["firstTimeLoginPopup"] ?? false;
    const hasShownWelcomeVideoOnce: boolean = data["hasShownWelcomeVideoOnce"] ?? false;
    const viewedContent: string[] = data["viewedContent"] ?? [];
    // const focusArea: WellnessCategory = WellnessCategoryHelper.valueOf(data["focusArea"] ?? null) ?? null;
    const isTreatedForHypertension: boolean = data["isTreatedForHypertension"] ?? false;
    const isDiabetic: boolean = data["isDiabetic"] ?? false;
    const isSmoker: boolean = data["isSmoker"] ?? false;
    const prefContactMethod: ContactMethod = ContactMethodHelper.valueOf(data["prefContactMethod"] ?? null) ?? ContactMethod.Email;
    const prefApptTimes: string[] = data["prefApptTimes"] ?? [];
    const prefServiceLocationId: string | null = data["prefServiceLocationId"] ?? null;
    const prefCHWId: string | null = data["prefCHWId"] ?? null;
    const hasRecommendationLetter: boolean = data["hasRecommendationLetter"] ?? false;
    const needsEnrollment: boolean = data["needsEnrollment"] ?? false;

    return new Patient({
      uid: uid,
      name: userName,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      trackingConsent: trackingConsent,
      appInfo: appInfo,
      platformInfo: platformInfo,
      // avatar: avatar,
      sex: sex,
      race: race,
      dob: dob,
      address: address,
      isHomeless: isHomeless,
      registrationDate: registrationDate,
      onboardingDate: onboardingDate,
      enrollmentDate: enrollmentDate,
      currentProgramStartDate: currentProgramStartDate,
      lastActivityDate: lastActivityDate,
      payerId: payerId,
      primaryCareProvId: primaryCareProvId,
      placeBasedCareProvId: placeBasedCareProvId,
      payerPolicyNumber: payerPolicyNumber,
      payerGroupNumber: payerGroupNumber,
      approvedBenefitsAssignmentDate: approvedBenefitsAssignmentDate,
      primaryCareMRN: primaryCareMRN,
      placeBasedCareMRN: placeBasedCareMRN,
      medicaidNumber: medicaidNumber,
      sourceForCHWFunds: sourceForCHWFunds,
      availableCHWUnits: availableCHWUnits,
      // currentJourney: currentJourney,
      journeyIsComplete: journeyIsComplete,
      // currentWellnessProfiles: currentWellnessProfiles,
      latestBpReading: latestBpReading,
      latestCholesterolReading: latestCholesterolReading,
      latestASCVDRisk: latestASCVDRisk,
      currentCarePlanId: currentCarePlanId,
      currentBpGoal: currentBpGoal,
      // profileTags: profileTags,
      // bpReminder: bpReminder,
      // shownBpSummaryDates: shownBpSummaryDates,
      firstTimeLoginPopup: firstTimeLoginPopup,
      hasShownWelcomeVideoOnce: hasShownWelcomeVideoOnce,
      viewedContent: viewedContent,
      // focusArea: focusArea,
      isTreatedForHypertension: isTreatedForHypertension,
      isDiabetic: isDiabetic,
      isSmoker: isSmoker,
      prefContactMethod: prefContactMethod,
      prefApptTimes: prefApptTimes,
      prefServiceLocationId: prefServiceLocationId,
      prefCHWId: prefCHWId,
      hasRecommendationLetter: hasRecommendationLetter,
      needsEnrollment: needsEnrollment
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "uid": this.uid, // not necessary to save UID (it is already part of the Firestore document)
      name: this.name.toJson(),
      email: this.email,
      phoneNumber: this.phoneNumber,
      enabled: this.enabled,
      pwdNeedsReset: this.pwdNeedsReset,
      trackingConsent: this.trackingConsent ?? null,
      appInfo: this.appInfo.toJson(),
      platformInfo: this.platformInfo.toJson(),
      // "avatar": this.avatar.name,
      sex: this.sex ?? null,
      race: this.race ?? null,
      dob: this.dob.toLocaleDateString("en-CA"), // special case for DOB (stored w/o time)
      address: this.address?.toJson() ?? null,
      isHomeless: this.isHomeless,
      registrationDate: this.registrationDate?.toISOString() ?? null,
      onboardingDate: this.onboardingDate?.toISOString() ?? null,
      enrollmentDate: this.enrollmentDate?.toISOString() ?? null,
      currentProgramStartDate: this.currentProgramStartDate?.toISOString() ?? null,
      lastActivityDate: this.lastActivityDate?.toISOString() ?? null,
      payerId: this.payerId ?? null,
      primaryCareProvId: this.primaryCareProvId ?? null,
      placeBasedCareProvId: this.placeBasedCareProvId ?? null,
      payerPolicyNumber: this.payerPolicyNumber ?? null,
      payerGroupNumber: this.payerGroupNumber ?? null,
      approvedBenefitsAssignmentDate: this.approvedBenefitsAssignmentDate ?? null,
      primaryCareMRN: this.primaryCareMRN ?? null,
      placeBasedCareMRN: this.placeBasedCareMRN ?? null,
      medicaidNumber: this.medicaidNumber ?? null,
      sourceForCHWFunds: this.sourceForCHWFunds ?? null,
      availableCHWUnits: this.availableCHWUnits ?? null,
      // "currentJourney": this.currentJourney.toJson(),
      journeyIsComplete: this.journeyIsComplete,
      // "currentWellnessProfiles": this.currentWellnessProfiles.toJson(),
      latestBpReading: this.latestBpReading?.toJson() ?? null,
      latestCholesterolReading: this.latestCholesterolReading?.toJson() ?? null,
      latestASCVDRisk: this.latestASCVDRisk?.toJson() ?? null,
      currentCarePlanId: this.currentCarePlanId ?? null,
      currentBpGoal: this.currentBpGoal?.toJson() ?? null,
      // "profileTags": this.profileTags,
      // "bpReminder": this.bpReminder?.toJson(),
      // "shownBpSummaryDates": [...this.shownBpSummaryDates],
      firstTimeLoginPopup: this.firstTimeLoginPopup,
      hasShownWelcomeVideoOnce: this.hasShownWelcomeVideoOnce,
      viewedContent: [...this.viewedContent],
      // "focusArea": this.focusArea ? CoreUtils.enumToString(this.focusArea) : null,
      isTreatedForHypertension: this.isTreatedForHypertension,
      isDiabetic: this.isDiabetic,
      isSmoker: this.isSmoker,
      prefContactMethod: this.prefContactMethod,
      prefApptTimes: [...this.prefApptTimes],
      prefServiceLocationId: this.prefServiceLocationId ?? null,
      prefCHWId: this.prefCHWId ?? null,
      hasRecommendationLetter: this.hasRecommendationLetter,
      needsEnrollment: this.needsEnrollment
    };
  }
}
