import useCurrentUser from "@/hooks/useCurrentUser";
import useProgramStaffMembers from "@/hooks/useProgramStaffMembers";
import internalTaskSchema from "@/resolvers/internalTask";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Tooltip,
  IconButton,
  VStack,
  Button
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IInternalTaskData,
  InternalTask,
  InternalTaskType,
  TaskCreatorType
} from "@oben-core-web/models/internal-task";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import React from "react";
import { useForm } from "react-hook-form";
import { TbCalendarClock } from "react-icons/tb";

interface IInternalTaskForm {
  patientId: string;
}

const InternalTaskForm = ({ patientId }: IInternalTaskForm) => {
  const { currentUser } = useCurrentUser();
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const { staffMembers } = useProgramStaffMembers(
    currentUser!.placeBasedCareProvId
  );
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      id: "",
      placeBasedCareProvId: currentUser!.placeBasedCareProvId,
      internalTaskType: InternalTaskType.ScheduleAppointment,
      name: "Schedule Appointment",
      description: "",
      patientId,
      assigneeId: "",
      creatorId: currentUser!.uid,
      creatorType: TaskCreatorType.StaffMember,
      createdDate: new Date(),
      dueDate: new Date(),
      completionDate: null
    },
    resolver: yupResolver(internalTaskSchema) as any
  });

  const submissionHandler = async (data: IInternalTaskData) => {
    const internalTaskService = new InternalTaskService();
    const task = new InternalTask(data);

    await internalTaskService
      .addTask(task)
      .then(() => {
        toast({ status: "success", description: "Task created" });
        onToggle();
      })
      .catch((err) => {
        console.log("Failed to create internal task", err);
        toast({ status: "error", description: "Failed to create task" });
      });
  };

  return (
    <>
      <Tooltip label='Create Scheduling Task'>
        <IconButton
          onClick={onToggle}
          aria-label='create-scheduling-task-btn'
          icon={<TbCalendarClock size={20} />}
          variant={"ghost"}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(submissionHandler)}>
            <ModalHeader>Create Internal Task</ModalHeader>
            <ModalBody>
              <VStack>
                <FormControl isInvalid={!!formState.errors?.name}>
                  <FormLabel>Name</FormLabel>
                  <Input {...register("name")} />
                  <FormErrorMessage>
                    {formState.errors?.name?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formState.errors?.description}>
                  <FormLabel>Description</FormLabel>
                  <Input {...register("description")} />
                  <FormErrorMessage>
                    {formState.errors?.description?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formState.errors?.assigneeId}>
                  <FormLabel>Assigned To</FormLabel>
                  <Select {...register("assigneeId")}>
                    {staffMembers.map((wu) => (
                      <option
                        key={`task-assignee-opt-${wu.uid}`}
                        value={wu.uid}
                      >
                        {wu.name.fullName}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {formState.errors?.assigneeId?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formState.errors?.dueDate}>
                  <FormLabel>Due Date</FormLabel>
                  <Input {...register("dueDate")} type='date' />
                  <FormErrorMessage>
                    {formState.errors?.dueDate?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter display={"flex"} justifyContent={"space-between"}>
              <Button onClick={onToggle}>Cancel</Button>
              <Button type='submit' colorScheme='blue'>
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InternalTaskForm;
