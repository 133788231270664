import React from "react";
import { useAuthContext } from "@/hooks/useAuthContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useSteps,
  VStack
} from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { StaffMember } from "@oben-core-web/models/staff-member";
import { StaffMemberService } from "@oben-core-web/services/staff-member-service";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { BsHeartPulseFill } from "react-icons/bs";
import { FaUserMd } from "react-icons/fa";
import { FaScissors } from "react-icons/fa6";
import { DefaultWorkingHours } from "@oben-core-web/models/default-working-hours";
import { yupResolver } from "@hookform/resolvers/yup";
import staffMemberSchema from "@/resolvers/staffMember";
import {
  pharmacistOnboardingSchema,
  physicianOnboardingSchema,
  workingHourSchema
} from "@resolvers/userOnboarding";

type WorkingHoursFormData = {
  defaultWorkingHours: {
    [key: string]: {
      dayOfWeek: number;
      startTime: string;
      endTime: string;
    };
  };
};

interface IOnboardingForm {
  uid: string;
  name: { first: string; last: string; display: string };
  email: string;
  phoneNumber: string;
  enabled: boolean;
  pwdNeedsReset: boolean;
  userType: UserType;
  address: {
    street1: string | null;
    street2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  };
  npi: string;
  placeBasedCareProvId: string;
  isProgramAdmin: boolean;
  defaultWorkingHours: {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
  }[];
}

const StaffMemberOnboarding = () => {
  const { currentUser } = useCurrentUser();
  const {
    state: { isFirstTimeLogin }
  } = useAuthContext();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen:
      isFirstTimeLogin &&
      (currentUser!.userType === UserType.Pharmacist ||
        currentUser!.userType === UserType.Physician ||
        currentUser!.userType === UserType.ProgramManager)
  });
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 4
  });

  // Get the current values properly formatted for the form
  const workingHoursDefaults = getParsedWorkingHours(currentUser!).reduce(
    (acc, day) => {
      acc[day.dayOfWeek] = {
        dayOfWeek: day.dayOfWeek,
        startTime: day.startTime,
        endTime: day.endTime
      };
      return acc;
    },
    {} as WorkingHoursFormData["defaultWorkingHours"]
  );
  const formMethods = useForm<IOnboardingForm>({
    defaultValues: {
      ...currentUser!.toJson(),
      uid: currentUser!.uid ?? "",
      defaultWorkingHours: Object.values(workingHoursDefaults)
    },
    mode: "onChange",
    resolver: yupResolver(
      currentUser!.userType === UserType.Pharmacist
        ? pharmacistOnboardingSchema
        : currentUser!.userType === UserType.Physician
        ? physicianOnboardingSchema
        : staffMemberSchema
    ) as any
  });
  const saveOnboardingForm = async () => {
    const formValues = formMethods.watch();
    const { defaultWorkingHours } = formValues;
    const filteredHours = defaultWorkingHours.filter(
      (dfw) => dfw.startTime && dfw.endTime
    );

    if (!currentUser || !formValues) return;

    const staffMember = StaffMember.fromMap(currentUser.uid, {
      ...formValues,
      defaultWorkingHours: filteredHours
        .map((workDay) => {
          if (workDay.startTime && workDay.endTime) {
            const [startHour, startMin] = workDay.startTime.split(":");
            const [endHour, endMin] = workDay.endTime.split(":");
            return new DefaultWorkingHours({
              dayOfWeek: workDay.dayOfWeek,
              startTime: new Date(
                1970,
                0,
                1,
                parseInt(startHour),
                parseInt(startMin),
                0,
                0
              ),
              endTime: new Date(
                1970,
                0,
                1,
                parseInt(endHour),
                parseInt(endMin),
                0,
                0
              )
            });
          } else {
            return null;
          }
        })
        .filter((r) => !!r)
    });
    const staffMemberService = new StaffMemberService();
    await staffMemberService.updateStaffMember(staffMember);
  };

  const steps = [
    {
      title: "Step 1",
      description: "Welcome",
      component: <ObenPharmacistWelcome />
    },
    {
      title: "Step 2",
      description: "Confirm Details",
      component: <UserProfileSetup />
    },
    {
      title: "Step 3",
      description: "Confirm Hours",
      component: <WorkingHourSetup />
    },
    {
      title: "Step 4",
      description: "Get Started",
      component: <PharmacistOnboardingSuccess onToggle={onToggle} />
    }
  ];

  // if (currentUser!.userType !== UserType.Pharmacist) {
  //   return <></>;
  // }
  return (
    <Modal isOpen={isOpen} onClose={onToggle} size={"full"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Welcome to Oben Health!</ModalHeader>
        <ModalBody>
          <SimpleGrid gridTemplateColumns={"20% 1fr"}>
            <div></div>
            <Center>
              {activeStep > 0 && activeStep < 3 && (
                <Heading as='h2' fontSize={"2xl"} mb={4}>
                  Let's get you set up
                </Heading>
              )}
            </Center>
          </SimpleGrid>
          <SimpleGrid gridTemplateColumns={"20% 1fr"} w={"full"}>
            <Stepper
              index={activeStep}
              orientation='vertical'
              height='50%'
              gap='0'
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <StepSeparator />
                  <Box>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </Step>
              ))}
            </Stepper>
            <Center w={"70%"} justifySelf={"center"}>
              <FormProvider {...formMethods}>
                {steps[activeStep].component}
              </FormProvider>
            </Center>
          </SimpleGrid>
        </ModalBody>
        {activeStep !== 3 && (
          <ModalFooter>
            <SimpleGrid gridTemplateColumns={"20% 1fr"} w={"full"}>
              <div></div>
              <HStack
                justifyContent='space-between'
                w={"70%"}
                justifySelf={"center"}
              >
                <Button onClick={goToPrevious}>Previous</Button>
                <Button
                  onClick={async () => {
                    const { trigger, setError, watch } = formMethods;
                    switch (activeStep) {
                      case 1: {
                        const isValid = await trigger([
                          "address",
                          "name",
                          "npi"
                        ]);
                        if (!isValid) return;
                        goToNext();
                        return;
                      }
                      case 2: {
                        const workingHours = watch("defaultWorkingHours");
                        const filteredHours = workingHours.filter(
                          (dfw) => dfw.startTime && dfw.endTime
                        );
                        // only run expanded validation on Pharmacists
                        if (
                          filteredHours.length === 0 &&
                          currentUser!.userType !== UserType.Pharmacist
                        ) {
                          await saveOnboardingForm();
                          goToNext();
                          return;
                        }
                        // 'early return' to ensure that there are hours to check
                        if (filteredHours.length === 0) {
                          setError("defaultWorkingHours", {
                            type: "custom",
                            message: "You must have at least one working hour"
                          });
                          return;
                        }
                        // check each hour against schema
                        for await (const hour of filteredHours) {
                          const hoursValid = await workingHourSchema.isValid(
                            hour
                          );
                          if (!hoursValid) {
                            // set error if we find an issue on a row
                            setError(
                              `defaultWorkingHours.${hour.dayOfWeek}.startTime`,
                              { type: "custom", message: "Invalid" }
                            );
                            setError(
                              `defaultWorkingHours.${hour.dayOfWeek}.endTime`,
                              { type: "custom", message: "Invalid" }
                            );
                          }
                        }
                        // once all filled rows have been checked submit.  save function will filter out empty rows
                        await saveOnboardingForm();
                        goToNext();
                        return;
                      }
                      default:
                        goToNext();
                        return;
                    }
                  }}
                  colorScheme='blue'
                >
                  Next
                </Button>
              </HStack>
            </SimpleGrid>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default StaffMemberOnboarding;

const ObenPharmacistWelcome = () => {
  return (
    <VStack alignItems='flex-start'>
      <Heading>Great to meet you</Heading>
      <Text>
        Welcome to the <b>Oben Health Web Portal</b> -- we're thrilled to have
        you on board.
      </Text>
      <Text>
        Oben Health is a connected system designed to help patients manage blood
        pressure and hypertension through three powerful applications:
      </Text>
      <List w='95%' alignSelf='center'>
        <ListItem>
          <ListIcon as={FaScissors} />
          Community Health Workers serve as the first point of contact, helping
          to bridge the gap between healthcare and the community. Through the{" "}
          <b>Community Health Worker app</b>, they can screen clients, perform
          blood pressure readings, and onboard new patients into the program —
          all while earning payments for their valuable role in preventive care.
        </ListItem>
        <ListItem>
          <ListIcon as={BsHeartPulseFill} />
          Patients take control of their health by recording blood pressure
          readings, tracking their medications, and completing tasks assigned in
          their personalized care plan. The <b>Patient app</b> provides
          reminders, educational resources, and progress tracking to help them
          stay engaged and on top of their well-being.
        </ListItem>
        <ListItem>
          <ListIcon as={FaUserMd} />
          Program staff have the tools they need to efficiently manage patient
          care. The <b>Web portal</b> allows them to schedule appointments,
          monitor patient progress, track key program metrics, and approve
          payments for Barber services. With real-time insights, staff can
          ensure that patients receive the support they need.
        </ListItem>
      </List>
      <Text>
        Together, these three applications create a seamless, community-driven
        system that connects patients, community health workers, and healthcare
        providers— making it easier to manage hypertension and improve long-term
        health outcomes.
      </Text>
      <Text>
        Ready to get started? In the next couple steps we will work on setting
        up your user profile. Don't worry if you dont have all of the
        information on hand, you can always edit your profile later.
      </Text>
    </VStack>
  );
};

const UserProfileSetup = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<IOnboardingForm>();
  return (
    <VStack
      alignItems='flex-start'
      w='full'
      border='1px solid lightgray'
      p={4}
      rounded='lg'
    >
      <Heading as='h2' fontSize='xl'>
        Confirm Details
      </Heading>
      <Text>
        First we'll make sure that we got all of your information correct.
      </Text>
      <HStack w='full'>
        <FormControl isInvalid={!!errors.name?.first}>
          <FormLabel>First Name</FormLabel>
          <Input placeholder='First Name' {...register("name.first")} />
          <FormErrorMessage>{errors.name?.first?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.name?.last}>
          <FormLabel>Last Name</FormLabel>
          <Input placeholder='Last Name' {...register("name.last")} />
          <FormErrorMessage>{errors.name?.last?.message}</FormErrorMessage>
        </FormControl>
      </HStack>
      <FormControl isInvalid={!!errors.phoneNumber}>
        <FormLabel>Phone Number</FormLabel>
        <Input placeholder='Phone Number' {...register("phoneNumber")} />
        <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
      </FormControl>
      <HStack w='full'>
        <FormControl isInvalid={!!errors.address?.street1}>
          <FormLabel>Street 1</FormLabel>
          <Input placeholder='Street 1' {...register("address.street1")} />
          <FormErrorMessage>
            {errors.address?.street1?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.address?.street2}>
          <FormLabel>Street 2</FormLabel>
          <Input placeholder='Street 2' {...register("address.street2")} />
          <FormErrorMessage>
            {errors.address?.street2?.message}
          </FormErrorMessage>
        </FormControl>
      </HStack>
      <HStack>
        <FormControl isInvalid={!!errors.address?.city}>
          <FormLabel>City</FormLabel>
          <Input placeholder='City' {...register("address.city")} />
          <FormErrorMessage>{errors.address?.city?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.address?.state}>
          <FormLabel>State</FormLabel>
          <Input placeholder='State' {...register("address.state")} />
          <FormErrorMessage>{errors.address?.state?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.address?.zip}>
          <FormLabel>Zip Code</FormLabel>
          <Input placeholder='Zip Code' {...register("address.zip")} />
          <FormErrorMessage>{errors.address?.zip?.message}</FormErrorMessage>
        </FormControl>
      </HStack>
      <FormControl w='30%' isInvalid={!!errors.npi}>
        <FormLabel>NPI</FormLabel>
        <Input placeholder='NPI' {...register("npi")} />
        <FormErrorMessage>{errors.npi?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

const WorkingHourSetup = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<IOnboardingForm>();

  return (
    <VStack
      alignItems='flex-start'
      w='full'
      border='1px solid lightgray'
      p={4}
      rounded='lg'
    >
      <Heading as='h2' fontSize='xl'>
        Set Working Hours
      </Heading>
      <Text mt={2}>
        Default working hours let your team know when you are available to take
        patient appointments.
      </Text>
      <Text>
        You can set these later, but you <i>must</i> set working hours before
        you can be assigned patients.
      </Text>
      <Text>For any day you are unavailable leave the row blank.</Text>
      <SimpleGrid columns={3} w='full' mt={4}>
        <Text as='b'>Day</Text>
        <Text as='b'>Start Time</Text>
        <Text as='b'>End Time</Text>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Monday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[1]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.1.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[1]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[1]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.1.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[1]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Tuesday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[2]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.2.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[2]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[2]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.2.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[2]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Wednesday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[3]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.3.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[3]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[3]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.3.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[3]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Thursday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[4]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.4.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[4]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[4]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.4.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[4]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Friday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[5]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.5.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[5]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[5]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.5.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[5]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Saturday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[6]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.6.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[6]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[6]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.6.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[6]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} w='full'>
        <Text alignSelf='flex-end' mb={2}>
          Sunday
        </Text>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[0]?.startTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.0.startTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[0]?.startTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours?.[0]?.endTime}>
          <Input
            type='time'
            w='80%'
            {...register("defaultWorkingHours.0.endTime")}
          />
          <FormErrorMessage>
            {errors.defaultWorkingHours?.[0]?.endTime?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.defaultWorkingHours}>
          <FormErrorMessage>
            {errors.defaultWorkingHours?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </VStack>
  );
};

const getParsedWorkingHours = (staffMember: StaffMember) => {
  const daysOfWeek = [
    { day: 0, name: "Sunday" },
    { day: 1, name: "Monday" },
    { day: 2, name: "Tuesday" },
    { day: 3, name: "Wednesday" },
    { day: 4, name: "Thursday" },
    { day: 5, name: "Friday" },
    { day: 6, name: "Saturday" }
  ];
  return daysOfWeek.map((d) => {
    const prefilledDay = findPrefilledWorkDay(staffMember, d.day);
    return {
      dayOfWeek: d.day,
      startTime: prefilledDay
        ? DateTime.fromJSDate(prefilledDay.startTime).toFormat("HH:mm")
        : "",
      endTime: prefilledDay
        ? DateTime.fromJSDate(prefilledDay.endTime).toFormat("HH:mm")
        : ""
    };
  });
};

const findPrefilledWorkDay = (staffMember: StaffMember, day: number) => {
  return staffMember.defaultWorkingHours.find((dwh) => dwh.dayOfWeek === day);
};

const PharmacistOnboardingSuccess = ({
  onToggle
}: {
  onToggle: () => void;
}) => {
  return (
    <Center
      w={"full"}
      h={"80vh"}
      flexDir={"column"}
      border={"1px solid"}
      rounded='lg'
    >
      <Heading as='h2' fontSize={"xl"}>
        You're all done!
      </Heading>
      <Text my={4} w={"50%"}>
        Onboarding has been completed succesfully. If you ever need to change
        any of details you entered here, use the edit form found in your user
        profile.
      </Text>
      <Button onClick={onToggle} colorScheme='teal' size='sm'>
        Go to app
      </Button>
    </Center>
  );
};
