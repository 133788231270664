import useCurrentUser from "@/hooks/useCurrentUser";
import {
  Box,
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from "@chakra-ui/react";
import React, { useState } from "react";
import AppHeader from "../organisms/AppHeader";
import AdminProgramManagement from "../organisms/AdminProgramManagement";
import AdminServiceLocationManagement from "../organisms/AdminServiceLocationManagement";
import AdminCHWManagement from "../organisms/AdminCHWManagement";
import AdminStaffMemberManagement from "../organisms/AdminStaffMemberManagement";
import AdminTaskBaseManagement from "../organisms/AdminTaskBaseManagement";
import AdminMedBaseManagement from "../organisms/AdminMedBaseManagement";

const AdminView = () => {
  const { currentUser } = useCurrentUser();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  if (!currentUser?.isProgramAdmin) {
    return (
      <Center h={"100vh"} w={"100vw"}>
        <Text>403: Unauthorized</Text>
      </Center>
    );
  }
  return (
    <VStack
      h={"100vh"}
      w={"100vw"}
      p={4}
      bg='blue.50'
      border={"4px solid"}
      borderColor={"blue.200"}
    >
      <Box h={"4.5rem"} w={"full"}>
        <AppHeader />
      </Box>
      <Tabs
        h={"full"}
        w={"full"}
        variant={"enclosed"}
        isLazy={true}
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList bg={"blue.50"}>
          <Tab bg={"white"}>Program</Tab>
          <Tab bg={"white"}>Users</Tab>
          <Tab bg={"white"}>Service Locations</Tab>
          <Tab bg={"white"}>Community Health Workers</Tab>
          <Tab bg={"white"}>Patient Task Bases</Tab>
          <Tab bg={"white"}>Medication Bases</Tab>
        </TabList>
        <TabPanels bg={"white"} h={"90%"}>
          <TabPanel h={"full"}>
            <AdminProgramManagement handleTabsChange={handleTabsChange} />
          </TabPanel>
          <TabPanel h={"full"}>
            <AdminStaffMemberManagement />
          </TabPanel>
          <TabPanel h={"full"}>
            <AdminServiceLocationManagement />
          </TabPanel>
          <TabPanel h={"full"}>
            <AdminCHWManagement />
          </TabPanel>
          <TabPanel h={"full"}>
            <AdminTaskBaseManagement />
          </TabPanel>
          <TabPanel h={"full"}>
            <AdminMedBaseManagement />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default AdminView;
