import { useCallback, useEffect, useState } from "react";
import { Outreach } from "@oben-core-web/models/outreach";
import { OutreachService } from "@oben-core-web/services/outreach-service";

const useOutreach = (patientId: string) => {
  const [outreaches, setOutreaches] = useState<Outreach[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const fetchOutreaches = useCallback(async (patientId: string) => {
    try {
      setLoading(true);
      if (!patientId) return;
      const outreachService = new OutreachService();
      const outreachResult = await outreachService.getPatientOutreaches(
        patientId
      );
      setOutreaches(outreachResult);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (patientId) fetchOutreaches(patientId);
  }, [patientId, fetchOutreaches]);

  return { outreaches, fetchOutreaches, loading, error };
};

export default useOutreach;
