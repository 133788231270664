// import useCarePlan from '@/hooks/useCarePlan';
import usePatientCarePlans from "@/hooks/usePatientCarePlans";
import usePatientSubscription from "@/hooks/usePatientSubscription";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import CarePlanDetails from "../organisms/CarePlanDetails";

const CarePlan = () => {
  const { id, carePlanId } = useParams();
  const { patient } = usePatientSubscription(id);
  const { carePlans } = usePatientCarePlans(id);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  return (
    <Box>
      {!carePlan || !patient ? (
        <div>Loading...</div>
      ) : (
        <CarePlanDetails patientId={id!} carePlanId={carePlanId!} />
      )}
    </Box>
  );
};

export default CarePlan;
