import { PatientTaskBase } from "@oben-core-web/models/patient-task-base";
import { PatientTaskBaseService } from "@oben-core-web/services/patient-task-base-service";
import { useCallback, useEffect, useState } from "react";

const useProgramTaskBases = (placeBasedCareProvId: string) => {
  const [patientTaskBases, setPatientTaskBases] = useState<PatientTaskBase[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const patientTaskBaseService = new PatientTaskBaseService();
      const taskBases = await patientTaskBaseService.getAllPatientTaskBases(
        placeBasedCareProvId
      );
      setPatientTaskBases(taskBases);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [placeBasedCareProvId]);
  useEffect(() => {
    refetch();
  }, [refetch]);
  return { patientTaskBases, loading, error, refetch };
};

export default useProgramTaskBases;
